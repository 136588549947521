import styled from "styled-components"

export const Container = styled.main `
@media screen and (min-width: 1200px) {
    .initial-success h2 {
        width: 480px;
        text-align: center;
        margin-top: 20px !important;
        float: left;
        font-size: 30px;
        line-height: 50px;
    }
    .initial-success p {
        margin-top: 10px
    }
    .initial-success img {
        width: 60px;
        float: left;
    }
    .bt-link img {
        width: 10px;
    }
    .initial-success .bt-link {
        margin-top: 50px
    }
    .others-posts {
        width: 100%;
        float: left;
        display: flex;
        justify-content: center;
        padding-top: 50px;
        padding-bottom: 80px;
    }
    .others-posts .posts-recommended {
        margin-top: 50px
    }
    .post-default p {
        color: rgb(0, 0, 0) !important;
    }
}

@media screen and (max-width: 1199px) {
    .initial-success h2 {
        width: 480px;
        text-align: center;
        margin-top: 20px !important;
        float: left;
        font-size: 30px;
        line-height: 43px;
    }
    .initial-success p {
        margin-top: 10px;
        text-align: center;
        padding-left: 5px;
        padding-right: 5px;
    }
    .initial-success img {
        width: 60px;
        float: left;
    }
    .initial-success .bt-link {
        margin-top: 50px
    }
    .others-posts {
        width: 100%;
        float: left;
        display: flex;
        justify-content: center;
        padding-top: 50px;
        /* padding-bottom: 32px; */
    }
    .others-posts .posts-recommended {
        margin-top: 35px;
    }
    .initial-success {
        height: 700px !important;
    }
}
`;