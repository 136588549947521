import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import ReactInputMask from 'react-input-mask';

import Header from '../components/Header'
import Footer from '../components/Footer'
import Subhead from '../components/SubHead'
import Loading from '../components/Loading'
import { Container } from '../assets/css/oportunidades/styles-oportunidades.js'
import AnimationOpportunities from './AnimatinoOpportunities';
import api from '../service/api'
import { encryptData } from '../service/auth'
import { registerPage } from '../service/services';

export default function Opportunities() {
    const [t, i18n] = useTranslation(['opportunities', 'contact'])

    const [fileName, setFileName] = useState('');
    const [inputFileError, setInputFileError] = useState(false);
    const [inputNameError, setInputNameError] = useState(false)
    const [inputEmailError, setInputEmailError] = useState(false)
    const [inputPhoneError, setInputPhoneError] = useState(false)
    const [inputCellError, setInputCellError] = useState(false)    
    const [solicitationError, setSolicitationError] = useState(false)
    const [inputTerms, setInputTerms] = useState(false)
    const [inputTermsError, setInputTermsError] = useState(false)
    const [verifyInputsOnBlur, setVerifyInputsOnBlur] = useState(false)
    const inputNameRef = useRef('');
    const inputEmailRef = useRef('');
    const inputPhoneRef = useRef('');
    const inputCellRef = useRef('');
    const solicitationRef = useRef('')
    const inputFile = useRef('');
    const recaptchaRef = useRef('')

    useEffect(() => {
        AnimationOpportunities();
        registerPage(968);

        recaptchaRef.current.execute()
        api.get('/form-token').then(res => localStorage.setItem('services_form_token', res.data.formToken))
    }, [])

    useEffect(() => {
        verifyNameInput()
        verifyEmailInput()
        verifyPhoneInput()
        verifyFileInput()
        verifySolicitationInput()
    }, [verifyInputsOnBlur])

    const verifyNameInput = () => {
        if (verifyInputsOnBlur) {
            let inputName = inputNameRef.current.value.trim();
            if (inputName.length < 1) {
                setInputNameError(true);
            } else {
                setInputNameError(false);
            }
        }
    }
    const verifyPhoneInput = () => {
        if (verifyInputsOnBlur) {
            let inputPhone = inputPhoneRef.current.value
            let inputCell = inputCellRef.current.value
            if (inputPhone.replace(/[^0-9]/g, "").length === 10 || inputCell.replace(/[^0-9]/g, "").length === 11) {
                setInputCellError(false);
                setInputPhoneError(false);
            } else {
                setInputCellError(true);
                setInputPhoneError(true);
            }
        }
    }
    const verifySolicitationInput = () => {
        if (verifyInputsOnBlur) {
            let solicitation = solicitationRef.current.value.trim();
            if (solicitation.length < 1) {
                setSolicitationError(true);
            } else {
                setSolicitationError(false);
            }
        }
    }
    const onChangeTerms = () => {
        setInputTerms(!inputTerms);
        if (verifyInputsOnBlur) {
            if (inputTerms) {
                setInputTermsError(true)
            } else {
                setInputTermsError(false)
            }
        }
    }
    const verifyEmailInput = () => {
        if (verifyInputsOnBlur) {
            let inputEmail = inputEmailRef.current.value.trim();
            if (inputEmail.length < 1
                || inputEmail.lastIndexOf('@') === -1
                || inputEmail.lastIndexOf('.') === -1
                || inputEmail.lastIndexOf('@') === inputEmail.length - 1
                || inputEmail.lastIndexOf('.') === inputEmail.length - 1
                || inputEmail.lastIndexOf('@.') !== -1
            ) {
                setInputEmailError(true);
            } else {
                setInputEmailError(false);
            }
        }
    }

    const addFile = (e) => {
        const [file] = e.target.files;
        file ? setFileName(file.name) : setFileName('')
        verifyFileInput()
    }

    const removeFile = () => {
        inputFile.current.value = ''
        setFileName('')
        verifyFileInput()
    }

    const verifyFileInput = () => {
        if (verifyInputsOnBlur) {
            if (!inputFile.current.value) {
                setInputFileError(true)
            } else {
                setInputFileError(false)
            }
        }
    }

    const sendMessage = async (e) => {
        e.preventDefault();

        let nomeCompleto = inputNameRef.current.value;
        let assunto = '';
        let email = inputEmailRef.current.value.trim();
        let telefoneFixo = inputPhoneRef.current.value.replace(/[^0-9]/g, '');
        let celular = inputCellRef.current.value.replace(/[^0-9]/g, '');
        let solicitacao = solicitationRef.current.value.trim();
        let recaptcha = recaptchaRef.current.getValue();
        let file = inputFile.current.files[0];
        let fileName = inputFile.current.value;

        if (!recaptcha) {
            await recaptchaRef.current.executeAsync().then(res => recaptcha = res);
        }

        setVerifyInputsOnBlur(true);

        if (inputTerms && !inputNameError && !inputFileError && recaptcha
            && !inputEmailError && (!inputPhoneError || !inputCellError)
            && nomeCompleto && email && telefoneFixo && celular && solicitacao
            && file && fileName) {


            let contentMessage = {
                idModelo: 987,
                nomeCompleto,
                assunto: '',
                email,
                telefoneFixo,
                celular,
                solicitacao,
            }


            let form = new FormData();

            form.append('hasFile', true);
            form.append('file', file, fileName);
            form.append('grecaptcha', recaptcha);
            form.append('content', encryptData(JSON.stringify(contentMessage)))

            registerPage(974, email, nomeCompleto, solicitacao)
            await api.post('send-email', form).then(() => window.location.href = '/oportunidades-sucesso')


                .catch(err => console.log(err))
        }
        if (!inputTerms) {
            setInputTermsError(true)
        } else {
            setInputTermsError(false)
        }
    }

    return (
        <Container>
           {/* <Loading /> */}
            <Header />
            <div className="all-video-modal" id="video-andre" style={{ display: 'none' }}>
                <button className="bt-close-video"></button>
                <video controls id="item-video-andre">
                    <source type="video/mp4" src={`https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/videos/oportunidades/depoimentos/${i18n.language === 'en' ? 'en/' : ''}Andre.mp4`} />
                </video>
            </div>
            <div className="all-video-modal" id="video-rainielly" style={{ display: 'none' }}>
                <button className="bt-close-video"></button>
                <video controls id="item-video-rainielly">
                    <source type="video/mp4" src={`https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/videos/oportunidades/depoimentos/${i18n.language === 'en' ? 'en/' : ''}Rainielly.mp4`} />
                </video>
            </div>
            <div className="all-video-modal" id="video-karla" style={{ display: 'none' }}>
                <button className="bt-close-video"></button>
                <video controls id="item-video-karla">
                    <source type="video/mp4" src={`https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/videos/oportunidades/depoimentos/${i18n.language === 'en' ? 'en/' : ''}KarlaSilveira.mp4`} />
                </video>
            </div>
            <div className="all-video-modal" id="video-trabalhe-conosco" style={{ display: 'none' }}>
                <button className="bt-close-video"></button>
                <video controls id="item-video-trabalhe-conosco">
                    <source type="video/mp4" src={`https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/videos/oportunidades/${i18n.language === 'en' ? 'en/' : ''}TrabalheConosco.mp4`} />
                </video>
            </div>
            <content>
                <ReCAPTCHA
                    sitekey="6LfFjz4bAAAAAPnAKFFBAK1NrBgyi-1ZDHNP2hbs"
                    size="invisible"
                    ref={recaptchaRef}
                    badge="bottomleft"
                />
                <Subhead />
                <section className="area-initial-opportunities">
                    <div className="area-text-initial">
                        <div className="container-medium">
                            <h3 data-anime="bottom">{t('hero.title')}</h3>
                            <h1 data-anime="bottom-2">{t('hero.subtitle1')}<br />{t('hero.subtitle2')}<br /> {t('hero.subtitle3')}.</h1>
                            <div className="border-title" motion-bar="bar-1" id="border-initial">
                                <figure className="bg-pink"></figure>
                                <figure className="bg-ciano"></figure>
                                <figure className="bg-orange"></figure>
                            </div>
                        </div>
                        <div className="row center">
                            <button className="bt-down" id="bt-down-initial"></button>
                        </div>
                    </div>
                    <div className="area-bg-initial"></div>
                    <div className="area-video-initial">
                        <button className="bt-scroll ">
                            <img loading="lazy" src={`https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/icon-scroll${i18n.language === 'en' ? '-en2' : ''}.svg`} />
                        </button>
                        <video loop muted playsInline id="video-initial">
                            <source type="video/mp4" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/videos/oportunidades/resume-trabalhe-conosco.mp4" />
                        </video>

                    </div>
                    <div className="align-bt-small">
                        <div className="container-medium">
                            <button className="bt-video-small light-element" id="bt-film-full">
                                <p>{t('hero.button')}</p>
                                <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/icon-play-video.svg" />
                            </button>
                        </div>
                    </div>
                    <div className="scroll-1-initial"></div>
                    <div className="scroll-2-initial"></div>
                </section>

                <section className="area-history-01">
                    <div className="box-history-01">
                        <div className="container-large">
                            <div className="row fix-history1">
                                <h2 data-anime="bottom-2">{t('history1.p1')} {t('history1.p2')} {t('history1.p3')}</h2>
                                <h2 data-anime="bottom-3" className="orange">{t('history1.p4')}</h2>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="area-history-02 before-02">
                    <div className="box-history-02">
                        <div className="align-text-02">
                            <div className="area-left" data-anime="right">
                                <h3>{t('history2.left.1')}<br />{t('history2.left.2')}<br />{t('history2.left.3')}<br />{t('history2.left.4')}<br />{t('history2.left.5')}</h3>
                            </div>
                            <div className="area-right" data-anime="left">
                                <h1 className="blue">{t('history2.right.1')}<br />{t('history2.right.2')}<br />{t('history2.right.3')}<br /></h1>
                                <h1 className="orange">{t('history2.right.4')}</h1>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="area-history-03 before-03">
                    <div className="box-history-03">
                        <div className="container-large">
                            <div className="col-left">
                                <div className="row" data-anime="bottom">
                                    <h2 className="blue">{t('history3.title.1')}<br />{t('history3.title.2')}<br />{t('history3.title.3')}</h2>
                                </div>
                                <div className="row">
                                    <div className="border-title" data-anime="border-default">
                                        <figure className="bg-green"></figure>
                                        <figure className="bg-orange"></figure>
                                        <figure className="bg-ciano"></figure>
                                    </div>
                                </div>
                                <div className="row" data-anime="bottom-3">
                                    <p>{t('history3.body')}</p>
                                </div>
                                <div className="row">
                                    <figure className="image-03-01"></figure>
                                </div>
                            </div>
                            <div className="col-right">
                                <figure className="image-03-02"></figure>
                                {/* <!-- <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/oportunidades/bg-beach.png" /> --> */}
                                <div className="align-title-03-02">
                                    <div className="row">
                                        <figure className="area-border-bottom bg-ciano" id="border-title-final-03">
                                        </figure>
                                    </div>
                                    <div className="row" id="title-final-03">
                                        {i18n.language === "pt" && (<h2>O colaborador </h2>)}
                                        <h2 className="ciano">{t('history3.footer.1')}</h2>
                                        <h2>{t('history3.footer.2')}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="area-history-04">
                    <div className="area-video-04">
                        <video muted playsInline id="video-04-pc" className="hide-mobile">
                            <source type="video/mp4" media="screen and (min-width:1200px)" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/videos/oportunidades/bg-woman-employees.mp4" />
                        </video>
                        <video muted playsInline id="video-04-mobile" className="hide-pc">
                            <source type="video/mp4" media="screen and (max-width:1199px)" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/videos/oportunidades/mobile/bg-woman-employees.mp4" />
                        </video>


                    </div>
                    <div className="box-history-04">
                        <div className="container-large">
                            <div className="row" data-anime="bottom-6">
                                <h1 className="blue fix-history4-title">{t('history4.title')}</h1>
                            </div>
                            <div className="row" data-anime="bottom-8">
                                <p className="fix-history4-body">{i18n.language === "pt" ? "Pela" : "About"} <span className="green">{t('history4.word1')}</span>, {i18n.language === "pt" ? "pela" : "about"} <span className="green">{t('history4.word2')}</span>, {i18n.language === "pt" ? "pela" : "about"} <span className="green">{t('history4.word3')}</span>, {i18n.language === "pt" ? "pelo" : "about"} <span className="green">{t('history4.word4')}</span>, {i18n.language === "pt" ? "pelo" : "about"} <span className="green">{t('history4.word5')}</span>.<br />{t('history4.rest')}</p>
                            </div>
                            {/* <div className="row center">
                                <button className="bt-down invert"></button>
                            </div> */}
                        </div>
                    </div>
                </section>

                <section className="area-history-05">
                    <div className="box-history-05" data-anime="bottom">
                        <div className="container-medium">
                            <div className="row center" data-anime="bottom-4">
                                <h3>{t('history5.1')}</h3>
                            </div>
                            <div className="row center" data-anime="bottom-5">
                                <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/oportunidades/logo-jxlover.png" />
                            </div>
                            <div className="row center" data-anime="bottom-6">
                                <h3>{t('history5.2')}</h3>
                            </div>
                            <div className="row center text-click" data-anime="bottom-7">
                                <p>{t('history5.3')}</p>
                            </div>
                            <div className="row center" data-anime="bottom-8">
                                <a className="bt-primary" target="_blank" href="http://gsrvices.com/vitoriasrv">
                                    <p>{t('history5.button')}</p>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="area-history-06">
                    <div className="box-history-06">
                        <figure className="box-testimony testimony-1" data-anime="bottom" id="box-andre">
                            <div className="content-testimony">
                                <div className="row">
                                    <h2>André<br />Lima</h2>
                                </div>
                                <div className="info-testimony">
                                    <div className="row">
                                        <h3>{t('history6.andre')}</h3>
                                    </div>
                                    <div className="row">
                                        <a className="bt-link">
                                            <p>{t('history6.button')}</p>
                                            <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/icon-play-video.svg" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </figure>

                        <figure className="box-testimony testimony-2" data-anime="bottom-2" id="box-rainielly">
                            <div className="content-testimony">
                                <div className="row">
                                    <h2>Rainielly<br />Alfanio
                                    </h2>
                                </div>
                                <div className="info-testimony">
                                    <div className="row">
                                        <h3>{t('history6.rainielly')}</h3>
                                    </div>
                                    <div className="row">
                                        <a className="bt-link">
                                            <p>{t('history6.button')}</p>
                                            <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/icon-play-video.svg" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </figure>

                        <figure className="box-testimony testimony-3" data-anime="bottom-3" id="box-karla">
                            <div className="content-testimony">
                                <div className="row">
                                    <h2>Karla<br />Silveira</h2>
                                </div>
                                <div className="info-testimony">
                                    <div className="row">
                                        <h3>{t('history6.karla')}</h3>
                                    </div>
                                    <div className="row">
                                        <a className="bt-link">
                                            <p>{t('history6.button')}</p>
                                            <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/icon-play-video.svg" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </figure>
                    </div>
                </section>

                <section className="area-form-candidate">
                    <div className="container-medium">
                        <div className="row">
                            <h2 className="blue" data-anime="bottom">{t('form.title2', { ns: 'contact' })}</h2>
                        </div>
                        <div className="row" data-anime="bottom-2">
                            <div className="row info-1">
                                <div className={`input-item ${inputNameError ? 'error-input' : ''}`}>
                                    <label>{t('form.name', { ns: 'contact' })}</label>
                                    <input type="text" onBlur={verifyNameInput} ref={inputNameRef} className="input-default" />
                                    {inputNameError ?
                                        <div className="font">*{t('form.required', { ns: 'contact' })}</div>
                                        : ''
                                    }
                                </div>
                            </div>
                            <div className="row info-2">
                                <div className={`input-item ${inputEmailError ? 'error-input' : ''}`}>
                                    <label>{t('form.email', { ns: 'contact' })}</label>
                                    <input type="email" onBlur={verifyEmailInput} ref={inputEmailRef} className="input-default" />
                                    {inputEmailError ?
                                        <div className="font">*{t('form.emailRequired', { ns: 'contact' })}</div>
                                        : ''
                                    }
                                </div>
                                <div className={`input-item ${inputPhoneError ? 'error-input' : ''}`}>
                                    <label>{t('form.landline', { ns: 'contact' })}</label>
                                    <ReactInputMask
                                        type="tel"
                                        placaholder="(99) 9999-9999"
                                        mask="(99) 9999-9999"
                                        className="input-default"
                                        ref={inputPhoneRef}
                                        onBlur={verifyPhoneInput}
                                    />
                                    {inputPhoneError ?
                                        <div className="font">*{t('form.phoneRequired', { ns: 'contact' })}</div>
                                        : ''
                                    }
                                </div>
                                <div className={`input-item ${inputCellError ? 'error-input' : ''}`}>
                                    <label>{t('form.cellphone', { ns: 'contact' })}</label>
                                    <ReactInputMask
                                        placaholder="(99) 9 9999-9999"
                                        mask="(99) 9 9999-9999"
                                        type="tel"
                                        className="input-default"
                                        ref={inputCellRef}
                                        onBlur={verifyPhoneInput}
                                    />
                                    {inputCellError ?
                                        <div className="font">*{t('form.phoneRequired', { ns: 'contact' })}</div>
                                        : ''
                                    }
                                </div>
                            </div>
                            <div className="row info-3">
                                <div className={`input-item ${solicitationError ? 'error-input' : ''}`}>
                                    <label>{t('form.message', { ns: 'contact' })}</label>
                                    <textarea ref={solicitationRef} onBlur={verifySolicitationInput}></textarea>
                                    {solicitationError ?
                                        <div className="font">*{t('form.required', { ns: 'contact' })}</div>
                                        : ''
                                    }
                                </div>
                            </div>
                            <div className="area-full-form">
                                <div className="area-attachment">
                                    <label className="bt-attachment">
                                        <input type="file"
                                            style={{ display: 'none' }}
                                            accept="application/msword, application/pdf, image/png, image/jpeg, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                            onChange={addFile}
                                            ref={inputFile}
                                        />
                                        <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/icon-clip.svg" />
                                        <p>{t('form.curriculum', { ns: 'contact' })}</p>
                                    </label>
                                    {fileName ?
                                        <div className="name-file">{fileName}<button className="bt-remove-file" onClick={removeFile}></button></div>
                                        : ''
                                    }
                                    {inputFileError ?
                                        <div className="error-input"><div className="font">*{t('form.curriculumRequired', { ns: 'contact' })}</div></div>
                                        : ''
                                    }
                                </div>

                                <div className="area-actions-form">
                                    <div className="align-actions-form">
                                        <div id="checklist">
                                            <input id="01" onChange={onChangeTerms} type="checkbox" name="r" value="1" />
                                            <label htmlFor="01">  {t('form.agreement1', { ns: 'contact' })}</label>
                                        </div>
                                        <a className="politic-confirmation" href="/central-privacidade" target="_blank">{t('form.agreement2', { ns: 'contact' })}.</a>
                                    </div>
                                    {/* <a className="bt-primary" href="/oportunidades-sucesso"> */}
                                    <a className="bt-primary" onClick={sendMessage}>
                                        <p>{t('form.send', { ns: 'contact' })}</p>
                                    </a>
                                    {/* <Link className="bt-primary" to="/oportunidades-sucesso">
                                        <p>{t('form.send', { ns: 'contact' })}</p>
                                    </Link> */}
                                </div>
                            </div>
                            {inputTermsError ? <div className="error-input">
                                <div className="font">*{t('form.agreementRequired', { ns: 'contact' })}</div>
                            </div> : ''}
                        </div>
                    </div>
                </section>
            </content>
            <Footer />
        </Container>
    )
}