export default function SubHead() {

    function redirectToZeus() {
        window.location.href = '/zeus'
    }
    // document.querySelector('.subhead-zeus').addEventListener('click', () => {
    //     window.location.href = '/zeus'
    // });

    return (
        <></>
        // <div className="subhead-zeus" onClick={redirectToZeus}>
        //     <div className="container-medium">
        //         <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/icon-head-zeus.png" />
        //         <div>
        //             <p>Converse com o nosso agente digital:</p>
        //             <a href="tel:08006430729">0800 643 0729</a>
        //         </div>
        //     </div>
        // </div>
    )
}