import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Base = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 9999;

  display: flex;
  flex-direction: column;

  border-radius: 0 0 8px 8px;
  border: 1px solid #e6e6e6;
  border-top: none;
  padding: 16px;
  gap: 8px;

  background-color: #fff;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
`;

const Button = styled.button`
  color: ${(props) => (props.active ? "#2c96f9" : "#000")};
  background-color: transparent;

  font-family: "sf";
  font-weight: ${(props) => (props.active ? "700" : "400")};
  text-align: left;
`;

export const LanguageSelector = ({ onClick }) => {
  const [_, i18n] = useTranslation();

  const handleClick = (lang) => {
    i18n.changeLanguage(lang);
    window.location.reload();
    onClick();
  };

  return (
    <Base>
      <Button active={i18n.language === "pt"} onClick={() => handleClick("pt")}>
        Português - PT
      </Button>
      <Button active={i18n.language === "en"} onClick={() => handleClick("en")}>
        English - EN
      </Button>
    </Base>
  );
};
