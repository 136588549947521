import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactInputMask from 'react-input-mask'
import ReCAPTCHA from 'react-google-recaptcha'

import Header from '../components/Header'
import Footer from '../components/Footer'
import SubHead from '../components/SubHead'
import Loading from '../components/Loading'

import api from '../service/api';
import { encryptData } from '../service/auth'
import { registerPage } from '../service/services'

import { Container } from '../assets/css/fale-conosco/styles-fale-conosco.js'
import AnimationContactUs from './AnimationContactUs'

export default function ContactUs() {
    const [t] = useTranslation('contact')

    const [inputNameError, setInputNameError] = useState(false)
    const [inputEmailError, setInputEmailError] = useState(false)
    const [inputPhoneError, setInputPhoneError] = useState(false)
    const [inputCellError, setInputCellError] = useState(false)
    const [solicitationError, setSolicitationError] = useState(false)
    const [selectError, setSelectError] = useState(false)
    const [inputTerms, setInputTerms] = useState(false)
    const [inputTermsError, setInputTermsError] = useState(false)
    const [verifyInputsOnBlur, setVerifyInputsOnBlur] = useState(false)
    const inputNameRef = useRef('');
    const inputEmailRef = useRef('');
    const inputPhoneRef = useRef('');
    const inputCellRef = useRef('');
    const selectRef = useRef('');
    const solicitationRef = useRef('');
    const recaptchaRef = useRef('');

    useEffect(() => {
        AnimationContactUs();
        registerPage(969);
        recaptchaRef.current.execute();
        api.get('/form-token').then(res => localStorage.setItem('services_form_token', res.data.formToken))
    }, [])

    useEffect(() => {
        verifyNameInput()
        verifySelect()
        verifyPhoneInput()
        verifyEmailInput()
        verifySolicitationInput()
    }, [verifyInputsOnBlur])

    const verifyNameInput = () => {
        if (verifyInputsOnBlur) {
            let inputName = inputNameRef.current.value.trim();
            if (inputName.length < 1) {
                setInputNameError(true);
            } else {
                setInputNameError(false);
            }
        }
    }
    const verifyEmailInput = () => {
        if (verifyInputsOnBlur) {
            let inputEmail = inputEmailRef.current.value.trim();
            if (inputEmail.length < 1
                || inputEmail.lastIndexOf('@') === -1
                || inputEmail.lastIndexOf('.') === -1
                || inputEmail.lastIndexOf('@') === inputEmail.length - 1
                || inputEmail.lastIndexOf('.') === inputEmail.length - 1
                || inputEmail.lastIndexOf('@.') !== -1
            ) {
                setInputEmailError(true);
            } else {
                setInputEmailError(false);
            }
        }
    }
    const verifyPhoneInput = () => {
        if (verifyInputsOnBlur) {
            let inputPhone = inputPhoneRef.current.value
            let inputCell = inputCellRef.current.value
            if (inputPhone.replace(/[^0-9]/g, "").length === 10 || inputCell.replace(/[^0-9]/g, "").length === 11) {
                setInputCellError(false);
                setInputPhoneError(false);
            } else {
                setInputCellError(true);
                setInputPhoneError(true);
            }
        }
    }
    const verifySelect = () => {
        if (verifyInputsOnBlur) {
            let select = selectRef.current.value;
            if (!select) {
                setSelectError(true)
            } else {
                setSelectError(false)
            }
        }
    }
    const verifySolicitationInput = () => {
        if (verifyInputsOnBlur) {
            let solicitation = solicitationRef.current.value.trim();
            if (solicitation.length < 1) {
                setSolicitationError(true);
            } else {
                setSolicitationError(false);
            }
        }
    }
    const onChangeTerms = () => {
        setInputTerms(!inputTerms);
        if (verifyInputsOnBlur) {
            if (inputTerms) {
                setInputTermsError(true)
            } else {
                setInputTermsError(false)
            }
        }
    }

    const sendMessage = async () => {
        let nomeCompleto = inputNameRef.current.value.trim();
        let assunto = selectRef.current.value;
        let email = inputEmailRef.current.value.trim();
        let telefoneFixo = inputPhoneRef.current.value.replace(/[^0-9]/g, '');
        let celular = inputCellRef.current.value.replace(/[^0-9]/g, '');
        let solicitacao = solicitationRef.current.value.trim();
        let recaptcha = recaptchaRef.current.getValue();

        if (!recaptcha) {
            await recaptchaRef.current.executeAsync().then(res => recaptcha = res);
        }

        setVerifyInputsOnBlur(true);

        if (inputTerms && !inputNameError && !selectError &&
            recaptcha && (!inputPhoneError || !inputCellError) &&
            nomeCompleto && assunto && email && telefoneFixo && celular && solicitacao) {

            let contentMessage = {
                idModelo: 998,
                nomeCompleto,
                assunto,
                email,
                telefoneFixo,
                celular,
                solicitacao
            }

            let messageInfo = {
                hasFile: false,
                file: '',
                grecaptcha: recaptcha,
                content: encryptData(JSON.stringify(contentMessage))
            }

            registerPage(975, email, nomeCompleto)
            await api.post('send-email-fale-conosco', messageInfo)

                .then(() => window.location.href = '/contato-sucesso')

                .catch(err => console.log(err))
        }
        if (!inputTerms) {
            setInputTermsError(true)
        } else {
            setInputTermsError(false)
        }
    }

    return (
        <Container>
           {/* <Loading /> */}
            <Header />
            <ReCAPTCHA
                sitekey="6LfFjz4bAAAAAPnAKFFBAK1NrBgyi-1ZDHNP2hbs"
                size="invisible"
                ref={recaptchaRef}
                badge="bottomleft"
            />
            <content>
                <SubHead />
                <div className="all-content">
                    <section className="area-head-page initial-contact bg-blue">
                        <div className="video-initial-contact">
                            <video autoPlay muted playsInline>
                                <source type="video/mp4" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/videos/fale-conosco/bg-initial-mobile.mp4" media="(max-width:1199px)" />
                                <source type="video/mp4" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/videos/fale-conosco/bg-initial.mp4" />
                            </video>
                        </div>
                        <div className="container-medium">
                            <h3 data-anime="bottom-2" className="">{t('hero.title')}</h3>
                            <h2 data-anime="bottom-4" className="">{t('hero.subtitle1')}<br />{t('hero.subtitle2')}<br /> <span className="orange">{t('hero.subtitle3')}</span></h2>
                            <figure className="border-title bg-orange -bar" motion-bar="bar-1" id="border-initial"></figure>
                        </div>
                    </section>
                    <section className="area-form-contact " data-anime="bottom-3">
                        <div className="container-medium">
                            <div className="row">
                                <h3 className="blue">{t('form.title')}</h3>
                            </div>
                            <div className="row">
                                <p className="text-contact">{t('form.body1')}<br />{t('form.body2')}</p>
                            </div>
                            <div className="row" data-anime="bottom">
                                <div className="row info-1 space-between-pc">
                                    <div className={`input-item ${inputNameError ? 'error-input' : ''}`}>
                                        <label className="font">{t('form.name')}</label>
                                        <input type="text" ref={inputNameRef} onBlur={verifyNameInput} className="input-default" />
                                        {inputNameError ?
                                            <div className="font">*{t('form.required')}</div>
                                            : ''
                                        }
                                    </div>
                                    <div className={`input-item ${inputEmailError ? 'error-input' : ''}`}>
                                        <label className="font">{t('form.email')}</label>
                                        <input type="email" onBlur={verifyEmailInput} ref={inputEmailRef} className="input-default" />
                                        {inputEmailError ?
                                            <div className="font">*{t('form.emailRequired')}</div>
                                            : ''
                                        }
                                    </div>
                                </div>
                                <div className="row info-2">
                                    <div className={`input-item  ${selectError ? 'error-input' : ''}`}>
                                        <label className="font">{t('form.subject')}</label>

                                        <select
                                            ref={selectRef}
                                            onBlur={verifySelect}
                                            name="select"
                                            className="input-default"
                                            style={selectError ? { border: 'solid 1px #F00', background: '#ffd6d6' } : {}}
                                        >
                                            <option value="" disabled="">{t('form.subjectOptions.0')}</option>
                                            <option value="Comercial">{t('form.subjectOptions.1')}</option>
                                            <option value="LGPD">{t('form.subjectOptions.2')}</option>
                                            <option value="Relacionamento">{t('form.subjectOptions.3')}</option>
                                            <option value="Ouvidoria">{t('form.subjectOptions.4')}</option>
                                            <option value="Rh_Rescisao">{t('form.subjectOptions.5')}</option>
                                            <option value="Rh_Recrutamento">{t('form.subjectOptions.6')}</option>
                                        </select>
                                        {selectError ?
                                            <div className="font">*{t('form.subjectRequired')}</div>
                                            : ''
                                        }
                                    </div>
                                    <div className={`input-item ${inputPhoneError ? 'error-input' : ''}`}>
                                        <label className="font">{t('form.landline')}</label>
                                        <ReactInputMask
                                            type="tel"
                                            placeholder="(99) 9999-9999"
                                            mask="(99) 9999-9999"
                                            className="input-default"
                                            onBlur={verifyPhoneInput}
                                            ref={inputPhoneRef}
                                        />
                                        {inputPhoneError ?
                                            <div className="font">*{t('form.phoneRequired')}</div>
                                            : ''
                                        }
                                    </div>
                                    <div className={`input-item ${inputCellError ? 'error-input' : ''}`}>
                                        <label className="font">{t('form.cellphone')}</label>
                                        <ReactInputMask
                                            placeholder="(99) 9 9999-9999"
                                            mask="(99) 9 9999-9999"
                                            type="tel"
                                            className="input-default"
                                            onBlur={verifyPhoneInput}
                                            ref={inputCellRef}
                                        />
                                        {inputCellError ?
                                            <div className="font">*{t('form.phoneRequired')}</div>
                                            : ''
                                        }
                                    </div>
                                </div>
                                <div className="row info-3">
                                    <div className={`input-item ${solicitationError ? 'error-input' : ''}`}>
                                        <label className="font">{t('form.message')}</label>
                                        <textarea ref={solicitationRef} onBlur={verifySolicitationInput}></textarea>
                                        {solicitationError ?
                                            <div className="font">*{t('form.required')}</div>
                                            : ''
                                        }
                                    </div>
                                </div>

                                <div className="area-full-form">
                                    <div className="align-actions-form">
                                        <div id="checklist">
                                            <input id="01" type="checkbox" onChange={onChangeTerms} name="r" value="1" />
                                            <label htmlFor="01"> {t('form.agreement1')} </label>
                                        </div>
                                        <a className="politic-confirmation" href="/central-privacidade">{t('form.agreement2')}.</a>
                                        {/* <Link className="politic-confirmation" to="/central-privacidade">Política de Privacidade.</Link> */}
                                    </div>
                                    {/* <a className="bt-primary" onClick={sendMessage} href="/contato-sucesso"> */}
                                    <a className="bt-primary" onClick={sendMessage}>
                                        <p>{t('form.send')}</p>
                                    </a>
                                    {/* <Link className="bt-primary" to="/contato-sucesso">
                                        <p>{t('form.send')}</p>
                                    </Link> */}
                                </div>
                                {inputTermsError ? <div className="error-input">
                                    <div className="font">*{t('form.agreementRequired')}</div>
                                </div> : ''}
                            </div>
                        </div>
                    </section>
                </div>
            </content>
            <Footer />
        </Container>
    )
}