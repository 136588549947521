import React from 'react';
import { Redirect, Route, Switch, Navigate } from 'react-router-dom';
import ContactSuccessful from './ContactSuccessful';
import OpportunitiesSuccessful from './OpportunitiesSuccessful';
import ContactUs from './ContactUs';
import Opportunities from './Opportunities';
import Blog from './Blog';
import Faq from './Faq';
import Privacy from './Privacy';
import Culture from './Culture';
import Institutional from './Institutional';
import Aio from './Aio';
import Zeus from './Zeus';
import Products from './Products';
import Home from './Home';
import Manifest from './Manifest';
import Post from './Blog/Post';
import JX from './Institutional/JX';

export default function Routes() {
    return (
        <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/oportunidades" component={Opportunities} />
            <Route path="/fale-conosco" component={ContactUs} />
            <Route path="/oportunidades-sucesso" component={OpportunitiesSuccessful} />
            <Route path="/contato-sucesso" component={ContactSuccessful} />
            <Route path="/blog" component={Blog} />
            <Route path="/post/:id/:slug" component={Post} />
            <Route path="/faq" component={Faq} />
            <Route path="/central-privacidade" component={Privacy} />
            <Route path="/cultura" component={Culture} />
            <Route exact path="/institucional" component={Institutional} />
            <Route path="/institucional/somosjx" component={JX} />
            <Route path="/aio" component={Aio} />
            <Route path="/zeus" component={Zeus} />
            <Route path="/produtos" component={Products} />
            <Route path="/manifesto" component={Manifest} />
            <Redirect from='*' to='/' />
        </Switch>
    )
}