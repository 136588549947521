import { useEffect, useState } from "react"

export default function Loading() {

    const [showLoading, setShowLoading] = useState(true);

    // useEffect(() => {
    //     setTimeout(() => {
    //         setShowLoading(false);
    //     }, 1000)
    // }, [])

    return (
        <>
            {/* {showLoading ? ( */}
                <div className="loading"></div>
            {/* ) : ''} */}
        </>
    )
}