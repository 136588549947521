import $ from 'jquery'

export default function AnimationPost() {
    window.onload = () => {
        setTimeout(() => {
            $('.loading').hide();
        }, 500)
    }
    // PS. Esse documento possui scripts da página INDEX executados apenas em dispositivos MOBILE.
    $(document).ready(function () {

        $('.bt-accept').click(function () {
            $('.component-privacy').hide();
            $('body').removeClass('privacy-active');
        });


        $('.bt-close-video').click(function () {
            $(".all-video-modal").hide();
            $('video').trigger('pause');
        });

        $('.subhead-zeus').click(function () {
            window.location.href = "/zeus";
        });

    });
    const debounce = function (func, wait, immediate) {
        let timeout;
        return function (...args) {
            const context = this;
            const later = function () {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            const callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    };

    const target = document.querySelectorAll('[data-anime]');
    const animationClass = 'animate';

    function animeScroll() {
        const windowTop = window.pageYOffset + ((window.innerHeight * 4) / 4);
        target.forEach(function (element) {
            if ((windowTop) > element.offsetTop) {
                element.classList.add(animationClass);
            } else {
                element.classList.remove(animationClass);
            }
        })
    }

    animeScroll();

    if (target.length) {
        window.addEventListener('scroll', debounce(function () {
            animeScroll();
        }, 0));
    }
}