import $ from 'jquery'

export default function AnimationHome() {
    window.onload = () => {
        setTimeout(() => {
            $('.loading').hide();
        }, 500)
    }
    // PS. Esse documento possui scripts da página INDEX executados apenas em dispositivos MOBILE.
    $(document).ready(function () {

        $('.bt-accept').click(function () {
            $('.component-privacy').hide();
            $('body').removeClass('privacy-active');
        });


        $('.bt-close-video').click(function () {
            $(".all-video-modal").hide();
            $('video').trigger('pause');
        });

        $('.subhead-zeus').click(function () {
            window.location.href = "/zeus";
        });

    });
    // PS. Esse documento possui scripts da página INDEX executados apenas em dispositivos MOBILE.
    $(document).ready(function () {

        if ($(window).width() < 1200) {

            $('.subhead-zeus').click(function () {
                window.location.href = "/zeus";
            });
        }
    });

    $(document).ready(function () {
        if ($(window).width() > 1200) {

            // EXIBIR CHAT
            $(window).scroll(function () {
                var height = $(window).height();
                if ($(window).scrollTop() > height * 0.20) {
                    $('.all-chat').css({
                        'z-index': '9998',
                    });
                    $('.chat-fixed').removeClass('anime-end');
                    $('.chat-fixed').addClass('anime-start');

                } else {
                    $('.all-chat').css({
                        'z-index': '2',
                    });
                    $('.chat-fixed').addClass('anime-end');
                    $('.chat-fixed').removeClass('anime-start');
                }
            });

            // EXIBIR VÍDEO MANIFESTO
            $('#box-manifesto').click(function () {
                $('.area-video').html("<div class='all-modal'><div class='bt-close-video'></div><div class='modal-video'><video class='video-play' autoplay controls><source type='video/mp4' src='https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/videos/galery-video/video-00.mp4'></video></div></div>");
                $('.bt-close-video').click(function () {
                    $('.area-video').html('<div></div>');
                });
            });

            // MUDANÇA DE FIXO PARA RELATIVO
            $(window).scroll(function () {
                if ($(window).scrollTop() < $('.scroll-01-home').offset().top) {
                    $('.area-fixed-home').css({
                        'position': 'fixed',
                        'top': 'calc(100vh - 546px)',
                    });
                } else {
                    $('.area-fixed-home').css({
                        'position': 'relative',
                        'top': '0',
                    });
                }
            });

            // INÍCIO - INTRO
            setTimeout(function () {
                $('.area-intro h1').addClass("animate");
            }, 300);
            // FINAL - INTRO

            // INÍCIO - BOX SOBRE E MANIFESTO
            $(window).scroll(function () {
                var scrollTop = $(window).scrollTop();
                var scrollItem = $('.box-about').offset();
                var calcHeight = $('body').innerHeight();
                if (scrollTop > scrollItem.top - calcHeight + 1200) {
                    $('.box-about').addClass("animate");
                    $('.box-manifest').addClass("animate");
                } else {
                    $(".box-about").removeClass("animate");
                    $('.box-manifest').removeClass("animate");

                }
            });
            // FINAL - BOX SOBRE E MANIFESTO


            // INÍCIO - BOX JX
            $(window).scroll(function () {
                if ($(window).scrollTop() > $('.scroll-01-home').offset().top + 100) {
                    $('.box-ads-2').addClass("animate");
                    $('.box-ads-3').addClass("animate");
                } else {
                    $(".box-ads-2").removeClass("animate");
                    $('.box-ads-3').removeClass("animate");
                }
            });
            // FINAL - BOX JX






            // MOTION ELEMENTS BOX SOBRE E MANIFESTO
            $(window).scroll(function () {
                if ($(window).scrollTop() > $('.scroll-02-home').offset().top - 50) {
                    $('.box-ads-1').css({
                        'opacity': '0.5',
                    });
                    $('.box-ads-2').css({
                        'opacity': '0.5',
                    });
                }
                if ($(window).scrollTop() > $('.scroll-02-home').offset().top) {
                    $('.box-ads-1').css({
                        'opacity': '0.6',
                    });
                    $('.box-ads-2').css({
                        'opacity': '0.6',
                    });
                }

                if ($(window).scrollTop() > $('.scroll-02-home').offset().top + 50) {
                    $('.box-ads-1').css({
                        'opacity': '0.7',
                    });
                    $('.box-ads-2').css({
                        'opacity': '0.7',
                    });
                }
                if ($(window).scrollTop() > $('.scroll-02-home').offset().top + 100) {
                    $('.box-ads-1').css({
                        'opacity': '0.8',
                    });
                    $('.box-ads-2').css({
                        'opacity': '0.8',
                    });
                }
                if ($(window).scrollTop() > $('.scroll-02-home').offset().top + 150) {
                    $('.box-ads-1').css({
                        'opacity': '0.9',
                    });
                    $('.box-ads-2').css({
                        'opacity': '0.9',
                    });
                }


                if ($(window).scrollTop() > $('.scroll-02-home').offset().top + 200) {
                    $('.box-ads-1').css({
                        'opacity': '1.0',
                    });

                    $('.box-ads-2').css({
                        'opacity': '1.0',
                    });
                } else {
                    // $('.box-ads-1').css({
                    //     'opacity': '0.5',
                    // });
                    // $('.box-ads-2').css({
                    //     'opacity': '0.5',
                    // });
                }


            });
        }
    });

    $(document).ready(function () {
        if ($(window).width() < 1200) {
            // VARIÁVEIS PAGE

            // EXIBIR VÍDEO MANIFESTO
            $('#box-manifesto').click(function () {
                $('.area-video').html("<div class='all-modal'><div class='bt-close-video'></div><div class='modal-video'><video class='video-play' autoplay controls><source type='video/mp4' src='https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/videos/galery-video/video-00.mp4'></video></div></div>");
                $('.bt-close-video').click(function () {
                    $('.area-video').html('<div></div>');
                });
            });

            // MUDANÇA DE FIXO PARA RELATIVO
            $(window).scroll(function () {
                if ($(window).scrollTop() < $('.scroll-01-home').offset().top) {
                    $('.area-fixed-home').css({
                        'position': 'fixed',
                        'top': 'calc(100vh - 546px)',
                    });


                } else {
                    $('.area-fixed-home').css({
                        'position': 'relative',
                        'top': '0',
                    });
                }
            });

            // INÍCIO - INTRO
            setTimeout(function () {
                $('.area-intro h1').addClass("animate");
            }, 300);
            // FINAL - INTRO

            // INÍCIO - BOX SOBRE E MANIFESTO
            $(window).scroll(function () {
                var scrollTop = $(window).scrollTop();
                var scrollItem = $('.box-about').offset();
                var calcHeight = $('body').innerHeight();
                if (scrollTop > ((scrollItem.top - 250) - calcHeight)) {
                    $('.box-about').addClass("animate");

                    setTimeout(function () {
                        $('.box-manifest').addClass("animate");
                    }, 300);
                } else {
                    $(".box-about").removeClass("animate");
                    $('.box-manifest').removeClass("animate");

                }
            });
            // FINAL - BOX SOBRE E MANIFESTO


            // INÍCIO - BOX JX
            $(window).scroll(function () {
                if ($(window).scrollTop() > $('.scroll-01-home').offset().top + 100) {
                    $('.box-ads-2').addClass("animate");
                    $('.box-ads-3').addClass("animate");
                } else {
                    $(".box-ads-2").removeClass("animate");
                    $('.box-ads-3').removeClass("animate");
                }
            });
            // FINAL - BOX JX


            // INÍCIO - BOX SOBRE E MANIFESTO
            $(window).scroll(function () {
                if ($(window).scrollTop() > $('.area-blog').offset().top - 700) {

                    $('.area-blog h2').addClass("animate");
                    $('.area-blog p').addClass("animate");
                    $('.post-default').addClass("animate");
                } else {
                    $(".area-blog h2").removeClass("animate");
                    $(".area-blog p").removeClass("animate");
                    $(".post-default").removeClass("animate");
                }
            });
        }
    });

    $(document).ready(function () {
        $('#bt-manifest').click(function () {
            window.location.href = '/manifesto';
            // $("#video-manifest").show();
            // $('#item-video-manifest').trigger('play');
        });
    });
}