import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Footer from "../components/Footer";
import Header from "../components/Header";
import Subhead from "../components/SubHead";
import Loading from "../components/Loading";
import { Container } from '../assets/css/institucional/styles-institucional.js';
import AnimationInstitutional from "./AnimationInstitutional";
import { registerPage } from "../service/services";

export default function Institutional() {
    const [t] = useTranslation('institutional');

    useEffect(() => {
        AnimationInstitutional();
        registerPage(964);
    }, [])

    return (
        <Container>
           {/* <Loading /> */}
            <Header />
            <content>
                <Subhead />
                <section className="area-head-page initial-corporate">
                    <div className="container-medium align-content-main">
                        <ul>
                            <li data-anime="bottom-3" id="link-about">{t('about-us.title')}</li>
                            <li data-anime="bottom-4" id="link-mission">{t('our-mission')}</li>
                            <li data-anime="bottom-5" id="link-culture">{t('culture.title')}</li>
                            <li data-anime="bottom-6" id="link-estruture">{t('structure')}</li>
                            <li data-anime="bottom-7" id="link-manifest">MANIFESTO</li>
                        </ul>
                        <h2 id="bt-page-down" data-anime="bottom-6">{t('institutional')} <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/institucional/icon-down.svg" /></h2>
                    </div>
                    <button className="bt-down hide-pc"></button>
                </section>
                <section className="area-about" id="area-about">
                    <div className="container-medium">
                        <div className="row">
                            <div className="area-title">
                                <h2 data-anime="bottom">{t('about-us.title')}</h2>
                            </div>
                            <div className="col-2-text">
                                <p data-anime="bottom-2">
                                    {/* Para que cada um se sinta especial, o Grupo Services sempre está presente e a frente. Propomos uma atitude alegre e contagiante para o dia a dia. Experiências sofisticadas que estimulam os sentidos e aproximam as pessoas. Porque
                                    uma promessa não pode ser criada ou imposta. Ela deve ser encontrada na verdade da marca, no DNA da empresa.
                                    <br /><br />Sempre com o foco em entender os desejos e necessidades de seus consumidores, o Grupo Services conta com uma ampla linha de produtos e serviços e atua em vários canais e formatos. Enquanto o mercado fala em Customer
                                    Experience, nós vamos além. Nós oferecemos Jornadas de Experiências aos nossos públicos, ou Journey Experience (JX).
                                    <br /><br /> Esse propósito abrange e interliga as jornadas de todos os públicos que se relacionam com a gente. O objetivo do JX é proporcionar experiências únicas em todos os nossos relacionamentos, ao longo de cada uma dessas
                                    jornadas. */}
                                    {t('about-us.p1')}<br /><br />{t('about-us.p2')}<br /><br />{t('about-us.p3')}
                                    </p>
                            </div>
                            <div className="col-3-text">
                                <p data-anime="bottom-3">
                                    {/* Somos híbridos, especialistas em tecnologia e apaixonados por pessoas. Unimos o melhor do atendimento humano e digital. Seja com nossas soluções de agentes digitais (robôs de voz e texto considerados os melhores do mercado), portais de autoatendimento,
                                    consultores digitais preparados para fazer todo atendimento por OMNI Channel, ou atendentes, sempre com empatia, dispostos a entender, compreender, transformar cada contato e resolver todo tipo de demanda, oferecendo jornadas
                                    de experiências únicas a todos os nossos públicos.
                                    <br /><br /> Nós somos otimistas, buscamos compartilhar conhecimentos e experiências procurando sempre a proximidade. Essa proximidade acarreta em empatia e diversidade, pois diferentes modos de pensar e agir sempre levam à inovação.
                                    <br /><br /> Esse é um resumo de como queremos construir nossa história. De como desejamos fazer dessa Jornada, um caminho de experiências e soluções únicas. Estando com você agora, e no futuro. */}
                                    {t('about-us.p4')}<br /><br />{t('about-us.p5')}<br />
                                </p>
                            </div>
                        </div>
                        <div className="row part-2-column">
                            <div className="area-title">
                                <h2 data-anime="bottom">{t('pillars.title')}</h2>
                            </div>
                            <div className="col-1-text">
                                <p data-anime="bottom">{t('pillars.p1')}<br /><br />{t('pillars.p2')}<br /><br /><b>{t('pillars.p3')}</b></p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="area-boxes">
                    <div className="container-large ">
                        <div className="area-box-about">
                            <p>{t('to-know.title')}</p>
                            <div className="item-box-about">{t('to-know.body')}</div>
                        </div>

                        <div className="area-box-about">
                            <p>{t('understand.title')}</p>
                            <div className="item-box-about">{t('understand.body')}</div>
                        </div>

                        <div className="area-box-about">
                            <p>{t('transform.title')}</p>
                            <div className="item-box-about">{t('transform.body')}</div>
                        </div>

                        <div className="area-box-about">
                            <p>{t('solve.title')}</p>
                            <div className="item-box-about">{t('solve.body')}</div>
                        </div>
                    </div>
                </section>
                <section className="area-lettering">
                    <div className="align-lettering">
                        <p className="typography-03-01">

                            <label className="typrography-secondary">{t('to-know.title')}</label>
                            <label>{t('understand.title')}</label>
                        </p>
                    </div>
                    <div className="align-lettering">
                        <p className="typography-03-02">
                            <label>{t('transform.title')}</label>
                            <label className="typrography-secondary">{t('solve.title')}</label>
                        </p>
                    </div>
                </section>
                <section className="area-mission">
                    <div className="container-medium">
                        <div className="row align-box-mission">
                            <div className="align-text-mission">
                                <h1 className="fix-values-subtitle">{t('values.subtitle')}</h1>
                            </div>
                            <div className="box-default-mission box-vision" id="area-mission">
                                <p>{t('vision.body')}</p>
                                <div className="name-box">
                                    <h2>{t('vision.title')}</h2>
                                    <figure className="border-title">
                                        <figure className="bg-green"></figure>
                                        <figure className="bg-orange"></figure>
                                        <figure className="bg-ciano"></figure>
                                    </figure>
                                </div>
                            </div>
                        </div>
                        <div className="row align-box-vision">
                            <div className="box-default-mission box-mission">
                                <p>{t('mission.body')}</p>
                                <div className="name-box">
                                    <h2>{t('mission.title')}</h2>
                                    <figure className="border-title">
                                        <figure className="bg-green"></figure>
                                        <figure className="bg-orange"></figure>
                                        <figure className="bg-ciano"></figure>
                                    </figure>
                                </div>
                            </div>
                        </div>
                        <div className="row align-box-value">
                            <div className="box-default-mission box-value">
                                <p>{t('values.item1')} {t('values.item2')}<br />{t('values.item3')} {t('values.item4')}<br />{t('values.item5')} {t('values.item6')}<br />{t('values.item7')} {t('values.item8')}</p>
                                <div className="name-box">
                                    <h2>{t('values.title')}</h2>
                                    <figure className="border-title">
                                        <figure className="bg-green"></figure>
                                        <figure className="bg-orange"></figure>
                                        <figure className="bg-ciano"></figure>
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="area-cx">
                    <div className="container-large">
                        <div className="area-left-cx">
                            <div className="row">
                                <h1 data-anime="bottom">{t('jx.title1')} <span className="title-color-orange">CX,</span><br />{t('jx.title2')} <span className="title-color-pk">JX.</span></h1>
                            </div>
                            <div className="border-title" data-anime="bottom-4">
                                <figure className="bg-green"></figure>
                                <figure className="bg-orange"></figure>
                                <figure className="bg-ciano"></figure>
                            </div>
                        </div>
                        <div className="area-right-cx">
                            <div className="row">
                                <h3 data-anime="bottom">{t('jx.p1')}<br /><br />{t('jx.p2')}</h3>
                            </div>
                        </div>

                        <div className="row">
                            <a href="/institucional/somosjx">
                                <figure className="video-cx-jx" id="bt-view-jx">
                                    <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/icon-big-play.svg" />
                                </figure>
                            </a>
                        </div>
                        <div className="row area-boxes-cx">
                            <div className="overflow-cx">
                                <div className="box-default-cx" data-anime="bottom-1" id="box-cx-1">
                                    <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/institucional/icon-product/icon-ura.svg" />
                                    <p>{t('jx.item.1')}</p>
                                </div>
                                <div className="box-default-cx" data-anime="bottom-2" id="box-cx-2">
                                    <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/institucional/icon-product/icon-bot-active.svg" />
                                    <p>{t('jx.item.2')}</p>
                                </div>
                                <div className="box-default-cx" data-anime="bottom-3" id="box-cx-3">
                                    <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/institucional/icon-product/icon-bot-receptive.svg" />
                                    <p>{t('jx.item.3')}</p>
                                </div>
                                <div className="box-default-cx" data-anime="bottom-4" id="box-cx-4">
                                    <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/institucional/icon-product/icon-cronos.svg" />
                                    <p>{t('jx.item.4')}</p>
                                </div>
                                <div className="box-default-cx" data-anime="bottom-5" id="box-cx-5">
                                    <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/institucional/icon-product/icon-bot-text.svg" />
                                    <p>{t('jx.item.5')}</p>
                                </div>
                                <div className="box-default-cx" data-anime="bottom-6" id="box-cx-6">
                                    <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/institucional/icon-product/icon-omnichannnel.svg" />
                                    <p>{t('jx.item.6')}</p>
                                </div>
                                <div className="box-default-cx" data-anime="bottom-7" id="box-cx-7">
                                    <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/institucional/icon-product/icon-digital.svg" />
                                    <p>{t('jx.item.7')}</p>
                                </div>
                                <div className="box-default-cx" data-anime="bottom-8" id="box-cx-8">
                                    <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/institucional/icon-product/icon-attendance-human.svg" />
                                    <p>{t('jx.item.8')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="area-culture" id="area-culture">
                    <div className="container-medium">
                        <h1 data-anime="bottom">{t('culture.title')}</h1>
                        <div className="row align-border">
                            <div className="border-title">
                                <figure className="bg-pink"></figure>
                                <figure className="bg-ciano"></figure>
                                <figure className="bg-orange"></figure>
                            </div>
                        </div>
                        <div className="row flex-end">
                            <p data-anime="bottom">{t('culture.body')}</p>
                        </div>
                        <div className="row flex-end align-bt-more">
                            <a className="bt-link" data-anime="bottom" href="/cultura">
                                <p>{t('culture.button')}</p>
                                <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/icon-arrow-right.svg" />
                            </a>
                            {/* <Link className="bt-link" data-anime="bottom" to="/cultura">
                                <p>{t('culture.button')}</p>
                                <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/icon-arrow-right.svg" />
                            </Link> */}
                        </div>
                    </div>
                </section>
                <div className="area-manifest" id="area-manifest">
                    <div className="scroll-manifest"></div>
                    <div className="container-medium">
                        <div className="row">
                            <h1 data-anime="bottom-1">MANIFESTO.</h1>
                        </div>
                        <div className="row center">
                            <figure className="border-title" data-anime="bottom-1">
                                <figure className="bg-green"></figure>
                                <figure className="bg-orange"></figure>
                                <figure className="bg-ciano"></figure>
                            </figure>
                        </div>
                        <div className="row">
                            <p data-anime="bottom-2">{t('manifesto.body1')}<br /><br />{t('manifesto.body2')}</p>
                        </div>
                    </div>

                    <div className="area-video-manifest">
                        <figure className="thumb-manifest">
                            <video autoPlay loop muted playsInline>
                                <source type="video/mp4" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/videos/institucional/manifest-resume-media.mp4" />
                            </video>
                        </figure>
                        <div className="bt-view">
                            <a className="bt-link-video" id="bt-view-manifest">
                                <p>{t('manifesto.button')}</p>
                                <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/icon-play-video.svg" />
                            </a>
                        </div>
                    </div>


                </div>
                <section className="area-segments">
                    <div className="row center">
                        <div className="container-medium">
                            <div className="row" data-anime="bottom-1">
                                <h1 className="ciano">{t('segments.title.1')}<br/>{t('segments.title.2')}</h1>
                            </div>
                        </div>
                    </div>
                    <button className="bt-left-segments" id="bt-left-segments"><img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/institucional/icon-left.svg" /></button>
                    <button className="bt-right-segments" id="bt-right-segments"><img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/institucional/icon-right.svg" /></button>
                    <div className="area-slider-segments">
                        <div className="overflow-segments">
                            <div className="box-default-segment">
                                <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/institucional/icon-segment/icon-telecom.svg" />
                                <p>Telecom</p>
                            </div>
                            <div className="box-default-segment">
                                <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/institucional/icon-segment/icon-varejo.svg" />
                                <p>{t('segments.item.1')}</p>
                            </div>
                            <div className="box-default-segment">
                                <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/institucional/icon-segment/icon-financial.svg" />
                                <p>{t('segments.item.2')}</p>
                            </div>
                            <div className="box-default-segment">
                                <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/institucional/icon-segment/icon-fintech.svg" />
                                <p>Fintech</p>
                            </div>
                            <div className="box-default-segment">
                                <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/institucional/icon-segment/icon-security.svg" />
                                <p>{t('segments.item.3')}</p>
                            </div>
                            <div className="box-default-segment">
                                <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/institucional/icon-segment/icon-games.svg" />
                                <p>{t('segments.item.4')}</p>
                            </div>
                            <div className="box-default-segment">
                                <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/institucional/icon-segment/icon-pedagio.svg" />
                                <p>{t('segments.item.5')}</p>
                            </div>
                            <div className="box-default-segment">
                                <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/institucional/icon-segment/icon-paytv.svg" />
                                <p>Pay TV</p>
                            </div>

                        </div>
                    </div>
                </section>
                <section className="area-info-culture" id="area-estruture">
                    <div className="container-medium">
                        <h1 data-anime="bottom fix-info-title">{t('info.title')}</h1>
                        <div className="row align-border">
                            <div className="border-title" data-anime="bottom">
                                <figure className="bg-green"></figure>
                                <figure className="bg-orange"></figure>
                                <figure className="bg-ciano"></figure>
                            </div>
                        </div>
                        <div className="row">
                            <p data-anime="bottom">{t('info.p1')}<br /><b>{t('info.p2')}</b></p>
                        </div>
                    </div>
                </section>
                <section className="area-world" id="a">
                    <div className="align-planet">
                        <div className="box-planet">
                            <div className="video-planet-word">
                                <video muted playsInline id="video-planet">
                                    <source type="video/mp4" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/videos/institucional/video-planet.mp4" />
                                </video>
                            </div>
                            <div className="align-word">
                                <div className="row align-border">
                                    <div className="border-title" motion-bar="bar-1" id="border-planet">
                                        <figure className="bg-blue-01"></figure>
                                        <figure className="bg-blue-02"></figure>
                                        <figure className="bg-blue-03"></figure>
                                    </div>
                                </div>
                                <div className="row">
                                    <h1 data-anime="bottom-2" className="animate">{t('world.title')}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-text">
                        <div className="area-text-world">
                            <div className="row">
                                <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/institucional/icon-placeholder.svg" data-anime="bottom" className="animate" />
                            </div>
                            <div className="row">
                                <p data-anime="bottom" className="animate">{t('world.body')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="scroll-planet" id="scroll-planet-1"></div>
                    <div className="scroll-planet-2" id="scroll-planet-2"></div>
                    <div className="scroll-planet-3" id="scroll-planet-3"></div>
                </section>
                <section className="area-solutions" id="area-solutions">
                    <div className="box-solutions">
                        <div className="container-medium">
                            <div className="row">
                                <h1 data-anime="bottom-1">{t('footer.highlight.body')}</h1>
                            </div>
                            <div className="row">
                                <a className="bt-link" data-anime="bottom-2" href="/produtos">
                                    <p>{t('footer.highlight.button')}</p>
                                    <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/icon-arrow-right.svg" />
                                </a>
                                {/* <Link className="bt-link" data-anime="bottom-2" to="/produtos">
                                    <p>{t('footer.highlight.button')}</p>
                                    <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/icon-arrow-right.svg" />
                                </Link> */}
                            </div>
                        </div>
                    </div>
                </section>
            </content>
            <Footer />
        </Container>
    )
}