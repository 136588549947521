import { useTranslation } from 'react-i18next';

// import { api } from '../../service/api';
import { getToken } from '../../service/auth';
import FenixChat, { EventListener } from '../../service/chat';


export default function ChatBot() {
    const [t] = useTranslation()

    const pages = [
        { url: '/', pagina: 1 },
        { url: '/institucional', pagina: 2 },
        { url: '/cultura', pagina: 3 },
        { url: '/produtos', pagina: 4 },
        { url: '/aio', pagina: 5 },
        { url: '/zeus', pagina: 6 },
        { url: '/oportunidades', pagina: 7 },
        { url: '/fale-conosco', pagina: 8 }
    ]
    const page = window.location.pathname;
    const pagina = pages.filter(p => p.url === page)[0]?.pagina

    var chat;

    sessionStorage.setItem('frame-state', 'hide');

    const openChat = () => {
        let chatContainer = document.querySelector('.chat-fenix-container')
        if (chatContainer) {
            chat.toggle();
        } else {
            var token = getToken();

            var chatObject = {
                enviroment: 'production',
                channel: 'chat',
                creditor: 'Q0cxbDl1cGxWRk5YRmRhWVl4WVBrUT09',
                queue: 'Y3lzOEkxVmwwRWNYeldDVHJrUWE4dz09',
                subject: 'MnF2NDExK2JuUDd1VVlhYmxYd21PUT09',
                pagina,
                token,
            }
            var chatStyle = {
                width: window.innerWidth < 410 ? `${window.innerWidth - 10}px` : '400px',
                height: '550px',
                position: 'fixed',
                bottom: '5px',
                right: '5px',
                overflow: 'hidden',
                borderTopRightRadius: '15px',
                borderTopLeftRadius: '15px',
                backgroundColor: 'white',
                boxShadow: '0 0 2px black',
                zIndex: '9999'
            }
	    var chatActions = { minimize: 'close', close: 'close' };
            chat = new FenixChat(chatObject, false, false);
            chat.style(chatStyle);
            chat.setActions(chatActions);
            chat.toggle();
            chat.on('finished-attendance', () => {
                setTimeout(() => {
                    if (!document.querySelector('.closeChatButton')) {
                        let closeChatButton = document.createElement('div');
                        // closeChatButton.innerHTML = '<img loading="lazy" width="15" style="margin: 5px" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/close-chat.svg" />';
                        closeChatButton.className = 'closeChatButton'
                        closeChatButton.onclick = closeChat;
                        closeChatButton.style.position = 'absolute';
                        // closeChatButton.style.display = 'flex';
                        closeChatButton.style.right = '5px';
                        // closeChatButton.style.backgroundColor = '#F6F6F6';
                        closeChatButton.style.width = '40px';
                        closeChatButton.style.height = '30px';
                        closeChatButton.style.top = '2px';
                        closeChatButton.style.color = '#000';
                        closeChatButton.style.alignItems = 'center';
                        // closeChatButton.style.fontSize = '25px';
                        // closeChatButton.style.fontWeight = 'bold';
                        closeChatButton.style.cursor = 'pointer';
                        document.querySelector('.chat-fenix-container').append(closeChatButton);
                    }
                }, 1000);
                
            });
            document.querySelector('.chat-fenix-container iframe').style.width = '100%';
            document.querySelector('.chat-fenix-container iframe').style.border = 'none';
        }
    }

    const closeChat = () => {
        document.querySelector('.closeChatButton').remove();
        document.querySelector('.chat-fenix-container').remove();
        sessionStorage.setItem('frame-state', 'hide');
    }

    return (
        <>
            {pagina ?
                <div className="all-chat">
                    <label className="chat-fixed" htmlFor="open-chat" style={pagina === 1 ? { opacity: 0 } : { opacity: 1 }} onClick={() => openChat()}>
                        <figure className="icon-chat"></figure>
                        <p>{t('nav.contact')}</p>
                    </label>
                </div>
                : ''}
        </>
    )
}
