import { useEffect, useState } from "react"
import { Trans, useTranslation } from "react-i18next"

import { Container } from '../assets/css/index/styles-home.js'

import Header from "../components/Header"
import SubHead from '../components/SubHead'
import Footer from "../components/Footer"
import Loading from "../components/Loading";
import env from "react-dotenv";

import { registerPage } from "../service/services";
import api from "../service/api";

import AnimationHome from "./AnimationHome"

export default function Home() {
    const [t, i18n] = useTranslation('home')

    const [posts, setPosts] = useState([]);
    const [ptbr, setPtbr] = useState(process.env.REACT_APP_PTBR);
    const [eng, setEng] = useState(process.env.REACT_APP_ENG);


    useEffect(() => {
        AnimationHome();
        registerPage(963);

        api.get('get-posts-blog').then(res => {
            i18n.language == 'en'? 
            setPosts(res.data.data.filter((post, index) => index < 4 && post.linguagem == eng)) :
            setPosts(res.data.data.filter((post, index) => index < 4 && post.linguagem == ptbr));
        });
    }, [])

    return (
        <Container>
           {/* <Loading /> */}
            <Header />
            <main>
                <SubHead />
                <div className={`subheader-webhelp ${i18n.language}`}></div>
                <section className="area-intro">
                    <div className="area-bg-initial">
                        <video autoPlay loop muted playsInline>
                            <source type="video/mp4" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/videos/index/bg-desktop.mp4" />
                        </video>
                    </div>
                    <div className="area-title">
                        <div className="container-medium align-vert">
                            <h1 className="fix-hero-welcome" data-anime="bottom">
                                {t('hero.welcome.part1')} <span className="ciano">{t('hero.welcome.part2')}</span>
                            </h1>
                        </div>
                    </div>
                    <section className="bar-tasks">
                        <div className="area-social-initial">
                            <a href="https://www.facebook.com/gruposervicesbywebhelp" target="_blank"><img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/icon-facebook.svg" /></a>
                            <a href="https://www.instagram.com/gruposervicesbywebhelp/" target="_blank"><img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/icon-instagram.svg" /></a>
                            <a href="https://www.linkedin.com/company/gruposervicesbywebhelp" target="_blank"><img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/icon-linkedin.svg" /></a>
                            <a href="https://www.youtube.com/channel/UCxFcMiEaaMbrdlc99L7C3uQ?sub_confirmation=1" target="_blank"><img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/icon-youtube.svg" /></a>
                        </div>
                        <div className="center-tasks">
                            <p className="title-item">{t('hero.footer.title')}</p>
                            <p className="subtitle-item">{t('hero.footer.subtitle')}</p>
                        </div>
                        <a href="/produtos" className="area-right-tasks">
                            <p>{t('hero.footer.button')}</p>
                            <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/icon-go-right.svg" />
                        </a>
                    </section>
                </section>
                <section className="area-about">
                    <div className="area-scroll">
                        <div className="scroll-01-home"></div>
                        <div className="scroll-02-home"></div>
                    </div>
                    <div className="container-large">
                        <div className="align-boxes">
                            <div className="box-default box-about" data-anime="bottom">
                                <div className="align-content-box">
                                    <div>
                                        <div className="row">
                                            <h2 className="fix-about-card1-title">{t('about.card1.title')}</h2>
                                        </div>
                                        <div className="row">
                                            <p>{t('about.card1.body')}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <a className="bt-link" href="/institucional">
                                            <p>{t('about.card1.button')}</p>
                                            <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/icon-arrow-right.svg" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="box-default box-manifest" data-anime="bottom-2">
                                <div className="align-content-box">
                                    <div className="row">
                                        <h2 className="fix-about-card2-title">{t('about.card2.title')}</h2>
                                    </div>
                                    <div className="row">
                                        <a className="bt-link-video" id="bt-manifest">
                                            <p>{t('about.card2.button')}</p>
                                            <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/icon-play-video.svg" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="area-fixed-home">
                    <section className="area-ads">
                        <div className="container-large">
                            <div className="row align-boxes">
                                <div className="box-default box-ads-1">
                                    <div className="row">
                                        <h2 className="fix-ads-card1-title">{t('ads.card1.title')}</h2>
                                    </div>
                                    <div className="row">
                                        <p>{t('ads.card1.body')}</p>
                                    </div>
                                    <div className="row">
                                        <a href="/cultura" className="bt-link">
                                            <p>{t('ads.card1.button')}</p>
                                            <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/icon-arrow-right.svg" />
                                        </a>
                                    </div>
                                </div>
                                <div className="box-default box-ads-2">
                                    <div className="row">
                                        <h2 className="fix-ads-card2-title">{t('ads.card2.title')}</h2>
                                    </div>

                                    <div className="row">
                                        <a className="bt-link" href="/produtos">
                                            <p>{t('ads.card2.button')}</p>
                                            <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/icon-arrow-right.svg" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="box-default box-ads-3" data-anime="bottom">
                                    <div>
                                        <div className="row">
                                            <h2 className="fix-ads-card3-title">{t('ads.card3.title')}</h2>
                                        </div>
                                        <div className="row">
                                            <p>{t('ads.card3.body')}</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <a className="bt-link" href="/oportunidades">
                                            <p>{t('ads.card3.button')}</p>
                                            <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/icon-arrow-right.svg" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="area-blog">
                        <div className="container-large">
                            <div className="row">
                                <h2>{t('blog.title')}</h2>
                            </div>
                            <div className="row">
                                <p className="text-blog-area">{t('blog.body')}</p>
                            </div>
                            <figure className="border-bottom"></figure>
                            <div className="area-posts-home">
                                <div className="align-posts-home">
                                    {posts.map((post, index) =>
                                        
                                        <a key={index} className="post-default" 
                                        href= {post.linguagem == eng ? `/post/${post.postId}/${post.titulo.replaceAll(' ', '-')}` : `/post/${post.id}/${post.titulo.replaceAll(' ', '-')}`}>
                                            <figure>
                                                <img loading="lazy" src={post.imagem} />
                                                <p>{post.titulo}</p>
                                            </figure>
                                            <p>{post.titulo}</p>
                                        </a>
                                    )}
                                </div>
                            </div>
                        <div className="row center">
                            <a className="bt-link" href="/blog">
                                <p>{t('blog.button')}</p>
                                <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/icon-arrow-right.svg" />
                            </a>
                        </div>
                        </div>
                    </section>
                </section>
            </main>
            <Footer />
        </Container>
    )
}