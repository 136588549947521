import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import Header from "../components/Header"
import Footer from "../components/Footer"
import SubHead from "../components/SubHead";
import Loading from "../components/Loading";
import {Container} from '../assets/css/produtos/styles-produtos.js';
import AnimationProducts from "./AnimationProducts";
import { registerPage } from "../service/services";

export default function Products() {
    const [t, i18n] = useTranslation('products')

    useEffect(() => {
        AnimationProducts();
        registerPage(966);
    }, [])
    
    return (
        <Container>
           {/* <Loading /> */}
            <Header />
            <content>
                <SubHead />
                <section className=" bg-initial">
                    <div className="row center">
                        <button className="bt-down down-blend " id="bt-go-results"></button>
                    </div>
                </section>
                <section className="area-results" id="area-results">
                    <div className="scroll-results" id="scroll-results"></div>
                    <div className="all-motion">
                        <div className="part-1">
                            <div className="align-svg" id="arrow-svg">
                                <video muted playsInline id="video-arrow">
                                    <source type="video/mp4" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/videos/produtos/arrow-desktop.mp4" />
                                </video>
                                <video muted playsInline id="video-arrow-mobile">
                                    <source type="video/mp4" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/videos/produtos/arrow-mobile.mp4" />
                                </video>
                            </div>
                        </div>
                    </div>
                    <div className="container-medium">
                        <h1 className="fix-products-hero">{t('hero.1')} <span id="you-text">{t('hero.2')}</span> {t('hero.3')}</h1>
                    </div>
                    <div className="scroll-results"></div>
                    <div className="row center">
                        {/* <button className="bt-down" id="bt-go-cx"></button> */}
                    </div>
                </section>
                <section className="area-cx" id="area-cx">
                    <div className="area-bg-cx">
                        <video muted playsInline id="bg-cx">
                            <source type="video/mp4" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/videos/produtos/bg-cx.mp4" />
                        </video>
                    </div>
                    <div className="container-medium">
                        <div className="row">
                            <h1 data-anime="bottom">{t('cx.title.1')} <span className="orange">CX,</span><br /> {t('cx.title.2')}<span className="pink">JX.</span></h1>
                        </div>
                        <div className="row center">
                            <p data-anime="bottom-2">{t('cx.body.1')} <b>Journey Experience.</b> {t('cx.body.2')}</p>
                        </div>
                        <div className="row center">
                            <button className="bt-down down-light" id="bt-go-ura"></button>
                        </div>
                    </div>
                </section>
                <section className="area-ura" id="area-ura">
                    <div className="content-ura">
                        <div className="container-medium">
                            <div className="align-ura">
                                <div className="row">
                                    <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/produtos/logo-ura-toptext.png" data-anime="bottom" />
                                </div>
                                <div className="row">
                                    <h1 data-anime="bottom-2">{t('ivr.title')} <span>PERFORMANCE</span> {i18n.language === "pt" && "DO SEU ATENDIMENTO."}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row center">
                        <button className="bt-down down-light" id="bt-go-bot-voz"></button>
                    </div>
                </section>
                <section className="area-bot-voice" id="area-bot-voz">
                    <div className="area-bg-bot-voice">
                        <video autoPlay loop muted playsInline id="video-bot-voice">
                            <source type="video/mp4" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/videos/produtos/bg-bot-voice.mp4" />
                        </video>
                    </div>
                    <div className="container-medium">
                        <div className="row center">
                            <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/produtos/icon-voice-area.png" data-anime="bottom" className="item-motion" />
                        </div>
                        <div className="row center">
                            <h1 className="blue item-motion" data-anime="bottom-2">{t('voice-bot.title')}</h1>
                        </div>
                        <div className="row align-type-bot">
                            <div className="col-item-left item-motion" data-anime="bottom-3">
                                <div className="row flex-end-pc">
                                    <div className="box-type-bot">{t('voice-bot.subtitle.1')}</div>
                                </div>
                                <p>{t('voice-bot.body.1')}</p>
                            </div>
                            <div className="col-item-right item-motion" data-anime="bottom-3">
                                <div className="row flex-start">
                                    <div className="box-type-bot">{t('voice-bot.subtitle.2')}</div>
                                </div>
                                <p>{t('voice-bot.body.2')}</p>
                            </div>
                        </div>
                        <div className="row center">
                            <a className="bt-tertiary item-motion" href="/zeus" data-anime="bottom-4 ">
                                <p>{t('voice-bot.button')}</p>
                            </a>
                            {/* <Link className="bt-tertiary item-motion" to="/zeus" data-anime="bottom-4 ">
                                <p>{t('voice-bot.button')}</p>
                            </Link> */}
                        </div>
                        <div className="row center">
                            <figure className="award-flag item-motion" data-anime="bottom-6">
                                <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/produtos/trophy_awards.svg" />
                                <p>{t('voice-bot.award')}</p>
                            </figure>
                        </div>
                    </div>
                    <div className="row center">
                        <button className="bt-down down-light" id="bt-go-portais"></button>
                    </div>
                </section>

                <section className="area-cronos" id="area-portais">
                    <div className="align-content-cronos">
                        <div className="text-cronos" id="text-cronos">
                            <div className="container-full">
                                <div className="row center">
                                    <h1 className="item-motion fix-products-portals-title">{t('portals.title')}</h1>
                                </div>
                                <div className="row center">
                                    <p className="item-motion fix-products-portals-body">{t('portals.body')}</p>
                                </div>
                            </div>
                        </div>
                        <div className="image-area-cronos">
                            <div className="container-screens">
                            <figure className={`screen-cronos screen-1 ${i18n.language}`}></figure>
                                <figure className={`screen-cronos screen-2 ${i18n.language}`}></figure>
                                <figure className={`screen-cronos screen-3 ${i18n.language}`}></figure>
                                <figure className={`screen-cronos screen-4 ${i18n.language}`}></figure>
                                <figure className={`screen-cronos screen-5 ${i18n.language}`}></figure>
                                <figure className={`screen-cronos screen-6 ${i18n.language}`}></figure>
                                <figure className={`screen-cronos screen-7 ${i18n.language}`}></figure>
                                <figure className="circle circle-1"></figure>
                                <figure className="circle circle-2"></figure>
                                <figure className="circle circle-3"></figure>
                                <figure className="circle circle-4"></figure>
                                <figure className="circle circle-5"></figure>
                                <figure className="circle circle-6"></figure>
                            </div>
                        </div>
                    </div>
                    <div className="scroll-cronos" id="scroll-cronos"></div>
                    <div className="scroll-cronos-2" id="scroll-cronos-2"></div>
                    <div className="row center">
                        <button className="bt-down " id="bt-go-bot-texto"></button>
                    </div>
                </section>


                <section className="area-bot-text" id="area-bot-texto">
                    <div className="area-stick" motion-bot-1="iconBot">
                        <div className="container-medium ">
                            <div className="row center">
                                <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/produtos/icon-bot-text.png" />
                            </div>
                            <div className="row center">
                                <h1>{t('text-bot.title')}</h1>
                            </div>
                            <div className="row center">
                                <p>{t('text-bot.body.1')}</p>
                            </div>
                            <div className="row center">
                                <p className="input-simulate" id="input-simulate">

                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="area-stick-flag">
                        <div className="row center">
                            <figure className="award-flag">
                                <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/produtos/trophy_awards.svg" />
                                <p>{t('text-bot.award')}</p>
                            </figure>
                        </div>
                    </div>

                    <div className="area-icons-bot" id="area-bot-texto">
                        <div className="container-large">
                            <figure className="icon-1" motion-bot-1="iconBot"></figure>
                            <figure className="icon-2" motion-bot-2="iconBot"></figure>
                            <figure className="icon-3" motion-bot-1="iconBot"></figure>
                            <figure className="icon-4" motion-bot-1="iconBot"></figure>
                            <figure className="icon-5" motion-bot-1="iconBot"></figure>
                            <figure className="icon-6" motion-bot-1="iconBot"></figure>
                            <figure className="icon-7" motion-bot-2="iconBot"></figure>
                            <figure className="icon-8" motion-bot-3="iconBot"></figure>
                            <figure className="icon-9" motion-bot-1="iconBot"></figure>
                            <figure className="icon-10" motion-bot-1="iconBot"></figure>
                            <figure className="icon-11" motion-bot="iconBot"></figure>
                            <figure className="icon-12" motion-bot-3="iconBot"></figure>
                            <figure className="icon-13" motion-bot-1="iconBot"></figure>
                            <figure className="icon-14" motion-bot-2="iconBot"></figure>
                        </div>
                    </div>
                    <div className="scroll-fenix"></div>
                    <div className="row center">
                        <button className="bt-down" id="bt-go-consultor-digital"></button>
                    </div>
                </section>
                <section className="area-attendance-digital" id="area-consultor-digital">
                    <div className="container-large">
                        <div className="row">
                            <h1 data-anime="bottom" className="item-motion fix-products-consultant-title">{t('consultant.title')}</h1>
                        </div>
                        <div className="row flex-start">
                            <p data-anime="bottom-2" className="item-motion">{t('consultant.body')}</p>
                        </div>
                    </div>
                    <div className="row center">
                        <button className="bt-down" id="bt-go-atendimento"></button>
                    </div>
                </section>
                <section className="area-human" data-motion="border" id="area-atendimento-humano">
                    <div className="area-video-human">
                        <video autoPlay loop muted playsInline id="bg-human-attendance">
                            <source type="video/mp4" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/videos/produtos/attendance-digital-bg.mp4" />
                        </video>
                    </div>
                    <div className="container-medium">
                        <div className="row">
                            <h1 className="item-motion" data-motion="bottom">{t('human.title')}</h1>
                        </div>
                        <div className="row flex-end">
                            <p data-motion="bottom-2" className="item-motion">{t('human.body')}</p>
                        </div>
                        <div className="row flex-end-pc align-flag">
                            <figure className="award-flag item-motion" data-motion="bottom-3">
                                <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/produtos/trophy_awards.svg" />
                                <p>{t('human.award')}</p>
                            </figure>
                        </div>
                        <div className="row center">
                            <button className="bt-down" id="bt-go-aio"></button>
                        </div>
                    </div>
                </section>

                <section className="area-aio-m">
                    <div className="scroll-m"></div>
                    <div className="element-m">
                        <div className="area-letter-m">
                            <div className="title-area-aio" style={{ opacity: 1 }}>
                                <label className="all">
                                    all&nbsp;
                                </label>

                                <label className="in">
                                    in&nbsp;
                                </label>
                                <label className="one color-verticais-motion">
                                    one.
                                </label>
                            </div>
                        </div>
                        <div className="row center">
                            <div className="phone-m">

                            </div>
                        </div>
                        <div className="space-m">
                            <div className="row center">
                                <div className="logo-m"></div>
                            </div>
                            <div className="align-planet">
                                <div className="planet-m">

                                </div>
                            </div>
                            <div className="align-intern-planet">
                                <div className="intern-planet-m">
                                    <div className="title-aio-m">
                                        {t('aio.title')}<br />ALL IN ONE.
                                    </div>


                                    <div className="bt-link-aio">
                                        <a className="bt-tertiary invert" href="/aio">
                                            <p>{t('aio.button')}</p>
                                        </a>
                                        {/* <Link className="bt-tertiary invert" to="/aio">
                                            <p>{t('aio.button')}</p>
                                        </Link> */}
                                    </div>
                                    <div className="area-icons-m">
                                        <div className="icon-m icon-01-m"></div>
                                        <div className="icon-m icon-02-m"></div>
                                        <div className="icon-m icon-03-m"></div>
                                        <div className="icon-m icon-04-m"></div>
                                        <div className="icon-m icon-05-m"></div>
                                        <div className="icon-m icon-06-m"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="area-aio-1-s" id="area-aio-solution">
                    <div className="row center m-60">
                        <div className="title-area-aio" style={{ opacity: 1 }}>
                            <label className="all">
                                all&nbsp;
                            </label>

                            <label className="in">
                                in&nbsp;
                            </label>
                            <span className="one color-verticais-motion">
                                one.
                            </span>
                        </div>
                    </div>
                    <div className="element" id="element">
                        <div className="area-scroll-s">
                            <div className="scroll-07-01-s" id="scroll-07-01"></div>
                            <div className="scroll-07-02-s" id="scroll-07-02"></div>
                        </div>
                        <div className="content-section" data-scroll="in">
                            <div className="content">
                                <div className="before" id="motion-phone">
                                    <div className="area-mask-aio-solution">
                                        <div className="area-phone-aio-solution" style={{ zIndex: 9994 }}></div>
                                        <div className="content-intern" style={{ zIndex: 9992 }}>
                                            <div className="row center">
                                                <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/logo-aio-light.png" className="logo-aio" style={{ width: 100, marginTop: 100 }} />
                                            </div>
                                            <div className="row center">
                                                <div className="planet-aio">

                                                </div>
                                            </div>
                                            <div className="align-text-aio-1">
                                                <div className="row center-pc m-60 z-9998 motion-style" id="title-center-planet">
                                                    <div className="title-area-aio-02">
                                                        {t('aio.title')}<br /> ALL IN ONE.
                                                    </div>
                                                </div>
                                                <div className="bt-link-aio">
                                                    <a className="bt-tertiary invert" href="/aio">
                                                        <p>{t('aio.button')}</p>
                                                    </a>
                                                    {/* <Link className="bt-tertiary invert" to="/aio">
                                                        <p>{t('aio.button')}</p>
                                                    </Link> */}
                                                </div>
                                                <div className="row area-icons z-9998">
                                                    <div className="scroll-icons"></div>
                                                    <div className="align-icons-motion" id="align-icons">
                                                        <div className="icon-planet icon-planet-01"></div>
                                                        <div className="icon-planet icon-planet-02"></div>
                                                        <div className="icon-planet icon-planet-03"></div>
                                                        <div className="icon-planet icon-planet-04"></div>
                                                        <div className="icon-planet icon-planet-05"></div>
                                                        <div className="icon-planet icon-planet-06"></div>
                                                        <div className="icon-planet icon-planet-07"></div>
                                                        <div className="icon-planet icon-planet-08"></div>
                                                        <div className="icon-planet icon-planet-09"></div>
                                                        <div className="icon-planet icon-planet-10"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="area-scroll-def" id="scroll-001"></div>
                    </div>

                </section>
            </content>
            <Footer />
        </Container>
    )
}