import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import Header from '../components/Header'
import Footer from '../components/Footer'
import SubHead from '../components/SubHead'
import Loading from '../components/Loading'
import AnimationBlog from '../Blog/AnimationBlog'
import { Container } from '../assets/css/thank-you/styles-thank-you.js'
import { registerPage } from '../service/services'

export default function OpportunitiesSuccessful() {
    const [t] = useTranslation('contact')

    useEffect(() => {
        AnimationBlog()
        registerPage(976)
    }, [])

    return (
        <Container>
           {/* <Loading /> */}
            <Header />
            <content>
                <SubHead />
                <section className="area-head-page initial-success bg-blue">
                    <div className="container-medium">
                        <div className="row center">
                            <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/thank-you/icon-success.svg" />
                        </div>
                        <div className="row center">
                            <h2 className="ciano">{t('successJX.title')}</h2>
                        </div>
                        <div className="row center">
                            <p>{t('successJX.body')}</p>
                        </div>

                        <div className="row center">
                            <a className="bt-link" href="/">
                                <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/icon-arrow-left.svg" />
                                <p>{t('successJX.button')}</p>
                            </a>
                            {/* <Link className="bt-link" to="/">
                                <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/icon-arrow-left.svg" />
                                <p>{t('successJX.button')}</p>
                            </Link> */}
                        </div>
                    </div>
                </section>
                <section className="others-posts">
                    <div className="container-medium">
                        <div className="row">
                            <h3 className="black">BLOG</h3>
                            <p>Quer conhecer mais sobre a empresa que está sempre à frente, mas sempre junto de você?<br />
                                Explore abaixo o nosso blog para saber um pouco mais sobre o universo do Grupo Services. </p>
                        </div>
                        <div className="row posts-recommended">
                            <div className="area-post">
                                <a className="post-default" href="/post1">
                                    <figure>
                                        <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/blog/post/thumb-model-1.jpg" />
                                        <label className="date-post-thumb">22/06/2021</label>
                                    </figure>
                                    <p>JXLOVER - Somos apaixonados por proporcionar experiências únicas!</p>
                                </a>
                                {/* <Link className="post-default" to="/post1">
                                    <figure>
                                        <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/blog/post/thumb-model-1.jpg" />
                                        <label className="date-post-thumb">22/06/2021</label>
                                    </figure>
                                    <p>JXLOVER - Somos apaixonados por proporcionar experiências únicas!</p>
                                </Link> */}
                                <a className="post-default" href="/post2">
                                    <figure>
                                        <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/blog/post/thumb-model-2.jpg" />
                                        <label className="date-post-thumb">22/06/2021</label>
                                    </figure>
                                    <p>JXLOVER - Somos apaixonados por proporcionar experiências únicas!</p>
                                </a>
                                {/* <Link className="post-default" to="/post2">
                                    <figure>
                                        <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/blog/post/thumb-model-2.jpg" />
                                        <label className="date-post-thumb">22/06/2021</label>
                                    </figure>
                                    <p>JXLOVER - Somos apaixonados por proporcionar experiências únicas!</p>
                                </Link> */}
                            </div>
                        </div>
                    </div>
                </section>
            </content>
            <Footer />
        </Container>
    )
}