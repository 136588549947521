import styled from "styled-components";

export const Container = styled.main `
@media screen and (min-width: 1200px) {
    .area-form-contact {
        width: 100%;
        display: flex;
        justify-content: center;
        padding-top: 100px;
        padding-bottom: 100px
    }
    .info-2 .input-item {
        width: calc(33.33% - 10px);
        float: left;
    }
    .info-2 {
        display: flex;
        justify-content: space-between;
        margin-top: 15px;
        margin-bottom: 15px;
    }
    .info-1 {
        width: 100%;
        float: left;
        margin-top: 50px;
        display: flex;
        justify-content: space-between;
    }
    .video-initial-contact {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        mix-blend-mode: lighten;
        display: flex;
        justify-content: flex-end;
    }
    .video-initial-contact video {
        height: 100%;
    }
    .initial-contact h2 {
        margin-top: 12px !important
    }
}

@media screen and (max-width: 1199px) {
    .area-form-contact {
        width: 100%;
        display: flex;
        justify-content: center;
        padding-top: 40px;
        padding-bottom: 70px;
    }
    .info-2 .input-item {
        width: 100%;
        float: left;
    }
    .info-2 {
        width: 100%;
        float: left;
    }
    .info-1 {
        width: 100%;
        float: left;
        margin-top: 50px
    }
    .video-initial-contact {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        mix-blend-mode: lighten;
        display: flex;
        justify-content: flex-end;
    }
    .video-initial-contact video {
        height: 55%;
    }
    .bt-primary {
        margin-top: 20px;
        width: 100%;
    }
    .initial-contact {
        height: auto !important;
        padding-bottom: 70px;
        padding-top: 122px;
    }
    .initial-contact h2 {
        font-size: 28px !important;
        line-height: 39px !important;
    }
    .initial-contact h3 {
        font-size: 11px !important;
        line-height: 26px !important;
    }
}
`;