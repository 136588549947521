import { useEffect } from "react"
import { useTranslation } from "react-i18next"

import Footer from "../components/Footer"
import Header from "../components/Header"
import Subhead from "../components/SubHead"
import Loading from "../components/Loading"

// import '../assets/css/aio/styles-aio.css'
import { Container } from '../assets/css/aio/styles-aio'
import AnimationAio from './AnimationAio'

import { registerPage } from "../service/services"

export default function Aio() {
    const [t, i18n] = useTranslation('aio')

    useEffect(() => {
        AnimationAio();
        registerPage(970);
    }, [])

    return (
        <>
           {/* <Loading /> */}
            <Header />
            <Container>
                <content id="aio-aio-container">
                <Subhead />
                <section className="cockpit aio-cockpit" id="cockpit" style={{ backgroundSize: "100%", opacity: 1, backgroundPosition: "center center" }}>
                    <div className="scroll-area aio-scroll-area">
                        <div className="scroll-01-01 aio-scroll-01-01" id="scroll-01-01"></div>
                    </div>
                </section>
                <section className="area-initial aio-area-initial">
                    <div className="all-topo-planet aio-all-topo-planet">
                        <figure className="planet-top aio-planet-top" style={{ opacity: 1, top: "54vh" }}>
                            <figure className="planet-shaddow aio-planet-shaddow"></figure>
                        </figure>
                    </div>
                    <div className="parte-01-01 aio-parte-01-01">
                        <div className="row center">
                            <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/logo-aio.svg" className="logo-aio-initial aio-logo-aio-initial" style={{ width: "120px", opacity: 1 }} />
                        </div>
                    </div>
                </section>

                <section className="about-aio aio-about-aio">
                    <div className="container-large">
                        <div className="col-left aio-col-left">
                            <div className="row">
                                <h1 data-anime="bottom" className="">{t('about.title')}<br /><span className="ciano">ALL IN ONE.</span></h1>
                            </div>
                        </div>
                        <div className="col-right aio-col-right">
                            <p data-anime="bottom" className="">{t('about.body')}</p>
                        </div>
                    </div>
                </section>
                <section className="area-cronos aio-area-cronos">
                    <div className="container-large">
                        <div className="row flex-pc">
                            <div className="col-left aio-col-left">
                                <div className="area-planet aio-area-planet">
                                    <figure className="planet-cronos aio-planet-cronos"></figure>
                                </div>
                                <div className="row">
                                    <h1 data-anime="bottom" className="">CRONOS.</h1>
                                </div>
                                <div className="row">
                                    <h3 data-anime="bottom" className="">{t('cronos.subtitle')}</h3>
                                </div>
                            </div>
                            <div className="col-right aio-col-right">
                                <p data-anime="bottom" className="">{t('cronos.body')}</p>
                            </div>
                        </div>
                        <div className="align-boxes-planet aio-align-boxes-planet">
                            <div className="overflow-boxes-planet aio-overflow-boxes-planet">
                                <div className="box-item-planet aio-box-item-planet" data-anime="bottom-2">
                                    <p>{t('cronos.item.1')}</p>
                                </div>
                                <div className="box-item-planet aio-box-item-planet" data-anime="bottom-3">
                                    <p>{t('cronos.item.2')}</p>
                                </div>
                                <div className="box-item-planet aio-box-item-planet" data-anime="bottom-4">
                                    <p>{t('cronos.item.3')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="area-fenix aio-area-fenix">
                    <div className="container-large">
                        <div className="row flex-pc">
                            <div className="col-left aio-col-left">
                                <div className="area-planet aio-area-planet">
                                    <figure className="planet-fenix aio-planet-fenix"></figure>
                                </div>
                                <div className="row">

                                    <h1 data-anime="bottom" className="animate" style={{ opacity: 0 }}>
                                        FÊNIX.<br />BOTS DE<br /><span className="ciano">TEXTO.</span>
                                    </h1>
                                </div>
                            </div>
                            <div className="col-right">
                                <p data-anime="bottom" className="">{t('fenix.body')} <strong>WhatsApp, Chat, SMS, E-mail {i18n.language === 'pt' ? 'e Ligação' : 'and calls'}.</strong> </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="area-androide aio-area-androide">

                    <div className="container-large">
                        <div className="area-planet aio-area-planet">
                            <figure className="planet-androide aio-planet-androide"></figure>
                        </div>
                        <div className="row center">
                            <h1 data-anime="bottom" className="">{t('android.title')}</h1>
                        </div>
                        <div className="row center">
                            <h3 data-anime="bottom" className="">{t('android.subtitle')}</h3>
                        </div>
                        <div className="row center">
                            <p data-anime="bottom" className="">{t('android.body')}</p>
                        </div>
                    </div>
                </section>
                <section className="area-zeus aio-area-zeus">

                    <div className="container-large">
                        <div className="area-planet aio-area-planet">
                            <figure className="planet-zeus aio-planet-zeus"></figure>
                        </div>
                        <div className="col-left aio-col-left">
                            <div className="row">
                                <h1 data-anime="bottom" className="">{t('zeus.title')}</h1>
                            </div>
                            <div className="row">
                                <p data-anime="bottom" className="">{t('zeus.body.1')}<br /><br />{t('zeus.body.2')}<br /><br />{t('zeus.body.3')}</p>
                            </div>
                            <div className="row flex-end-pc hide-mobile">
                                <a className="bt-tertiary zeus-bt" href="/zeus" data-anime="bottom">
                                    <p>{t('zeus.button')}</p>
                                </a>
                                {/* <Link className="bt-tertiary zeus-bt" to="/zeus" data-anime="bottom">
                                    <p>{t('zeus.button')}</p>
                                </Link> */}
                            </div>
                        </div>
                        <div className="col-right aio-col-right">
                            <div className="align-boxes-planet aio-align-boxes-planet">
                                <div className="overflow-boxes-planet aio-overflow-boxes-planet">
                                    <div className="item-box-zeus aio-item-box-zeus" id="box-zeus-1" style={{ opacity: 0 }}>
                                        <p>{t('zeus.item.1')}</p>
                                    </div>
                                    <div className="item-box-zeus aio-item-box-zeus" id="box-zeus-2" style={{ opacity: 0 }}>
                                        <p>{t('zeus.item.2')}</p>
                                    </div>
                                    <div className="item-box-zeus aio-item-box-zeus" id="box-zeus-3" style={{ opacity: 0 }}>
                                        <p>{t('zeus.item.3')}</p>
                                    </div>
                                    <div className="item-box-zeus aio-item-box-zeus" id="box-zeus-4" style={{ opacity: 0 }}>
                                        <p>{t('zeus.item.4')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row flex-end-pc hide-pc">
                            <a className="bt-tertiary zeus-bt" href="/zeus">
                                <p>{t('zeus.button')}</p>
                            </a>
                            {/* <Link className="bt-tertiary zeus-bt" to="/zeus">
                                <p>{t('zeus.button')}</p>
                            </Link> */}
                        </div>
                    </div>
                </section>

                <section className="area-09 aio-area-09">
                    <div className="area-scroll aio-area-scroll">
                        <div className="scroll-09-01-aio aio-scroll-09-01-aio" id="scroll-09-01-aio"></div>
                    </div>
                    <div className="area-scroll-09 aio-area-scroll-09">
                        <div className="scroll-09-01 aio-scroll-09-01"></div>
                        <div className="scroll-09-02 aio-scroll-09-02"></div>
                        <div className="scroll-09-03 aio-scroll-09-03"></div>
                        <div className="scroll-09-04 aio-scroll-09-04"></div>
                    </div>
                    <div className="align-09 aio-align-09">
                        <div className="content-09 aio-content-09" style={{ backgroundPositionY: "-2906px", width: "100%", height: "100vh", borderRadius: "0px" }}>
                            <div className="align-title-aio-final aio-align-title-aio-final padrao-animacao aio-padrao-animacao">
                                <div className="title-aio-final aio-title-aio-final">{t('footer.title.1')}<br />
                                    <label className="gradiente-aio aio-gradiente-aio">{t('footer.title.2')}</label>
                                </div>
                            </div>
                            <div className="container-09 aio-container-09 op-9 aio-op-9" style={{ opacity: 1 }}>
                                <section className="area-nr aio-area-nr">

                                    <div className="container-large">
                                        <div className="area-planet aio-area-planet">
                                            <figure className="planet-nr17 aio-planet-nr17"></figure>
                                        </div>
                                        <div className="row center">
                                            <h1 data-anime="bottom" className="animate">{t('nr17.title.1')}<br /><span className="ciano">{t('nr17.title.2')}</span>
                                            </h1>
                                        </div>
                                        <div className="row center">
                                            <p data-anime="bottom" className="animate">{t('nr17.body')}</p>
                                        </div>
                                        <div className="align-boxes-planet aio-align-boxes-planet">
                                            <div className="overflow-boxes-planet aio-overflow-boxes-planet">
                                                <div className="box-item-planet aio-box-item-planet">
                                                    <p>{t('nr17.item.1')}</p>
                                                </div>
                                                <div className="box-item-planet aio-box-item-planet">
                                                    <p>{t('nr17.item.2')}</p>
                                                </div>
                                                <div className="box-item-planet aio-box-item-planet">
                                                    <p>{t('nr17.item.3')}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                            <div className="align-text-aio-final aio-align-text-aio-final" style={{ opacity: 0 }}>
                                <a className="bt-commercial aio-bt-commercial" href="/fale-conosco">
                                    <p>{t('footer.button')}</p>
                                </a>
                                {/* <Link className="bt-commercial" to="/fale-conosco">
                                    <p>{t('footer.button')}</p>
                                </Link> */}
                            </div>
                        </div>
                        <div className="logo-aio-fechamento aio-logo-aio-fechamento" style={{ opacity: 0 }}></div>
                    </div>
                </section>
                </content>
            </Container>
            <Footer />
        </>
    )
}