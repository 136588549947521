import $ from 'jquery'

export default function AnimationInstitutional() {
    window.onload = () => {
        setTimeout(() => {
            $('.loading').hide();
        }, 500)
    }
    // PS. Esse documento possui scripts da página INDEX executados apenas em dispositivos MOBILE.
    $(document).ready(function () {

        $('.bt-accept').click(function () {
            $('.component-privacy').hide();
            $('body').removeClass('privacy-active');
        });


        $('.bt-close-video').click(function () {
            $(".all-video-modal").hide();
            $('#item-video-jx').trigger('pause');
        });

        $('.subhead-zeus').click(function () {
            window.location.href = "/zeus";
        });

    });
    // PS. Esse documento possui scripts da página INDEX executados apenas em dispositivos MOBILE.
    $(document).ready(function () {

        if ($(window).width() < 1200) {

            $('.subhead-zeus').click(function () {
                window.location.href = "/zeus";
            });
        }
    });
    $(document).ready(function () {
        if ($(window).width() > 1200) {
            function scrollDetect() {
                var lastScroll = 0;
                var count = 0;
                var tp1 = -800;
                var tp2 = -300;
                window.onscroll = function () {
                    let currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
                    if (currentScroll > 0 && lastScroll <= currentScroll) {
                        lastScroll = currentScroll;
                        count = count + 1;
                        tp1 = tp1 + 4;
                        tp2 = tp2 - 4;

                        $('.typography-03-01').css({
                            'margin-left': tp1 / 2,
                        });
                        $('.typography-03-02').css({
                            'margin-left': tp2 / 2,
                        });
                    } else {
                        lastScroll = currentScroll;
                        count = count - 1;
                        tp1 = tp1 - 4;
                        tp2 = tp2 + 4;
                        $('.typography-03-01').css({
                            'margin-left': tp1 / 2,
                        });
                        $('.typography-03-02').css({
                            'margin-left': tp2 / 2,
                        });
                    }
                };
            }
            scrollDetect();

            var height = $(window).height();
            // ESCURECER OS TITLE PRINCIPAIS
            $(window).scroll(function () {
                if ($(window).scrollTop() > height * 0.400) {
                    $('.align-content-main').css({
                        'opacity': '0'
                    });
                    $('.initial-corporate').css({
                        'background-position': 'center 12%'
                    });
                } else if ($(window).scrollTop() > height * 0.250) {
                    $('.align-content-main').css({
                        'opacity': '0.3'
                    });
                    $('.initial-corporate').css({
                        'background-position': 'center 8%'
                    });

                } else if ($(window).scrollTop() > height * 0.90) {
                    $('.align-content-main').css({
                        'opacity': '0.6'
                    });
                    $('.initial-corporate').css({
                        'background-position': 'center 4%'
                    });
                } else if ($(window).scrollTop() < height * 0.003) {
                    $('.align-content-main').css({
                        'opacity': '1.0'
                    });
                    $('.initial-corporate').css({
                        'background-position': 'center 0%'
                    });
                }
            });

            var count = 10;
            $(function () {
                var lastScrollTop = 0,
                    delta = 5;
                $(window).scroll(function () {
                    var nowScrollTop = $(this).scrollTop();
                    if (Math.abs(lastScrollTop - nowScrollTop) >= delta) {
                        if (nowScrollTop > lastScrollTop) {
                            count += 1;

                            $('.area-world').css({
                                'background': 'linear-gradient( ' + count + 'deg, rgb(0, 0, 0) 0%, rgb(17 63 104) 100%)',
                            });

                        } else {
                            count -= 1;

                            $('.area-world').css({
                                'background': 'linear-gradient( ' + count + 'deg, rgb(0, 0, 0) 0%, rgb(17 63 104) 100%)',
                            });
                        }
                        lastScrollTop = nowScrollTop;
                    }
                });
            });

            // INICIO - PLANET VIDEO
            var videoPlaneta = false;
            $(window).scroll(function () {
                var scrollTop = $(window).scrollTop();
                if ((scrollTop > $('#scroll-planet-1').offset().top) && (videoPlaneta == false)) {
                    $('.align-word').css({
                        'opacity': '1.0',

                    });

                    $('.align-word').css({

                        'padding-top': 'calc(50vh - 100px)',

                    });
                    $('#border-planet').css({
                        'width': '330px',
                    });
                    $('#video-planet').trigger('play');
                    setTimeout(function () {
                        $('html').animate({
                            scrollTop: $('#scroll-planet-2').offset().top + 100
                        }, 100);
                    }, 8000);
                }
            });

            $(window).scroll(function () {
                var scrollTop = $(window).scrollTop();
                if ((scrollTop > $('#scroll-planet-2').offset().top)) {
                    $('.align-planet').css({
                        'width': 'calc(50% - 600px + 713px',
                        'margin-top': '100px',
                    });
                    $('.align-word').css({

                        'padding-top': '80px',

                    });
                    $('.box-planet').css({
                        'height': '67vh',
                        'top': '15vh',
                        'border-radius': '40px 0px 0px 40px',
                        'padding-bottom': '0vh',
                    });
                    $('.planet-item').css({
                        'top': '0px',
                        'left': '415px',
                    });
                    setTimeout(function () {
                        videoPlaneta = true;
                    }, 100);
                    setTimeout(function () {
                        $('.area-text-world').css({
                            'opacity': '1',
                            'left': '0px',
                        });

                    }, 500);
                }
            });


            $(window).scroll(function () {
                var scroll = $(window).scrollTop();
                var typo1 = 0 + scroll;
                $('.align-content-main').css({
                    'margin-top': +typo1 / 4.5,
                });
            });

            $(window).scroll(function () {
                if ($(window).scrollTop() > $('.scroll-manifest').offset().top + 100) {
                    $('.thumb-manifest').css({
                        'border': '0px solid #fff',
                        'margin-top': '80px',
                        'border-radius': '0px'
                    });
                } else {
                    $('.thumb-manifest').css({
                        'border': '50px solid #fff',
                        'margin-top': '110px',
                        'border-radius': '80px'
                    });
                }
            });
            $('#bt-right-segments').click(function () {
                $(".area-slider-segments").animate({
                    scrollLeft: 1200
                }, 500);
            });

            $('#bt-left-segments').click(function () {
                $(".area-slider-segments").animate({
                    scrollLeft: 0
                }, 500);
            });
            $('#link-about').click(function () {
                $('html').animate({
                    scrollTop: $('.area-about').offset().top - 50
                }, 200);
            });
            $('#bt-page-down').click(function () {
                $('html').animate({
                    scrollTop: $('.area-about').offset().top - 50
                }, 200);
            });
            $('#bt-about').click(function () {
                $('html').animate({
                    scrollTop: $('.area-about').offset().top - 50
                }, 200);
            });

            $('#link-mission').click(function () {
                $('html').animate({
                    scrollTop: $('.area-mission').offset().top - 400
                }, 200);
            });

            $('#link-culture').click(function () {
                $('html').animate({
                    scrollTop: $('.area-culture').offset().top
                }, 200);
            });

            $('#link-estruture').click(function () {
                $('html').animate({
                    scrollTop: $('#area-estruture').offset().top
                }, 200);
            });

            $('#link-manifest').click(function () {
                $('html').animate({
                    scrollTop: $('#area-manifest').offset().top
                }, 200);
            });

            $(window).scroll(function () {
                if ($(window).scrollTop() > $('.area-culture').offset().top - 400) {
                    $('.area-culture .border-title').css({
                        'width': '320px',
                    });
                    $('.area-culture h1').css({
                        'opacity': '1',
                    });
                    $('.area-culture p').css({
                        'opacity': '1',
                    });
                    $('.area-culture a').css({
                        'opacity': '1',
                    });

                } else {
                    $('.area-culture .border-title').css({
                        'width': '0px',
                    });
                    $('.area-culture h1').css({
                        'opacity': '0',
                    });
                    $('.area-culture p').css({
                        'opacity': '0',
                    });
                    $('.area-culture a').css({
                        'opacity': '0',
                    });
                }
            });

            $(window).scroll(function () {
                if ($(window).scrollTop() > $('.area-manifest').offset().top - 700) {
                    $('.area-manifest .border-title').css({
                        'width': '320px',
                    });
                    $('.area-manifest h1').css({
                        'opacity': '1.0',
                    });

                    $('.area-manifest p').css({
                        'opacity': '1.0',
                    });
                } else {
                    $('.area-manifest .border-title').css({
                        'width': '0px',
                    });
                    $('.area-manifest h1').css({
                        'opacity': '0',
                    });
                    $('.area-manifest p').css({
                        'opacity': '0',
                    });
                }
            });

            $('#bt-view-manifest').click(function () {
                // $("#video-manifest").show();
                localStorage.setItem('_routeBack', '/institucional');
                window.location.href = '/manifesto';
                $('#item-video-manifest').trigger('play');
                // $('#item-video-manifest').muted = false;
            });

            // $('#bt-view-jx').click(function () {
            //     $("#video-jx").show();
            //     $('#item-video-jx').trigger('play');
            // });

            $(window).scroll(function () {
                if ($(window).scrollTop() > $('#area-estruture').offset().top - 600) {
                    $('#area-estruture .border-title').css({
                        'width': '320px',
                    });
                    $('#area-estruture h1').css({
                        'opacity': '1',
                    });
                    $('#area-estruture p').css({
                        'opacity': '1',
                    });

                } else {
                    $('#area-estruture .border-title').css({
                        'width': '0px',
                    });
                    $('#area-estruture h1').css({
                        'opacity': '0',
                    });
                    $('#area-estruture p').css({
                        'opacity': '0',
                    });
                }
            });
            $(window).scroll(function () {
                if ($(window).scrollTop() > $('.area-cx').offset().top - 600) {

                    $('.area-cx h1').css({
                        'opacity': '1',
                    });
                    $('.area-cx h3').css({
                        'opacity': '1',
                    });

                    $("#box-cx-1").addClass('animate');
                    $("#box-cx-2").addClass('animate');
                    $("#box-cx-3").addClass('animate');
                    $("#box-cx-4").addClass('animate');
                    $("#box-cx-5").addClass('animate');
                    $("#box-cx-6").addClass('animate');
                    $("#box-cx-7").addClass('animate');
                    $("#box-cx-8").addClass('animate');

                } else {
                    $('.area-cx h1').css({
                        'opacity': '0',
                    });
                    $('.area-cx h3').css({
                        'opacity': '0',
                    });
                    $("#box-cx-1").removeClass('animate');
                    $("#box-cx-2").removeClass('animate');
                    $("#box-cx-3").removeClass('animate');
                    $("#box-cx-4").removeClass('animate');
                    $("#box-cx-5").removeClass('animate');
                    $("#box-cx-6").removeClass('animate');
                    $("#box-cx-7").removeClass('animate');
                    $("#box-cx-8").removeClass('animate');
                }
            });
        }
    });
    // PS. Esse documento possui scripts da página INSTITUCIONAL executados apenas em  MOBILE.
    $(document).ready(function () {

        if ($(window).width() < 1200) {
            function scrollDetect() {
                var lastScroll = 0;
                var count = 0;
                var tp1 = -800;
                var tp2 = -300;
                window.onscroll = function () {
                    let currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
                    if (currentScroll > 0 && lastScroll <= currentScroll) {
                        lastScroll = currentScroll;
                        count = count + 1;
                        tp1 = tp1 + 1;
                        tp2 = tp2 - 1;

                        $('.typography-03-01').css({
                            'margin-left': tp1 / 2,
                        });
                        $('.typography-03-02').css({
                            'margin-left': tp2 / 2,
                        });
                    } else {
                        lastScroll = currentScroll;
                        count = count - 1;
                        tp1 = tp1 - 1;
                        tp2 = tp2 + 1;
                        $('.typography-03-01').css({
                            'margin-left': tp1 / 2,
                        });
                        $('.typography-03-02').css({
                            'margin-left': tp2 / 2,
                        });
                    }
                };
            }
            scrollDetect();

            var height = $(window).height();
            // ESCURECER OS TITLE PRINCIPAIS
            $(window).scroll(function () {
                if ($(window).scrollTop() > height * 0.400) {
                    $('.align-content-main').css({
                        'opacity': '0'
                    });
                    $('.initial-corporate').css({
                        'background-position': 'center 12%'
                    });
                } else if ($(window).scrollTop() > height * 0.250) {
                    $('.align-content-main').css({
                        'opacity': '0.3'
                    });
                    $('.initial-corporate').css({
                        'background-position': 'center 8%'
                    });

                } else if ($(window).scrollTop() > height * 0.90) {
                    $('.align-content-main').css({
                        'opacity': '0.6'
                    });
                    $('.initial-corporate').css({
                        'background-position': 'center 4%'
                    });
                } else if ($(window).scrollTop() < height * 0.003) {
                    $('.align-content-main').css({
                        'opacity': '1.0'
                    });
                    $('.initial-corporate').css({
                        'background-position': 'center 0%'
                    });
                }
            });

            var count = 10;
            $(function () {
                var lastScrollTop = 0,
                    delta = 5;
                $(window).scroll(function () {
                    var nowScrollTop = $(this).scrollTop();
                    if (Math.abs(lastScrollTop - nowScrollTop) >= delta) {
                        if (nowScrollTop > lastScrollTop) {
                            count += 1;

                            $('.area-world').css({
                                'background': 'linear-gradient( ' + count + 'deg, rgb(0, 0, 0) 0%, rgb(17 63 104) 100%)',
                            });

                        } else {
                            count -= 1;

                            $('.area-world').css({
                                'background': 'linear-gradient( ' + count + 'deg, rgb(0, 0, 0) 0%, rgb(17 63 104) 100%)',
                            });
                        }
                        lastScrollTop = nowScrollTop;
                    }
                });
            });

            // INICIO - PLANET VIDEO
            var videoPlaneta = false;
            $(window).scroll(function () {
                var scrollTop = $(window).scrollTop();
                if ((scrollTop > $('#scroll-planet-1').offset().top) && (videoPlaneta == false)) {
                    $('.align-word').css({
                        'opacity': '1.0',
                    });
                    $('#border-planet').css({
                        'width': '100%',
                    });
                    $('#video-planet').trigger('play');
                    setTimeout(function () {
                        videoPlaneta = false
                    }, 100);
                }
            });

            $('#video-planet').on("ended", function () {
                $('.align-planet').css({
                    'width': 'calc(100vw - 40px)',
                    'margin-top': '40px',
                    'margin-left': '40px',
                });

                $('.align-word').css({
                    'padding-top': 'calc(50vh - 406px)',
                });
                $('.box-planet').css({
                    'height': '400px',
                    'border-radius': '40px 0px 0px 40px',
                    'padding-bottom': '0vh',
                });
                $('.planet-item').css({
                    'top': '0px',
                    'left': '415px',
                });
                setTimeout(function () {
                    videoPlaneta = true;
                }, 100);
                setTimeout(function () {
                    $('.area-text-world').css({
                        'opacity': '1',
                        'left': '0px',
                    });

                }, 500);

            });


            $(window).scroll(function () {
                var scroll = $(window).scrollTop();
                var typo1 = 0 + scroll;
                $('.align-content-main').css({
                    'margin-top': +typo1 / 4.5,
                });
            });

            $(window).scroll(function () {
                if ($(window).scrollTop() > $('.scroll-manifest').offset().top + 100) {
                    $('.thumb-manifest').css({
                        'border': '0px solid #fff',

                        'border-radius': '0px'
                    });
                } else {
                    $('.thumb-manifest').css({
                        'border': '15px solid #fff',

                        'border-radius': '6px'
                    });
                }
            });
            $('#bt-right-segments').click(function () {
                $(".area-slider-segments").animate({
                    scrollLeft: 1200
                }, 500);
            });

            $('#bt-left-segments').click(function () {
                $(".area-slider-segments").animate({
                    scrollLeft: 0
                }, 500);
            });

            $('#bt-page-down').click(function () {
                $('html').animate({
                    scrollTop: $('.area-about').offset().top - 50
                }, 200);
            });

            $('.bt-down').click(function () {
                $('html').animate({
                    scrollTop: $('.area-about').offset().top - 50
                }, 200);
            });

            $('#link-mission').click(function () {
                $('html').animate({
                    scrollTop: $('.area-mission').offset().top - 400
                }, 200);
            });

            $('#link-culture').click(function () {
                $('html').animate({
                    scrollTop: $('.area-culture').offset().top
                }, 200);
            });

            $('#link-estruture').click(function () {
                $('html').animate({
                    scrollTop: $('#area-estruture').offset().top
                }, 200);
            });

            $('#link-manifest').click(function () {
                $('html').animate({
                    scrollTop: $('#area-manifest').offset().top
                }, 200);
            });

            $(window).scroll(function () {
                if ($(window).scrollTop() > $('.area-culture').offset().top - 400) {
                    $('.area-culture .border-title').css({
                        'width': '100%',
                    });
                    $('.area-culture h1').css({
                        'opacity': '1',
                    });
                    $('.area-culture p').css({
                        'opacity': '1',
                    });
                    $('.area-culture a').css({
                        'opacity': '1',
                    });

                } else {
                    $('.area-culture .border-title').css({
                        'width': '0px',
                    });
                    $('.area-culture h1').css({
                        'opacity': '0',
                    });
                    $('.area-culture p').css({
                        'opacity': '0',
                    });
                    $('.area-culture a').css({
                        'opacity': '0',
                    });
                }
            });

            $(window).scroll(function () {
                if ($(window).scrollTop() > $('.area-manifest').offset().top - 600) {
                    $('.area-manifest .border-title').css({
                        'width': '100%',
                    });
                    $('.area-manifest h1').css({
                        'opacity': '1.0',
                    });

                    $('.area-manifest p').css({
                        'opacity': '1.0',
                    });
                } else {
                    $('.area-manifest .border-title').css({
                        'width': '0px',
                    });
                    $('.area-manifest h1').css({
                        'opacity': '0',
                    });
                    $('.area-manifest p').css({
                        'opacity': '0',
                    });
                }
            });

            $('#bt-view-manifest').click(function () {
                // $("#video-manifest").show();
                // $('#item-video-manifest').trigger('play');
                localStorage.setItem('_routeBack', '/institucional');
                window.location.href = '/manifesto';
                $('#item-video-manifest').trigger('play');
            });

            // $('#bt-view-jx').click(function () {
            //     $("#video-jx").show();
            //     $('#item-video-jx').trigger('play');
            // });

            $(window).scroll(function () {
                if ($(window).scrollTop() > $('#area-estruture').offset().top - 600) {
                    $('#area-estruture .border-title').css({
                        'width': '100%',
                    });
                    $('#area-estruture h1').css({
                        'opacity': '1',
                    });
                    $('#area-estruture p').css({
                        'opacity': '1',
                    });

                } else {
                    $('#area-estruture .border-title').css({
                        'width': '0px',
                    });
                    $('#area-estruture h1').css({
                        'opacity': '0',
                    });
                    $('#area-estruture p').css({
                        'opacity': '0',
                    });
                }
            });
            $(window).scroll(function () {
                if ($(window).scrollTop() > $('.area-cx').offset().top - 600) {

                    $('.area-cx h1').css({
                        'opacity': '1',
                    });
                    $('.area-cx h3').css({
                        'opacity': '1',
                    });

                    $("#box-cx-1").addClass('animate');
                    $("#box-cx-2").addClass('animate');
                    $("#box-cx-3").addClass('animate');
                    $("#box-cx-4").addClass('animate');
                    $("#box-cx-5").addClass('animate');
                    $("#box-cx-6").addClass('animate');
                    $("#box-cx-7").addClass('animate');
                    $("#box-cx-8").addClass('animate');

                } else {
                    $('.area-cx h1').css({
                        'opacity': '0',
                    });
                    $('.area-cx h3').css({
                        'opacity': '0',
                    });
                    $("#box-cx-1").removeClass('animate');
                    $("#box-cx-2").removeClass('animate');
                    $("#box-cx-3").removeClass('animate');
                    $("#box-cx-4").removeClass('animate');
                    $("#box-cx-5").removeClass('animate');
                    $("#box-cx-6").removeClass('animate');
                    $("#box-cx-7").removeClass('animate');
                    $("#box-cx-8").removeClass('animate');
                }
            });
        }
    });
    const debounce = function (func, wait, immediate) {
        let timeout;
        return function (...args) {
            const context = this;
            const later = function () {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            const callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    };

    const target = document.querySelectorAll('[data-anime]');
    const animationClass = 'animate';

    function animeScroll() {
        const windowTop = window.pageYOffset + ((window.innerHeight * 4) / 4);
        target.forEach(function (element) {
            if ((windowTop) > element.offsetTop) {
                element.classList.add(animationClass);
            } else {
                element.classList.remove(animationClass);
            }
        })
    }

    animeScroll();

    if (target.length) {
        window.addEventListener('scroll', debounce(function () {
            animeScroll();
        }, 0));
    }
}