import $ from 'jquery'

export default function AnimationPrivacy() {
    window.onload = () => {
        setTimeout(() => {
            $('.loading').hide();
        }, 500)
    }
    $(window).ready(function () {
        setTimeout(function () {
            $(window).scrollTop(0);
            // $('.loading').hide();
            setTimeout(function () {


                $('[motion-bar]').addClass('animate-bar');
            }, 300);
        });
    });
    // PS. Esse documento possui scripts da página INDEX executados apenas em dispositivos MOBILE.
    $(document).ready(function () {

        $('.bt-accept').click(function () {
            $('.component-privacy').hide();
            $('body').removeClass('privacy-active');
        });


        $('.bt-close-video').click(function () {
            $(".all-video-modal").hide();
            $('video').trigger('pause');
        });

        $('.subhead-zeus').click(function () {
            window.location.href = "/zeus";
        });
    });
    


    const debounce = function (func, wait, immediate) {
        let timeout;
        return function (...args) {
            const context = this;
            const later = function () {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            const callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    };

    const target = document.querySelectorAll('[data-anime]');
    const animationClass = 'animate';

    function animeScroll() {
        const windowTop = window.pageYOffset + ((window.innerHeight * 4) / 4);
        target.forEach(function (element) {
            if ((windowTop) > element.offsetTop) {
                element.classList.add(animationClass);
            } else {
                element.classList.remove(animationClass);
            }
        })
    }

    animeScroll();

    if (target.length) {
        window.addEventListener('scroll', debounce(function () {
            animeScroll();
        }, 0));
    }
    $(document).ready(function () {
        $('#link-01').click(function () {
            window.scrollTo({
                top:  $('#parte-01').offset().top - 50,
                behavior: 'smooth'
            })
            // $('body').animate({
            //     scrollTop: $('#parte-01').offset().top - 50
            // }, 100);
        });

        $('#link-02').click(function () {
            window.scrollTo({
                top:  $('#parte-02').offset().top - 50,
                behavior: 'smooth'
            })
            // $('body').animate({
            //     scrollTop: $('#parte-02').offset().top - 50
            // }, 100);
        });

        $('#link-03').click(function () {
            window.scrollTo({
                top:  $('#parte-03').offset().top - 50,
                behavior: 'smooth'
            })
            // $('body').animate({
            //     scrollTop: $('#parte-03').offset().top - 50
            // }, 100);
        });


        $('#link-04').click(function () {
            window.scrollTo({
                top:  $('#parte-04').offset().top - 50,
                behavior: 'smooth'
            })
            // $('body').animate({
            //     scrollTop: $('#parte-04').offset().top - 50
            // }, 100);
        });

        $('#link-05').click(function () {
            window.scrollTo({
                top:  $('#parte-05').offset().top - 50,
                behavior: 'smooth'
            })
            // $('body').animate({
            //     scrollTop: $('#parte-05').offset().top - 50
            // }, 100);
        });



        $(window).scroll(function () {
            var scrollTop = $(window).scrollTop();
            var parte1 = $('#parte-01').offset();
            if (scrollTop > parte1.top - 70) {
                $('#link-01').addClass('bt-privacy-active');
                $('#link-02').removeClass('bt-privacy-active');
                $('#link-03').removeClass('bt-privacy-active');
                $('#link-04').removeClass('bt-privacy-active');
                $('#link-05').removeClass('bt-privacy-active');
            }
        });
        $(window).scroll(function () {
            var scrollTop = $(window).scrollTop();
            var parte2 = $('#parte-02').offset();
            if (scrollTop > parte2.top - 70) {
                $('#link-01').removeClass('bt-privacy-active');
                $('#link-02').addClass('bt-privacy-active');
                $('#link-03').removeClass('bt-privacy-active');
                $('#link-04').removeClass('bt-privacy-active');
                $('#link-05').removeClass('bt-privacy-active');
            }
        });

        $(window).scroll(function () {
            var scrollTop = $(window).scrollTop();
            var parte3 = $('#parte-03').offset();
            if (scrollTop > parte3.top - 70) {
                $('#link-01').removeClass('bt-privacy-active');
                $('#link-02').removeClass('bt-privacy-active');
                $('#link-03').addClass('bt-privacy-active');
                $('#link-04').removeClass('bt-privacy-active');
                $('#link-05').removeClass('bt-privacy-active');
            }
        });

        $(window).scroll(function () {
            var scrollTop = $(window).scrollTop();
            var parte4 = $('#parte-04').offset();
            if (scrollTop > parte4.top - 70) {
                $('#link-01').removeClass('bt-privacy-active');
                $('#link-02').removeClass('bt-privacy-active');
                $('#link-03').removeClass('bt-privacy-active');
                $('#link-04').addClass('bt-privacy-active');
                $('#link-05').removeClass('bt-privacy-active');
            }
        });
        $(window).scroll(function () {
            var scrollTop = $(window).scrollTop();
            var parte5 = $('#parte-05').offset();
            if (scrollTop > parte5.top - 170) {
                $('#link-01').removeClass('bt-privacy-active');
                $('#link-02').removeClass('bt-privacy-active');
                $('#link-03').removeClass('bt-privacy-active');
                $('#link-04').removeClass('bt-privacy-active');
                $('#link-05').addClass('bt-privacy-active');
            }
        });
    });
}