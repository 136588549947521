import styled from "styled-components";

export const Container = styled.div `
@media (max-width: 1300px) {
    .mask {
        width: 100%;
        height: 100vh;
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/zeus/mask.svg');
        background-size: 130%;
        background-repeat: no-repeat;
        background-position: center center;
        position: sticky;
        top: 0px;
        left: 0px;
        transition: all 0.8s ease;
    }
    .mask-expanded {
        background-size: 445%
    }
}

@media (min-width: 1300px) {
    .mask {
        width: 100%;
        height: 100vh;
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/zeus/mask.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center center;
        position: sticky;
        top: 0px;
        left: 0px;
        transition: all 0.8s ease;
        margin-top: -28px;
    }
    .mask-expanded {
        background-size: 500%
    }
}

@media screen and (min-width: 1200px) {
    .initial-zeus {
        height: 100vh !important;
        background-color: black !important;
    }
    .bt-play-zeus {
        background-color: transparent;
        border: 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 40px;
        height: 9px;
    }
    .bt-play-zeus p {
        color: white;
        font-weight: 700;
        margin-left: 8px;
        margin-right: 12px;
        font-size: 14px;
    }
    .bt-play-zeus .icon-play {
        filter: brightness(5.0) grayscale(1.0);
    }
    .icon-wave {
        width: 35px !important;
        margin: 0px !important;
        display: none
    }
    .align-title-item {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    .area-agent {
        width: 100%;
        min-height: 800px;
        background-color: #010101;
        float: left;
        display: flex;
        justify-content: center;
        padding-top: 140px;
        color: white;
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/zeus/bg-head-zeus.jpg');
        background-position: bottom;
        background-size: 100%;
        background-repeat: no-repeat;
        z-index: 10;
        position: relative;
    }
    .head-zeus-figure {
        width: 100%;
        height: 700px;
        float: left;
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/zeus/head-zeus.png');
        background-position: top;
        background-repeat: no-repeat;
        background-size: 100%;
        margin-top: 20px;
    }
    .area-award {
        width: 100%;
        min-height: 100%;
        float: left;
        padding-top: 220px;
        padding-bottom: 130px;
        display: flex;
        justify-content: center;
        background-color: #000000;
        flex-direction: column;
        align-items: center;
    }
    .area-award .container-large {
        width: calc(100% - 70px) !important;
    }
    .title-decorate-award {
        height: 50px;
        background-color: #FED843;
        color: Black;
        border-radius: 6px;
        padding-left: 100px;
        padding-right: 100px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 3px;
        border-radius: 6px;
        overflow: hidden;
        margin-top: 5px;
        margin-bottom: 15px;
    }
    .title-decorate-award p {
        font-weight: 700;
        font-size: 40px;
        text-transform: uppercase;
        line-height: 58px;
        z-index: 5;
        position: relative;
    }
    .title-decorate-award:after {
        width: 50%;
        height: 50px;
        float: left;
        content: "";
        background-color: #FABE2E;
        border-color: black;
        position: absolute;
        right: 0px;
        top: 0px
    }
    .title-area-award {
        width: 100%;
        float: left;
        color: white;
    }
    .align-title-award {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .title-area-award img {
        margin-bottom: 30px;
    }
    .title-award {
        float: left;
        color: white;
    }
    .title-award img {
        width: 50px;
        margin-bottom: 20px;
    }
    .area-boxes-award {
        width: 1250px;
        float: left;
        margin-top: 120px;
        display: flex;
        justify-content: space-between;
    }
    .box-award {
        width: calc(33.33% - 25px);
        padding-bottom: calc(33.33% - 25px);
        float: left;
        background-color: #070707;
        border-radius: 25px;
        position: relative;
        opacity: 0;
        transition: all 0.4s linear !important;
    }
    .content-intern-award {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        padding: 40px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }
    .box-award img {
        width: 100px;
        margin-bottom: 40px;
    }
    .title-award {
        font-size: 24px;
        text-transform: uppercase;
        font-weight: 600;
        float: left;
        line-height: 38px;
    }
    .year-award {
        font-size: 12px;
        margin-top: -6px;
        float: left;
        color: white;
        margin-left: 5px;
    }
    .subtitle-award {
        width: 100%;
        float: left;
        font-size: 14px;
        color: white;
        font-weight: 400;
        text-transform: uppercase;
        border-bottom: 1px solid rgb(49, 49, 49);
        padding-bottom: 8px;
    }
    .text-award {
        width: 100%;
        float: left;
        font-size: 15px;
        color: white;
        font-weight: 400;
        padding-top: 8px;
    }
    .area-spotlight-zeus {
        width: 100%;
        float: left;
    }
    .item-spotlight {
        width: 100%;
        float: left;
        background-color: rgb(20, 20, 20);
        display: flex;
        position: relative;
    }
    .item-spotlight .col-title {
        width: 50%;
        float: left;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        opacity: 0;
    }
    .item-spotlight .col-title img {
        width: 55px;
        margin-bottom: 30px;
    }
    .item-spotlight .col-title p {
        color: white;
        font-weight: 700;
        text-align: center;
        font-size: 40px;
        line-height: 50px;
    }
    .item-spotlight .col-title label {
        font-weight: 700;
        text-align: center;
        font-size: 40px;
        line-height: 50px;
    }
    .area-01 {
        background-color: #070707;
    }
    .col-title figure {
        width: 185px;
        height: 12px;
        float: left;
        margin-top: 30px;
        background-color: #1d1d1d;
    }
    .area-03 label {
        font-size: 26px !important;
        margin-top: 10px;
        line-height: 35px !important;
    }
    .area-01 .col-bg {
        position: relative;
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/zeus/spotlight-01.jpg');
    }
    .area-02 {
        background-color: #0B0B0B;
    }
    .area-02 .col-bg {
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/zeus/spotlight-02.jpg');
    }
    .area-03 {
        background-color: #0d0d0d;
    }
    .area-03 .col-bg {
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/zeus/spotlight-03.jpg');
    }
    footer {
        position: relative;
        z-index: 8;
    }
    .col-bg {
        width: 50%;
        padding-bottom: 50%;
        float: left;
        background-attachment: fixed;
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: calc(100% + 40px);
    }
    .area-personas {
        width: 100%;
        height: 200vh;
        float: left;
        background-color: white;
        padding-top: 200px;
        position: relative;
    }
    .area-personas p {
        color: #2FCFE9;
        font-size: 300px;
        font-weight: 700;
        line-height: 300px;
        user-select: none;
    }
    .area-lettering-personas {
        width: 100%;
        float: left;
        overflow: hidden;
        margin-top: 92px;
        position: sticky;
        top: calc(100vh - 600px);
    }
    .row-lettering {
        display: flex;
        transition: all 0.4s linear !important;
    }
    div,
    figure,
    p,
    section {
        transition: all 0.4s linear !important;
    }
    .letter-op {
        opacity: 0.2;
    }
    .area-image-personas {
        width: 100%;
        height: 600px;
        position: sticky;
        top: calc(100vh - 600px);
        margin-top: 260px;
    }
    .area-image-personas figure {
        position: absolute;
        bottom: 0px;
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
    }
    .area-image-personas figure {
        transition: all 0.4s linear !important;
    }
    .persona-1 {
        width: 300px !important;
        height: 550px;
        left: calc(50% - 150px);
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/zeus/persona-1.png');
        filter: brightness(0);
    }
    .persona-2 {
        width: 400px;
        height: 600px;
        left: calc(50% - 200px);
        filter: brightness(0.2);
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/zeus/persona-2.png');
    }
    .persona-3 {
        width: 400px;
        height: 650px;
        left: calc(50% - 200px);
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/zeus/persona-3.png');
    }
    .area-commercial {
        width: 100%;
        height: 150vh;
        float: left;
        background-color: black;
        position: relative;
        transition: all 0.4s linear !important;
        flex-direction: column;
        padding: 50px;
        padding-bottom: 50px !important;
    }
    .content-area-commercial {
        width: 100%;
        height: 100vh;
        float: left;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: sticky;
        top: 0px;
        margin-top: -100vh;
    }
    .area-comercial video {
        width: 100%;
    }
    .area-video-commercial {
        width: 100%;
        left: 50px;
        top: 0px;
        height: 100vh;
        border-radius: 30px;
        overflow: hidden;
        /* position: absolute; */
        position: sticky;
        /* margin-top: 40px; */
    }
    .area-commercial h1 {
        width: 800px;
        position: relative;
        z-index: 5;
        color: white;
        text-align: center;
    }
    .area-commercial a {
        height: 50px;
        border-radius: 100px;
        padding-left: 40px;
        padding-right: 40px;
        font-weight: 700;
        position: relative;
        z-index: 5;
        /* text-transform: uppercase; */
        margin-top: 50px;
        /* background-color: white; */
        text-align: center;
        align-items: center;
        color: black;
        font-size: 14px;
        text-decoration: none;
        display: flex;
        align-items: center;
    }
    .area-commercial a:hover {
        background-color: rgb(3, 3, 3);
        color: #2FCFE9
    }
    .area-solutions {
        /* background-color: black !important; */
        /* padding-left: 48px !important; */
        /* padding-right: 48px !important; */
        /* padding-top: 0px !important; */
        /* padding-bottom: 60px !important; */
    }
    /* PARTE 1 */
    .part-01 {
        width: 100%;
        float: left;
        background-color: rgb(51, 51, 51);
        height: 200vh;
        color: white;
    }
    .area-title-page h2 {
        font-size: 38px !important;
        line-height: 49px;
    }
    .bg-zeus {
        width: 100%;
        height: 100vh;
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/zeus/zeus-bg.jpg');
        background-size: 137vh;
        background-repeat: no-repeat;
        background-position: center top;
        position: fixed;
        top: 0px;
        filter: blur(20px);
    }
    .icon-play {
        width: 22px !important;
        margin: 0px !important;
    }
    .area-scroll {
        width: 100%;
        height: 100px;
        position: fixed;
        bottom: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 100px
    }
    .area-scroll img {
        width: 170px;
    }
    .conarec-icon {
        width: 130px !important;
    }
    .area-title-page {
        width: 100%;
        float: left;
        transition: all 0.5s ease;
        margin-top: 223px;
        display: flex;
        justify-content: center;
        opacity: 0;
    }
    .area-title-page img {
        width: 60px;
        margin-bottom: 20px;
    }
    #typography-names-01 {
        margin-left: -400px
    }
    .bt-down {
        position: fixed !important;
    }
    .scroll-01 {
        width: 10px;
        height: 50vh;
        margin-top: 100px;
        background-color: red;
        position: absolute;
        left: 0px;
        right: 0px;
        z-index: 9999;
        visibility: hidden;
    }
    section {
        position: relative;
    }
    .scroll-persona {
        width: 50px;
        height: 100vh;
        /* background-color: red; */
        position: absolute;
        top: -100vh;
        left: 0px;
    }
    .scroll-persona-2 {
        width: 50px;
        height: 100vh;
        /* background-color: blue; */
        position: absolute;
        top: 0vh;
        left: 0px;
    }
}

@media screen and (max-width: 1199px) {
    .initial-zeus {
        height: 100vh !important;
        background-color: black !important;
    }
    .bt-play-zeus {
        background-color: transparent;
        border: 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 8px;
    }
    .mask-expanded {
        background-size: 4115px !important;
    }
    .col-bg {
        width: 100%;
        padding-bottom: 100%;
        float: left;
        /* background-attachment: fixed; */
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
    .all-menu {
        position: absolute;
    }
    .bt-play-zeus p {
        color: white;
        font-weight: 700;
        margin-left: 15px;
        margin-right: 12px;
        font-size: 11px;
        margin-top: 2px;
    }
    .subhead-zeus {
        position: relative;
        z-index: 9;
    }
    .bt-play-zeus .icon-play {
        filter: brightness(5.0) grayscale(1.0);
    }
    .icon-wave {
        width: 29px !important;
        margin: 0px !important;
    }
    .area-agent {
        width: 100%;
        min-height: 800px;
        background-color: #010101;
        float: left;
        display: flex;
        justify-content: center;
        padding-top: 140px;
        color: white;
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/zeus/bg-head-zeus.png');
        background-position: bottom;
        background-size: 100%;
        background-repeat: no-repeat;
        z-index: 10;
        position: relative;
    }
    .head-zeus-figure {
        width: 100%;
        height: 700px;
        float: left;
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/zeus/head-zeus.png');
        background-position: top;
        background-repeat: no-repeat;
        background-size: 100%;
        margin-top: 20px;
    }
    .area-award {
        width: 100%;
        min-height: 100%;
        float: left;
        padding-top: 20px;
        padding-bottom: 55px;
        display: flex;
        justify-content: center;
        background-color: #000000;
        flex-direction: column;
        align-items: center;
        margin-top: -2px;
    }
    .area-award .container-large {
        width: calc(100% - 70px) !important;
    }
    .title-decorate-award {
        height: 43px;
        background-color: #FED843;
        color: Black;
        border-radius: 6px;
        padding-left: 40px;
        padding-right: 40px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 3px;
        border-radius: 6px;
        overflow: hidden;
        margin-top: 5px;
        margin-bottom: 11px;
    }
    .title-decorate-award p {
        font-weight: 700;
        font-size: 27px;
        text-transform: uppercase;
        /* line-height: 43px; */
        z-index: 5;
        position: relative;
    }
    .title-decorate-award:after {
        width: 50%;
        height: 43px;
        float: left;
        content: "";
        background-color: #FABE2E;
        border-color: black;
        position: absolute;
        right: 0px;
        top: 0px;
    }
    .title-area-award {
        width: 100%;
        float: left;
        color: white;
    }
    .title-area-award h2 {
        font-family: "grupo-services";
        font-weight: 700;
        font-size: 24px;
        text-transform: uppercase;
        line-height: 33px;
    }
    .align-title-award {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .title-area-award img {
        margin-bottom: 28px;
        width: 42px;
    }
    .title-award {
        float: left;
        color: white;
    }
    .title-award img {
        width: 50px;
        margin-bottom: 20px;
    }
    .area-boxes-award {
        width: 100%;
        float: left;
        margin-top: 50px;
        /* display: flex; */
        /* justify-content: space-between; */
        padding-left: 30px;
        padding-right: 30px;
    }
    .box-award {
        width: 100%;
        padding-bottom: 100%;
        float: left;
        background-color: #070707;
        border-radius: 25px;
        position: relative;
        opacity: 0;
        transition: all 0.4s linear !important;
        margin-top: 20px;
    }
    .content-intern-award {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        padding: 40px;
        display: flex;
        align-items: center;
        flex-direction: column;
        /* margin-top: 20px; */
        justify-content: center;
    }
    .box-award img {
        width: 78px;
        margin-bottom: 28px;
    }
    .title-award {
        font-size: 17px;
        text-transform: uppercase;
        font-weight: 600;
        float: left;
        line-height: 25px;
    }
    .year-award {
        font-size: 8px;
        margin-top: -6px;
        float: left;
        color: white;
        margin-left: 5px;
    }
    .subtitle-award {
        width: 100%;
        float: left;
        font-size: 11px;
        color: white;
        font-weight: 400;
        text-transform: uppercase;
        border-bottom: 1px solid rgb(49, 49, 49);
        padding-bottom: 8px;
    }
    .text-award {
        width: 100%;
        float: left;
        font-size: 13px;
        color: white;
        font-weight: 400;
        text-align: left;
        padding-top: 8px;
        line-height: 18px;
    }
    .area-spotlight-zeus {
        width: 100%;
        float: left;
    }
    .item-spotlight {
        width: 100%;
        float: left;
        background-color: rgb(20, 20, 20);
        /* display: flex; */
        /* position: relative; */
    }
    .item-spotlight .col-title {
        width: 100%;
        height: 0px;
        position: relative;
        float: left;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        opacity: 0;
        padding-bottom: 100%;
    }
    .item-spotlight .col-title img {
        width: 40px;
        margin-bottom: 25px;
    }
    .item-spotlight .col-title p {
        color: white;
        font-weight: 700;
        text-align: center;
        font-size: 25px;
        line-height: 40px;
    }
    .item-spotlight .col-title label {
        font-weight: 700;
        text-align: center;
        font-size: 40px;
        line-height: 50px;
    }
    .col-title figure {
        width: 90px;
        height: 12px;
        float: left;
        margin-top: 30px;
        background-color: #1d1d1d;
    }
    .area-03 label {
        font-size: 26px !important;
        margin-top: 10px;
        line-height: 35px !important;
    }
    .area-01 {
        background-color: #070707;
    }
    section {
        position: relative;
    }
    .area-01 .col-bg img {
        width: 100%;
        top: 0px;
        position: fixed;
        z-index: 7;
    }
    .area-01 .col-bg {
        position: relative;
        overflow: hidden;
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/zeus/spotlight-01.jpg');
    }
    .area-02 {
        background-color: #0B0B0B;
    }
    .area-02 .col-bg {
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/zeus/spotlight-02.jpg');
    }
    .area-03 {
        background-color: #0d0d0d;
    }
    .area-03 .col-bg {
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/zeus/spotlight-03.jpg');
    }
    footer {
        position: relative;
        z-index: 8;
    }
    .area-personas {
        width: 100%;
        height: 250vh;
        float: left;
        background-color: white;
        padding-top: 120px;
        position: relative;
        text-align: center;
    }
    .area-personas p {
        color: #2FCFE9;
        font-size: 211px;
        font-weight: 700;
        line-height: 189px;
        user-select: none;
    }
    .area-lettering-personas {
        width: 100%;
        float: left;
        overflow: hidden;
        margin-top: 92px;
        position: sticky;
        top: calc(100vh - 600px);
    }
    .row-lettering {
        display: flex;
        transition: all 0.4s linear !important;
    }
    div,
    figure,
    p,
    section {
        transition: all 0.4s linear !important;
    }
    .letter-op {
        opacity: 0.2;
    }
    .area-image-personas {
        width: 100%;
        height: 600px;
        position: sticky;
        top: calc(100vh - 600px);
        margin-top: 260px;
        overflow: hidden;
    }
    .area-image-personas figure {
        position: absolute;
        bottom: 0px;
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
    }
    .area-image-personas figure {
        transition: all 0.4s linear !important;
        background-size: 100% !important;
        background-position: bottom;
    }
    .persona-1 {
        width: 232px !important;
        height: 531px;
        left: calc(50% - 144px);
        transform: translate3d(0px, 0px, 0px);
        filter: brightness(0);
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/zeus/persona-1.png');
    }
    .persona-2 {
        width: 239px;
        height: 458px;
        left: calc(50% - 112px);
        filter: brightness(0.2);
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/zeus/persona-2.png');
    }
    .persona-3 {
        width: 332px;
        height: 550px;
        /* background-size: 100% !important; */
        left: calc(50% - 125px);
        transform: translate3d(0px, 0px, 0px);
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/zeus/persona-3.png');
    }
    .area-commercial {
        width: 100%;
        height: 150vh;
        float: left;
        background-color: black;
        position: relative;
        transition: all 0.4s linear !important;
        flex-direction: column;
        /* padding: 30px; */
        padding-bottom: 50px !important;
    }
    .content-area-commercial {
        width: 100%;
        height: 100vh;
        float: left;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        position: sticky;
        top: 0px;
        margin-top: -100vh;
        padding-top: 70px !important;
        padding-bottom: 70px;
        padding: 40px;
    }
    .area-comercial video {
        width: 100%;
    }
    .area-video-commercial {
        width: 100%;
        left: 50px;
        top: 0px;
        height: 100vh;
        border-radius: 30px;
        overflow: hidden;
        /* position: absolute; */
        position: sticky;
        opacity: 0.5;
    }
    .area-commercial h1 {
        width: 100%;
        position: relative;
        z-index: 5;
        color: white;
        text-align: left;
        /* padding-left: 40px; */
        /* padding-right: 40px; */
        font-size: 34px;
        line-height: 43px;
    }
    .area-commercial a {
        position: relative;
        z-index: 5;
    }
    .area-commercial a:hover {
        background-color: rgb(3, 3, 3);
        color: #2FCFE9
    }
    /* PARTE 1 */
    .part-01 {
        width: 100%;
        float: left;
        background-color: rgb(51, 51, 51);
        height: 200vh;
        color: white;
    }
    .area-title-page h2 {
        font-size: 24px !important;
        line-height: 36px;
    }
    .bg-zeus {
        width: 100%;
        height: 100vh;
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/zeus/zeus-bg.jpg');
        background-size: 137vh;
        background-repeat: no-repeat;
        background-position: center top;
        position: fixed;
        top: 0px;
        filter: blur(20px);
    }
    .icon-play {
        width: 14px !important;
        margin: 0px !important;
    }
    .mask-expanded {
        background-size: 4500px !important;
    }
    .mask {
        width: 100%;
        height: calc(100vh + 2px);
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/zeus/mobile/mask.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center center;
        position: sticky;
        top: 0px;
        left: 0px;
        transition: all 0.8s ease;
        margin-top: -20px
    }
    .area-scroll {
        width: 100%;
        height: 100px;
        position: fixed;
        bottom: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 100px
    }
    .area-scroll img {
        width: 170px;
    }
    .conarec-icon {
        width: 107px !important;
        margin-left: -17px;
        margin-bottom: 15px !important;
    }
    .area-title-page {
        width: 100%;
        float: left;
        transition: all 0.5s ease;
        margin-top: 89px;
        display: flex;
        justify-content: center;
        opacity: 0;
    }
    .area-title-page img {
        width: 43px;
        margin-bottom: 12px;
    }
    #typography-names-01 {
        margin-left: -400px
    }
    .bt-down {
        position: fixed !important;
    }
    .scroll-01 {
        width: 10px;
        height: 50vh;
        margin-top: 100px;
        position: absolute;
        left: 0px;
        right: 0px;
        z-index: 9999;
        visibility: hidden;
    }
    section {
        position: relative;
    }
    .scroll-persona {
        width: 50px;
        height: 100vh;
        position: absolute;
        top: -55vh;
        left: 0px;
    }
    .scroll-persona-2 {
        width: 50px;
        height: 100vh;
        position: absolute;
        top: 57vh;
        left: 0px;
    }
    .title-index h2 {
        font-size: 10px
    }
    .align-title-item {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .intro-mobile {
        width: 100%;
        height: 459px;
        float: left;
        background-color: black;
        padding-top: 140px;
        display: flex;
        justify-content: center;
        color: white;
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/zeus/bg-wave.gif');
        background-position: bottom;
        background-repeat: no-repeat;
        background-size: 800px;
        overflow: hidden;
    }
    .logo-zeus {
        width: 67px;
        margin-bottom: 10px;
    }
    .intro-mobile .container-large {
        width: calc(100% - 80px)
    }
    .video-wave-mobile {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        z-index: 7;
        overflow: hidden;
    }
    .video-wave-mobile video {
        width: 100%;
    }
    .intro-mobile .container-large {
        position: relative;
        z-index: 8;
    }
    #video-ia-mobile {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
`;