import $ from 'jquery';
import { t } from 'i18next';

export default function AnimationProducts() {
    window.onload = () => {
        setTimeout(() => {
            $('.loading').hide();
        }, 500)
    }
    $(document).ready(function () {

        $('.content-section').css({
            '--visible-y': '1',
            '--viewport-y': '0.9256',
        });
        var sPageURL = window.location.search.substring(1);
        var sURLVariables = sPageURL.split('&');
        for (var i = 0; i < sURLVariables.length; i++) {
            var sParameterName = sURLVariables[i].split('=');
            if (sParameterName[0] == 'ura') {
                $('html').animate({
                    scrollTop: $('#area-ura').offset().top - 50
                }, 200);
            } else if (sParameterName[0] == 'bot-voz') {
                $('html').animate({
                    scrollTop: $('#area-bot-voz').offset().top - 50
                }, 200);
            } else if (sParameterName[0] == 'portais') {
                $('html').animate({
                    scrollTop: $('#area-portais').offset().top - 50
                }, 200);
            } else if (sParameterName[0] == 'bot-texto') {
                $('html').animate({
                    scrollTop: $('#area-bot-texto').offset().top - 150
                }, 200);
            } else if (sParameterName[0] == 'consultor-digital') {
                $('html').animate({
                    scrollTop: $('#area-consultor-digital').offset().top - 50
                }, 200);
            } else if (sParameterName[0] == 'ath') {
                $('html').animate({
                    scrollTop: $('#area-atendimento-humano').offset().top - 0
                }, 200);
            } else {
                $('html').animate({
                    scrollTop: 0
                }, 10);
            }
        }
        // if (performance.navigation.type == 1) {
        //     window.location = '/produtos';
        // }
        // $('#bt-go-results').click(function () {

        //     $('html').animate({
        //         scrollTop: $('.scroll-results').offset().top + 10
        //     }, 600);
        // });
        
        $('#bt-go-results').click(function() {
            $('html').animate({
                scrollTop: $('.all-motion').offset().top - 55
            }, 200);
        });

        $('#bt-go-cx').click(function () {
            $('html').animate({
                scrollTop: $('#area-cx').offset().top
            }, 100);
        });

        $('#bt-go-ura').click(function () {
            $('html').animate({
                scrollTop: $('#area-ura').offset().top
            }, 100);
        });

        $('#bt-go-bot-voz').click(function () {
            $('html').animate({
                scrollTop: $('#area-bot-voz').offset().top
            }, 100);
        });

        $('#bt-go-portais').click(function () {
            $('html').animate({
                scrollTop: $('#area-portais').offset().top - 50
            }, 100);
        });

        $('#bt-go-bot-texto').click(function () {
            $('html').animate({
                scrollTop: $('#area-bot-texto').offset().top - 50
            }, 100);
        });

        $('#bt-go-consultor-digital').click(function () {
            $('html').animate({
                scrollTop: $('#area-consultor-digital').offset().top - 50
            }, 100);
        });

        $('#bt-go-atendimento').click(function () {
            $('html').animate({
                scrollTop: $('#area-atendimento-humano').offset().top - 50
            }, 100);
        });

        $('#bt-go-aio').click(function () {
            $('html').animate({
                scrollTop: $('#area-aio-solution').offset().top - 50
            }, 100);
        });
    });
    $(document).ready(function () {
        if ($(window).width() > 1200) {
            $('.area-phone-aio-solution').click(function () {
                window.location.href = "/aio";
            });
            $(window).scroll(function () {
                if ($(window).scrollTop() > $('#area-cx').offset().top - 600) {
                    $('#bg-cx').trigger('play');
                }
            });


            var videoArrow = false;
            var count = 10;
            $(function () {
                var lastScrollTop = 0,
                    delta = 5;
                $(window).scroll(function () {
                    var nowScrollTop = $(this).scrollTop();
                    if (Math.abs(lastScrollTop - nowScrollTop) >= delta) {
                        if (nowScrollTop > lastScrollTop) {
                            count += 6;
                            $('#area-results').css({
                                'background': 'linear-gradient( ' + count + 'deg, rgba(14, 74, 126, 1) 0%, rgba(3, 38, 72, 1) 100%)',
                            });
                        } else {
                            count -= 6;
                            $('#area-results').css({
                                'background': 'linear-gradient( ' + count + 'ddeg, rgba(14, 74, 126, 1) 0%, rgba(3, 38, 72, 1) 100%)',
                            });
                        }
                        lastScrollTop = nowScrollTop;
                    }
                });
            });

            // INICIO - ANIMACAO EXCLAMACAO
            $('.bg-initial').css({
                'background-size': '100%',
                'opacity': '1.0'
            });
            $('.area-results').css({
                'filter': 'blur(20px)',
            });
            // FINAL - ANIMACAO EXCLAMACAO

            // INÍCIO - CX - TEXT
            $(window).scroll(function () {
                var scrollTop = $(window).scrollTop();
                var scrollItem = $('.area-cx h1').offset();
                var calcHeight = $('body').innerHeight();
                if (scrollTop > ((scrollItem.top - 600) - calcHeight)) {

                    $('.area-cx h1').addClass("animate");
                    $('.area-cx p').addClass("animate");

                } else {
                    $(".area-cx h1").removeClass("animate");
                    $(".area-cx p").removeClass("animate");
                }
            });
            // FINAL - CX - TEXT

            // INÍCIO - URA - TEXT
            $(window).scroll(function () {
                var scrollTop = $(window).scrollTop();
                var scrollItem = $('.area-ura h1').offset();
                var calcHeight = $('body').innerHeight();
                if (scrollTop > ((scrollItem.top - 600) - calcHeight)) {
                    $('.area-ura h1').addClass("animate");
                    $('.area-ura p').addClass("animate");
                    $('.area-ura img').addClass("animate");

                } else {
                    $(".area-ura h1").removeClass("animate");
                    $(".area-ura p").removeClass("animate");
                    $(".area-ura img").removeClass("animate");
                }
            });
            // FINAL - URA - TEXT

            // INÍCIO - BOT VOICE - TEXT
            $(window).scroll(function () {
                var scrollTop = $(window).scrollTop();
                var scrollItem = $('.area-bot-voice h1').offset();
                var calcHeight = $('body').innerHeight();
                if (scrollTop > ((scrollItem.top - 600) - calcHeight)) {
                    $('.area-bot-voice .item-motion').addClass("animate");
                } else {
                    $(".area-bot-voice .item-motion").removeClass("animate");
                }
            });
            // FINAL - BOT VOICE - TEXT

            // INICIO - RESULTS AREA
            $(window).scroll(function () {
                var scrollTop = $(window).scrollTop();
                if (scrollTop >= 0 && scrollTop <= 50) {
                    $('.bg-initial').css({
                        'background-size': '100%',
                        'opacity': '1.0',
                        'display': 'block',
                    });
                    $('.area-results').css({
                        'filter': 'blur(20px)',
                    });
                    $('.title-solution-initial').css({
                        'opacity': '0.0',
                    });
                    $('.icon-graph').css({
                        'opacity': '0',
                    });
                    $('#bt-go-results').css({
                        'opacity': '1.0',
                    });
                } else if (scrollTop > 50 && scrollTop <= 100) {
                    $('.bg-initial').css({
                        'background-size': '120%',
                        'opacity': '1.0',
                        'display': 'block',
                    });
                    $('.area-results').css({
                        'filter': 'blur(30px)',
                    });
                    $('#bt-go-results').css({
                        'opacity': '1.0',
                    });
                } else if (scrollTop > 100 && scrollTop <= 150) {
                    $('.bg-initial').css({
                        'background-size': '130%',
                        'opacity': '1.0',
                        'display': 'block',
                    });
                    $('.area-results').css({
                        'filter': 'blur(16px)',
                    });
                    $('#bt-go-results').css({
                        'opacity': '1.0',
                    });
                } else if (scrollTop > 150 && scrollTop <= 200) {
                    $('.bg-initial').css({
                        'background-size': '140%',
                        'opacity': '1.0',
                        'display': 'block',
                    });
                    $('.area-results').css({
                        'filter': 'blur(12px)',
                    });
                    $('#bt-go-results').css({
                        'opacity': '1.0',
                    });
                } else if (scrollTop > 200 && scrollTop <= 250) {
                    $('.bg-initial').css({
                        'background-size': '150%',
                        'opacity': '1.0',
                        'display': 'block',
                    });
                    $('.area-results').css({
                        'filter': 'blur(12px)',
                    });
                    $('#bt-go-results').css({
                        'opacity': '1.0',
                    });
                } else if (scrollTop > 250 && scrollTop <= 300) {
                    $('.bg-initial').css({
                        'background-size': '160%',
                        'opacity': '1.0',
                        'display': 'block',
                    });
                    $('.area-results').css({
                        'filter': 'blur(10px)',
                    });
                    $('#bt-go-results').css({
                        'opacity': '1.0',
                    });
                } else if (scrollTop > 300 && scrollTop <= 350) {
                    $('.bg-initial').css({
                        'background-size': '170%',
                        'opacity': '1.0',
                        'display': 'block',
                    });
                    $('.area-results').css({
                        'filter': 'blur(8px)',
                    });
                } else if (scrollTop > 350 && scrollTop <= 400) {
                    $('.bg-initial').css({
                        'background-size': '180%',
                        'opacity': '1.0',
                        'display': 'block',
                    });
                    $('.area-results').css({
                        'filter': 'blur(4px)',
                    });
                    $('#bt-go-results').css({
                        'opacity': '1.0',
                    });

                } else if (scrollTop > 400 && scrollTop <= 450) {
                    $('.bg-initial').css({
                        'background-size': '190%',
                        'opacity': '1.0',
                        'display': 'block',
                    });

                    $('.area-results').css({
                        'filter': 'blur(2px)',
                    });
                    $('#bt-go-results').css({
                        'opacity': '1.0',
                    });
                } else if (scrollTop > 450 && scrollTop <= 500) {
                    $('.bg-initial').css({
                        'background-size': '300%',
                        'opacity': '1.0',
                        'display': 'block',
                    });

                    $('.area-results').css({
                        'filter': 'blur(4px)',
                    });

                    $('#bt-go-results').css({
                        'opacity': '1.0',
                    });
                } else if (scrollTop > 500 && scrollTop <= 550) {
                    $('.bg-initial').css({
                        'background-size': '400%',
                        'opacity': '1.0',
                        'display': 'block',
                    });
                    $('.area-results').css({
                        'filter': 'blur(2px)',
                    });
                    $('#bt-go-results').css({
                        'opacity': '0',
                    });



                } else if (scrollTop > 550 && scrollTop <= 600) {
                    $('.bg-initial').css({
                        'background-size': '500%',
                        'opacity': '1.0',
                        'display': 'block',
                    });
                    $('.area-results').css({
                        'filter': 'blur(2px)',
                    });

                    $('#bt-go-results').css({
                        'opacity': '0',
                    });
                } else if (scrollTop > 600 && scrollTop <= 625) {
                    $('.bg-initial').css({
                        'background-size': '600%',
                        'opacity': '1.0',
                        'display': 'block',
                    });
                    $('.area-results').css({
                        'filter': 'blur(2px)',
                    });
                } else if (scrollTop > 625 && scrollTop <= 650) {
                    $('.bg-initial').css({
                        'background-size': '750%',
                        'opacity': '1.0',
                        'display': 'block',
                    });
                    $('.area-results').css({
                        'filter': 'blur(2px)',
                    });
                } else if (scrollTop > 650 && scrollTop <= 675) {
                    $('.bg-initial').css({
                        'background-size': '900%',
                        'opacity': '1.0',
                        'display': 'block',
                    });
                    $('.area-results').css({
                        'filter': 'blur(2px)',
                    });
                } else if (scrollTop > 675 && scrollTop <= 700) {
                    $('.bg-initial').css({
                        'background-size': '1200%',
                        'opacity': '1.0',
                        'display': 'block',
                    });
                    $('.area-results').css({
                        'filter': 'blur(2px)',
                    });
                } else if (scrollTop > 725) {
                    $('.bg-initial').css({
                        'background-size': '1500%',
                        'display': 'none',
                    });
                    $('#bt-go-results').css({
                        'opacity': '0',
                    });


                    if (videoArrow == false) {
                        setTimeout(function () {
                            $('#video-arrow').trigger('play');
                            $('#video-arrow').css({
                                'opacity': '1.0',
                            });
                            setTimeout(function () {
                                videoArrow = true;
                            }, 100);
                            setTimeout(function () {
                                $("#you-text").addClass("green");
                                videoArrow = true;
                            }, 1000);
                        }, 500);
                    }


                    $('.area-results').css({
                        'filter': 'blur(0px)',
                    });
                } else {
                    $('.bg-initial').css({
                        'background-size': '1500%',
                        'display': 'none',
                    });
                    $('.area-results').css({
                        'filter': 'blur(0px)',
                    });
                    if (videoArrow == false) {
                        setTimeout(function () {
                            $('#video-arrow').trigger('play');
                            $('#video-arrow').css({
                                'opacity': '1.0',
                            });
                            setTimeout(function () {
                                videoArrow = true;
                            }, 100);
                            setTimeout(function () {
                                $("#you-text").addClass("green");
                                videoArrow = true;
                            }, 1000);
                        }, 800);
                    }
                }
            });
            // INICIO - RESULTS AREA

            // INÍCIO - DIGITAL ATTENDANCE - TEXT
            $(window).scroll(function () {
                var scrollTop = $(window).scrollTop();
                var scrollItem = $('.area-attendance-digital').offset();
                var calcHeight = $('body').innerHeight();
                if (scrollTop > ((scrollItem.top - 400) - calcHeight)) {
                    $('.area-attendance-digital .item-motion').addClass("animate");
                } else {
                    $(".area-attendance-digital .item-motion").removeClass("animate");
                }
            });
            // FINAL - DIGITAL ATTENDANCE - TEXT


            // INÍCIO - HUMAN ATTENDANCE - TEXT
            $(window).scroll(function () {
                var scrollTop = $(window).scrollTop();
                var scrollItem = $('.area-human').offset();
                if (scrollTop > scrollItem.top - 300) {
                    $('.area-human').addClass("animate");
                    $('.area-human h1').addClass("animate");
                    $('.area-human p').addClass("animate");
                    $('.area-human figure').addClass("animate");

                } else {
                    $(".area-human").removeClass("animate");
                    $(".area-human h1").removeClass("animate");
                    $(".area-human p").removeClass("animate");
                    $(".area-human figure").removeClass("animate");
                }
            });
            // FINAL - HUMAN ATTENDANCE - TEXT

            // INÍCIO - PORTAIS - TEXT
            $(window).scroll(function () {
                var scrollTop = $(window).scrollTop();
                var scrollItem = $('.area-cronos h1').offset();
                var calcHeight = $('body').innerHeight();
                if (scrollTop > ((scrollItem.top - 600) - calcHeight)) {
                    $('.area-cronos h1').addClass("animate");
                    $('.area-cronos p').addClass("animate");

                } else {
                    $(".area-cronos h1").removeClass("animate");
                    $(".area-cronos p").removeClass("animate");
                }
            });
            // FINAL - PORTAIS - TEXT

            // INÍCIO - PORTAIS - SCREENS
            $(window).scroll(function () {
                var scrollTop = $(window).scrollTop();
                var scrollCronos = $('#scroll-cronos').offset();
                if (scrollTop >= scrollCronos.top - 25 && scrollTop >= scrollCronos.top) {
                    $('.area-cronos').css({
                        'border': '20px solid white',
                    });
                    $('.container-screens').css({
                        'transform': 'scale(2.8) translate(0px, 160px)',
                    });

                    $('#text-cronos').css({
                        'transform': 'scale(1.0)',
                    });

                }
                if (scrollTop >= scrollCronos.top && scrollTop >= scrollCronos.top + 25) {
                    $('.area-cronos').css({
                        'border': '0px solid white',
                    });
                    $('.container-screens').css({
                        'transform': 'scale(2.0) translate(0px, 135px)',
                    });

                    $('#text-cronos').css({
                        'transform': 'scale(0.7)',
                    });
                }
                if (scrollTop >= scrollCronos.top + 25 && scrollTop >= scrollCronos.top + 50) {
                    $('.container-screens').css({
                        'transform': 'scale(1.8) translate(0px, 110px)',
                    });

                    $('#text-cronos').css({
                        'transform': 'scale(0.7)',
                    });

                }
                if (scrollTop >= scrollCronos.top + 50 && scrollTop >= scrollCronos.top + 75) {
                    $('.container-screens').css({
                        'transform': 'scale(1.6) translate(0px, 55px)',
                    });

                }
                if (scrollTop >= scrollCronos.top + 75 && scrollTop >= scrollCronos.top + 100) {
                    $('.container-screens').css({
                        'transform': 'scale(1.4) translate(0px, 35px)',
                    });

                    $('#text-cronos').css({
                        'transform': 'scale(0.7)',
                    });
                }
                if (scrollTop >= scrollCronos.top + 100 && scrollTop >= scrollCronos.top + 150) {
                    $('.container-screens').css({
                        'transform': 'scale(1.2) translate(0px, 25px)',
                    });

                    $('#text-cronos').css({
                        'transform': 'scale(0.7)',
                    });
                }
                if (scrollTop >= scrollCronos.top + 150 && scrollTop >= scrollCronos.top + 200) {
                    $('.container-screens').css({
                        'transform': 'scale(1.0) translate(0px, 0px)',
                    });

                    $('#text-cronos').css({
                        'transform': 'scale(0.7)',
                    });
                    $('#text-cronos').css({
                        'transform': 'scale(0.7)',
                    });
                    $('#text-cronos').css({
                        'transform': 'scale(0.7)',
                    });
                    $('.area-cronos').css({
                        'border': '0px solid white',
                    });
                }
                if (scrollTop > scrollCronos.top + 25) {
                    var scrollTop = $(window).scrollTop();
                    var scrollItem = $('.scroll-cronos').offset();
                    var positionElement = $(".scroll-cronos").offset().top - $(window).scrollTop();
                    var number = Math.abs(positionElement);
                    $('.circle-1').css({
                        'transform': 'matrix(1, 0, 0, 1,' + number / 25 + ',0)',
                    });
                    $('.circle-2').css({
                        'transform': 'matrix(1, 0, 0, 1, 0, ' + number / 28 + ')',
                    });
                    $('.circle-3').css({
                        'transform': 'matrix(1, 0, 0, 1, 0, ' + number / 23 + ')',
                    });
                    $('.circle-4').css({
                        'transform': 'matrix(1, 0, 0, 1,' + number / 25 + ',0)',
                    });
                    $('.circle-5').css({
                        'transform': 'matrix(1, 0, 0, 1,' + number / 35 + ',0)',
                    });
                    $('.circle-6').css({
                        'transform': 'matrix(1, 0, 0, 1,' + number / 40 + ',' + number / 20 + ')',
                    });
                }
                if (scrollTop > scrollCronos.top + 250) {
                    var scrollTop = $(window).scrollTop();
                    var positionElement = $(".scroll-cronos").offset().top - $(window).scrollTop();
                    var number = Math.abs(positionElement);
                    $('.screen-1').css({
                        'transform': 'matrix(1, 0, 0, 1,' + positionElement / 30 + ',0)',
                    });

                    $('.screen-2').css({
                        'transform': 'matrix(1, 0, 0, 1,' + positionElement / 35 + ',' + number / 35 + ')',
                    });

                    $('.screen-3').css({
                        'transform': 'matrix(1, 0, 0, 1,' + positionElement / 65 + ',0)',
                    });

                    $('.screen-5').css({
                        'transform': 'matrix(1, 0, 0, 1,' + number / 35 + ',0)',
                    });

                    $('.screen-6').css({
                        'transform': 'matrix(1, 0, 0, 1,' + number / 35 + ',' + number / 35 + ')',
                    });

                    $('.screen-7').css({
                        'transform': 'matrix(1, 0, 0, 1,' + number / 30 + ',0)',
                    });
                }
            });
            // FINAL - PORTAIS - SCREENS


            // INÍCIO ANIMAÇÃO PHONE
            $(window).scroll(function () {
                var scrollTop = $(window).scrollTop();
                var scrollaio = $('#scroll-001').offset();
                if (scrollTop > scrollaio.top) {
                    $("#motion-phone").addClass("after");
                    $("#motion-phone").removeClass("before");

                }

                if (scrollTop < scrollaio.top + 200) {
                    $("#motion-phone").removeClass("after");
                    $("#motion-phone").addClass("before");
                }
            });
            // FINAL ANIMAÇÃO PHONE

            // INICIO - ICONS - AIO
            $(window).scroll(function () {
                var scrollTop = $(window).scrollTop();
                var scroll0701 = $('#scroll-07-02').offset();
                if (scrollTop > (scroll0701.top + 500)) {
                    setTimeout(function () {
                        $("#align-icons").addClass("align-icons-motion");
                        $("#align-icons").removeClass("align-icons");

                        // $('.content-intern').css({
                        //     'z-index': '9995',
                        // });
                    }, 500);

                } else {
                    setTimeout(function () {
                        $("#align-icons").removeClass("align-icons-motion");
                        $("#align-icons").addClass("align-icons");

                        // $('.content-intern').css({
                        //     'z-index': '9994',
                        // });
                    }, 500);
                }
            });
            // FINAL - ICONS - AIO

            // FENIX
            $(window).scroll(function () {
                var scrollTop = $(window).scrollTop();
                var scrollItem = $('.scroll-fenix').offset();
                var positionElement = $(".scroll-fenix").offset().top - $(window).scrollTop();
                var number = Math.abs(positionElement);
                if (scrollTop > scrollItem.top) {
                    $('[motion-bot-0]').css({
                        'transform': 'matrix(1, 0, 0, 1, 0, ' + number / 15 + ')',
                    });
                    $('[motion-bot-1]').css({
                        'transform': 'matrix(1, 0, 0, 1, 0, ' + positionElement / 4 + ')',
                    });
                    $('[motion-bot-2]').css({
                        'transform': 'matrix(1, 0, 0, 1, 0, ' + positionElement / 10 + ')',
                    });
                }
            });

            // INÍCIO - ICONS - FENIX
            $(window).scroll(function () {
                var scrollTop = $(window).scrollTop();
                var scrollItem = $('.scroll-fenix').offset();
                var positionElement = $(".scroll-fenix").offset().top - $(window).scrollTop();
                var number = Math.abs(positionElement);
                if (scrollTop > scrollItem.top) {
                    $('[motion-bot-0]').css({
                        'transform': 'matrix(1, 0, 0, 1, 0, ' + number / 80 + ')',
                    });
                    $('[motion-bot-1]').css({
                        'transform': 'matrix(1, 0, 0, 1, 0, ' + positionElement / 4 + ')',
                    });
                    $('[motion-bot-2]').css({
                        'transform': 'matrix(1, 0, 0, 1, 0, ' + positionElement / 40 + ')',
                    });
                    $('[motion-bot-3]').css({
                        'transform': 'matrix(1, 0, 0, 1, 0, ' + positionElement / -40 + ')',
                    });
                }
            });
            // FINAL - ICONS - FENIX

            // INÍCIO - LETTERING - FENIX
            var option = false;
            $(window).scroll(function () {
                var scrollTop = $(window).scrollTop();
                var scrollbottext = $('#area-bot-texto').offset();
                if ((scrollTop >= (scrollbottext.top - 300)) && (option == false)) {
                    option = true;
                    typeWrite(titulo);

                } else if (scrollTop < scrollbottext.top - 350) {
                    option = false;
                    $('#input-simulate').html('');
                }
            });

            function typeWrite(elemento) {
                const textRender = t('text-bot.body.2', { ns: 'products' });
                const textoArray = textRender.split('');
                elemento.innerHTML = ' ';
                textoArray.forEach(function (letra, i) {
                    setTimeout(function () {
                        elemento.innerHTML += letra;
                    }, 75 * i)
                });
            }
            const titulo = document.querySelector('#input-simulate');
            if (option) {

                typeWrite(titulo);
            }
            // FINAL - LETTERING - FENIX

        }

    });
    $(document).ready(function () {
        if ($(window).width() < 1200) {
            $(window).scroll(function () {

                $('.phone-m').click(function () {
                    window.location.href = "/aio";
                });
                var scrollm = $('.scroll-m').offset();
                var scrollTop = $(window).scrollTop();
                if (scrollTop > scrollm.top + 400) {
                    $('.logo-m').css({
                        'margin-top': ' calc(41% - 226px)',
                    });
                    $('.intern-planet-m').css({
                        'margin-top': 'calc(90vh - 450px);',
                        'opacity': '1.0'
                    });
                    setTimeout(function () {
                        $('.icon-m').css({
                            'left': '0',
                            'width': '70px',
                            'height': '70px',
                        });
                        $('.area-icons-m').css({
                            'left': 'calc(50% - 70px)',
                            'margin-top': '90px'
                        });

                    }, 600);
                    setTimeout(function () {
                        $(".area-icons-m").addClass("rotate-icons");
                    }, 1100);
                } else if (scrollTop > scrollm.top) {
                    $('.phone-m').css({
                        'width': '155%',
                        'top': '-20px',
                    });
                    $('.logo-m').css({
                        'margin-top': '40%',
                    });
                    $('.intern-planet-m').css({
                        'margin-top': '200px',
                        'opacity': '0'
                    });

                    $('.planet-m').css({
                        'width': '150%',
                        'padding-bottom': '150%',
                    });
                    $('.space-m').css({
                        'top': '0',
                    });
                } else if (scrollTop > scrollm.top - 80) {
                    $('.phone-m').css({
                        'width': '150%',
                        'top': 'calc(100% - 550px)',
                    });
                    $('.intern-planet-m').css({
                        'margin-top': '200px',
                        'opacity': '0'
                    });
                    $('.space-m').css({
                        'top': 'calc(100% - 550px)',
                    });
                    $('.planet-m').css({
                        'width': '90%',
                        'padding-bottom': '90%',
                    });
                } else if (scrollTop > scrollm.top - 300) {
                    $('.phone-m').css({
                        'width': '140%',
                        'top': 'calc(100% - 550px)',
                    });
                    $('.space-m').css({
                        'top': 'calc(100% - 550px)',
                    });
                } else if (scrollTop > scrollm.top - 500) {
                    $('.phone-m').css({
                        'width': '130%',
                        'top': 'calc(100% - 550px)',
                    });
                    $('.space-m').css({
                        'top': 'calc(100% - 550px)',
                    });
                } else if (scrollTop > scrollm.top - 700) {
                    $('.phone-m').css({
                        'width': '120%',
                        'top': 'calc(100% - 550px)',
                    });
                    $('.space-m').css({
                        'top': 'calc(100% - 550px)',
                    });
                } else if (scrollTop > scrollm.top - 900) {
                    $('.phone-m').css({
                        'width': '110%',
                        'top': 'calc(100% - 550px)',
                    });
                    $('.space-m').css({
                        'top': 'calc(100% - 550px)',
                    });
                }
            });

            $(window).scroll(function () {
                if ($(window).scrollTop() > $('#area-cx').offset().top - 600) {
                    $('#bg-cx').trigger('play');
                }
            });


            var videoArrow = false;
            var count = 10;
            $(function () {
                var lastScrollTop = 0,
                    delta = 5;
                $(window).scroll(function () {
                    var nowScrollTop = $(this).scrollTop();
                    if (Math.abs(lastScrollTop - nowScrollTop) >= delta) {
                        if (nowScrollTop > lastScrollTop) {
                            count += 6;
                            $('#area-results').css({
                                'background': 'linear-gradient( ' + count + 'deg, rgba(14, 74, 126, 1) 0%, rgba(3, 38, 72, 1) 100%)',
                            });
                        } else {
                            count -= 6;
                            $('#area-results').css({
                                'background': 'linear-gradient( ' + count + 'ddeg, rgba(14, 74, 126, 1) 0%, rgba(3, 38, 72, 1) 100%)',
                            });
                        }
                        lastScrollTop = nowScrollTop;
                    }
                });
            });

            // INICIO - ANIMACAO EXCLAMACAO
            $('.bg-initial').css({
                'background-size': '100%',
                'opacity': '1.0'
            });
            $('.area-results').css({
                'filter': 'blur(20px)',
            });
            // FINAL - ANIMACAO EXCLAMACAO

            // INÍCIO - CX - TEXT
            $(window).scroll(function () {
                var scrollTop = $(window).scrollTop();
                var scrollItem = $('.area-cx h1').offset();
                var calcHeight = $('body').innerHeight();
                if (scrollTop > ((scrollItem.top - 600) - calcHeight)) {

                    $('.area-cx h1').addClass("animate");
                    $('.area-cx p').addClass("animate");

                } else {
                    $(".area-cx h1").removeClass("animate");
                    $(".area-cx p").removeClass("animate");
                }
            });
            // FINAL - CX - TEXT

            // INÍCIO - URA - TEXT
            $(window).scroll(function () {
                var scrollTop = $(window).scrollTop();
                var scrollItem = $('.area-ura h1').offset();
                var calcHeight = $('body').innerHeight();
                if (scrollTop > ((scrollItem.top - 600) - calcHeight)) {
                    $('.area-ura h1').addClass("animate");
                    $('.area-ura p').addClass("animate");
                    $('.area-ura img').addClass("animate");

                } else {
                    $(".area-ura h1").removeClass("animate");
                    $(".area-ura p").removeClass("animate");
                    $(".area-ura img").removeClass("animate");
                }
            });
            // FINAL - URA - TEXT

            // INÍCIO - BOT VOICE - TEXT
            $(window).scroll(function () {
                var scrollTop = $(window).scrollTop();
                var scrollItem = $('.area-bot-voice ').offset();
                var calcHeight = $('body').innerHeight();
                if (scrollTop > ((scrollItem.top - 400) - calcHeight)) {
                    $('.area-bot-voice .item-motion').addClass("animate");
                } else {
                    $(".area-bot-voice .item-motion").removeClass("animate");
                }
            });
            // FINAL - BOT VOICE - TEXT

            // INICIO - RESULTS AREA
            $(window).scroll(function () {
                var scrollTop = $(window).scrollTop();
                if (scrollTop >= 0 && scrollTop <= 50) {
                    $('.bg-initial').css({
                        'background-size': '100%',
                        'opacity': '1.0',
                        'display': 'block',
                    });
                    $('.area-results').css({
                        'filter': 'blur(20px)',
                    });
                    $('.title-solution-initial').css({
                        'opacity': '0.0',
                    });
                    $('.icon-graph').css({
                        'opacity': '0',
                    });
                    $('#bt-go-results').css({
                        'opacity': '1.0',
                    });
                } else if (scrollTop > 50 && scrollTop <= 100) {
                    $('.bg-initial').css({
                        'background-size': '120%',
                        'opacity': '1.0',
                        'display': 'block',
                    });
                    $('.area-results').css({
                        'filter': 'blur(30px)',
                    });
                    $('#bt-go-results').css({
                        'opacity': '1.0',
                    });
                } else if (scrollTop > 100 && scrollTop <= 150) {
                    $('.bg-initial').css({
                        'background-size': '130%',
                        'opacity': '1.0',
                        'display': 'block',
                    });
                    $('.area-results').css({
                        'filter': 'blur(16px)',
                    });
                    $('#bt-go-results').css({
                        'opacity': '1.0',
                    });
                } else if (scrollTop > 150 && scrollTop <= 200) {
                    $('.bg-initial').css({
                        'background-size': '140%',
                        'opacity': '1.0',
                        'display': 'block',
                    });
                    $('.area-results').css({
                        'filter': 'blur(12px)',
                    });
                    $('#bt-go-results').css({
                        'opacity': '1.0',
                    });
                } else if (scrollTop > 200 && scrollTop <= 250) {
                    $('.bg-initial').css({
                        'background-size': '150%',
                        'opacity': '1.0',
                        'display': 'block',
                    });
                    $('.area-results').css({
                        'filter': 'blur(12px)',
                    });
                    $('#bt-go-results').css({
                        'opacity': '1.0',
                    });
                } else if (scrollTop > 250 && scrollTop <= 300) {
                    $('.bg-initial').css({
                        'background-size': '160%',
                        'opacity': '1.0',
                        'display': 'block',
                    });
                    $('.area-results').css({
                        'filter': 'blur(10px)',
                    });
                    $('#bt-go-results').css({
                        'opacity': '1.0',
                    });
                } else if (scrollTop > 300 && scrollTop <= 350) {
                    $('.bg-initial').css({
                        'background-size': '170%',
                        'opacity': '1.0',
                        'display': 'block',
                    });
                    $('.area-results').css({
                        'filter': 'blur(8px)',
                    });
                } else if (scrollTop > 350 && scrollTop <= 400) {
                    $('.bg-initial').css({
                        'background-size': '180%',
                        'opacity': '1.0',
                        'display': 'block',
                    });
                    $('.area-results').css({
                        'filter': 'blur(4px)',
                    });
                    $('#bt-go-results').css({
                        'opacity': '1.0',
                    });

                } else if (scrollTop > 400 && scrollTop <= 450) {
                    $('.bg-initial').css({
                        'background-size': '190%',
                        'opacity': '1.0',
                        'display': 'block',
                    });

                    $('.area-results').css({
                        'filter': 'blur(2px)',
                    });
                    $('#bt-go-results').css({
                        'opacity': '1.0',
                    });
                } else if (scrollTop > 450 && scrollTop <= 500) {
                    $('.bg-initial').css({
                        'background-size': '300%',
                        'opacity': '1.0',
                        'display': 'block',
                    });

                    $('.area-results').css({
                        'filter': 'blur(4px)',
                    });

                    $('#bt-go-results').css({
                        'opacity': '1.0',
                    });
                } else if (scrollTop > 500 && scrollTop <= 550) {
                    $('.bg-initial').css({
                        'background-size': '400%',
                        'opacity': '1.0',
                        'display': 'block',
                    });
                    $('.area-results').css({
                        'filter': 'blur(2px)',
                    });
                    $('#bt-go-results').css({
                        'opacity': '0',
                    });



                } else if (scrollTop > 550 && scrollTop <= 600) {
                    $('.bg-initial').css({
                        'background-size': '500%',
                        'opacity': '1.0',
                        'display': 'block',
                    });
                    $('.area-results').css({
                        'filter': 'blur(2px)',
                    });
                } else if (scrollTop > 600 && scrollTop <= 625) {
                    $('.bg-initial').css({
                        'background-size': '600%',
                        'opacity': '1.0',
                        'display': 'block',
                    });
                    $('.area-results').css({
                        'filter': 'blur(2px)',
                    });
                } else if (scrollTop > 625 && scrollTop <= 650) {
                    $('.bg-initial').css({
                        'background-size': '750%',
                        'opacity': '1.0',
                        'display': 'block',
                    });
                    $('.area-results').css({
                        'filter': 'blur(2px)',
                    });
                } else if (scrollTop > 650 && scrollTop <= 675) {
                    $('.bg-initial').css({
                        'background-size': '900%',
                        'opacity': '1.0',
                        'display': 'block',
                    });
                    $('.area-results').css({
                        'filter': 'blur(2px)',
                    });
                } else if (scrollTop > 675 && scrollTop <= 700) {
                    $('.bg-initial').css({
                        'background-size': '1200%',
                        'opacity': '1.0',
                        'display': 'block',
                    });
                    $('.area-results').css({
                        'filter': 'blur(2px)',
                    });
                } else if (scrollTop >= 725) {
                    $('.bg-initial').css({
                        'background-size': '1500%',
                        'display': 'none',
                    });



                    if (videoArrow == false) {
                        setTimeout(function () {
                            $('#video-arrow-mobile').trigger('play');
                            $('#video-arrow-mobile').css({
                                'opacity': '1.0',
                            });
                            setTimeout(function () {
                                videoArrow = true;
                            }, 100);
                            setTimeout(function () {
                                $("#you-text").addClass("green");
                                videoArrow = true;
                            }, 1000);
                        }, 800);
                    }


                    $('.area-results').css({
                        'filter': 'blur(0px)',
                    });
                } else {

                    $('.area-results').css({
                        'filter': 'blur(0px)',
                    });
                    if (videoArrow == false) {
                        setTimeout(function () {
                            $('#video-arrow-mobile').trigger('play');
                            $('#video-arrow-mobile').css({
                                'opacity': '1.0',
                            });
                            setTimeout(function () {
                                videoArrow = true;
                            }, 100);
                            setTimeout(function () {
                                $("#you-text").addClass("green");
                                videoArrow = true;
                            }, 1000);
                        }, 800);
                    }
                }
            });
            // INICIO - RESULTS AREA

            // INÍCIO - DIGITAL ATTENDANCE - TEXT
            $(window).scroll(function () {
                var scrollTop = $(window).scrollTop();
                var scrollItem = $('.area-attendance-digital').offset();
                var calcHeight = $('body').innerHeight();
                if (scrollTop > scrollItem.top - calcHeight) {
                    $('.area-attendance-digital .item-motion').addClass("animate");
                } else {
                    $(".area-attendance-digital .item-motion").removeClass("animate");
                }
            });
            // FINAL - DIGITAL ATTENDANCE - TEXT


            // INÍCIO - HUMAN ATTENDANCE - TEXT
            $(window).scroll(function () {
                var scrollTop = $(window).scrollTop();
                var scrollItem = $('.area-human').offset();
                if (scrollTop > scrollItem.top - 300) {
                    $('.area-human').addClass("animate");
                    $('.area-human h1').addClass("animate");
                    $('.area-human p').addClass("animate");
                    $('.area-human figure').addClass("animate");

                } else {
                    $(".area-human").removeClass("animate");
                    $(".area-human h1").removeClass("animate");
                    $(".area-human p").removeClass("animate");
                    $(".area-human figure").removeClass("animate");
                }
            });
            // FINAL - HUMAN ATTENDANCE - TEXT

            // INÍCIO - PORTAIS - TEXT
            $(window).scroll(function () {
                var scrollTop = $(window).scrollTop();
                var scrollItem = $('.area-cronos h1').offset();
                var calcHeight = $('body').innerHeight();
                if (scrollTop > scrollItem.top - calcHeight) {
                    $('.area-cronos h1').addClass("animate");
                    $('.area-cronos p').addClass("animate");

                } else {
                    $(".area-cronos h1").removeClass("animate");
                    $(".area-cronos p").removeClass("animate");
                }
            });
            // FINAL - PORTAIS - TEXT

            // INÍCIO - PORTAIS - SCREENS
            $(window).scroll(function () {
                var scrollTop = $(window).scrollTop();
                var scrollCronos = $('#scroll-cronos').offset();
                if (scrollTop >= scrollCronos.top - 25 && scrollTop >= scrollCronos.top) {
                    $('.area-cronos').css({
                        'border': '10px solid white',
                    });
                    $('.container-screens').css({
                        'transform': 'scale(2.3) translate(0px, 180px)',
                    });

                    $('#text-cronos').css({
                        'transform': 'scale(0.9)',
                    });

                }
                if (scrollTop >= scrollCronos.top && scrollTop >= scrollCronos.top + 25) {
                    $('.area-cronos').css({
                        'border': '0px solid white',
                    });
                    $('.container-screens').css({
                        'transform': 'scale(2.0) translate(0px, 135px)',
                    });

                    $('#text-cronos').css({
                        'transform': 'scale(0.8)',
                    });
                }
                if (scrollTop >= scrollCronos.top + 25 && scrollTop >= scrollCronos.top + 50) {
                    $('.container-screens').css({
                        'transform': 'scale(1.8) translate(0px, 110px)',
                    });

                    $('#text-cronos').css({
                        'transform': 'scale(0.8)',
                    });

                }
                if (scrollTop >= scrollCronos.top + 50 && scrollTop >= scrollCronos.top + 75) {
                    $('.container-screens').css({
                        'transform': 'scale(1.6) translate(0px, 55px)',
                    });

                }
                if (scrollTop >= scrollCronos.top + 75 && scrollTop >= scrollCronos.top + 100) {
                    $('.container-screens').css({
                        'transform': 'scale(1.4) translate(0px, 35px)',
                    });

                    $('#text-cronos').css({
                        'transform': 'scale(0.8)',
                    });
                }
                if (scrollTop >= scrollCronos.top + 100 && scrollTop >= scrollCronos.top + 150) {
                    $('.container-screens').css({
                        'transform': 'scale(1.2) translate(0px, 25px)',
                    });

                    $('#text-cronos').css({
                        'transform': 'scale(0.8)',
                    });
                }
                if (scrollTop >= scrollCronos.top + 150 && scrollTop >= scrollCronos.top + 200) {
                    $('.container-screens').css({
                        'transform': 'scale(1.0) translate(0px, 0px)',
                    });

                    $('#text-cronos').css({
                        'transform': 'scale(0.8)',
                    });

                    $('.area-cronos').css({
                        'border': '0px solid white',
                    });
                }
                if (scrollTop > scrollCronos.top + 25) {
                    var scrollTop = $(window).scrollTop();
                    var scrollItem = $('.scroll-cronos').offset();
                    var positionElement = $(".scroll-cronos").offset().top - $(window).scrollTop();
                    var number = Math.abs(positionElement);
                    $('.circle-1').css({
                        'transform': 'matrix(1, 0, 0, 1,' + number / 25 + ',0)',
                    });
                    $('.circle-2').css({
                        'transform': 'matrix(1, 0, 0, 1, 0, ' + number / 28 + ')',
                    });
                    $('.circle-3').css({
                        'transform': 'matrix(1, 0, 0, 1, 0, ' + number / 23 + ')',
                    });
                    $('.circle-4').css({
                        'transform': 'matrix(1, 0, 0, 1,' + number / 25 + ',0)',
                    });
                    $('.circle-5').css({
                        'transform': 'matrix(1, 0, 0, 1,' + number / 35 + ',0)',
                    });
                    $('.circle-6').css({
                        'transform': 'matrix(1, 0, 0, 1,' + number / 40 + ',' + number / 20 + ')',
                    });
                }
                if (scrollTop > scrollCronos.top + 250) {
                    var scrollTop = $(window).scrollTop();
                    var positionElement = $(".scroll-cronos").offset().top - $(window).scrollTop();
                    var number = Math.abs(positionElement);
                    $('.screen-1').css({
                        'transform': 'matrix(1, 0, 0, 1, 0,' + positionElement / 20 + ')',
                    });

                    $('.screen-2').css({
                        'transform': 'matrix(1, 0, 0, 1, 0,' + number / 20 + ')',
                    });

                    $('.screen-3').css({
                        'transform': 'matrix(1, 0, 0, 1, 0,' + positionElement / 40 + ')',
                    });
                }
            });
            // FINAL - PORTAIS - SCREENS




            // INICIO - ICONS - AIO
            $(window).scroll(function () {
                var scrollTop = $(window).scrollTop();
                var scroll0701 = $('#scroll-07-02').offset();
                if (scrollTop > scroll0701.top) {
                    setTimeout(function () {
                        $("#align-icons").addClass("align-icons-motion");
                        $("#align-icons").removeClass("align-icons");

                        // $('.content-intern').css({
                        //     'z-index': '9995',
                        // });
                    }, 500);

                } else {
                    setTimeout(function () {
                        $("#align-icons").removeClass("align-icons-motion");
                        $("#align-icons").addClass("align-icons");

                        // $('.content-intern').css({
                        //     'z-index': '9994',
                        // });
                    }, 500);
                }
            });
            // FINAL - ICONS - AIO

            // FENIX
            $(window).scroll(function () {
                var scrollTop = $(window).scrollTop();
                var scrollItem = $('.scroll-fenix').offset();
                var positionElement = $(".scroll-fenix").offset().top - $(window).scrollTop();
                var number = Math.abs(positionElement);
                if (scrollTop > scrollItem.top) {
                    $('[motion-bot-0]').css({
                        'transform': 'matrix(1, 0, 0, 1, 0, ' + number / 15 + ')',
                    });
                    $('[motion-bot-1]').css({
                        'transform': 'matrix(1, 0, 0, 1, 0, ' + positionElement / 4 + ')',
                    });
                    $('[motion-bot-2]').css({
                        'transform': 'matrix(1, 0, 0, 1, 0, ' + positionElement / 10 + ')',
                    });
                }
            });

            // INÍCIO - ICONS - FENIX
            $(window).scroll(function () {
                var scrollTop = $(window).scrollTop();
                var scrollItem = $('.scroll-fenix').offset();
                var positionElement = $(".scroll-fenix").offset().top - $(window).scrollTop();
                var number = Math.abs(positionElement);
                if (scrollTop > scrollItem.top) {
                    $('[motion-bot-0]').css({
                        'transform': 'matrix(1, 0, 0, 1, 0, ' + number / 80 + ')',
                    });
                    $('[motion-bot-1]').css({
                        'transform': 'matrix(1, 0, 0, 1, 0, ' + positionElement / 4 + ')',
                    });
                    $('[motion-bot-2]').css({
                        'transform': 'matrix(1, 0, 0, 1, 0, ' + positionElement / 40 + ')',
                    });
                    $('[motion-bot-3]').css({
                        'transform': 'matrix(1, 0, 0, 1, 0, ' + positionElement / -40 + ')',
                    });
                }
            });
            // FINAL - ICONS - FENIX

            // INÍCIO - LETTERING - FENIX
            var option = false;
            $(window).scroll(function () {
                var scrollTop = $(window).scrollTop();
                var scrollbottext = $('#area-bot-texto').offset();
                if ((scrollTop >= (scrollbottext.top - 300)) && (option == false)) {
                    option = true;
                    typeWrite(titulo);

                } else if (scrollTop < scrollbottext.top - 350) {
                    option = false;
                    $('#input-simulate').html('');
                }
            });

            function typeWrite(elemento) {
                const textRender = t('text-bot.body.2', { ns: 'products' });
                const textoArray = textRender.split('');
                elemento.innerHTML = ' ';
                textoArray.forEach(function (letra, i) {
                    setTimeout(function () {
                        elemento.innerHTML += letra;
                    }, 75 * i)
                });
            }
            const titulo = document.querySelector('#input-simulate');
            if (option) {

                typeWrite(titulo);
            }
            // FINAL - LETTERING - FENIX

        }

    });
}