import api from './api';

export async function registerPage(id,email="",nome="",assunto="") {

     

    const params = {
        cookie: document.cookie,
        recurso: id,
        url: window.location.href,
        resolucao: `${window.screen.height}x${window.screen.width}`,
        idportal: 66,
        name: nome,
        email: email,
        assunto: assunto
    };
    await api.post('/register-page', params);
}
