import i18n from "i18next";
import { initReactI18next as react } from "react-i18next";
import backend from "i18next-http-backend";
import detector from "i18next-browser-languagedetector";

const options = {
  debug: process.env.NODE_ENV === "development",

  detection: {
    lookupLocalStorage: "lang",
    lookupFromPathIndex: 0,
    order: ["localStorage", "navigator", "path"],
  },

  fallbackNS: "translation",
  fallbackLng: "pt",
  load: "currentOnly",
};

i18n.use(backend).use(detector).use(react).init(options);

export default i18n;
