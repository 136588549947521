import { format } from 'date-fns'
import crypto from 'crypto'

export function getToken() {
    let ENC_KEY = '4a41748f384143b3588dca248aab782d';
    let IV = '249547c08178d387';

    let nDate = new Date().toLocaleString('pt-BR', {
        timeZone: 'America/Sao_Paulo'
    });

    let day = nDate.substr(0, 2);
    let month = nDate.substr(3, 2);
    let year = nDate.substr(6, 4);
    let hour = nDate.substr(11, 2);

    let dateHour = `${year}${month}${day}${hour}`;
    let content = `YXBpQ2llbG9CYWl4YXM=${dateHour}`
    let cipher = crypto.createCipheriv('aes-256-cbc', ENC_KEY, IV);
    let hash = cipher.update(content, 'utf8', 'base64');
    hash += cipher.final('base64');

    return hash;
}

export function encryptData(content) {
    let ENC_KEY = '4a41748f384143b3588dca248aab782d';
    let IV = '249547c08178d387';
    let cipher = crypto.createCipheriv('aes-256-cbc', ENC_KEY, IV);
    let hash = cipher.update(content, 'utf8', 'base64');
    hash += cipher.final('base64');

    return hash;
}