import $ from 'jquery';

export default function AnimationZeus() {
    window.onload = () => {
        setTimeout(() => {
            $('.loading').hide();
        }, 500)
    }

    $(window).ready(function () {
        setTimeout(function () {
            $(window).scrollTop(0);

            setTimeout(function () {
                // $('.loading').hide();
                $('.area-initial-opportunities h1').addClass('animate');
                $('.area-initial-opportunities h3').addClass('animate');
                $('[motion-bar]').addClass('animate-bar');
            }, 100);
        });
    });

    $(document).ready(function () {
        if ($(window).width() > 1200) {

            $('.bt-play-zeus').click(function () {
                $('#audio-intro').trigger('play');
                $('.icon-wave').show();

            });
            $('#audio-intro').on("ended", function () {
                $('.icon-wave').hide();
            });

            $('.bt-down').click(function() {
                $('html').animate({
                    scrollTop: $('.area-award').offset().top + 100
                }, 200);
            });


            $(window).scroll(function () {
                var scrollInitial = $('#scrollInitial').offset();
                var scrollTop = $(window).scrollTop();
                if (scrollTop < scrollInitial.top) {
                    $('.mask').removeClass("mask-expanded");
                    $('.bg-zeus').css({
                        'filter': 'blur(20px)',
                    });
                    $('.area-title-page').css({
                        'opacity': '0',
                    });
                } else {
                    $('.mask').addClass("mask-expanded");
                    $('.bg-zeus').css({
                        'filter': 'blur(0px)',
                    });
                    $('.area-title-page').css({
                        'opacity': '1.0',
                    });
                }
            });

            var count = 0;
            var lastScrollTop = 0,
                delta = 5;
            $(window).scroll(function () {
                var nowScrollTop = $(this).scrollTop();
                if ($(window).scrollTop() > $('#scroll-persona-2').offset().top && $(window).scrollTop() < $('.area-commercial').offset().top) {
                    if (Math.abs(lastScrollTop - nowScrollTop) >= delta) {
                        if (nowScrollTop > lastScrollTop) {
                            count += 6;
                            $('#typography-names-01').css({
                                'transform': 'translate3d(' + count / 4 + 'px, 0px, 0px)',
                            });
                            $('#typography-names-02').css({
                                'transform': 'translate3d(' + -count / 4 + 'px, 0px, 0px)',
                            });

                        } else {
                            count -= 6;
                            $('#typography-names-01').css({
                                'transform': 'translate3d(' + count / 4 + 'px, 0px, 0px)',
                            });
                            $('#typography-names-02').css({
                                'transform': 'translate3d(' + -count / 4 + 'px, 0px, 0px)',
                            });
                        }
                        lastScrollTop = nowScrollTop;
                    }
                }
            });
            $(window).scroll(function () {
                var scrollTop = $(window).scrollTop();
                var scrollPersona = $('#scroll-persona-2').offset();
                if (scrollTop > scrollPersona.top && scrollTop < scrollPersona.top + 50) {
                    $('#persona-1').css({
                        'transform': 'translate3d(0px, 0px, 0px)',
                        'filter': 'brightness(0)',
                    });
                    $('#persona-2').css({
                        'filter': 'brightness(0.2)',
                    });
                    $('#persona-3').css({
                        'transform': 'translate3d(100px, 0px, 0px)'
                    });
                }
                if (scrollTop > scrollPersona.top + 50 && scrollTop < scrollPersona.top + 100) {
                    $('#persona-1').css({
                        'transform': 'translate3d(-100px, 0px, 0px)',
                        'filter': 'brightness(0.2)',
                    });
                    $('#persona-1').css({
                        'filter': 'brightness(0.2)',
                    });
                    $('#persona-3').css({
                        'transform': 'translate3d(100px, 0px, 0px)',
                    });
                }
                if (scrollTop > scrollPersona.top + 100 && scrollTop < scrollPersona.top + 150) {
                    $('#persona-1').css({
                        'transform': 'translate3d(-110px, 0px, 0px)',
                        'filter': 'brightness(0.4)'
                    });
                    $('#persona-2').css({
                        'filter': 'brightness(0.4)'
                    });
                    $('#persona-3').css({
                        'transform': 'translate3d(110px, 0px, 0px)',
                    });
                }
                if (scrollTop > scrollPersona.top + 150 && scrollTop < scrollPersona.top + 200) {
                    $('#persona-1').css({
                        'transform': 'translate3d(-120px, 0px, 0px)',
                        'filter': 'brightness(0.6)'
                    });
                    $('#persona-2').css({
                        'filter': 'brightness(0.6)'
                    });
                    $('#persona-3').css({
                        'transform': 'translate3d(120px, 0px, 0px)',
                    });
                }
                if (scrollTop > scrollPersona.top + 200 && scrollTop < scrollPersona.top + 250) {
                    $('#persona-1').css({
                        'transform': 'translate3d(-130px, 0px, 0px)',
                        'filter': 'brightness(0.8)'
                    });
                    $('#persona-2').css({
                        'filter': 'brightness(0.8)'
                    });
                    $('#persona-3').css({
                        'transform': 'translate3d(130px, 0px, 0px)',
                    });
                }
                if (scrollTop > scrollPersona.top + 250 && scrollTop < scrollPersona.top + 300) {
                    $('#persona-1').css({
                        'transform': 'translate3d(-140px, 0px, 0px)',
                        'filter': 'brightness(1.0)'
                    });
                    $('#persona-2').css({
                        'filter': 'brightness(1.0)'
                    });
                    $('#persona-3').css({
                        'transform': 'translate3d(140px, 0px, 0px)',
                    });
                }
                if (scrollTop > scrollPersona.top + 300 && scrollTop < scrollPersona.top + 350) {
                    $('#persona-1').css({
                        'transform': 'translate3d(-150px, 0px, 0px)',
                        'filter': 'brightness(1.0)'
                    });
                    $('#persona-2').css({
                        'filter': 'brightness(1.0)'
                    });
                    $('#persona-3').css({
                        'transform': 'translate3d(150px, 0px, 0px)',
                    });
                }
                if (scrollTop > scrollPersona.top + 350 && scrollTop < scrollPersona.top + 400) {
                    $('#persona-1').css({
                        'transform': 'translate3d(-160px, 0px, 0px)',
                        'filter': 'brightness(1.0)'
                    });
                    $('#persona-2').css({
                        'filter': 'brightness(1.0)'
                    });
                    $('#persona-3').css({
                        'transform': 'translate3d(160px, 0px, 0px)',
                    });
                }
                if (scrollTop > scrollPersona.top + 400 && scrollTop < scrollPersona.top + 450) {
                    $('#persona-1').css({
                        'transform': 'translate3d(-200px, 0px, 0px)',
                        'filter': 'brightness(1.0)'
                    });
                    $('#persona-2').css({
                        'filter': 'brightness(1.0)'
                    });
                    $('#persona-3').css({
                        'transform': 'translate3d(200px, 0px, 0px)',
                    });
                }
                if (scrollTop > scrollPersona.top + 450 && scrollTop < scrollPersona.top + 500) {
                    $('#persona-1').css({
                        'transform': 'translate3d(-250px, 0px, 0px)',
                        'filter': 'brightness(1.0)'
                    });
                    $('#persona-2').css({
                        'filter': 'brightness(1.0)'
                    });
                    $('#persona-3').css({
                        'transform': 'translate3d(250px, 0px, 0px)',
                    });
                }
                if (scrollTop > scrollPersona.top + 500 && scrollTop < scrollPersona.top + 550) {
                    $('#persona-1').css({
                        'transform': 'translate3d(-300px, 0px, 0px)',
                        'filter': 'brightness(1.0)'
                    });
                    $('#persona-2').css({
                        'filter': 'brightness(1.0)'
                    });
                    $('#persona-3').css({
                        'transform': 'translate3d(300px, 0px, 0px)',
                    });
                }
                if (scrollTop > scrollPersona.top + 550 && scrollTop < scrollPersona.top + 600) {
                    $('#persona-1').css({
                        'transform': 'translate3d(-350px, 0px, 0px)',
                        'filter': 'brightness(1.0)'
                    });
                    $('#persona-2').css({
                        'filter': 'brightness(1.0)'
                    });
                    $('#persona-3').css({
                        'transform': 'translate3d(350px, 0px, 0px)',
                    });
                }
                if (scrollTop > scrollPersona.top + 600) {
                    $('#persona-1').css({
                        'transform': 'translate3d(-350px, 0px, 0px)',
                        'filter': 'brightness(1.0)'
                    });
                    $('#persona-2').css({
                        'filter': 'brightness(1.0)'
                    });
                    $('#persona-3').css({
                        'transform': 'translate3d(400px, 0px, 0px)',
                    });
                }
            });
            $(window).scroll(function () {
                if ($(window).scrollTop() > $('.area-video-commercial').offset().top - 400) {
                    $('.area-commercial').css({
                        'padding': '0px',
                    });
                    $('.area-video-commercial').css({
                        'border-radius': '0px',
                    });

                } else {
                    $('.area-commercial').css({
                        'padding': '40px',
                    });
                    $('.area-video-commercial').css({
                        'border-radius': '30px',
                    });
                }
            });

            var videoIa = false;
            $(window).scroll(function () {
                if ($(window).scrollTop() > $('.area-video-commercial').offset().top - 300 && videoIa == false) {
                    $('#video-ia').trigger('play');
                    setTimeout(function () {
                        videoIa = true;
                    }, 100);
                }
            });

            $(window).scroll(function () {
                if ($(window).scrollTop() > $('.box-award').offset().top - 800) {
                    $('#box-award-1').css({
                        'opacity': '1.0',
                    });
                    setTimeout(function () {
                        $('#box-award-2').css({
                            'opacity': '1.0',
                        });
                    }, 100);
                    setTimeout(function () {
                        $('#box-award-3').css({
                            'opacity': '1.0',
                        });
                    }, 500);
                } else {
                    $('#box-award-1').css({
                        'opacity': '0',
                    });
                    $('#box-award-2').css({
                        'opacity': '0',
                    });
                    $('#box-award-3').css({
                        'opacity': '0',
                    });
                }
            });

            $(window).scroll(function () {
                if ($(window).scrollTop() > $('.area-01').offset().top - 400) {
                    $('.area-01 .col-title').css({
                        'opacity': '1.0',
                    });
                } else {
                    $('.area-01 .col-title').css({
                        'opacity': '0',
                    });
                }
            });
            $(window).scroll(function () {
                if ($(window).scrollTop() > $('.area-02').offset().top - 400) {
                    $('.area-02 .col-title').css({
                        'opacity': '1.0',
                    });
                } else {
                    $('.area-02 .col-title').css({
                        'opacity': '0',
                    });
                }
            });
            $(window).scroll(function () {
                if ($(window).scrollTop() > $('.area-03').offset().top - 400) {
                    $('.area-03 .col-title').css({
                        'opacity': '1.0',
                    });
                } else {
                    $('.area-03 .col-title').css({
                        'opacity': '0',
                    });
                }
            });
        }
    });

    $(document).ready(function () {
        if ($(window).width() < 1200) {

            $('.bt-play-zeus').click(function () {
                $('#audio-intro').trigger('play');
                $('.icon-wave').show();
            });

            $(window).scroll(function () {
                var scrollInitial = $('#scrollInitial').offset();
                var scrollTop = $(window).scrollTop();
                if (scrollTop < scrollInitial.top) {
                    $('.mask').removeClass("mask-expanded");
                    $('.bg-zeus').css({
                        'filter': 'blur(20px)',
                    });
                    $('.area-title-page').css({
                        'opacity': '0',
                    });
                } else {
                    $('.mask').addClass("mask-expanded");
                    $('.bg-zeus').css({
                        'filter': 'blur(0px)',
                    });
                    $('.area-title-page').css({
                        'opacity': '1.0',
                    });
                }
            });

            var count = 0;
            var lastScrollTop = 0,
                delta = 5;
            $(window).scroll(function () {
                var nowScrollTop = $(this).scrollTop();
                if ($(window).scrollTop() > $('#scroll-persona-1').offset().top && $(window).scrollTop() < $('.area-commercial').offset().top) {
                    if (Math.abs(lastScrollTop - nowScrollTop) >= delta) {
                        if (nowScrollTop > lastScrollTop) {
                            count += 6;

                            $('#typography-names-01').css({
                                'transform': 'translate3d(' + count / 4 + 'px, 0px, 0px)',
                            });
                            $('#typography-names-02').css({
                                'transform': 'translate3d(' + -count / 4 + 'px, 0px, 0px)',
                            });

                        } else {
                            count -= 6;

                            $('#typography-names-01').css({
                                'transform': 'translate3d(' + count / 4 + 'px, 0px, 0px)',
                            });
                            $('#typography-names-02').css({
                                'transform': 'translate3d(' + -count / 4 + 'px, 0px, 0px)',
                            });
                        }
                        lastScrollTop = nowScrollTop;
                    }
                }
            });
            $(window).scroll(function () {
                var scrollTop = $(window).scrollTop();
                var scrollPersona = $('#scroll-persona-2').offset();
                if (scrollTop > scrollPersona.top && scrollTop < scrollPersona.top + 50) {
                    $('#persona-1').css({
                        'transform': 'translate3d(0px, 0px, 0px)',
                        'filter': 'brightness(0)',
                    });
                    $('#persona-2').css({
                        'filter': 'brightness(0.2)',
                    });
                    $('#persona-3').css({
                        'transform': 'translate3d(0px, 0px, 0px)'
                    });
                }
                if (scrollTop > scrollPersona.top + 50 && scrollTop < scrollPersona.top + 100) {
                    $('#persona-1').css({
                        'transform': 'translate3d(-10px, 0px, 0px)',
                        'filter': 'brightness(0.2)',
                    });
                    $('#persona-2').css({
                        'filter': 'brightness(0.2)',
                    });
                    $('#persona-3').css({
                        'transform': 'translate3d(10px, 0px, 0px)',
                    });
                }
                if (scrollTop > scrollPersona.top + 100 && scrollTop < scrollPersona.top + 150) {
                    $('#persona-1').css({
                        'transform': 'translate3d(-20px, 0px, 0px)',
                        'filter': 'brightness(0.4)'
                    });
                    $('#persona-2').css({
                        'filter': 'brightness(0.4)'
                    });
                    $('#persona-3').css({
                        'transform': 'translate3d(20px, 0px, 0px)',
                    });
                }
                if (scrollTop > scrollPersona.top + 150 && scrollTop < scrollPersona.top + 200) {
                    $('#persona-1').css({
                        'transform': 'translate3d(-30px, 0px, 0px)',
                        'filter': 'brightness(0.6)'
                    });
                    $('#persona-2').css({
                        'filter': 'brightness(0.6)'
                    });
                    $('#persona-3').css({
                        'transform': 'translate3d(30px, 0px, 0px)',
                    });
                }
                if (scrollTop > scrollPersona.top + 200 && scrollTop < scrollPersona.top + 250) {
                    $('#persona-1').css({
                        'transform': 'translate3d(-40px, 0px, 0px)',
                        'filter': 'brightness(0.8)'
                    });
                    $('#persona-2').css({
                        'filter': 'brightness(0.8)'
                    });
                    $('#persona-3').css({
                        'transform': 'translate3d(40px, 0px, 0px)',
                    });
                }
                if (scrollTop > scrollPersona.top + 250 && scrollTop < scrollPersona.top + 300) {
                    $('#persona-1').css({
                        'transform': 'translate3d(-50px, 0px, 0px)',
                        'filter': 'brightness(1.0)'
                    });
                    $('#persona-2').css({
                        'filter': 'brightness(1.0)'
                    });
                    $('#persona-3').css({
                        'transform': 'translate3d(50px, 0px, 0px)',
                    });
                }
                if (scrollTop > scrollPersona.top + 300 && scrollTop < scrollPersona.top + 350) {
                    $('#persona-1').css({
                        'transform': 'translate3d(-60px, 0px, 0px)',
                        'filter': 'brightness(1.0)'
                    });
                    $('#persona-2').css({
                        'filter': 'brightness(1.0)'
                    });
                    $('#persona-3').css({
                        'transform': 'translate3d(60px, 0px, 0px)',
                    });
                }
                if (scrollTop > scrollPersona.top + 350 && scrollTop < scrollPersona.top + 400) {
                    $('#persona-1').css({
                        'transform': 'translate3d(-70px, 0px, 0px)',
                        'filter': 'brightness(1.0)'
                    });
                    $('#persona-2').css({
                        'filter': 'brightness(1.0)'
                    });
                    $('#persona-3').css({
                        'transform': 'translate3d(70px, 0px, 0px)',
                    });
                }
                if (scrollTop > scrollPersona.top + 400 && scrollTop < scrollPersona.top + 450) {
                    $('#persona-1').css({
                        'transform': 'translate3d(-80px, 0px, 0px)',
                        'filter': 'brightness(1.0)'
                    });
                    $('#persona-2').css({
                        'filter': 'brightness(1.0)'
                    });
                    $('#persona-3').css({
                        'transform': 'translate3d(80px, 0px, 0px)',
                    });
                }
                if (scrollTop > scrollPersona.top + 450 && scrollTop < scrollPersona.top + 500) {
                    $('#persona-1').css({
                        'transform': 'translate3d(-90px, 0px, 0px)',
                        'filter': 'brightness(1.0)'
                    });
                    $('#persona-2').css({
                        'filter': 'brightness(1.0)'
                    });
                    $('#persona-3').css({
                        'transform': 'translate3d(90px, 0px, 0px)',
                    });
                }
                if (scrollTop > scrollPersona.top + 500 && scrollTop < scrollPersona.top + 550) {
                    $('#persona-1').css({
                        'transform': 'translate3d(-100px, 0px, 0px)',
                        'filter': 'brightness(1.0)'
                    });
                    $('#persona-2').css({
                        'filter': 'brightness(1.0)'
                    });
                    $('#persona-3').css({
                        'transform': 'translate3d(100px, 0px, 0px)',
                    });
                }
                if (scrollTop > scrollPersona.top + 550 && scrollTop < scrollPersona.top + 600) {
                    $('#persona-1').css({
                        'transform': 'translate3d(-120px, 0px, 0px)',
                        'filter': 'brightness(1.0)'
                    });
                    $('#persona-2').css({
                        'filter': 'brightness(1.0)'
                    });
                    $('#persona-3').css({
                        'transform': 'translate3d(110px, 0px, 0px)',
                    });
                }
                if (scrollTop > scrollPersona.top + 600) {
                    $('#persona-1').css({
                        'transform': 'translate3d(-140px, 0px, 0px)',
                        'filter': 'brightness(1.0)'
                    });
                    $('#persona-2').css({
                        'filter': 'brightness(1.0)'
                    });
                    $('#persona-3').css({
                        'transform': 'translate3d(120px, 0px, 0px)',
                    });
                }
            });

            var videoIa = false;
            $(window).scroll(function () {

                if ($(window).scrollTop() > $('.area-video-commercial').offset().top - 300 && videoIa == false) {

                    $('#video-ia-mobile').trigger('play');
                    setTimeout(function () {
                        videoIa = true;
                    }, 100);
                }
            });




            $(window).scroll(function () {
                if ($(window).scrollTop() > $('.box-award').offset().top - 800) {
                    $('#box-award-1').css({
                        'opacity': '1.0',
                    });

                    setTimeout(function () {
                        $('#box-award-2').css({
                            'opacity': '1.0',
                        });
                    }, 100);
                    setTimeout(function () {
                        $('#box-award-3').css({
                            'opacity': '1.0',
                        });
                    }, 500);

                } else {
                    $('#box-award-1').css({
                        'opacity': '0',
                    });
                    $('#box-award-2').css({
                        'opacity': '0',
                    });
                    $('#box-award-3').css({
                        'opacity': '0',
                    });
                }
            });

            $(window).scroll(function () {
                if ($(window).scrollTop() > $('.area-01').offset().top - 400) {
                    $('.area-01 .col-title').css({
                        'opacity': '1.0',
                    });
                } else {
                    $('.area-01 .col-title').css({
                        'opacity': '0',
                    });
                }
            });
            $(window).scroll(function () {
                if ($(window).scrollTop() > $('.area-02').offset().top - 400) {
                    $('.area-02 .col-title').css({
                        'opacity': '1.0',
                    });
                } else {
                    $('.area-02 .col-title').css({
                        'opacity': '0',
                    });
                }
            });
            $(window).scroll(function () {
                if ($(window).scrollTop() > $('.area-03').offset().top - 400) {
                    $('.area-03 .col-title').css({
                        'opacity': '1.0',
                    });
                } else {
                    $('.area-03 .col-title').css({
                        'opacity': '0',
                    });
                }
            });
        }
    });

    // PS. Esse documento possui scripts da página INDEX executados apenas em dispositivos MOBILE.
    $(document).ready(function () {

        $('.bt-accept').click(function () {
            $('.component-privacy').hide();
            $('body').removeClass('privacy-active');
        });


        $('.bt-close-video').click(function () {
            $(".all-video-modal").hide();
            $('video').trigger('pause');
        });

        $('.subhead-zeus').click(function () {
            window.location.href = "/zeus";
        });

    });
}