import Footer from "../components/Footer";
import Header from "../components/Header";
import Loading from "../components/Loading";
import { Container } from '../assets/css/privacy/styles-privacy.js';
import AnimationPrivacy from "./AnimationPrivacy";
import { useEffect } from "react";
import { registerPage } from '../service/services';

export default function Privacy() {

    useEffect(() => {
        AnimationPrivacy();
        registerPage(972)
    }, []);

    return (
        <Container>
           {/* <Loading /> */}
            <Header />
            <content>
                <section className="area-head-page initial-privacy bg-ciano">
                    <div className="container-medium">
                        <h2 className="blue" data-anime="bottom">CENTRAL DE<br />PRIVACIDADE<br /></h2>
                        <figure className="border-title bg-blue" motion-bar="bar-1"></figure>
                        <div className="row hide-pc">
                            <a className="bt-link" href="/">
                                <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/icon-arrow-left.svg" />
                                <p>Voltar ao início</p>
                            </a>
                        </div>
                        <div className="options-privacy">
                            <a className="bt-link" href="/">
                                <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/icon-arrow-left.svg" />
                                <p>Voltar ao início</p>
                            </a>
                            <div className="bt-tab">
                                <a className="tab-privacy-active" href="/central-privacidade">Política de privacidade</a>
                                <a href="/faq">Perguntas e respostas</a>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="area-privacy">
                    <div className="container-medium">
                        <div className="left-privacy">

                            <div className="menu-privacy" data-anime="bottom-2">
                                <button className="bt-privacy-active" id="link-01">Política de privacidade</button>
                                <button id="link-02">Coleta de dados</button>
                                <button id="link-03">Uso das informações</button>
                                <button id="link-04">Canal de comunicação</button>
                                <button id="link-05">Atualização de política</button>

                            </div>
                        </div>
                        <div className="right-privacy">
                            <div className="row" id="parte-01">
                                <h2 data-anime="bottom-2" className="">Política de privacidade</h2>
                            </div>
                            <div className="row">
                                <p data-anime="bottom-3" className="">O Grupo Services (SERVICES TECH EXPERIENCE - INOVAÇÃO E TECNOLOGIA EM RELACIONAMENTO LTDA., inscrita no CNPJ no 00.584.567/0001-52, estabelecida comercialmente à Rua José Loureiro, 371 – Centro – Curitiba – PR – CEP 80010-010) é comprometido em proteger a segurança
                                    de seus dados e desenvolve seus processos e produtos adotando a privacidade por padrão.
                                    <br /><br /> A Política de Privacidade do Grupo Services é aplicável para os serviços e produtos que coletam dados pessoais em plataformas web/online e visa detalhar, de forma transparente, todos os processos adotados pela empresa
                                    na utilização dos dados, as medidas de segurança e boas práticas empregadas, assim como, o canal de comunicação para que dúvidas decorrentes desta política ou o exercício de direitos previstos na Lei 13.709/2018 (Lei Geral
                                    de Proteção de Dados – LGPD), possam ser recepcionados.
                                    <br /><br /> Para tanto, é importante que você conheça e compreenda alguns termos previstos na legislação e que sustentam esta política:
                                </p>
                            </div>
                            <div className="row">
                                <div className="area-item " data-anime="bottom">
                                    <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/privacy/icons-privacy/icon-doc.svg" />
                                    <h3>Dados pessoais</h3>
                                    <p>Informação relacionada a pessoa natural identificada ou identificável (qualquer dado pessoal que lhe identifique ou lhe torne identificável).</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="area-item " data-anime="bottom">
                                    <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/privacy/icons-privacy/icon-people.svg" />
                                    <h3>Titular</h3>
                                    <p>Pessoa natural a quem se referem os dados pessoais que são objeto de tratamento (você, pessoa física, que insere os seus dados pessoais em nosso site ou em nossos portais de negociação).</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="area-item " data-anime="bottom">
                                    <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/privacy/icons-privacy/icon-controller.svg" />
                                    <h3>Controlador</h3>
                                    <p>Pessoa natural ou jurídica, de direito público ou privado, a quem competem as decisões referentes ao tratamento de dados pessoais (no caso, o Grupo Services no tocante aos dados coletados em nosso site institucional - www.gruposervices.com.br
                                        , ou, nossos clientes - aos quais disponibilizamos portais de negociação de débitos- junto aos quais você mantém ou manteve algum relacionamento comercial).</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="area-item" data-anime="bottom">
                                    <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/privacy/icons-privacy/icon-attendance.svg" />
                                    <h3>Operador</h3>
                                    <p>Pessoa natural ou jurídica, de direito público ou privado, que realiza o tratamento de dados pessoais em nome do controlador (No caso, o Grupo Services, nas situações em que realiza o tratamento de seus dados pessoais em nome
                                        de nossos clientes e seguindo as orientações dos mesmos, como nos portais de negociação, por exemplo).</p>
                                </div>
                            </div>
                            <div className="row">
                                <p data-anime="bottom" className="">O Grupo Services está comprometido em respeitar a privacidade do usuário de seu site institucional e de seus portais de negociação que são disponibilizados à diversos clientes e demonstrará neste documento as razões pelas quais
                                    coletamos informações sobre nossos usuários durante sua navegação, explicando de maneira clara e detalhada todo o processo desde a coleta, a forma de utilização de suas informações e o período que serão armazenadas.
                                    <br /><br /> Ao acessar e utilizar os serviços/produtos disponibilizados, bem como, interagir e enviar informações com dados pessoais, o usuário concorda com os termos desta política e fornece expresso consentimento para a que
                                    o Grupo Services realize o tratamento de seus dados na forma descrita nesta política, incluindo, também, a utilização de cookies.
                                </p>
                            </div>
                            <div className="row" id="parte-02">
                                <h2 data-anime="bottom" className="">Coleta de informações</h2>
                            </div>
                            <div className="row">
                                <h3 className="m-0-m" data-anime="bottom">Portais de autoatendimento</h3>
                            </div>
                            <div className="row">
                                <div className="area-item" data-anime="bottom">
                                    <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/privacy/icons-privacy/icon-form.svg" />
                                    <h3>Preenchimento de formulários</h3>
                                    <p>Dados como CPF, nome, IP1 e e-mail são solicitados para validação e são previamente fornecidos por nossos contratantes.</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="area-item" data-anime="bottom">
                                    <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/privacy/icon-privacy.svg" />
                                    <h3>Navegação no site</h3>
                                    <p>O Grupo Services utiliza as ferramentas (Adsense, Analytics, Adwords) que identificarão informações como IP, localização geográfica e tipo de navegador (isso ocorre durante a utilização dos portais.</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="area-item" data-anime="bottom">
                                    <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/privacy/icons-privacy/icon-controller.svg" />
                                    <h3>Controle de navegabilidade</h3>
                                    <p>Todo o acesso aos portais é mapeado e analisado por ferramentas próprias para analisar a navegabilidade e controlar a segurança/histórico via token de registro único.</p>
                                </div>
                            </div>
                            <div className="row">
                                <p data-anime="bottom">Todo o acesso aos sistemas online serão registrados em logs. As informações coletadas são: IP, versão Navegador, dispositivo utilizado, localização geográfica, token de acesso entre outros.
                                    <br /><br /> Cabe destacar que tais dados não são utilizados separadamente e sim em conjunto com outros usuários, resultando na sua não individualização, resguardando sua privacidade. Ressaltamos que a qualquer momento você poderá,
                                    por meio de seu navegador, apagar este cookie. As informações fornecidas na plataforma serão coletadas e utilizadas para ajudar a tornar a navegação mais produtiva. Garantimos que suas informações pessoais não serão utilizadas
                                    indevidamente (Spams ou venda de informações), bem como, que os processos internos respeitam os princípios da descritos na LGPD e que as melhores práticas de segurança e governança dos dados pessoais são empreendidos pela empresa.
                                    Os produtos e serviços do Grupo Services são utilizados por diversas empresas dos mais variados ramos (bancos, empresas de telefonia, televisão à cabo, etc.) e, portanto, possuem ligações com outros sites, sendo que esta Política
                                    de Privacidade se não se aplica a sites de terceiros.
                                </p>
                            </div>
                            <div className="row">
                                <h3 data-anime="bottom">Site institucional</h3>
                            </div>
                            <div className="row">
                                <p data-anime="bottom">No site institucional <a>(www.gruposervices.com.br)</a> são coletadas da seguinte maneira:</p>
                            </div>
                            <div className="row">
                                <div className="area-item" data-anime="bottom">
                                    <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/privacy/icon-privacy.svg" />
                                    <h3>Preenchimento de formulários e envio de documentos</h3>
                                    <p>O site institucional disponibiliza diversos segmentos para interação, os quais demandam o envio e a consequente coleta e armazenamento de dados pessoais.</p>
                                </div>
                            </div>
                            <div className="row">
                                <p data-anime="bottom">Nele, existem formulários específicos para Recrutamento e Seleção, contatos comerciais e ouvidoria, onde, a fim de providenciar uma adequada recepção do contato são necessários a coleta de: Nome, E-mail, telefone. Eventuais informações
                                    complementares e/ou anexos que contemplem informações pessoais não requeridas e disponibilizadas espontaneamente pelo titular serão igualmente armazenadas pelo prazo previsto.
                                    <br /><br /> Neste particular, destacamos serem estes dados mínimos necessários para o aperfeiçoamento das atividades e recepção das solicitações. Os dados serão direcionados apenas para um restrito grupo de colaboradores da empresa,
                                    dentro dos departamentos competentes, não havendo previsão de compartilhamento com terceiros.
                                </p>
                            </div>
                            <div className="row">
                                <div className="area-item" data-anime="bottom">
                                    <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/privacy/icon-privacy.svg" />
                                    <h3>Navegação no site</h3>
                                    <p>O Grupo Services utiliza as ferramentas (Adsense, Analytics, Adwords) que identificarão informações como IP, localização geográfica e tipo de navegador (isso ocorre durante a utilização dos portais.</p>
                                </div>
                            </div>
                            <div className="row">
                                <p data-anime="bottom">Garantimos que suas informações pessoais não serão utilizadas indevidamente (Spams ou venda de informações), bem como, que os processos internos respeitam os princípios da descritos na LGPD e que as melhores práticas de segurança
                                    e governança dos dados pessoais são empreendidos pela empresa.
                                </p>
                            </div>
                            <div className="row" id="parte-03">
                                <h2 data-anime="bottom">Uso das informações</h2>
                            </div>
                            <div className="row">
                                <p data-anime="bottom">
                                    As informações coletadas nos portais de negociação serão utilizadas para validação/exibição dos dados solicitados, geração de relatórios estratégicos e mapeamento de oportunidades para melhor atendimento, bem como, uso para estatísticas e levantamento
                                    de navegabilidade das plataformas utilizadas.
                                    <br /><br /> E-mail e/ou telefone serão utilizados para envios de informações solicitadas durante a interação no portal online. Apenas uma equipe interna devidamente autorizada terá acesso as informações coletadas e os dados ficarão
                                    armazenados por até 90 (noventa) dias após saldados os débitos respectivos. Os referidos dados, podem, também, serem compartilhados com o cliente ao qual o portal é licenciado.
                                    <br /><br /> Não utilizaremos as informações para outros fins que não detalhados aqui nesta Política de Privacidade. No tocante as informações coletadas no site institucional, estas possuem finalidades distintas e previamente esclarecidas,
                                    conforme a interação promovida pelo titular. Os dados recebidos à título de recrutamento e seleção, assim como eventuais anexos, visam auxiliar os processos de seleção de candidatos as vagas oferecidas pela empresa.
                                    <br /><br /> Aqueles relativos à relacionamento comercial, visam recepcionar contatos de eventuais interessados em conhecer e contratar os serviços do Grupo Services, assim como, realizar o call back (retorno do contato telefônico).
                                    Por fim, dados direcionados na aba ouvidoria visam possibilitar o auxílio e adequado retorno em solicitações diversas, como dúvidas gerais (direcionamentos de negociações, envio de segunda via, baixa de pagamento), sugestões
                                    e reclamações. O tempo de armazenamento das informações é variado, sendo de 1 (um) ano para informações relacionadas à recrutamento e seleção, 1 (um) ano para contatos comerciais e 5 (cinco) anos para contatos com a ouvidoria.
                                </p>
                            </div>
                            <div className="row">
                                <h2 data-anime="bottom">Como protegemos</h2>
                            </div>
                            <div className="row">
                                <p data-anime="bottom">
                                    As informações coletadas pelo Grupo Services são armazenadas em data center’s seguros e controlados, localizados no Brasil, não havendo, portanto, transferência internacional das informações compartilhadas em nosso site ou portais por nós desenvolvidos.
                                    A empresa adota as melhores práticas na governança dos dados pessoais, sempre objetivando a segurança e a privacidade dos dados que lhe são confiados.
                                    <br /><br /> Todos os dados desde a sua coleta até o seu armazenamento trafegam por conexões criptografadas são armazenados em servidores com acessos restritos e controlados, criptografados com algoritmos fortes padrão do mercado.
                                    O Grupo Services regularmente efetua atualizações tecnológicas para elevar os controles e minimizar riscos de vazamento de dados, buscando garantir sua integridade, confiabilidade e disponibilidade. Para tanto, utiliza ferramenta
                                    para prevenção de perda de dados (DLP), gerenciamento e correção de eventos de segurança (SIEM) e múltiplo fator de autenticação (MFA) em seus sistemas e aplicações, soluções estas que auxiliam na segurança e proteção de seus
                                    dados pessoais.
                                </p>
                            </div>
                            <div className="row" id="parte-04">
                                <h2 data-anime="bottom">Canal de comunicação</h2>
                            </div>
                            <div className="row">
                                <p data-anime="bottom">
                                    Dúvidas ou solicitações decorrentes desta política, assim como, para o exercício dos direitos previstos na Lei Geral de Proteção de Dados devem ser direcionadas para lgpd@gruposervices.com.br aos cuidados de nosso Encarregado de Proteção de Dados (Alexandre
                                    Naunapper Santos, advogado inscrito na OAB/PR sob o no 58.575, certificado EXIN).
                                </p>
                            </div>
                            <div className="row">
                                <div className="area-item p-l-0" data-anime="bottom">
                                    <p>Este canal é exclusivo para tratativas relacionadas ao tema. Este canal não é adequado para solicitação de informação sobre processos seletivos, contatos comerciais, consulta de valores e/ou emissão de boletos entre outros,
                                        devendo, para tais propósitos, ser utilizado o meio próprio.
                                    </p>
                                </div>
                            </div>
                            <div className="row" id="parte-05">
                                <h2 data-anime="bottom">Atualização desta Política de privacidade</h2>
                            </div>
                            <div className="row">
                                <p data-anime="bottom">
                                    O Grupo Services pode analisar e revisar periodicamente as práticas, as políticas e os procedimentos de proteção de dados, inclusive esta política de privacidade e proteção de dados pessoais, visando sempre resguardar a privacidade do usuário em conformidade
                                    com as premissas da LGPD, cujos termos serão sempre divulgados nos canais próprios.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </content>
            <Footer />
        </Container>
    )
}