import Header from '../components/Header'
import Footer from '../components/Footer'
import SubHead from '../components/SubHead';
import {Container} from '../assets/css/zeus/styles-zeus.js';
import AnimationZeus from './AnimationZeus'
import Loading from '../components/Loading';
import { useEffect } from 'react'
import { Link } from 'react-router-dom';
import { registerPage } from '../service/services';

export default function Zeus() {

    useEffect(() => {
        AnimationZeus();
        registerPage(971);
    }, [])

    return (
        <Container>
           {/* <Loading /> */}
            <Header />
            <content>
                <SubHead />

                <section className="part-01">
                    <div className="scroll-01" id="scrollInitial"></div>
                    <div className="bg-zeus"></div>
                    <div className="mask">
                        <div className="area-title-page hide-mobile">
                            <div className="container-large">
                                <div className="row">
                                    <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/zeus/logo-zeus.png" />
                                </div>
                                <div className="row" id="title-index">
                                    <h2><span className="ciano">a Inteligência<br /> Artificial </span>do <br />Grupo Services.</h2>
                                </div>
                                <div className="row">
                                    <button className="bt-play-zeus">
                                        <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/zeus/icon-audio.svg" className="icon-wave" />
                                        <p>OUVIR O ZEUS</p>
                                        <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/icon-play-video.svg" className="icon-play" />
                                    </button>
                                    <audio controls className="hide" id="audio-intro">
                                        <source src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/audio/zeus-intro.wav" type="audio/wav" />
                                    </audio>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row center">
                        <button className="bt-down">
                        </button>
                    </div>
                </section>

                <section className="intro-mobile hide-pc">
                    <div className="video-wave-mobile">
                        <video autoPlay loop muted playsInline>
                            <source type="video/mp4" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/videos/zeus/bg-wave.mp4" />
                        </video>
                    </div>
                    <div className="container-large">
                        <div className="row">
                            <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/zeus/logo-zeus.png" className="logo-zeus" />
                        </div>
                        <div className="row" id="title-index">
                            <h2><span className="gradiente-aio">A Inteligência</span> <br /> <span className="gradiente-aio">Artificial do</span> <br />Grupo Services.</h2>
                        </div>
                        <div className="row">
                            <button className="bt-play-zeus">
                                <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/zeus/icon-audio.svg" className="icon-wave" />
                                <p>OUVIR O ZEUS</p>
                                <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/icon-play-video.svg" className="icon-play" />
                            </button>
                        </div>
                    </div>
                </section>


                <section className="area-award">
                    <div className="container-large">
                        <div className="title-area-award">
                            <div className="row center">
                                <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/zeus/icon-award.svg" />
                            </div>
                            <div className="row center">
                                <h2>ROBÔ MAIS</h2>
                            </div>
                            <div className="row center">
                                <div className="title-decorate-award">
                                    <p>
                                        PREMIADO
                                    </p>
                                </div>
                            </div>
                            <div className="row center">
                                <h2>DA CATEGORIA</h2>
                            </div>
                        </div>
                    </div>
                    <div className="area-boxes-award">
                        <div className="box-award" id="box-award-1">
                            <div className="content-intern-award">
                                <div className="row center">
                                    <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/zeus/award-icon.svg" />
                                </div>
                                <div className="row">
                                    <p className="title-award">
                                        Prêmio
                                    </p>
                                    <label className="year-award">2020</label>
                                    <p className="title-award">
                                        Consumidor Moderno
                                    </p>
                                </div>
                                <div className="row">
                                    <p className="subtitle-award">
                                        De excelência em serviço ao cliente
                                    </p>
                                </div>
                                <div className="row">
                                    <p className="text-award">
                                        Agente/Assistente virtual para atendimento por voz
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="box-award" id="box-award-2">
                            <div className="content-intern-award">
                                <div className="row center">
                                    <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/zeus/conarec-icon.svg" className="conarec-icon" />
                                </div>
                                <div className="row align-title-award">
                                    <p className="title-award">
                                        Prêmio Conarec
                                    </p>
                                    <label className="year-award">2020</label>
                                </div>
                                <div className="row">
                                    <p className="subtitle-award">
                                        Grupo Services
                                    </p>
                                </div>
                                <div className="row">
                                    <p className="text-award">
                                        Solução de atendimento via BOT por Voz
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="box-award" id="box-award-3">
                            <div className="content-intern-award">
                                <div className="row center">
                                    <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/zeus/conarec-icon.svg" className="conarec-icon" />
                                </div>
                                <div className="row align-title-award">
                                    <p className="title-award">
                                        Prêmio Conarec
                                    </p>
                                    <label className="year-award">2020</label>
                                </div>
                                <div className="row">
                                    <p className="subtitle-award">
                                        Grupo Services
                                    </p>
                                </div>
                                <div className="row">
                                    <p className="text-award">
                                        Soluções de inteligência artificial
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="area-spotlight-zeus">
                    <div className="item-spotlight area-01">
                        <div className="col-title">
                            <div className="align-title-item">
                                <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/zeus/icon-spotlight-01.svg" />
                                <p>INTELIGÊNCIA<br />ARTIFICIAL</p>
                                <figure></figure>
                            </div>
                        </div>
                        <div className="col-bg">

                        </div>
                    </div>
                    <div className="item-spotlight area-02">
                        <div className="col-title">
                            <div className="align-title-item">
                                <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/zeus/icon-spotlight-02.svg" />
                                <p>MULTI<span className="gray">PROVIDER</span></p>
                                <figure></figure>
                            </div>
                        </div>
                        <div className="col-bg"></div>
                    </div>
                    <div className="item-spotlight area-03">
                        <div className="col-title">
                            <div className="align-title-item">
                                <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/zeus/icon-spotlight-03.svg" />
                                <p>100%</p>
                                <label className="gray">DESENVOLVIMENTO<br />GRUPO SERVICES</label>
                                <figure></figure>
                            </div>
                        </div>
                        <div className="col-bg"></div>
                    </div>
                </section>
                <section className="area-personas">
                    <div className="scroll-persona" id="scroll-persona-1"></div>
                    <div className="scroll-persona-2" id="scroll-persona-2"></div>
                    <div className="row center">
                        <h1>MÚLTIPLAS PERSONAS</h1>
                    </div>
                    <div className="area-lettering-personas">
                        <div className="row-lettering" id="typography-names-01">
                            <p>MILENA</p>
                            <p className="letter-op">JORGE</p>
                            <p>DEBORA</p>
                        </div>
                        <div className="row-lettering" id="typography-names-02">
                            <p className="letter-op">NZO</p>
                            <p>ADRIANO</p>
                            <p className="letter-op">EDNA</p>
                        </div>
                    </div>
                    <div className="area-image-personas">
                        <figure className="persona-1" id="persona-1"></figure>
                        <figure className="persona-2" id="persona-2"></figure>
                        <figure className="persona-3" id="persona-3"></figure>
                    </div>
                </section>
                <section className="area-commercial">
                    <div className="area-video-commercial">
                        <video id="video-ia" muted playsInline className="hide-mobile">
                            <source type="video/mp4" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/videos/zeus/zeus-ia-desktop.mp4" />
                        </video>
                        <video id="video-ia-mobile" loop muted playsInline className="hide-pc">
                            <source type="video/mp4" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/videos/zeus/zeus-ia-mobile.mp4" />
                        </video>
                    </div>
                    <div className="content-area-commercial">
                        <h1>Conheça aqui o que o Zeus é capaz de fazer, as nossas personas. </h1>
                        <div className="row center">
                            <a className="bt-tertiary invert" href="/fale-conosco">
                                <p>Contate nossa área comercial</p>
                            </a>
                            {/* <Link className="bt-tertiary invert" to="/fale-conosco">
                                <p>Contate nossa área comercial</p>
                            </Link> */}
                        </div>
                    </div>
                </section>
                <section className="area-solutions">
                    <div className="box-solutions">
                        <div className="container-medium">
                            <div className="row">
                                <h1>Soluções sob medida Para os nossos clientes.
                                </h1>
                            </div>
                            <div className="row">
                                <a className="bt-link" href="/produtos">
                                    <p>Saiba mais</p>

                                </a>
                                {/* <Link className="bt-link" to="/produtos">
                                    <p>Saiba mais</p>

                                </Link> */}
                            </div>
                        </div>
                    </div>
                </section>
            </content>
            <Footer />
        </Container>
    )
}