import styled from "styled-components"

export const Container = styled.div`

@media only screen and (min-width: 2000px) {
    .box-history-04 .container-large {
        width: 1600px;
        max-width: 2000px;
    }
}

@media screen and (min-width: 1200px) {
    .initial-opportunities {
        height: 100vh !important;
        min-height: 800px;
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/oportunidades/bg-initial.png');
        background-size: cover;
        background-repeat: no-repeat;
    }
    .area-history-01 {
        width: 100%;
        float: left;
        padding: 30px;
        padding-top: 0px !important;
    }
    .box-history-01 {
        width: 100%;
        height: 600px;
        float: left;
        background-color: rgb(207, 207, 207);
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/oportunidades/bg-history-01-desktop.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        background-position: right;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }
    .box-history-01 .container-large {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
    }
    .box-history-01 h2 {
        color: white;
        filter: brightness(1.7);
    }
    .align-text-02 {
        width: calc(100% - 511px);
        float: left;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .area-history-02 .area-left {
        width: 40%;
        float: left;
        display: flex;
        justify-content: flex-end;
        padding-right: 80px;
    }
    .area-history-02 .area-right {
        width: 60%;
        float: left;
        border-left: 4px solid #e2e2e2 !important;
        padding-left: 80px;
    }
    .area-history-02 h3 {
        font-weight: 500;
        font-size: 28px;
        line-height: 42px;
    }
    .box-history-05 h3 {
        font-size: 26px;
        line-height: 37px;
        font-weight: 500;
        color: white;
    }
    .box-history-05 p {
        font-size: 24px;
        line-height: 37px;
        font-weight: 500;
        color: white;
    }
    .box-history-05 img {
        width: 450px;
    }
    .area-history-05 {
        width: 100%;
        float: left;
        background-color: #f9f9f9;
        padding-top: 0px;
    }
    .box-history-05 {
        width: 100%;
        float: left;
        height: calc(100vh - 131px);
        background-color: #2998FA;
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/oportunidades/bg-jxlover.png');
        background-size: cover;
        background-repeat: no-repeat;
        padding: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .box-history-05 .bt-primary {
        background-color: #032648;
        margin-top: 30px;
    }
    .area-history-06 {
        width: 100%;
        float: left;
        padding-top: 0px !important;
        padding: 40px;
    }
    .box-history-06 {
        width: 100%;
        float: left;
        display: flex;
        justify-content: space-between;
    }
    .area-form-candidate {
        width: 100%;
        float: left;
        display: flex;
        justify-content: center;
        padding-top: 30px;
        padding-bottom: 90px;
    }
    .area-history-03 {
        width: 100%;
        float: left;
        padding: 40px;
    }
    .box-history-03 {
        width: 100%;
        display: flex;
        justify-content: center;
        float: left;
        background-color: #FAFAFA;
        padding-top: 135px;
        padding-bottom: 135px;
    }
    .area-history-03 .col-left {
        width: 50%;
        float: left;
        padding-right: 70px;
    }
    .area-history-03 .col-right {
        width: 50%;
        height: 100%;
        float: left;
        padding-left: 70px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .area-border-bottom {
        width: 340px;
        height: 10px;
        margin-top: 20px;
        float: left;
        background-color: orange;
    }
    .area-history-03 p {
        width: 502px;
        font-size: 22px;
        margin-top: 40px;
        line-height: 37px;
        font-weight: 400;
    }
    .area-history-03 .col-left img,
    .area-history-03 .col-right img {
        width: 100%;
    }
    .area-history-03 .col-left img {
        margin-top: 117px;
    }
    .area-history-03 .col-right .area-border-bottom {
        /* margin-top: 223px; */
        margin-bottom: 30px;
    }
    .info-1 .input-item {
        width: 100%;
        float: left;
    }
    .info-2 {
        display: flex;
        justify-content: space-between;
        margin-top: 15px;
        margin-bottom: 15px;
    }
    .info-2 .input-item {
        width: calc(33.33% - 10px);
        float: left;
    }
    .info-1 {
        margin-top: 50px
    }
    .name-file {
        height: 50px;
        max-width: 300px;
        float: left;
        /* display: flex; */
        align-items: center;
        justify-content: center;
        padding-left: 42px;
        padding-right: 15px;
        background-color: #b8b8b8;
        margin-left: 10px;
        color: #ffffff;
        border-radius: 6px;
        padding-right: 46px;
        font-family: 'sf';
        font-size: 14px;
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/icon-file.svg');
        background-repeat: no-repeat;
        background-position: 12px;
        position: relative;
        /* text-overflow: clip; */
        white-space: nowrap;
        /* width: 100%; */
        overflow: hidden;
        /* "overflow" value must be different from "visible" */
        text-overflow: ellipsis !important;
        /* background-color: red; */
        padding-top: 14px;
    }
    .politic-confirmation a {
        color: #2998fa;
        font-weight: 600;
        text-decoration: none;
    }
    .politic-confirmation {
        font-weight: 400;
    }
    .box-testimony {
        width: calc(33.33% - 15px);
        height: 0px;
        float: left;
        border-radius: 10px;
        position: relative;
        cursor: pointer;
        transition: all 0.2s linear;
        overflow: hidden;
        padding-bottom: calc(33.33% - 15px);
        /* position: absolute; */
        overflow: hidden;
    }
    .testimony-1 {
        background-size: cover;
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/oportunidades/depoimentos/thumb-andre.jpg');
    }
    .testimony-2 {
        background-size: cover;
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/oportunidades/depoimentos/thumb-rainielly.jpg');
    }
    .testimony-3 {
        background-size: cover;
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/oportunidades/depoimentos/thumb-karla.jpg');
    }
    .box-testimony:hover .info-testimony {
        opacity: 1.0;
    }
    .box-testimony:hover h2 {
        color: #FC5B3F;
        margin-top: calc(100% - 260px);
    }
    .box-testimony .bt-link {
        margin-top: 6px !important;
    }
    .box-testimony h3 {
        font-weight: 600;
        font-size: 22px;
        line-height: 27px !important;
        color: #fff;
        /* margin-top: 5px; */
    }
    .box-testimony h2 {
        color: white;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 44px;
        line-height: 58px;
        position: relative;
        margin-top: calc(100% - 121px);
        transition: all 0.4s linear;
    }
    .box-testimony:hover h2 {
        margin-top: calc(100% - 180px);
    }
    .info-testimony {
        opacity: 0;
        transition: all 0.4s linear;
    }
    /* HIDE */
    .hide-pc {
        display: none !important;
    }
    .image-03-01 {
        width: 100%;
        height: 779px;
        background-color: gray;
        float: left;
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/oportunidades/bg-employees.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: left top;
        margin-top: 60px;
        transition: all 0.8s ease !important;
        opacity: 0;
    }
    .image-03-02 {
        width: 100%;
        height: 730px;
        background-color: gray;
        float: left;
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/oportunidades/bg-beach.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        transition: all 0.8s ease !important;
        opacity: 0;
    }
    .area-initial-opportunities {
        width: 100%;
        height: 300vh;
        float: left;
        background-color: black;
    }
    .area-bg-initial {
        width: 100%;
        height: 100vh;
        background-color: rgb(0, 0, 0);
        float: left;
        position: sticky;
        top: 0px;
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/oportunidades/bg-initial.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-attachment: fixed;
        margin-top: -100vh;
    }
    .area-video-initial {
        width: 100%;
        height: 100vh;
        background-color: rgb(0, 0, 0);
        float: left;
        position: sticky;
        top: -1px;
        opacity: 0;
        margin-top: -50vh;
        border: 0px solid white;
        border-top: 0px !important;
        overflow: hidden;
        transition: all 0.4s linear;
        padding: 0px;
    }
    section {
        transition: all 0.4s linear;
    }
    .area-video-initial video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 0.5;
    }
    .area-text-initial {
        width: 100%;
        height: calc(100vh - 50px);
        top: 50px;
        position: sticky;
        /* background-color: red; */
        display: flex;
        align-items: center;
        color: white;
        flex-direction: column;
        text-align: left;
        z-index: 9;
    }
    .area-text-initial .container-medium {
        display: flex;
        justify-content: center;
        flex-direction: column;
        /* align-items: center; */
    }
    .area-text-initial h1 {
        font-size: 40px !important;
        line-height: 52px;
    }
    .scroll-1-initial {
        width: 50px;
        height: 100vh;
        background-color: red;
        position: absolute;
        top: 50vh;
        left: 0px;
        visibility: hidden;
    }
    .scroll-2-initial {
        width: 50px;
        height: 100vh;
        background-color: orange;
        position: absolute;
        top: 70vh;
        left: 0px;
        visibility: hidden;
    }
    .align-bt-small {
        width: 100%;
        float: left;
        position: sticky;
        top: calc(100vh - 335px);
        display: flex;
        justify-content: center;
        padding-bottom: 292px;
        z-index: 9;
    }
    .align-bt-small .container-medium {
        display: flex;
        justify-content: flex-end;
    }
    .before-02 {
        background-color: #0f0f0f !important;
    }
    .before-02 .box-history-02 {
        background-color: #0f0f0f;
    }
    .before-02 .align-text-02 {
        filter: brightness(10.0);
    }
    .before-02 .box-history-02 {
        background-position: left 208px;
    }
    .box-history-02 {
        width: 100%;
        height: 700px;
        float: left;
        background-color: #fff;
        padding: 90px;
        padding-right: 198px;
        display: flex;
        align-items: center;
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/oportunidades/bg-black-man.png');
        background-size: 700px;
        background-repeat: no-repeat;
        display: flex;
        justify-content: flex-end;
        background-position: left 28px;
        transition: background-position 1.0s cubic-bezier(0.65, 0.05, 0.36, 1);
    }
    .area-history-02 {
        width: 100%;
        padding: 30px;
        float: left;
        background-position: left bottom;
        background-color: #fff;
        transition: all 0.6s ease;
    }
    .before-03 {
        background-color: #0f0f0f;
    }
    .before-03 .box-history-03 {
        background-color: #0f0f0f;
    }
    .before-03 .box-history-03 p,
    .before-03 .box-history-03 h2,
    .before-03 .area-border-bottom {
        color: white !important;
        filter: brightness(10.0);
    }
    #title-final-03 {
        opacity: 0;
        transform: translate3d(0px, 40px, 0px);
    }
    .motion-title {
        opacity: 1 !important;
        transform: translate3d(0px, 0px, 0px) !important;
        border: 0px solid white;
    }
    .before-03 #border-title-final-03 {
        width: 0px;
    }
    #border-title-final-03 {
        transition: all 0.6s ease;
    }
    .area-text-initial h3 {
        font-family: "grupo-services";
        font-weight: 700;
        font-size: 14px;
    }
    .content-testimony {
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: #00000080;
        backdrop-filter: saturate(100%) blur(0px);
        padding: 50px;
        padding-bottom: 30px;
    }
    .content-testimony:hover {
        background-color: rgb(0 0 0 / 70%);
        backdrop-filter: saturate(120%) blur(3px);
    }
    .area-history-04 {
        width: 100%;
        height: 200vh;
        float: left;
        /* padding-top: 0px !important; */
        background-color: #F9F9F9;
        padding: 40px !important;
    }
    .box-history-04 span {
        font-weight: 700;
    }
    .box-history-04 {
        width: 100%;
        height: calc(100vh - 115px);
        float: left;
        padding-top: 130px;
        padding-bottom: 130px;
        display: flex;
        justify-content: center;
        position: sticky;
        top: 80px;
        margin-top: -100vh;
        z-index: 10;
        opacity: 0;
        transition: all 0.5s linear;
        align-items: center;
    }
    .box-history-04 p {
        font-size: 22px;
        line-height: 37px;
        font-weight: 400;
        margin-top: 20px;
    }
    .box-history-04 .bt-down {
        display: none
    }
    .area-video-04 {
        width: 100%;
        height: calc(100vh - 117px);
        float: left;
        position: sticky;
        top: 0px;
        z-index: 9;
        transition: all 0.5s linear;
        display: flex;
        justify-content: center;
        background-color: white;
    }
    .area-video-04 video {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
    .area-history-05 .container-medium {
        height: auto;
    }
    .text-click {
        margin-top: 17px;
    }
    .area-actions-form {
        display: flex;
        justify-content: space-between;
        align-items: center;
        /* margin-top: 20px; */
        width: 600px;
        /* background-color: red; */
    }
    .area-attachment {
        float: left;
    }
    .bt-remove-file {
        width: 15px;
        height: 15px;
        position: absolute;
        right: 12px;
        top: 17px;
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/icon-close.svg');
        background-size: cover;
        background-repeat: no-repeat;
        background-color: transparent;
    }
}

@media screen and (max-width: 1199px) {
    .initial-opportunities {
        height: 100vh !important;
        min-height: 800px;
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/oportunidades/mobile/bg-history-01-mobile.jpg');
        background-size: cover;
        background-repeat: no-repeat;
    }
    .area-form-candidate .container-medium {
        width: calc(100% - 30px) !important;
    }
    .area-history-01 {
        width: 100%;
        float: left;
        padding: 30px;
        padding-top: 0px !important;
    }
    .box-history-01 {
        width: 100%;
        height: 600px;
        float: left;
        background-color: rgb(207, 207, 207);
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/oportunidades/mobile/bg-history-01-mobile.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: flex-end;
        text-align: right;
        padding-bottom: 40px;
    }
    .box-history-01 .container-large {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
    }
    .box-history-01 h2 {
        color: white;
        filter: brightness(1.7);
        font-size: 26px;
        line-height: 38px;
    }
    .align-text-02 {
        width: 100%;
        float: left;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .area-history-02 .area-left {
        width: 100%;
        float: left;
        display: flex;
        justify-content: flex-end;
        /* padding-right: 80px; */
        /* background-image: url(https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/oportunidades/bg-black-man.png); */
        /* background-size: 406px; */
        /* background-position: left; */
        /* background-image: url(https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/oportunidades/bg-black-man.png); */
        /* background-size: 406px; */
        background-position: -200px bottom;
        background-repeat: no-repeat;
        /* background-color: #f9f9f9; */
    }
    .area-history-02 .area-right {
        width: 100%;
        float: left;
        /* border-left: 4px solid #e2e2e2 !important; */
        padding-left: 40px;
        padding-top: 70px;
        padding-bottom: 70px;
        background-color: #e9e9e9;
    }
    .area-history-02 h3 {
        width: 50%;
        font-weight: 500;
        font-size: 18px;
        line-height: 42px;
        /* background: red; */
        /* background-image: url(https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/oportunidades/bg-black-man.png); */
        background-size: 406px;
        background-position: -200px top;
        background-repeat: no-repeat;
        margin-left: 50%;
        line-height: 26px;
        height: 350px;
        display: flex;
        align-items: center;
        border-left: 3px solid #e8e8e8;
        padding-left: 30px;
        margin-top: 50px;
        margin-bottom: 50px;
    }
    .box-history-05 h3 {
        font-size: 18px;
        line-height: 28px;
        font-weight: 500;
        color: white;
        text-align: center;
    }
    .box-history-05 p {
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        color: white;
        text-align: center;
    }
    .box-history-05 img {
        width: 212px;
    }
    .area-history-05 {
        width: 100%;
        float: left;
        background-color: #f9f9f9;
        padding-top: 0px;
    }
    .box-history-05 {
        width: 100%;
        float: left;
        height: 600p;
        background-color: #2998FA;
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/oportunidades/bg-jxlover.png');
        background-size: cover;
        background-repeat: no-repeat;
        /* padding: 100px; */
        padding-top: 70px;
        padding-bottom: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .box-history-05 .bt-primary {
        background-color: #032648;
        margin-top: 30px;
    }
    .area-history-06 {
        width: 100%;
        float: left;
        padding-top: 0px !important;
        padding: 20px;
    }
    .box-history-06 {
        width: 100%;
        float: left;
        /* display: flex; */
        /* justify-content: space-between; */
    }
    .area-form-candidate {
        width: 100%;
        float: left;
        display: flex;
        justify-content: center;
        padding-top: 30px;
        padding-bottom: 70px;
    }
    .area-history-03 {
        width: 100%;
        float: left;
        /* padding: 40px; */
    }
    .box-history-03 {
        width: 100%;
        display: flex;
        justify-content: center;
        float: left;
        background-color: #FAFAFA;
        padding-top: 40px;
        padding-bottom: 135px;
        transition: all 0.4s linear;
    }
    .area-history-03 .col-left {
        width: 100%;
        float: left;
        transition: all 0.4s linear;
    }
    .area-history-03 .col-right {
        width: 100%;
        /* height: 100%; */
        float: left;
        transition: all 0.4s linear;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 20px;
    }
    .area-border-bottom {
        width: 100%;
        height: 10px;
        margin-top: 20px;
        float: left;
        background-color: orange;
    }
    .area-history-03 p {
        width: 100%;
        font-size: 14px;
        /* margin-top: 40px; */
        line-height: 28px;
        font-weight: 400;
    }
    .area-history-03 .col-left img,
    .area-history-03 .col-right img {
        width: 100%;
    }
    .area-history-03 .col-left img {
        margin-top: 117px;
    }
    .area-history-03 .col-right .area-border-bottom {
        /* margin-top: 223px; */
        margin-bottom: 30px;
    }
    .info-1 .input-item {
        width: 100%;
        float: left;
    }
    .info-2 {
        /* display: flex; */
        /* justify-content: space-between; */
        /* margin-top: 15px; */
        /* margin-bottom: 15px; */
    }
    .info-2 .input-item {
        width: calc(33.33% - 10px);
        float: left;
    }
    .info-1 {
        margin-top: 26px;
    }
    .name-file {
        /* background-color: red; */
        height: 40px;
        float: left;
        max-width: 100%;
        min-width: 170px;
        /* display: flex; */
        /* align-items: center; */
        /* justify-content: center; */
        padding-left: 42px;
        padding-right: 26px;
        background-color: #b8b8b8;
        margin-top: 15px;
        color: #ffffff;
        border-radius: 4px;
        padding-right: 46px;
        font-family: 'sf';
        font-size: 12px;
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/icon-file.svg');
        background-repeat: no-repeat;
        background-position: 12px;
        position: relative;
        text-overflow: ellipsis !important;
        white-space: nowrap;
        overflow: hidden;
        padding-top: 8px;
    }
    .politic-confirmation a {
        color: #2998fa;
        font-weight: 600;
        text-decoration: none;
    }
    .politic-confirmation {
        font-weight: 400;
    }
    .box-testimony {
        width: 100%;
        height: 0px;
        float: left;
        border-radius: 10px;
        position: relative;
        cursor: pointer;
        transition: all 0.2s linear;
        overflow: hidden;
        padding-bottom: 100%;
        /* position: absolute; */
        overflow: hidden;
        margin-top: 20px;
    }
    .testimony-1 {
        background-size: cover;
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/oportunidades/depoimentos/thumb-andre.jpg');
    }
    .testimony-2 {
        background-size: cover;
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/oportunidades/depoimentos/thumb-rainielly.jpg');
    }
    .testimony-3 {
        background-size: cover;
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/oportunidades/depoimentos/thumb-karla.jpg');
    }
    .box-testimony:hover .info-testimony {
        opacity: 1.0;
    }
    .box-testimony:hover h2 {
        color: #FC5B3F;
        margin-top: calc(100% - 260px);
    }
    .box-testimony .bt-link {
        margin-top: 6px !important;
    }
    .box-testimony h3 {
        font-weight: 600;
        font-size: 22px;
        line-height: 27px !important;
        color: #fff;
        /* margin-top: 5px; */
    }
    .box-testimony h2 {
        color: white;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 31px;
        line-height: 44px;
        position: relative;
        margin-top: calc(100% - 85px);
        transition: all 0.4s linear;
    }
    .box-testimony:hover h2 {
        margin-top: calc(100% - 180px);
    }
    .info-testimony {
        opacity: 0;
        transition: all 0.4s linear;
    }
    /* HIDE */
    .image-03-01 {
        width: 100%;
        height: 500px;
        background-color: gray;
        float: left;
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/oportunidades/bg-employees.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: left top;
        margin-top: 60px;
        transition: all 0.8s ease !important;
    }
    .image-03-02 {
        width: 100%;
        height: 730px;
        background-color: gray;
        float: left;
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/oportunidades/bg-beach.jpg');
        background-size: auto 112%;
        background-repeat: no-repeat;
        transition: all 0.8s ease !important;
    }
    .area-initial-opportunities {
        width: 100%;
        height: 300vh;
        float: left;
        background-color: black;
    }
    .area-bg-initial {
        width: 100%;
        height: 100vh;
        background-color: rgb(0, 0, 0);
        float: left;
        position: sticky;
        top: 0px;
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/oportunidades/bg-initial.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: right;
        margin-top: -100vh;
    }
    .area-video-initial {
        width: 100%;
        height: 100vh;
        background-color: rgb(0, 0, 0);
        float: left;
        position: sticky;
        top: -1px;
        opacity: 0;
        margin-top: -50vh;
        border: 0px solid white;
        border-top: 0px !important;
        overflow: hidden;
        transition: all 0.4s linear;
        padding: 0px;
    }
    section {
        transition: all 0.4s linear;
    }
    .area-video-initial video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 0.5;
    }
    .area-text-initial {
        width: 100%;
        height: calc(100vh - 50px);
        top: 50px;
        position: sticky;
        /* background-color: red; */
        display: flex;
        align-items: center;
        color: white;
        flex-direction: column;
        text-align: left;
        z-index: 9;
    }
    .area-text-initial .container-medium {
        display: flex;
        justify-content: center;
        flex-direction: column;
        /* align-items: center; */
        height: 100vh;
    }
    .area-text-initial h1 {
        font-size: 25px !important;
        line-height: 39px;
    }
    .scroll-1-initial {
        width: 50px;
        height: 100vh;
        background-color: red;
        position: absolute;
        top: 50vh;
        left: 0px;
        visibility: hidden;
    }
    .scroll-2-initial {
        width: 50px;
        height: 100vh;
        background-color: orange;
        position: absolute;
        top: 70vh;
        left: 0px;
        visibility: hidden;
    }
    .align-bt-small {
        width: 100%;
        float: left;
        position: sticky;
        top: calc(100vh - 30px);
        display: flex;
        justify-content: center;
        padding-bottom: 75px;
        z-index: 9;
    }
    .align-bt-small .container-medium {
        display: flex;
        justify-content: flex-end;
    }
    .before-02 {
        background-color: #0f0f0f !important;
    }
    .before-02 .box-history-02 {
        background-color: #0f0f0f;
    }
    .before-02 .align-text-02 {
        filter: brightness(10.0);
    }
    .before-02 .box-history-02 {
        /* background-position: left 208px; */
    }
    .box-history-02 {
        width: 100%;
        /* height: 700px; */
        float: left;
        background-color: #fff;
        /* padding: 90px; */
        /* padding-right: 198px; */
        display: flex;
        align-items: center;
        /* background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/oportunidades/bg-black-man.png'); */
        /* background-size: 406px; */
        background-repeat: no-repeat;
        /* background-position: -140px; */
        display: flex;
        justify-content: flex-end;
        /* background-position: left 28px; */
        transition: background-position 1.0s cubic-bezier(0.65, 0.05, 0.36, 1);
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/oportunidades/bg-black-man.png');
        background-size: 406px;
        background-position: -199px 45px;
    }
    .area-history-02 {
        width: 100%;
        padding: 10px;
        float: left;
        background-position: left bottom;
        background-color: #fff;
        transition: all 0.6s ease;
    }
    .before-03 {
        background-color: #0f0f0f;
    }
    .before-03 .box-history-03 {
        background-color: #0f0f0f;
    }
    .before-03 .box-history-03 p,
    .before-03 .box-history-03 h2,
    .before-03 .area-border-bottom {
        color: white !important;
        filter: brightness(10.0);
    }
    #title-final-03 {
        opacity: 0;
        transform: translate3d(0px, 40px, 0px);
        line-height: 30px;
    }
    .motion-title {
        opacity: 1 !important;
        transform: translate3d(0px, 0px, 0px) !important;
        border: 0px solid white;
    }
    .before-03 #border-title-final-03 {
        width: 0px;
    }
    #border-title-final-03 {
        transition: all 0.6s ease;
    }
    .area-text-initial h3 {
        font-family: "grupo-services";
        font-weight: 700;
        font-size: 11px;
        line-height: 20px;
    }
    .content-testimony {
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: #00000080;
        backdrop-filter: saturate(100%) blur(0px);
        padding: 35px;
        padding-bottom: 30px;
    }
    .content-testimony:hover {
        background-color: rgb(0 0 0 / 70%);
        backdrop-filter: saturate(120%) blur(3px);
    }
    .area-history-04 {
        width: 100%;
        /* overflow-x: hidden; */
        height: 155vh;
        float: left;
        padding-top: 0px !important;
        background-color: #ffffff;
        position: relative;
        /* padding-bottom: 70px !important; */
        /* padding-left: 20px; */
        /* overflow: hidden; */
    }
    .box-history-04 span {
        font-weight: 700;
    }
    .box-history-04 {
        width: 100%;
        /* height: calc(100vh - 115px); */
        float: left;
        padding-top: 223px;
        /* padding-bottom: 130px; */
        display: flex;
        align-items: flex-end;
        position: sticky;
        top: 0px;
        margin-top: -86vh;
        z-index: 10;
        opacity: 0;
        transition: all 0.5s linear;
        /* align-items: center; */
        /* position: sticky; */
        /* background-color: red; */
        padding-left: 30px;
        padding-bottom: 161px;
    }
    .box-history-04 p {
        font-size: 18px;
        line-height: 31px;
        font-weight: 400;
        margin-top: 20px;
    }
    .area-video-04 {
        width: 100%;
        height: 100vh;
        float: left;
        position: sticky;
        top: 0px;
        z-index: 9;
        transition: all 0.5s linear;
        display: flex;
        justify-content: center;
        background-color: white;
    }
    .area-video-04 video {
        max-width: 100vw;
        height: 100%;
        object-fit: cover;
    }
    .area-history-05 .container-medium {
        height: auto;
        min-height: auto;
    }
    .text-click {
        margin-top: 17px;
    }
    .area-actions-form {
        display: flex;
        justify-content: space-between;
        align-items: center;
        /* margin-top: 20px; */
        width: 100%;
        /* background-color: red; */
    }
    .area-attachment {
        float: left;
        width: 100%;
    }
    .bt-remove-file {
        width: 15px;
        height: 15px;
        position: absolute;
        right: 12px;
        top: 12px;
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/icon-close.svg');
        background-size: cover;
        background-repeat: no-repeat;
        background-color: transparent;
    }
    .container-medium {
        width: calc(100% - 80px);
    }
    #border-initial {
        margin-top: 15px
    }
    .border-title {
        width: 0px;
        transition: all 0.4s linear;
    }
    .animate-bar {
        width: 100%;
    }
    #title-final-03 h2 {
        line-height: 39px;
    }
    .box-history-04 {
        width: 100%;
        float: left;
    }
    .area-history-04 h1 {
        font-size: 30px;
    }
    .info-2 .input-item {
        width: 100%;
    }
    .area-full-form {
        width: 100%;
    }
    .align-text-02{
        overflow:hidden
    }
}
`;