import Footer from "../components/Footer";
import Header from "../components/Header";
import Subhead from "../components/SubHead";
import Loading from "../components/Loading";
import { Container } from '../assets/css/faq/styles-faq.js';
import AnimationBlog from '../Blog/AnimationBlog'
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { registerPage } from '../service/services';

export default function Faq() {

    useEffect(() => {
        AnimationBlog();
        registerPage(973);
    }, [])

    return (
        <Container>
           {/* <Loading /> */}
            <Header />
            <content>
                <Subhead />
                <section className="area-head-page initial-privacy bg-ciano">
                    <div className="container-medium">
                        <h2 className="blue animate" data-anime="bottom">CENTRAL DE<br />PRIVACIDADE<br /></h2>
                        <figure className="border-title bg-blue animate-bar" motion-bar="bar-1"></figure>
                        <div className="row hide-pc">
                            <a className="bt-link" href="/">
                                <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/icon-arrow-left.svg" />
                                <p>Voltar ao início</p>
                            </a>
                            {/* <Link className="bt-link" to="/">
                                <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/icon-arrow-left.svg" />
                                <p>Voltar ao início</p>
                            </Link> */}
                        </div>
                        <div className="options-privacy">
                            <a className="bt-link" href="/">
                                <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/icon-arrow-left.svg" />
                                <p>Voltar ao início</p>
                            </a>
                            {/* <Link className="bt-link" to="/">
                                <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/icon-arrow-left.svg" />
                                <p>Voltar ao início</p>
                            </Link> */}
                            <div className="bt-tab">
                                <a href="/central-privacidade">Política de privacidade</a>
                                <a className="tab-privacy-active" href="/faq">Perguntas e respostas</a>
                                {/* <Link to="/central-privacidade">Política de privacidade</Link>
                                <Link className="tab-privacy-active" to="/faq">Perguntas e respostas</Link> */}
                            </div>
                        </div>
                    </div>
                </section>
                <section className="area-privacy">
                    <div className="container-medium">
                        <div className="about-privacy">
                            <div className="row">
                                <h2>Perguntas e respostas</h2>
                            </div>
                            <div className="row">
                                <p>
                                    O Grupo Services protege seus dados pessoais e respeita sua privacidade. <br /> Nossa política detalha de maneira clara e transparente todos os tratamentos por nós realizados e que são necessários para o desenvolvimento das
                                    atividades e/ou produtos disponibilizados. Para tanto, convidamos a ler nossa política, desenvolvida de maneira bastante objetiva. <br /> Ainda, oportunizamos um FAQ com perguntas recorrentes recebidas em nosso CANAL LGPD.
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <details>
                                <summary>
                                    Este canal é para solicitações de boletos de acordo e/ou segunda via?
                                </summary>
                                <p>
                                    Não, o canal lgpd@gruposervices.com.br é apenas para dúvidas e solicitações decorrentes da política de privacidade do Grupo Services. Solicitações de boletos e segunda via devem ser solicitados pelos canais próprios.
                                </p>
                            </details>
                        </div>
                        <div className="row animate" data-anime="bottom">
                            <details>
                                <summary>
                                    Estou com meu usuário bloqueado ou com dificuldades de fazer login nos portais de negociação. Este é o canal adequado?
                                </summary>
                                <p>
                                    Não, o canal lgpd@gruposervices.com.br é apenas para dúvidas e solicitações decorrentes da política de privacidade do Grupo Services. Solicitações desta natureza devem ser encaminhadas à ouvidoria.
                                </p>
                            </details>
                        </div>
                        <div className="row animate" data-anime="bottom">
                            <details>
                                <summary>
                                    Quero alterar meu plano de telefone e/ou TV. É por aqui?
                                </summary>
                                <p>
                                    Não, o canal lgpd@gruposervices.com.br é apenas para dúvidas e solicitações decorrentes da política de privacidade do Grupo Services. Solicitações desta natureza devem ser realizadas diretamente com a operadora do serviço.
                                </p>
                            </details>
                        </div>
                        <div className="row animate" data-anime="bottom">
                            <details>
                                <summary>
                                    Quero negociar um parcelamento dos meus débitos. Posso realizar pelo canal LGPD?
                                </summary>
                                <p>
                                    Não, o canal lgpd@gruposervices.com.br é apenas para dúvidas e solicitações decorrentes da política de privacidade do Grupo Services. Esta providência pode ser realizada por intermédio dos portais de negociação.
                                </p>
                            </details>
                        </div>
                        <div className="row animate" data-anime="bottom">
                            <details>
                                <summary>
                                    Paguei uma conta em duplicidade ou indevidamente. Consigo resolver no canal LGPD?
                                </summary>
                                <p>
                                    Não, o canal lgpd@gruposervices.com.br é apenas para dúvidas e solicitações decorrentes da política de privacidade do Grupo Services. Neste caso, solicitamos fazer contato diretamente com a prestadora do serviço contratado.
                                </p>
                            </details>
                        </div>
                        <div className="row animate" data-anime="bottom">
                            <details>
                                <summary>
                                    Quero negociar um parcelamento dos meus débitos. Posso realizar pelo canal LGPD?
                                </summary>
                                <p>
                                    Não, o canal lgpd@gruposervices.com.br é apenas para dúvidas e solicitações decorrentes da política de privacidade do Grupo Services. Esta providência pode ser realizada por intermédio dos portais de negociação.
                                </p>
                            </details>
                        </div>
                        <div className="row animate" data-anime="bottom">
                            <details>
                                <summary>
                                    Não concordo em fornecer os dados solicitados para emissão de segunda via nos portais, o que fazer?
                                </summary>
                                <p>
                                    Os portais de negociação são uma forma prática de autoatendimento e necessitam da validação de alguns dados pessoais para confirmarmos a sua identidade. Acaso não concorde em fornecer os dados pessoais, outros canais para o recebimento de boletos são
                                    disponibilizados.
                                </p>
                            </details>
                        </div>
                        <div className="row animate" data-anime="bottom">
                            <details>
                                <summary>
                                    Não estou conseguindo cadastrar meu cartão. O que posso fazer?
                                </summary>
                                <p>
                                    Solicitações de suporte técnico devem ser direcionadas à ouvidoria, que internalizará o tema.
                                </p>
                            </details>
                        </div>
                    </div>
                </section>
            </content>
            <Footer />
        </Container>
    )
}