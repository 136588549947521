import styled from "styled-components"

export const Container = styled.main `

@media screen and (min-width: 1200px) {
    .initial-privacy {
        height: 470px !important;
        padding-top: 100px
    }
    
    .options-privacy {
        width: 100%;
        float: left;
        background-color: #4BBFD4;
        /* backdrop-filter: saturate(180%) blur(20px); */
        padding: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 6px;
        color: white;
        padding-left: 20px !important;
        margin-top: 40px;
    }
    
    .about-privacy {
        margin-bottom: 50px;
        float: left;
    }
    
    .about-privacy p {
        float: left;
        margin-top: 20px;
    }
    
    .bt-tab a {
        width: auto;
        margin: 5px;
        height: 44px;
        padding: 6px;
        float: left;
        border: 0px;
        padding-left: 18px;
        padding-right: 18px;
        border-radius: 4px;
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
        padding-top: 10px !important;
        text-decoration: none;
        background-color: white;
        color: gray;
    }
    
    .options-privacy .bt-link {
        margin: 0px !important;
    }
    
    .options-privacy .bt-link p {
        color: white
    }
    
    .options-privacy .bt-link img {
        filter: brightness(5.0);
    }
    
    .area-privacy {
        width: 100%;
        float: left;
        display: flex;
        justify-content: center;
        min-height: 800px;
        padding-top: 80px;
        padding-bottom: 180px;
    }
    
    .left-privacy {
        width: 300px;
        min-height: 100%;
        float: left;
        position: absolute;
        top: 0px;
    }
    
    .right-privacy {
        width: 898px;
        min-height: 100%;
        float: left;
        /* border: 1px solid orange; */
        margin-left: 301px;
    }
    
    .menu-privacy {
        width: 100%;
        float: left;
        position: sticky;
        top: 88px;
    }
    
    .menu-privacy button {
        width: 100%;
        float: left;
        text-align: left;
        background-color: transparent;
        height: 50px;
        font-size: 16px;
        border: 0px;
        padding-left: 15px;
    }
    
    .bt-privacy-active {
        border-left: 4px solid #2998FA !important;
        padding-left: 10px;
        color: #2998FA;
        font-weight: 700;
    }
    
    .right-privacy h2 {
        text-transform: none;
        color: black;
        margin-top: 25px;
        color: black;
    }
    
    .right-privacy h3 {
        color: black;
        margin-top: 25px;
    }
    
    .area-privacy h2 {
        text-transform: none;
        color: black;
    }
    
    .right-privacy p {
        margin-top: 15px !important
    }
    
    .area-item {
        width: 100%;
        float: left;
        background-color: #F0F0F0;
        padding: 25px;
        border-radius: 6px;
        margin-top: 20px;
        padding-left: 100px;
        position: relative;
    }
    
    .area-item p {
        margin-top: 0px !important;
        font-size: 16px;
        line-height: 22px;
    }
    
    .area-item img {
        width: 40px;
        position: absolute;
        left: 30px;
        top: 30px;
    }
    
    .area-item h3 {
        line-height: 30px;
        color: black;
        margin-top: 0px !important;
        color: black;
    }
    
    .p-l-0 {
        padding-left: 30px !important;
    }
    
    .tab-privacy-active {
        color: #2998FA !important
    }
    
    summary {
        color: black;
        font-size: 20px;
        cursor: pointer;
        font-weight: 700;
        position: relative;
        /* padding-right: 50px; */
    }
    
    details {
        border-bottom: 1px solid #c4c4c4;
        padding: 20px;
        padding-top: 30px;
        padding-bottom: 32px;
        padding-right: 0px;
        padding-left: 0px;
    }
    
    details p {
        padding-top: 20px;
        padding-right: 62px;
    }
    
    summary:before {
        content: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/privacy/icon-more.svg');
        color: #753bbd;
        float: right;
        text-align: center;
        width: 0px;
        justify-content: center;
        align-items: center;
        display: flex;
        position: absolute;
        top: -8px;
        right: 20px;
        height: 50px;
        transform: scale(0.8);
    }
    
    details[open] summary:before {
        content: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/privacy/icon-faq.svg');
    }
    
    summary::marker {
        display: none;
        visibility: hidden;
        opacity: 0;
        width: 0px;
        font-size: 0px;
    }
    
    .sticky-privacy {
        width: 100%;
    }
    
    .container-medium {
        position: relative;
    }
}

@media screen and (max-width: 1199px) {
    .initial-privacy {
        height: 470px !important;
        padding-top: 100px;
        position: relative;
        padding-bottom: 80px;
    }
    
    .initial-privacy .bt-link p {
        color: white
    }
    
    .initial-privacy .bt-link {
        margin-top: 40px !important;
    }
    
    .initial-privacy .bt-link img {
        filter: brightness(5.0);
    }
    
    .options-privacy {
        width: 100%;
        float: left;
        background-color: rgba(0, 0, 0, 0.1);
        padding: 8px;
        border-radius: 6px;
        color: white;
        margin-top: 40px;
        position: absolute;
        bottom: 0px;
        left: 0px;
    }
    
    .bt-tab {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    
    .bt-tab a {
        width: calc(50% - 5px);
        height: 44px;
        padding: 6px;
        float: left;
        border: 0px;
        /* padding-left: 18px; */
        /* padding-right: 18px; */
        border-radius: 4px;
        font-size: 10px;
        font-weight: 700;
        text-transform: uppercase;
        text-decoration: none;
        /* padding-top: 10px !important; */
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        color: gray;
    }
    
    .options-privacy .bt-link {
        margin-top: 10px !important;
        display: none;
    }
    
    .options-privacy .bt-link p {
        color: white
    }
    
    .options-privacy .bt-link img {
        filter: brightness(5.0);
    }
    
    .area-privacy {
        width: 100%;
        float: left;
        display: flex;
        justify-content: center;
        /* min-height: 800px; */
        padding-top: 5px;
        padding-bottom: 80px;
    }
    
    .left-privacy {
        width: 100%;
        margin-top: 20px;
        padding-bottom: 20px;
        float: left;
        border-bottom: 1px solid #d0d0d0;
    }
    
    .right-privacy {
        width: 100%;
        min-height: 100%;
        float: left;
        /* border: 1px solid orange; */
    }
    
    .menu-privacy {
        width: 100%;
        float: left;
    }
    
    .menu-privacy button {
        width: 100%;
        float: left;
        text-align: left;
        background-color: transparent;
        height: 40px;
        font-size: 15px;
        border: 0px;
        padding-left: 15px;
    }
    
    .bt-privacy-active {
        border-left: 4px solid #2998FA !important;
        padding-left: 10px;
        color: #2998FA;
        font-weight: 600;
    }
    
    .area-privacy h2 {
        text-transform: none;
        color: black;
        margin-top: 20px;
        color: black;
        font-size: 24px;
    }
    
    .right-privacy h2 {
        text-transform: none;
        color: black;
        margin-top: 13px;
        color: black;
        font-size: 22px;
    }
    
    .right-privacy h3 {
        color: black;
        margin-top: 25px;
    }
    
    .area-item h3 {
        /* text-transform: none; */
        /* color: black; */
        /* margin-top: 5px !important; */
        /* font-size: 20px */
    }
    
    .right-privacy p {
        margin-top: 15px !important;
        font-weight: 300px;
        color: gray;
        line-height: 22px;
        font-size: 14px;
    }
    
    .area-item {
        width: 100%;
        float: left;
        background-color: #F0F0F0;
        padding: 16px;
        border-radius: 6px;
        margin-top: 20px;
        padding-left: 70px;
        position: relative;
    }
    
    .area-item p {
        margin-top: 6px !important;
        font-size: 13px;
        line-height: 18px;
    }
    
    .area-item img {
        width: 28px;
        position: absolute;
        left: 18px;
        top: 30px;
    }
    
    .area-item h3 {
        line-height: 17px;
        color: black;
        margin-top: 0px !important;
        color: black;
        font-weight: 600;
        font-size: 17px;
    }
    
    .p-l-0 {
        padding-left: 30px !important;
    }
    
    .tab-privacy-active {
        color: #2998FA !important
    }
    
    .m-0-m {
        margin-top: 0px !important
    }
    
    summary::-webkit-details-marker {
        display: inline-block;
        width: 0em;
        height: 0em;
        margin-right: 0em;
    }
    
    summary {
        color: black;
        font-size: 17px;
        cursor: pointer;
        font-weight: 600;
        line-height: 22px;
        position: relative;
        padding-right: 66px;
        outline: 0;
    }
    
    details {
        border-top: 1px solid #c4c4c4;
        padding: 20px;
        padding-top: 25px;
        padding-bottom: 25px;
        padding-right: 0px;
        padding-left: 0px;
    }
    
    details p {
        padding-top: 20px;
        padding-right: 62px;
    }
    
    summary:before {
        content: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/privacy/icon-more.svg');
        color: #753bbd;
        float: right;
        text-align: center;
        width: 5px;
        justify-content: center;
        align-items: center;
        display: flex;
        position: absolute;
        top: 0px;
        right: 11px;
        height: 50px;
        transform: scale(0.7);
    }
    
    details[open] summary:before {
        content: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/privacy/icon-faq.svg');
    }
    
    summary::marker {
        display: none;
        visibility: hidden;
        opacity: 0;
        width: 0px;
        font-size: 0px;
    }
    
    .about-privacy {
        width: 100%;
        float: left;
        margin-bottom: 40px;
    }
}
`;