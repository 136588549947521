import { Link } from "react-router-dom";
import { useParams } from "react-router";

import { useTranslation } from "react-i18next"

export default function Footer() {
    const [t, i18n] = useTranslation()
    const pathName = window.location.pathname.replace('/', '')
        .replaceAll('-', ' ').replace(/\b\w/g, l => l.toUpperCase())

    const { id } = useParams();

    return (
        <>
            {/* <!--
                Start of Floodlight Tag: Please do not remove
                Activity name of this tag: Grupo Services - RTG
                URL of the webpage where the tag is expected to be placed: 
                This tag must be placed between the <body> and </body> tags, as close as possible to the opening tag.
                Creation Date: 09/23/2021
            --> */}
            <script type="text/javascript">
                var axel = Math.random() + "";
                var a = axel * 10000000000000;
                document.write('<img loading="lazy" src="https://ad.doubleclick.net/ddm/activity/src=11323910;type=invmedia;cat=grupo00;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=' + a + '?" width="1" height="1" alt="" />');
            </script>
            <noscript>
                <img
                    src="https://ad.doubleclick.net/ddm/activity/src=11323910;type=invmedia;cat=grupo00;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?"
                    width="1" height="1" alt="" />
            </noscript>
            {/* <!-- End of Floodlight Tag: Please do not remove --> */}

            {/* <!--
                Start of Floodlight Tag: Please do not remove
                Activity name of this tag: Grupo Services - Conversao
                URL of the webpage where the tag is expected to be placed: 
                This tag must be placed between the <body> and </body> tags, as close as possible to the opening tag.
                Creation Date: 09/23/2021
            --> */}
            <script type="text/javascript">
                var axel = Math.random() + "";
                var a = axel * 10000000000000;
                document.write('<img loading="lazy" src="https://ad.doubleclick.net/ddm/activity/src=11323910;type=invmedia;cat=grupo0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1;num=' + a + '?" width="1" height="1" alt="" />');
            </script>
            <noscript>
                <img
                    src="https://ad.doubleclick.net/ddm/activity/src=11323910;type=invmedia;cat=grupo0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1;num=1?"
                    width="1" height="1" alt="" />
            </noscript>
            {/* <!-- End of Floodlight Tag: Please do not remove --> */}

            <footer>
                <div className="container-medium">
                    <div className="head-footer">
                        <div className="left-footer">
                            <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/logo-services.svg" className="logo-footer" />
                            {pathName ?
                                <div className="breadcrumb">

                                    <a className="item-path" href="/">
                                        <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/icon-arrow-breadcrumb.svg" />
                                        <p>{t('nav.home')}</p>
                                    </a>
                                    {/* <Link className="item-path" to="/">
                                        <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/icon-arrow-breadcrumb.svg" />
                                        <p>{t('nav.home')}</p>
                                    </Link> */}
                                    {id ?
                                        <>
                                            <a className="item-path" href="/blog">
                                                <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/icon-arrow-breadcrumb.svg" />
                                                <p>Blog</p>
                                            </a>
                                            <a className="item-path path-active">
                                                <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/icon-arrow-breadcrumb.svg" />
                                                <p>{id.replaceAll('-', ' ')}</p>
                                            </a>
                                        </>
                                        :
                                        <a className="item-path path-active">
                                            <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/icon-arrow-breadcrumb.svg" />
                                            <p>{pathName}</p>
                                        </a>
                                    }
                                </div>
                                : ''
                            }
                        </div>
                        <div className="social-icons-footer">
                            <a target="_blank" href="https://www.facebook.com/gruposervicesbywebhelp"><img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/icon-facebook.svg" /></a>
                            <a target="_blank" href="https://www.instagram.com/gruposervicesbywebhelp/"><img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/icon-instagram.svg" /></a>
                            <a target="_blank" href="https://www.linkedin.com/company/gruposervicesbywebhelp"><img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/icon-linkedin.svg" /></a>
                            <a target="_blank" href="https://www.youtube.com/channel/UCxFcMiEaaMbrdlc99L7C3uQ?sub_confirmation=1"><img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/icon-youtube.svg" /></a>
                        </div>
                    </div>
                    <div className="content-footer">
                        <div className="col-footer">
                            <div className="category-footer">
                                <input type="radio" id="category-contact" name="category-footer" />
                                <label htmlFor="category-contact" className="title-category">{t('nav.contact')}</label>
                                <ul className="contact-footer">
                                    <li>Rua José Loureiro, 371 </li>
                                    <li> Centro - Curitiba - PR </li>
                                    <li> <a href="tel:+554138839500">+55 (41) 3883-9500</a> </li>
                                </ul>
                            </div>
                            {/* <div className="box-spotlight-zeus">
                                <div className="head-zeus"><img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/icon-head-zeus.png" /></div>
                                <div className="number-zeus">
                                    <p>Converse com o nosso agente digital</p>
                                    <a href="tel:08006430729">0800 643 0729</a>
                                </div>
                            </div> */}
                        </div>
                        <div className="col-footer">
                            <div className="category-footer">
                                <input type="radio" id="category-about" name="category-footer" />
                                <label htmlFor="category-about" className="title-category">{t('nav.company')}</label>
                                <ul className="about-footer">
                                    <li><a href="/institucional">{t('footer.aboutus')}</a></li>
                                    <li><a href="/cultura">{t('nav.culture')}</a></li>
                                    {/* <li><Link to="/institucional">{t('footer.aboutus')}</Link></li>
                                <li><Link to="/cultura">{t('nav.culture')}</Link></li> */}
                                </ul>
                            </div>
                        </div>
                        <div className="col-footer">
                            <div className="category-footer">
                                <input type="radio" id="category-products" name="category-footer" />
                                <label htmlFor="category-products" className="title-category">{t('footer.services')}</label>
                                <ul className="products-footer">
                                    <li><a href="/produtos?ura">URA</a></li>
                                    <li><a href="/produtos?bot-voz">{t('footer.voicebot')}</a></li>
                                    <li><a href="/produtos?portais">{t('footer.portals')}</a></li>
                                    <li><a href="/produtos?bot-texto">{t('footer.chatbot')}</a></li>
                                    <li><a href="/produtos?consultor-digital">{t('footer.consultant')}</a></li>
                                    <li><a href="/produtos?ath">{t('footer.attendence')}</a></li>
                                    <li><a href="/produtos?aio">AIO</a></li>
                                    {/* <li><Link to="/produtos?ura">URA</Link></li>
                                    <li><Link to="/produtos?bot-voz">{t('footer.voicebot')}</Link></li>
                                    <li><Link to="/produtos?portais">{t('footer.portals')}</Link></li>
                                    <li><Link to="/produtos?bot-texto">{t('footer.chatbot')}</Link></li>
                                    <li><Link to="/produtos?consultor-digital">{t('footer.consultant')}</Link></li>
                                    <li><Link to="/produtos?atendimento-humano">{t('footer.attendence')}</Link></li>
                                    <li><Link to="/produtos?aio">AIO</Link></li> */}
                                </ul>
                            </div>
                        </div>
                        <div className="col-footer">
                            <div className="category-footer">
                                <input type="radio" id="category-help" name="category-footer" />
                                <label htmlFor="category-help" className="title-category">{t('footer.help')}</label>
                                <ul className="help-footer">
                                    <li><a href="/fale-conosco">{t('nav.contact')}</a></li>
                                    {/* <li><Link to="/fale-conosco">{t('nav.contact')}</Link></li> */}
                                </ul>
                            </div>
                            <div className="category-footer">
                                <input type="radio" id="category-blog" name="category-footer" />
                                <label htmlFor="category-blog" className="title-category">{t('footer.news')}</label>
                                <ul className="blog-footer">
                                    <li><a href="/blog">Blog</a></li>
                                    {/* <li><Link to="/blog">Blog</Link></li> */}
                                </ul>
                            </div>
                        </div>
                        <div className="col-footer">
                            <div className="category-footer">
                                <input type="radio" id="category-opportunities" name="category-footer" />
                                <label htmlFor="category-opportunities" className="title-category">{t('footer.work')}</label>
                                <ul className="opportunities-footer">
                                    <li><a href="/oportunidades">{t('nav.jobs')}</a></li>
                                    {/* <li><Link to="/oportunidades">{t('nav.jobs')}</Link></li> */}
                                </ul>
                            </div>
                            <div className="category-footer">
                                <input type="radio" id="category-policy" name="category-footer" />
                                <label htmlFor="category-policy" className="title-category">{t('footer.privacy')}</label>
                                <ul className="policy-footer">
                                    <li><a href="/central-privacidade">{t('footer.policy')}</a></li>
                                    <li><a href="/faq">FAQ</a></li>
                                    {/* <li><Link to="/central-privacidade">{t('footer.policy')}</Link></li>
                                <li><Link to="/faq">FAQ</Link></li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="area-copyright">
                        <p>{t('footer.copyright')}</p>
                        <div className="area-language-footer">
                            <img loading="lazy" src={i18n.language === "pt" ? "https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/icon-br.svg" : "https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/icon-us.svg"} alt="" />
                            <p>{i18n.language === "pt" ? "PT - BR" : "EN - US"}</p>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}