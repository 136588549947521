export default function Manifest() {

    function back() {
        const url = localStorage.getItem('_routeBack');
        if (url) {
            localStorage.removeItem('_routeBack');
            window.location.href = url
        } else {
            window.location.href = window.location.origin;
        }
    }

    return (
        <>{/* <!--
            Start of Floodlight Tag: Please do not remove
            Activity name of this tag: Grupo Services - RTG
            URL of the webpage where the tag is expected to be placed: 
            This tag must be placed between the <body> and </body> tags, as close as possible to the opening tag.
            Creation Date: 09/23/2021
        --> */}
            <script type="text/javascript">
                var axel = Math.random() + "";
                var a = axel * 10000000000000;
                document.write('<img loading="lazy" src="https://ad.doubleclick.net/ddm/activity/src=11323910;type=invmedia;cat=grupo00;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=' + a + '?" width="1" height="1" alt="" />');
            </script>
            <noscript>
                <img
                    src="https://ad.doubleclick.net/ddm/activity/src=11323910;type=invmedia;cat=grupo00;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?"
                    width="1" height="1" alt="" />
            </noscript>
            {/* <!-- End of Floodlight Tag: Please do not remove --> */}

            {/* <!--
            Start of Floodlight Tag: Please do not remove
            Activity name of this tag: Grupo Services - Conversao
            URL of the webpage where the tag is expected to be placed: 
            This tag must be placed between the <body> and </body> tags, as close as possible to the opening tag.
            Creation Date: 09/23/2021
        --> */}
            <script type="text/javascript">
                var axel = Math.random() + "";
                var a = axel * 10000000000000;
                document.write('<img loading="lazy" src="https://ad.doubleclick.net/ddm/activity/src=11323910;type=invmedia;cat=grupo0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1;num=' + a + '?" width="1" height="1" alt="" />');
            </script>
            <noscript>
                <img
                    src="https://ad.doubleclick.net/ddm/activity/src=11323910;type=invmedia;cat=grupo0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1;num=1?"
                    width="1" height="1" alt="" />
            </noscript>
            {/* <!-- End of Floodlight Tag: Please do not remove --> */}
            <div className="all-video-modal" id="video-manifest">
                <a className="bt-close-video" onClick={back}></a>
                <video controls id="item-video-manifest" autoPlay poster="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/institucional/thumbnail-manifesto.png">
                    <source type="video/mp4" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/videos/institucional/manifesto.mp4" />
                </video>
            </div>
        </>
    )
}