import { Container } from "../assets/css/post/styles-post.js"
import { Trans, useTranslation } from "react-i18next"
import env from "react-dotenv";
import Loading from "../components/Loading"
import Header from "../components/Header"
import SubHead from "../components/SubHead"
import Footer from "../components/Footer"
import AnimationPost from "./AnimationPost"
import { useEffect, useState } from "react"
import api from "../service/api"
import { useParams } from "react-router-dom"


export default function Post() {

    const [t, i18n] = useTranslation('blog')
    const [posts, setPosts] = useState([]);
    const [post, setPost] = useState([]);
    const [ptbr, setPtbr] = useState(process.env.REACT_APP_PTBR);
    const [eng, setEng] = useState(process.env.REACT_APP_ENG);


    const { id } = useParams();

    useEffect(() => {
        AnimationPost();

        api.get('get-posts-blog').then(res => {
            if(i18n.language == 'en'){
                setPosts(res.data.data.filter((post, index) => post.linguagem == eng));
                setPost(res.data.data.filter((post, index) => post.postId == id)[0]);
            } else{
                setPosts(res.data.data.filter((post, index) => post.linguagem == ptbr));
                setPost(res.data.data.filter((post, index) => post.id == id)[0]);
            }
        }).catch(() => {
            window.location.href = '/blog';
        });
    }, []);

    useEffect(() => {
        if (post) {
            document.querySelector('.text-post').innerHTML = post.texto
        }
    }, [post])

    return (
        <Container>
           {/* <Loading /> */}
            <Header />
            <content>
                <SubHead />
                <section className="area-head-page initial-post">
                    <div className="container-medium">
                        <h3 className="blue" data-anime="bottom">BLOG</h3>
                        <h2 className="blue" data-anime="bottom-2">{post.categoria}</h2>
                        <figure className="border-title bg-blue"></figure>
                        <div className="row">
                            <a className="bt-link" href="/blog" data-anime="bottom-3">
                                <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/icon-arrow-left.svg" />
                                <p>{t('all-posts')}</p>
                            </a>
                        </div>
                    </div>
                </section>
                <section className="area-post-blog">
                    <div className="container-medium">
                        <div className="left-post">
                            <div className="row">
                                <h3>{post.titulo}</h3>
                            </div>
                            {post.subtitulo !== post.titulo && post.subtitulo !== '' ?
                                <div class="row">
                                    <h4>{post.subtitulo}</h4>
                                </div>
                                : ''
                            }
                            <div className="row">
                                <p className="date-post">{new Date(post.data_cadastro).toLocaleDateString('pt-br')} - {new Date(post.data_cadastro).toLocaleTimeString('pt-br').slice(0, 5)}</p>
                            </div>
                            {post.autor === "Teste" ?
                                <div class="row">
                                    <p class="author-post">Grupo Services</p>
                                </div>
                                : 
                                <div class="row">
                                    <p class="author-post">{post.autor}</p>
                                </div>
                            }
                            <div className="row image-post">
                                <img loading="lazy" src={post.imagem} />
                            </div>
                            <div className="row text-post"></div>
                        </div>
                        <div className="right-post">
                            <h4 className="blue">INSTAGRAM</h4>
                            <div className="area-insta">
                                <figure>
                                    <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/blog/instagram/1.jpg" />
                                </figure>
                                <figure>
                                    <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/blog/instagram/2.jpg" />
                                </figure>
                                <figure>
                                    <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/blog/instagram/3.jpg" />
                                </figure>
                                <figure>
                                    <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/blog/instagram/4.jpg" />
                                </figure>
                                <figure>
                                    <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/blog/instagram/5.jpg" />
                                </figure>
                                <figure>
                                    <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/blog/instagram/6.jpg" />
                                </figure>
                                <figure>
                                    <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/blog/instagram/7.jpg" />
                                </figure>
                                <figure>
                                    <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/blog/instagram/8.jpg" />
                                </figure>
                                <figure>
                                    <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/blog/instagram/9.jpg" />
                                </figure>
                            </div>
                            <div className="row">
                                <a className="bt-insta" href="https://www.instagram.com/gruposervices/" target="_blank">
                                    <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/icon-instagram.svg" />
                                    <p>{t('follow')} @gruposervicesbywebhelp</p>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="container-medium area-recommended">
                        <div className="row">
                            <h3 className="black">{t('recommended-posts')}</h3>
                        </div>
                        <div className="row posts-recommended">
                            <div className="area-post">
                                {posts.map((post, index) => index < 4 &&
                                    <a key={index} className="post-default" 
                                    href= {post.linguagem == eng ? `/post/${post.postId}/${post.titulo.replaceAll(' ', '-')}` : `/post/${post.id}/${post.titulo.replaceAll(' ', '-')}`}>
                                        <figure>
                                            <img loading="lazy" src={post.imagem} />
                                            <label className="date-post-thumb">{new Date(post.data_cadastro).toLocaleDateString('pt-br')}</label>
                                        </figure>
                                        <p>{post.titulo}</p>
                                    </a>
                                )}
                            </div>
                        </div>
                    </div>
                </section>
            </content>
            <Footer />
        </Container>
    )
}