import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import env from "react-dotenv";

import Footer from '../components/Footer'
import Header from '../components/Header'
import Subhead from '../components/SubHead'
import Loading from '../components/Loading'

import { registerPage } from '../service/services'
import api from '../service/api'

import { Container } from '../assets/css/blog/styles-blog.js'
import AnimationBlog from './AnimationBlog'

export default function Blog() {
    const [t, i18n] = useTranslation('blog')

    const [allPosts, setAllPosts] = useState([]);
    const [posts, setPosts] = useState([]);
    const [postsDestaque, setPostsDestaque] = useState([]);
    const [categories, setCategories] = useState([]);
    const [postLimite, setPostLimite] = useState(8);
    const [showMorePostsButton, setShowMorePostsButton] = useState(true);
    const [ptbr, setPtbr] = useState(process.env.REACT_APP_PTBR);
    const [eng, setEng] = useState(process.env.REACT_APP_ENG);

    useEffect(() => {
        AnimationBlog();
        registerPage(967);

        api.get('get-posts-blog').then(res => {
            setAllPosts(res.data.data);
            setPosts(res.data.data);
            if(i18n.language == 'en'){
                setPosts(res.data.data.filter((post, index) => post.linguagem == eng));
                setPostsDestaque(res.data.data.filter(post => post.linguagem == eng));
            } else{
                setPosts(res.data.data.filter((post, index) => post.linguagem == ptbr));
                setPostsDestaque(res.data.data.filter(post => post.linguagem == ptbr));
            }

        });
        api.get('get-categories').then(res => {
            i18n.language == 'en'? 
            setCategories(res.data.data.filter((category, index) => category.language_id == eng)) :
            setCategories(res.data.data.filter((category, index) => category.language_id == ptbr));
        });
    }, []);

    useEffect(() => {
        if(posts.length <= postLimite) {
            setShowMorePostsButton(false);
        } else {
            setShowMorePostsButton(true);
        }
        if(showMorePostsButton === posts.length) {
            setShowMorePostsButton(false);
        }
    }, [posts])

    function getPostByCategory(id) {
        // if (id) {
        //     api.get(`get-posts?categoria=${id}`).then(res => {
        //         setPosts(res.data.data);
        //         setPostsDestaque(res.data.data.filter(post => post.destaque === 1));
        //     });
        // } else {
        //     setPosts(allPosts);
        //     allPosts.filter(post => post.destaque === 1);
        // }
    }

    function showMorePosts() {
        if (posts.length >= postLimite && posts.length >= (postLimite + 4)) {
            setPostLimite(postLimite + 4);
            if(posts.length === postLimite + 4) {
                setShowMorePostsButton(false);
            }
        } else {
            setPostLimite(posts.length);
            setShowMorePostsButton(false);
        }
    }

    return (
        <Container>
           {/* <Loading /> */}
            <Header />
            <content>
                <Subhead />
                <section className="area-head-page initial-blog">
                    <div className="container-medium">
                        <h2 data-anime="bottom" className="animate">BLOG</h2>
                        <div className="border-title animate-bar" motion-bar="bar-1" id="border-initial">
                            <figure className="bg-pink"></figure>
                            <figure className="bg-ciano"></figure>
                            <figure className="bg-orange"></figure>
                        </div>
                        <p data-anime="bottom-2" className="animate">{t('body')}</p>
                    </div>
                </section>

                <section className="area-actions-blog">
                    <div className="container-medium">
                        <div className="category-blog">
                            <h3>{t('highlights')}</h3>
                            <div className="bt-category-blog">
                                {categories.map((categoria, index) =>
                                    <>
                                        <button key={index} className="bt-item-category" onClick={() => getPostByCategory(categoria.id)}>
                                            {categoria.name}
                                        </button>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </section>
                <section className="area-spotlight">
                    <div className="container-medium">
                        <div className="col-left">
                            {postsDestaque?.map((post, index) => index === postsDestaque.length-1 &&
                                <a key={index} className="post-default" 
                                href= {post.linguagem == eng ? `/post/${post.postId}/${post.titulo.replaceAll(' ', '-')}` : `/post/${post.id}/${post.titulo.replaceAll(' ', '-')}`}>
                                    <figure style={{height: '100%'}}>
                                        <img loading="lazy" src={post.imagem} />
                                        <h4>{post.titulo}</h4>
                                        <label className="date-post">{new Date(post.data_cadastro).toLocaleDateString('pt-br')}</label>
                                        <label className="type-category">{post.categoria}</label>
                                    </figure>
                                </a>
                            )
                            }
                        </div>
                        <div className="col-right">
                            {postsDestaque?.map((post, index) => index === postsDestaque.length-2 &&
                                <a key={index} className="post-default" 
                                href= {post.linguagem == eng ? `/post/${post.postId}/${post.titulo.replaceAll(' ', '-')}` : `/post/${post.id}/${post.titulo.replaceAll(' ', '-')}`}>
                                    <figure style={{height: '100%'}}>
                                        <img loading="lazy" src={post.imagem} />
                                        <h4>{post.titulo}</h4>
                                        <label className="date-post">{new Date(post.data_cadastro).toLocaleDateString('pt-br')}</label>
                                        <label className="type-category">{post.categoria}</label>
                                    </figure>
                                </a>
                            )
                            }
                        </div>
                    </div>
                </section>
                <section className="all-post">
                    <div className="container-medium">
                        <div className="row">
                            <h3>{t('all-posts')}</h3>
                        </div>
                        <div className="area-post">
                            {posts.map((post, index) => index < postLimite &&
                                <a key={index} className="post-default" 
                                href= {post.linguagem == eng ? `/post/${post.postId}/${post.titulo.replaceAll(' ', '-')}` : `/post/${post.id}/${post.titulo.replaceAll(' ', '-')}`}>
                                    <figure>
                                        <img loading="lazy" src={post.imagem} />
                                        <label className="date-post-thumb">{new Date(post.data_cadastro).toLocaleDateString('pt-br')}</label>
                                    </figure>
                                    <p>{post.titulo}</p>
                                </a>
                            )}
                        </div>
                        {showMorePostsButton ?
                            <div className="row center  b-t">
                                <a className="bt-link" onClick={showMorePosts}>
                                    <p>{t('more-posts')}</p>
                                    <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/icon-arrow-right.svg" />
                                </a>
                            </div>
                            : ''
                        }
                    </div>
                </section>
            </content>
            <Footer />
        </Container>
    )
}