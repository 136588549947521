import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import Header from '../components/Header'
import Footer from '../components/Footer'
import Loading from '../components/Loading'

import AnimationBlog from '../Blog/AnimationBlog'
import { Container } from '../assets/css/thank-you/styles-thank-you.js'

import { registerPage } from '../service/services'

export default function ContactSuccessful() {
    const [t] = useTranslation('contact')

    useEffect(() => {
        AnimationBlog()
        registerPage(977)
    }, [])

    return (
        <Container>
           {/* <Loading /> */}
            <Header />
            <content>
                <section className="area-head-page initial-success bg-blue">
                    <div className="container-medium">
                        <div className="row center animate" data-anime="bottom">
                            <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/thank-you/icon-success.svg" />
                        </div>
                        <div className="row center">
                            <h2 className="ciano animate" data-anime="bottom-2">{t('success.title')}</h2>
                        </div>
                        <div className="row center">
                            <p data-anime="bottom-3" className="animate">{t('success.body')}</p>
                        </div>

                        <div className="row center">
                            <a className="bt-link animate" href="/" data-anime="bottom-4">
                                <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/icon-arrow-left.svg" />
                                <p>{t('success.button')}</p>
                            </a>
                        </div>
                    </div>
                </section>

            </content>
            <Footer />
        </Container>
    )
}