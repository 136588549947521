import axios from 'axios';
import { getToken } from './auth';
import env from "react-dotenv";

let base = process.env.REACT_APP_API_BASE;

export const api = axios.create({
    baseURL: base
});

api.interceptors.request.use(async config => {
    const token = getToken();
    if (token) {
        config.headers.Authorization = token;
        if (config.url === '/send-email') {
            config.headers['Content-Type'] = 'multipart/form-data;';
        }
        config.headers['xsrf-token'] = localStorage.getItem('services_form_token')
    }
    return config;
})

export default api;