import styled from "styled-components";

export const Container = styled.div `

    @media screen and (min-width: 1200px) {
        .area-initial {
            width: 100%;
            height: 250vh;
            float: left;
        }
        #aio-aio-container {
            background-color: transparent !important;
            background-color: transparent !important;
            background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/aio/bg-space.jpg');
            background-attachment: fixed;
            background-size: 100%;
            scroll-behavior: smooth;
            min-height: 100vh;
        }
        @keyframes planet-aio {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
        .area-planet {
            width: 100%;
            float: left;
            position: relative;
        }
        .subhead-zeus {
            z-index: 99 !important;
        }
        figure,
        h1,
        p {
            transition: all 0.5s ease;
        }
        .logo-aio-initial {
            width: 120px;
            transition: all 0.5s ease;
            position: relative;
            z-index: 2;
            margin-top: calc(50vh - 17px);
            position: fixed;
        }
        .parte-01-02 {
            width: 100%;
            height: 90vh;
            float: left;
            display: flex;
            align-items: center;
            justify-content: center;
            position: sticky;
            top: 10vh;
            z-index: 5;
        }
        .title-gran-z {
            color: white;
            font-size: 55px;
            line-height: 60px;
        }
        .scroll-01-02 {
            width: 10px;
            height: 100vh;
            margin-top: -27vh;
            position: relative;
            z-index: 9999;
        }
        .scroll-02-02 {
            width: 10px;
            height: 100vh;
            margin-top: -90vh;
            position: relative;
            z-index: 9999;
        }
        #title-initial {
            transition: all 0.2s ease;
            transition: opacity 0.5s linear !important;
            opacity: 0;
        }
        .area-icon-scroll {
            width: 50px;
            height: 50px;
            transform: scale(0.8);
            position: absolute;
            top: calc(100vh - 72px);
            z-index: 9998;
            display: flex;
            justify-content: center;
            cursor: pointer;
        }
        .scroll-downs {
            position: absolute;
            display: flex;
            justify-content: center;
            width: 100%;
            height: 50px;
        }
        .scroll-light .mousey {
            width: 3px;
            padding: 10px 10px;
            height: 20px;
            border: 2px solid rgb(255, 255, 255);
            border-radius: 25px;
            opacity: 0.75;
            box-sizing: content-box;
        }
        .scroll-light .scroller {
            width: 3px;
            height: 10px;
            border-radius: 25%;
            background-color: rgb(255, 255, 255);
            animation-name: scroll;
            animation-duration: 2.2s;
            animation-timing-function: cubic-bezier(.15, .41, .69, .94);
            animation-iteration-count: infinite;
        }
        @keyframes scroll {
            0% {
                opacity: 0;
            }
            10% {
                transform: translateY(0);
                opacity: 1;
            }
            100% {
                transform: translateY(15px);
                opacity: 0;
            }
        }
        .about-aio {
            width: 100%;
            float: left;
            display: flex;
            justify-content: center;
            color: white;
            padding-top: 400px;
            padding-bottom: 400px;
        }
        .about-aio h1 {
            text-align: right;
        }
        .about-aio p {
            width: 353px;
            margin-top: 50px;
            font-size: 24px;
            line-height: 38px;
        }
        .flex {
            display: flex
        }
        .col-left,
        .col-right {
            width: 50%;
            float: left;
        }
        .col-left {
            padding-right: 50px;
        }
        .col-right {
            padding-left: 50px;
        }
        .area-cronos {
            width: 100%;
            min-height: 600px;
            float: left;
            display: flex;
            justify-content: center;
            color: white;
            padding-bottom: 400px;
        }
        .area-cronos h1 {
            font-size: 86px;
            text-transform: uppercase;
        }
        .area-cronos h3 {
            font-size: 18px;
            text-transform: uppercase;
        }
        .align-boxes-planet {
            width: 100%;
            float: left;
            margin-top: 60px;
        }
        .box-item-planet {
            width: calc(33.33% - 20px);
            float: left;
            border: 2px solid white !important;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding: 25px;
            border-radius: 8px;
        }
        .box-item-planet p {
            font-weight: 700;
            font-size: 14px !important;
            margin-top: 0px !important;
            line-height: 22px !important;
        }
        .area-fenix {
            width: 100%;
            min-height: 600px;
            float: left;
            display: flex;
            justify-content: center;
            color: white;
            padding-bottom: 400px;
        }
        .area-fenix h1 {
            text-align: right;
        }
        .area-fenix p {
            margin-top: 150px;
        }
        .area-androide {
            width: 100%;
            min-height: 400px;
            float: left;
            display: flex;
            justify-content: center;
            color: white;
            padding-bottom: 400px;
        }
        .area-androide p {
            width: 80%;
            text-align: center;
            margin-top: 30px;
        }
        .area-zeus {
            width: 100%;
            float: left;
            display: flex;
            justify-content: center;
            color: white;
            margin-top: 150px;
        }
        .area-zeus h1 {
            width: 100%;
            text-align: right;
        }
        .area-zeus p {
            margin-top: 40px;
            text-align: right;
        }
        .item-box-zeus {
            width: calc(50% - 10px);
            height: 0px;
            float: left;
            border: 2px solid white;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding-bottom: 100%;
            border-radius: 8px;
            margin-bottom: 20px;
            position: relative;
            padding-bottom: calc(50% - 10px);
        }
        .item-box-zeus p {
            width: 100%;
            float: left;
            font-weight: 700;
            font-size: 15px !important;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            margin-top: 0px !important;
            position: absolute;
            top: 0px;
            height: 100%;
            padding: 16px;
        }
        .area-zeus .bt-link {
            margin-top: 60px
        }
        .area-nr {
            width: 100%;
            float: left;
            display: flex;
            justify-content: center;
            color: white;
            margin-top: 200px;
            padding-bottom: 200px;
        }
        .area-nr p {
            width: 80%;
            margin-top: 40px;
            text-align: center;
        }
        .area-nr h1 {
            text-align: center;
        }
        .area-final {
            width: 100%;
            float: left;
            background-color: #fff;
            min-height: 100vh;
        }
        .cokpit {
            width: 100%;
            float: left;
        }
        .scroll-area {
            width: 100px;
            height: 100%;
            float: left;
            background-color: rgb(70, 0, 0);
            position: absolute;
            visibility: hidden;
        }
        .parte-01-01 {
            width: 100%;
            height: 250vh;
            float: left;
            position: sticky;
            top: 0;
            margin-top: -250vh;
            z-index: 4;
        }
        .scroll-01-01 {
            width: 100px;
            height: 500px;
            margin-top: 400px;
            position: absolute;
            background-color: red;
        }
        .cockpit {
            width: 100%;
            height: 250vh;
            float: left;
            background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/aio/cockpit.png');
            background-size: 100%;
            background-repeat: no-repeat;
            background-position: center;
            position: absolute;
            z-index: 21;
            background-attachment: fixed;
            transition: all 0.8s ease;
        }
        .cockpit-after {
            background-size: 548%;
            background-position: center -236vh;
        }
        .all-topo-planet {
            width: 100%;
            height: 250vh;
            float: left;
            position: sticky;
            top: 0px;
            background-size: auto 130vh;
            background-repeat: no-repeat;
            background-position: center;
            z-index: 4;
            display: flex;
            justify-content: center;
        }
        .planet-top {
            width: 130vh;
            height: 130vh;
            border-radius: 100%;
            background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/aio/topo-planet/bg-earth.png');
            background-size: auto 100%;
            background-repeat: no-repeat;
            background-position: center;
            z-index: 3;
            position: sticky;
            top: 54vh;
            animation: planet-aio 400s ease;
            margin-top: 54vh;
            overflow: hidden;
            transition: top 0.8s ease;
        }
        .planet-shaddow {
            width: 100%;
            height: 100%;
            background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/aio/topo-planet/shadow-earth.png');
            background-size: auto 130vh;
            background-repeat: no-repeat;
            background-position: center;
            z-index: 4;
            transition: all 0.8s ease;
        }
        .title-planet-nr17 {
            color: white;
            font-size: 65px;
            font-family: 'sf-black';
            margin-right: -30px
        }
        .title-about-bpo {
            color: white;
            font-size: 50px;
            font-family: 'sf-black';
            line-height: 50px;
            position: relative;
            z-index: 6;
            margin-top: 70px
        }
        .gradiente-aio {
            background: linear-gradient(45deg, #008EC0 0%, #45fff5 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
        .container-09 {
            width: 1050px;
            position: absolute;
            left: calc(50% - 525px);
            z-index: 9;
        }
        .planet-bpo {
            width: 500px;
            height: 296px;
            float: left;
            margin-top: -63px;
            background-size: 260px;
            background-position: 171px;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            background-repeat: no-repeat;
            position: relative;
            z-index: 6;
        }
        .text-box-bpo {
            width: 600px;
            padding: 30px;
            border-radius: 10px;
            color: white;
            line-height: 24px;
            text-align: left;
            margin-top: 40px;
            position: relative;
            z-index: 6;
            text-align: center;
        }
        .area-scroll-09 {
            width: 20px;
            position: absolute;
            left: 0;
            z-index: 9999;
        }
        .area-09 {
            width: 100%;
            min-height: 400vh;
            float: left;
            position: relative;
            z-index: 1;
            background-color: rgb(255, 255, 255);
            display: flex;
            justify-content: center;
            margin-top: 200px;
        }
        .align-09 {
            width: 100%;
            height: 100vh;
            float: left;
            top: 0;
            position: sticky;
            display: flex;
            align-items: center;
            justify-content: center;
            /* background-color: red; */
            z-index: 999;
        }
        .content-09 {
            width: 100%;
            min-width: 100px;
            min-height: 100px;
            height: 100vh;
            float: left;
            display: flex;
            justify-content: center;
            align-items: center;
            background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/aio/bg-space.jpg');
            background-attachment: fixed;
            background-size: 100%;
            overflow: hidden;
            border-radius: 10px;
            transition: all 0.1s ease;
        }
        .area-09 div {
            transition: all 0.2s ease;
        }
        .area-09 {
            transition: all 0.2s ease;
        }
        .scroll-09-01 {
            width: 10px;
            height: 100vh;
            margin-top: 200vh;
            position: absolute;
        }
        .scroll-09-02 {
            width: 10px;
            height: 100vh;
            margin-top: -50vh;
            position: absolute;
            margin-left: 10px;
        }
        .scroll-09-03 {
            width: 10px;
            height: 100vh;
            margin-top: 120vh;
            position: absolute;
            margin-left: 20px;
        }
        .scroll-09-04 {
            width: 10px;
            height: 100vh;
            margin-top: -110vh;
            position: absolute;
            margin-left: 50px;
        }
        .logo-aio-fechamento {
            transition: all 0.1s linear;
            width: 100px;
            height: 100px;
            background-color: rgb(0, 0, 0);
            margin-left: -100px;
            z-index: 9999;
            border-radius: 20px;
            background-position: center;
            background-size: 50px;
            background-repeat: no-repeat;
            background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/logo-aio.svg');
        }
        .title-aio-final,
        .title-aio-final label {
            color: rgb(0, 0, 0);
            font-size: 38px;
            line-height: 40px;
            text-align: center;
            text-transform: uppercase;
            font-weight: 700;
            padding-top: 20px;
        }
        .align-text-aio-final {
            width: 100%;
            float: left;
            position: absolute;
            justify-content: center;
            display: flex;
            margin-top: 160px;
            opacity: 0;
            transition: all 1.0s ease;
            transition-delay: 0.5s;
        }
        .text-aio-final {
            color: rgb(107, 107, 107);
            font-size: 20px;
            font-family: 'sf-black';
            line-height: 24px;
            text-align: center;
            width: 300px;
        }
        .align-title-aio-final {
            width: 100%;
            float: left;
            position: absolute;
            justify-content: center;
            display: flex;
            margin-top: -280px;
        }
        .padrao-animacao {
            opacity: 0;
            transform: translate3d(0, 70px, 0);
        }
        .area-scroll {
            position: absolute;
            width: 50px;
            height: 100vh;
            position: absolute;
            left: 0;
        }
        .scroll-09-01-aio {
            width: 10px;
            height: 100vh;
            margin-top: -50vh;
            margin-left: 20px;
            position: absolute;
            z-index: 9999;
        }
        .bt-commercial {
            float: left;
            height: 50px;
            padding-left: 40px;
            padding-right: 40px;
            background-color: #000000;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 200px;
            margin-top: 150px;
            z-index: 9999;
        }
        .bt-commercial p {
            font-size: 14px !important;
            font-weight: 700;
            color: white;
            text-transform: uppercase;
            float: left;
        }
        .bt-commercial:hover {
            background-color: #3a3a3a;
        }
        .planet-cronos {
            width: 180px;
            height: 180px;
            float: left;
            position: absolute;
            top: -160px;
            left: -100px;
            background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/aio/cronos/planet-cronos.png');
            background-size: 100%
        }
        .planet-fenix {
            width: 180px;
            height: 180px;
            float: left;
            position: absolute;
            top: -101px;
            right: 164px;
            background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/aio/fenix/planet-fenix.png');
            background-size: 100%;
        }
        .planet-androide {
            width: 180px;
            height: 180px;
            float: left;
            position: absolute;
            top: -140px;
            left: calc(50% - 90px);
            background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/aio/androide/planet-androide.png');
            background-size: 100%;
        }
        .planet-zeus {
            width: 160px;
            height: 160px;
            float: left;
            position: absolute;
            top: -177px;
            left: calc(50% - 90px);
            background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/aio/zeus/planet-zeus.png');
            background-size: 100%;
            background-repeat: no-repeat;
        }
        .planet-nr17 {
            width: 200px;
            height: 200px;
            float: left;
            position: absolute;
            top: -164px;
            left: calc(50% - 130px);
            background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/aio/nr17/planet-nr17.png');
            background-size: 100%;
            background-repeat: no-repeat;
        }
        .area-androide p,
        .area-09 p,
        .area-zeus p,
        .area-cronos p,
        .area-fenix p {
            font-size: 20px;
            line-height: 28px;
        }
        .area-zeus .col-right {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
        .area-zeus .container-large {
            width: calc(100% - 327px) !important;
            min-width: auto;
            max-width: 1357px !important;
        }
        .area-zeus .col-right {
            width: 50%;
        }
        .area-cronos .container-large,
        .area-fenix .container-large {
            width: 1100px;
            max-width: 1100px;
            min-width: 1100px;
        }
        .area-cronos .col-left {
            width: 462px;
        }
        .area-cronos .col-right {
            width: 623px;
        }
        .area-fenix .col-left {
            width: 462px !important;
        }
        .area-fenix .col-right {
            width: 623px !important;
        }
        .overflow-boxes-planet {
            width: 100%;
            float: left;
            display: flex;
            justify-content: space-between;
        }
        .area-zeus .overflow-boxes-planet {
            width: 100%;
            float: left;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }
        .area-zeus .align-boxes-planet {
            margin-top: 0px !important
        }
    }

    @media screen and (max-width: 1199px) {
        .area-initial {
            width: 100%;
            height: 250vh;
            float: left;
        }
        #aio-aio-container {
            background-color: transparent !important;
            background-attachment: fixed;
            background-size: 100%;
            scroll-behavior: smooth;
            min-height: 100vh;
            background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/aio/bg-space.jpg');
            background-position: center !important;
        }
        @keyframes planet-aio {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
        .area-planet {
            width: 100%;
            float: left;
            position: relative;
        }
        figure,
        h1,
        p {
            transition: all 0.5s ease;
        }
        .logo-aio-initial {
            width: 90px;
            transition: all 0.5s ease;
            position: relative;
            z-index: 2;
            margin-top: calc(50vh - 17px);
            position: fixed;
        }
        .parte-01-02 {
            width: 100%;
            height: 90vh;
            float: left;
            display: flex;
            align-items: center;
            justify-content: center;
            position: sticky;
            top: 10vh;
            z-index: 5;
        }
        .title-gran-z {
            color: white;
            font-size: 55px;
            line-height: 60px;
        }
        .scroll-01-02 {
            width: 10px;
            height: 100vh;
            margin-top: -27vh;
            position: relative;
            z-index: 9999;
        }
        .scroll-02-02 {
            width: 10px;
            height: 100vh;
            margin-top: -90vh;
            position: relative;
            z-index: 9999;
        }
        #title-initial {
            transition: all 0.2s ease;
            transition: opacity 0.5s linear !important;
            opacity: 0;
        }
        .area-icon-scroll {
            width: 50px;
            height: 50px;
            transform: scale(0.8);
            position: absolute;
            top: calc(100vh - 72px);
            z-index: 9998;
            display: flex;
            justify-content: center;
            cursor: pointer;
        }
        .scroll-downs {
            position: absolute;
            display: flex;
            justify-content: center;
            width: 100%;
            height: 50px;
        }
        .scroll-light .mousey {
            width: 3px;
            padding: 10px 10px;
            height: 20px;
            border: 2px solid rgb(255, 255, 255);
            border-radius: 25px;
            opacity: 0.75;
            box-sizing: content-box;
        }
        .scroll-light .scroller {
            width: 3px;
            height: 10px;
            border-radius: 25%;
            background-color: rgb(255, 255, 255);
            animation-name: scroll;
            animation-duration: 2.2s;
            animation-timing-function: cubic-bezier(.15, .41, .69, .94);
            animation-iteration-count: infinite;
        }
        @keyframes scroll {
            0% {
                opacity: 0;
            }
            10% {
                transform: translateY(0);
                opacity: 1;
            }
            100% {
                transform: translateY(15px);
                opacity: 0;
            }
        }
        .about-aio {
            width: 100%;
            float: left;
            display: flex;
            justify-content: center;
            color: white;
            padding-top: 150px;
            padding-bottom: 300px;
        }
        .about-aio h1 {
            width: 100%;
            text-align: left;
        }
        .about-aio p {
            width: 99%;
            margin-top: 8px;
            font-size: 18px;
            line-height: 30px;
        }
        .flex {
            display: flex
        }
        .col-left,
        .col-right {
            width: 100%;
            float: left;
        }
        .area-cronos {
            width: 100%;
            min-height: 600px;
            float: left;
            display: flex;
            justify-content: center;
            color: white;
            padding-bottom: 200px;
        }
        .area-cronos h1 {
            font-size: 46px;
            text-transform: uppercase;
        }
        .area-cronos h3 {
            font-size: 16px;
            text-transform: uppercase;
            line-height: 26px;
        }
        .align-boxes-planet {
            width: 100vw;
            float: left;
            margin-top: 60px;
            overflow-x: scroll;
            margin-left: -40px;
            padding-left: 40px;
            padding-right: 40px;
        }
        .box-item-planet {
            width: 256px;
            height: 87px;
            float: left;
            border: 2px solid white !important;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            border-radius: 8px;
            margin-top: 20px;
            margin: 6px;
        }
        .box-item-planet p {
            font-weight: 700;
            font-size: 11px !important;
            margin-top: 3px !important;
            line-height: 19px !important;
            text-align: center !important;
        }
        .area-fenix {
            width: 100%;
            min-height: 600px;
            float: left;
            display: flex;
            justify-content: center;
            color: white;
            padding-bottom: 200px;
        }
        .about-aio .container-large,
        .area-androide .container-large {
            width: calc(100% - 80px)
        }
        .area-fenix h1 {
            text-align: left;
        }
        .area-fenix p {
            margin-top: 150px;
        }
        .area-androide {
            width: 100%;
            min-height: 400px;
            float: left;
            display: flex;
            justify-content: center;
            color: white;
            padding-bottom: 200px;
            text-align: left !important;
        }
        .area-androide p {
            width: 100%;
            text-align: center;
            margin-top: 30px;
        }
        .area-androide h1 {
            width: 100%;
            text-align: left
        }
        .area-androide h3 {
            width: 100%;
            text-align: left;
            font-size: 16px;
        }
        .area-zeus {
            width: 100%;
            float: left;
            display: flex;
            justify-content: center;
            color: white;
            margin-top: 150px;
        }
        .area-zeus h1 {
            width: 100%;
            text-align: left;
        }
        .area-zeus p {
            margin-top: 40px;
            text-align: right;
        }
        .item-box-zeus {
            width: 300px;
            float: left;
            border: 2px solid #92fcfe;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            border-radius: 8px;
            margin-bottom: 20px;
            position: relative;
            margin: 6px;
        }
        .item-box-zeus p {
            width: 100%;
            float: left;
            font-weight: 700;
            font-size: 13px !important;
            line-height: 19px !important;
            justify-content: center;
            align-items: center;
            text-align: center !important;
            margin-top: 0px !important;
            top: 0px;
            padding: 22px;
        }
        .area-zeus .bt-link {
            margin-top: 60px
        }
        .area-nr {
            width: 100vw;
            float: left;
            display: flex;
            justify-content: center;
            color: white;
            margin-top: 200px;
            padding-bottom: 200px;
        }
        .area-nr p {
            width: 80%;
            margin-top: 40px;
            text-align: center;
        }
        .area-nr h1 {
            text-align: center;
        }
        .area-final {
            width: 100%;
            float: left;
            background-color: #fff;
            min-height: 100vh;
        }
        .cokpit {
            width: 100%;
            float: left;
        }
        .scroll-area {
            width: 10px;
            height: 100%;
            float: left;
            background-color: rgb(70, 0, 0);
            position: absolute;
            visibility: hidden;
        }
        .parte-01-01 {
            width: 100%;
            height: 250vh;
            float: left;
            position: sticky;
            top: 0;
            margin-top: -250vh;
            z-index: 4;
        }
        .scroll-01-01 {
            width: 10px;
            height: 500px;
            margin-top: 400px;
            position: absolute;
            background-color: red;
        }
        .cockpit {
            width: 100%;
            height: 100vh;
            float: left;
            background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/aio/cockpit-mobile.png');
            background-size: 100%;
            background-repeat: no-repeat;
            position: fixed;
            transition: all 0.8s ease 0s;
            z-index: 7;
            top: 0px;
            background-position: center center !important;
        }
        .cockpit-after {
            background-size: 548%;
            background-position: center -236vh;
        }
        .all-topo-planet {
            width: 100%;
            height: 250vh;
            float: left;
            position: sticky;
            top: 0px;
            background-size: auto 130vh;
            background-repeat: no-repeat;
            background-position: center;
            z-index: 4;
            justify-content: center;
            overflow: hidden;
        }
        .planet-top {
            width: 600px;
            height: 600px;
            background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/aio/topo-planet/bg-earth.png');
            background-size: auto 100%;
            background-repeat: no-repeat;
            background-position: center;
            z-index: 3;
            position: sticky;
            top: 54vh;
            animation: planet-aio 400s ease;
            margin-top: 54vh;
            transition: top 0.8s ease;
            margin-left: calc(50% - 300px);
        }
        .planet-shaddow {
            width: 100%;
            height: 100%;
            background-size: auto 130vh;
            background-repeat: no-repeat;
            background-position: center;
            z-index: 4;
            transition: all 0.8s ease;
        }
        .title-planet-nr17 {
            color: white;
            font-size: 65px;
            font-family: 'sf-black';
            margin-right: -30px
        }
        .title-about-bpo {
            color: white;
            font-size: 50px;
            font-family: 'sf-black';
            line-height: 50px;
            position: relative;
            z-index: 6;
            margin-top: 70px
        }
        .gradiente-aio {
            background: linear-gradient(45deg, #008EC0 0%, #45fff5 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
        .container-09 {
            width: 100vw;
            left: 0px;
            z-index: 9;
        }
        .planet-bpo {
            width: 500px;
            height: 296px;
            float: left;
            margin-top: -63px;
            background-size: 260px;
            background-position: 171px;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            background-repeat: no-repeat;
            position: relative;
            z-index: 6;
        }
        .text-box-bpo {
            width: 600px;
            padding: 30px;
            border-radius: 10px;
            color: white;
            line-height: 24px;
            text-align: left;
            margin-top: 40px;
            position: relative;
            z-index: 6;
            text-align: center;
        }
        .area-scroll-09 {
            width: 20px;
            position: absolute;
            left: 0;
            z-index: 9999;
        }
        .area-09 {
            width: 100wv;
            min-height: 400vh;
            float: left;
            position: relative;
            z-index: 1;
            background-color: rgb(255, 255, 255);
            display: flex;
            justify-content: center;
            margin-top: 200px;
        }
        .align-09 {
            width: 100vw;
            height: 100vh;
            float: left;
            top: 0;
            position: sticky;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 999;
            overflow: hidden;
        }
        .content-09 {
            width: 100%;
            min-width: 100px;
            min-height: 100px;
            height: 100vh;
            float: left;
            display: flex;
            align-items: center;
            background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/aio/bg-space.jpg');
            background-attachment: fixed;
            background-size: 100%;
            overflow: hidden;
            left: 0px;
            border-radius: 10px;
            transition: all 0.1s ease;
        }
        .area-09 div {
            transition: all 0.2s ease;
        }
        .area-09 {
            transition: all 0.2s ease;
        }
        .scroll-09-01 {
            width: 10px;
            height: 100vh;
            margin-top: 200vh;
            position: absolute;
        }
        .scroll-09-02 {
            width: 10px;
            height: 100vh;
            margin-top: -50vh;
            position: absolute;
            margin-left: 10px;
        }
        .scroll-09-03 {
            width: 10px;
            height: 100vh;
            margin-top: 120vh;
            position: absolute;
            margin-left: 20px;
        }
        .scroll-09-04 {
            width: 10px;
            height: 100vh;
            margin-top: -110vh;
            position: absolute;
            margin-left: 50px;
        }
        .logo-aio-fechamento {
            transition: all 0.1s linear;
            width: 100px;
            height: 100px;
            background-color: rgb(0, 0, 0);
            margin-left: -100px;
            z-index: 9999;
            border-radius: 20px;
            background-position: center;
            background-size: 50px;
            background-repeat: no-repeat;
            background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/logo-aio.svg');
        }
        .title-aio-final,
        .title-aio-final label {
            color: rgb(0, 0, 0);
            font-size: 26px;
            line-height: 35px;
            text-align: center;
            text-transform: uppercase;
            font-weight: 700;
            padding-top: 20px;
        }
        .align-text-aio-final {
            width: 100%;
            float: left;
            position: absolute;
            justify-content: center;
            display: flex;
            left: 0px;
            margin-top: 160px;
            opacity: 0;
            transition: all 1.0s ease;
            transition-delay: 0.5s;
        }
        .text-aio-final {
            color: rgb(107, 107, 107);
            font-size: 20px;
            font-family: 'sf-black';
            line-height: 24px;
            text-align: center;
            width: 300px;
        }
        .align-title-aio-final {
            width: 100vw;
            float: left;
            position: absolute;
            /* justify-content: center; */
            left: 0px;
            /* display: flex; */
            margin-top: -280px;
            padding: 40px;
        }
        .padrao-animacao {
            opacity: 0;
            transform: translate3d(0, 70px, 0);
        }
        .area-scroll {
            position: absolute;
            width: 50px;
            height: 100vh;
            background-color: rgba(128, 128, 128, 0.267);
            position: absolute;
            left: 0;
        }
        .scroll-09-01-aio {
            width: 10px;
            height: 100vh;
            margin-top: -50vh;
            margin-left: 20px;
            position: absolute;
            z-index: 9999;
        }
        .bt-commercial {
            float: left;
            height: 50px;
            padding-left: 40px;
            padding-right: 40px;
            background-color: #000000;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 200px;
            margin-top: 150px;
            z-index: 9999;
        }
        .bt-commercial p {
            font-size: 11px !important;
            font-weight: 700;
            color: white;
            text-transform: uppercase;
            float: left;
            margin-top: 0px !important;
        }
        .bt-commercial:hover {
            background-color: #3a3a3a;
        }
        .planet-cronos {
            width: 100px;
            height: 153px;
            float: left;
            position: absolute;
            top: -89px;
            left: -41px;
            background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/aio/cronos/planet-cronos.png');
            background-size: 100%;
            background-repeat: no-repeat;
        }
        .planet-fenix {
            width: 180px;
            height: 180px;
            float: left;
            position: absolute;
            top: -98px;
            left: 19px;
            background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/aio/fenix/planet-fenix.png');
            background-size: 100%;
        }
        .planet-androide {
            width: 180px;
            height: 180px;
            float: left;
            position: absolute;
            top: -140px;
            left: calc(50% - 90px);
            background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/aio/androide/planet-androide.png');
            background-size: 100%;
        }
        .planet-zeus {
            width: 140px;
            height: 140px;
            float: left;
            position: absolute;
            top: -133px;
            left: calc(50% - 60px);
            background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/aio/zeus/planet-zeus.png');
            background-size: 100%;
            background-repeat: no-repeat;
        }
        .planet-nr17 {
            width: 154px;
            height: 180px;
            float: left;
            position: absolute;
            top: -132px;
            left: calc(50% - 130px);
            background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/aio/nr17/planet-nr17.png');
            background-size: 100%;
            background-repeat: no-repeat;
        }
        .area-androide p,
        .area-09 p,
        .area-zeus p,
        .area-cronos p,
        .area-fenix p {
            font-size: 14px;
            line-height: 26px;
            margin-top: 20px;
            text-align: left;
            width: 100%;
        }
        .area-nr .container-large,
        .area-zeus .container-large {
            width: calc(100% - 80px) !important;
            min-width: calc(100% - 80px) !important;
            max-width: calc(100% - 80px) !important;
        }
        .area-zeus .col-right {
            width: 100%;
        }
        .area-cronos .container-large,
        .area-fenix .container-large {
            width: calc(100% - 80px);
            max-width: calc(100% - 80px);
            min-width: calc(100% - 80px);
        }
        .area-cronos .col-left {
            width: 100%;
        }
        .area-cronos .col-right {
            width: 100%;
        }
        .area-fenix .col-left {
            width: 100% !important;
        }
        .area-fenix .col-right {
            width: 100%;
        }
        .overflow-boxes-planet {
            width: auto;
            float: left;
            display: flex;
            padding-right: 20px;
            overflow-y: hidden;
        }
    }
`;