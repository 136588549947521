import $ from 'jquery'

export default function AnimationOpportunities() {
    window.onload = () => {
        setTimeout(() => {
            $('.loading').hide();
        }, 500)
    }
    $(window).ready(function () {
        setTimeout(function () {
            $(window).scrollTop(0);

            setTimeout(function () {
                // $('.loading').hide();
                $('.area-initial-opportunities h1').addClass('animate');
                $('.area-initial-opportunities h3').addClass('animate');
                $('[motion-bar]').addClass('animate-bar');
            }, 100);
        });
    });
    // PS. Esse documento possui scripts da página INDEX executados apenas em dispositivos MOBILE.
    $(document).ready(function () {

        $('.bt-accept').click(function () {
            $('.component-privacy').hide();
            $('body').removeClass('privacy-active');
        });


        $('.bt-close-video').click(function () {
            $(".all-video-modal").hide();
            $('video').trigger('pause');
        });

        $('.subhead-zeus').click(function () {
            window.location.href = "/zeus";
        });

        $('#bt-down-initial').click(function() {
            $('html').animate({
                scrollTop: $('.area-video-initial').offset().top + 100
            }, 200);
        });

    });

    $(document).ready(function () {
        if ($(window).width() > 1200) {


            $('#box-andre').click(function () {
                $("#video-andre").show();
                $('#item-video-andre').trigger('play');
            });

            $('#box-rainielly').click(function () {
                $("#video-rainielly").show();
                $('#item-video-rainielly').trigger('play');
            });

            $('#box-karla').click(function () {
                $("#video-karla").show();
                $('#item-video-karla').trigger('play');
            });

            $('#bt-film-full').click(function () {
                $("#video-trabalhe-conosco").show();
                $('#item-video-trabalhe-conosco').trigger('play');
            });

            $(window).scroll(function () {
                if ($(window).scrollTop() > $('.area-history-05').offset().top - 600) {
                    $('.area-history-05').css({
                        'padding': '40px',
                        'padding-top': '0px !important'
                    });
                } else {
                    $('.area-history-05').css({
                        'padding': '0px',
                    });
                }
            });


            var play04 = true;
            $("#video-04-pc").on("ended", function () {
                $('.box-history-04').css({
                    'opacity': '1',
                    'margin-top': 'calc(-100vh - -100px)'
                });
                $('.area-history-04').css({
                    'padding': '40px',
                });
                $('.area-video-04').css({
                    'height': 'calc(100vh - 117px)',
                    'top': '80px'
                });
            });

            $(window).scroll(function () {
                if ($(window).scrollTop() > $('.area-history-04').offset().top - 100 && play04 == true) {
                    $('#video-04-pc').trigger('play');
                    setTimeout(function () {
                        play04 = false
                    }, 100);
                }
            });

            $(window).scroll(function () {
                if ($(window).scrollTop() > $('.scroll-1-initial').offset().top - 100) {
                    $('.area-bg-initial').css({
                        'opacity': '0.2',
                    });
                }
                if ($(window).scrollTop() > $('.scroll-1-initial').offset().top) {
                    $('.area-video-initial').css({
                        'opacity': '1.0',
                    });
                    $('#bt-down-initial').css({
                        'opacity': '0',
                    });
                    $('#video-initial').trigger('play');
                } else {
                    $('.area-video-initial').css({
                        'opacity': '0',
                    });
                    $('#bt-down-initial').css({
                        'opacity': '1.0',
                    });
                    $('.area-bg-initial').css({
                        'opacity': '1.0',
                    });
                }
            });


            $(window).scroll(function () {
                if ($(window).scrollTop() > $('.scroll-2-initial').offset().top) {
                    $('.area-video-initial').css({
                        'border': '30px solid white',
                    });

                } else {
                    $('.area-video-initial').css({
                        'border': '0px solid white',
                    });

                }
            });



            $(window).scroll(function () {
                if ($(window).scrollTop() > $('.area-history-01').offset().top - 400) {
                    $('.area-history-01').css({
                        'padding': '0px',
                    });

                } else {
                    $('.area-history-01').css({
                        'padding': '30px',
                    });
                }
            });



            $(window).scroll(function () {
                if ($(window).scrollTop() > $('.area-history-02').offset().top - 400) {
                    $(".area-history-02").removeClass("before-02");

                } else {
                    $(".area-history-02").addClass("before-02");
                }
            });


            $(window).scroll(function () {
                var scrollTop = $(window).scrollTop();
                var scroll03 = $('.area-history-03').offset();
                if (scrollTop > scroll03.top - 600 && scrollTop < scroll03.top - 400) {
                    $('.image-03-02').css({
                        'transform': 'translate3d(0px, 0px, 0px) scale(1.0)',
                        'opacity': '0',
                    });

                    $('.image-03-01').css({
                        'transform': 'translate3d(0px, 200px, 0px) scale(1.0)',
                        'opacity': '0',
                    });
                    $(".area-history-03").addClass("before-03");
                }
                if (scrollTop > scroll03.top - 400 && scrollTop < scroll03.top - 200) {
                    $('.image-03-02').css({
                        'transform': 'translate3d(0px, 50px, 0px) scale(1.0)',
                        'opacity': '0',
                    });

                    $('.image-03-01').css({
                        'transform': 'translate3d(0px, 150px, 0px) scale(1.0)',
                        'opacity': '0.5',
                    });
                    $(".area-history-03").addClass("before-03");

                }
                if (scrollTop > scroll03.top - 200 && scrollTop < scroll03.top - 0) {
                    $('.image-03-02').css({
                        'transform': 'translate3d(0px, 100px, 0px) scale(1.0)',
                        'opacity': '0',
                    });

                    $('.image-03-01').css({
                        'transform': 'translate3d(0px, 100px, 0px) scale(1.0)',
                        'opacity': '1.0',
                    });
                    $(".area-history-03").addClass("before-03");

                }
                if (scrollTop > scroll03.top - 0 && scrollTop < scroll03.top + 200) {
                    $('.image-03-02').css({
                        'transform': 'translate3d(0px, 150px, 0px) scale(1.0)',
                        'opacity': '0',
                    });

                    $('.image-03-01').css({
                        'transform': 'translate3d(0px, 50px, 0px) scale(1.0)',
                        'opacity': '1.0',
                    });
                    $("#title-final-03").removeClass("motion-title");

                    $(".area-history-03").addClass("before-03");


                }
                if (scrollTop > scroll03.top + 200 && scrollTop < scroll03.top + 400) {
                    $(".area-history-03").removeClass("before-03");
                    $('.image-03-02').css({
                        'transform': 'translate3d(0px, 200px, 0px) scale(1.0)',
                        'opacity': '1.0',
                    });

                    $('.image-03-01').css({
                        'transform': 'translate3d(0px, 0px, 0px) scale(1.0)',
                        'opacity': '1.0',
                    });
                    $("#title-final-03").removeClass("motion-title");

                    $(".area-history-03").addClass("before-03");
                }
                if (scrollTop > scroll03.top + 600 && scrollTop < scroll03.top + 800) {
                    $('.image-03-02').css({
                        'transform': 'translate3d(0px, 250px, 0px) scale(1.0)',
                        'opacity': '1.0',
                    });
                    $('.image-03-01').css({
                        'transform': 'translate3d(0px, 0px, 0px) scale(1.0)',
                        'opacity': '1.0',
                    });

                    $("#title-final-03").addClass("motion-title");

                    $(".area-history-03").removeClass("before-03");
                }
                if (scrollTop > scroll03.top + 800 && scrollTop < scroll03.top + 1000) {
                    $('.image-03-02').css({
                        'transform': 'translate3d(0px, 250px, 0px) scale(1.0)',
                        'opacity': '1.0',
                    });

                    $('.image-03-01').css({
                        'transform': 'translate3d(0px, 0px, 0px) scale(1.0)',
                        'opacity': '1.0',
                    });
                    $("#title-final-03").addClass("motion-title");

                    $(".area-history-03").removeClass("before-03");
                }
                if (scrollTop > scroll03.top + 1000 && scrollTop < scroll03.top + 1200) {
                    $('.image-03-02').css({
                        'transform': 'translate3d(0px, 250px, 0px) scale(1.0)',
                        'opacity': '1.0',
                    });

                    $('.image-03-01').css({
                        'transform': 'translate3d(0px, 0px, 0px) scale(1.0)',
                        'opacity': '1.0',
                    });
                    $("#title-final-03").addClass("motion-title");

                    $(".area-history-03").removeClass("before-03");
                }

            });

        }
    });

    $(document).ready(function () {
        if ($(window).width() < 1200) {


            $('#box-andre').click(function () {
                $("#video-andre").show();
                $('#item-video-andre').trigger('play');
            });

            $('#box-rainielly').click(function () {
                $("#video-rainielly").show();
                $('#item-video-rainielly').trigger('play');
            });

            $('#box-karla').click(function () {
                $("#video-karla").show();
                $('#item-video-karla').trigger('play');
            });

            $('#bt-film-full').click(function () {
                $("#video-trabalhe-conosco").show();
                $('#item-video-trabalhe-conosco').trigger('play');
            });

            $(window).scroll(function () {
                if ($(window).scrollTop() > $('.area-history-05').offset().top - 600) {
                    $('.area-history-05').css({
                        'padding': '0px',
                        'padding-top': '0px !important'
                    });
                } else {
                    $('.area-history-05').css({
                        'padding': '15px',
                    });
                }
            });


            var play04 = true;
            $("#video-04-mobile").on("ended", function () {
                $('.box-history-04').css({
                    'opacity': '1',
                });

                // $('.area-video-04').css({
                //     'height': 'calc(100vh - 117px)',
                // });
            });

            $(window).scroll(function () {
                if ($(window).scrollTop() > $('.area-history-04').offset().top - 100 && play04 == true) {
                    $('#video-04-mobile').trigger('play');
                    setTimeout(function () {
                        play04 = false
                    }, 100);
                }
            });

            $(window).scroll(function () {
                if ($(window).scrollTop() > $('.scroll-1-initial').offset().top - 100) {
                    $('.area-bg-initial').css({
                        'opacity': '0.2',
                    });
                }
                if ($(window).scrollTop() > $('.scroll-1-initial').offset().top) {
                    $('.area-video-initial').css({
                        'opacity': '1.0',
                    });
                    $('#video-initial').trigger('play');
                } else {
                    $('.area-video-initial').css({
                        'opacity': '0',
                    });

                    $('.area-bg-initial').css({
                        'opacity': '1.0',
                    });
                }
            });


            $(window).scroll(function () {
                if ($(window).scrollTop() > $('.scroll-2-initial').offset().top) {
                    $('.area-video-initial').css({
                        'border': '10px solid white',
                    });
                    $('.bt-down').css({
                        'opacity': '0',
                    });
                } else {
                    $('.area-video-initial').css({
                        'border': '0px solid white',
                    });
                    $('.bt-down').css({
                        'opacity': '1.0',
                    });
                }
            });



            $(window).scroll(function () {
                if ($(window).scrollTop() > $('.area-history-01').offset().top - 400) {
                    $('.area-history-01').css({
                        'padding': '10px',
                    });

                } else {
                    $('.area-history-01').css({
                        'padding': '0px',
                    });
                }
            });



            $(window).scroll(function () {
                if ($(window).scrollTop() > $('.area-history-02').offset().top - 400) {
                    $(".area-history-02").removeClass("before-02");

                } else {
                    $(".area-history-02").addClass("before-02");
                }
            });


            $(window).scroll(function () {
                var scrollTop = $(window).scrollTop();
                var scroll03 = $('.area-history-03').offset();
                if (scrollTop > scroll03.top - 400 && scrollTop < scroll03.top - 200) {
                    $('.image-03-01').css({
                        'background-position': '10px',
                    });
                    $('.image-03-02').css({
                        'background-position': '-170px',
                    });

                    $(".area-history-03").addClass("before-03");
                }
                if (scrollTop > scroll03.top - 200 && scrollTop < scroll03.top - 100) {
                    $('.image-03-02').css({
                        'background-position': '-160px',
                    });
                    $('.image-03-01').css({
                        'background-position': '0px',
                    });

                    $(".area-history-03").addClass("before-03");
                }
                if (scrollTop > scroll03.top - 100 && scrollTop < scroll03.top - 0) {
                    $('.image-03-02').css({
                        'background-position': '-150px',
                    });
                    $('.image-03-01').css({
                        'background-position': '-10px',
                    });

                    $(".area-history-03").addClass("before-03");
                }
                if (scrollTop > scroll03.top - 0 && scrollTop < scroll03.top + 100) {
                    $('.image-03-02').css({
                        'background-position': '-140px',
                    });
                    $('.image-03-01').css({
                        'background-position': '-20px',
                    });

                    $("#title-final-03").removeClass("motion-title");
                    $(".area-history-03").addClass("before-03");
                }
                if (scrollTop > scroll03.top + 100 && scrollTop < scroll03.top + 200) {
                    $('.image-03-02').css({
                        'background-position': '-130px',
                    });
                    $('.image-03-01').css({
                        'background-position': '-30px',
                    });
                    $(".area-history-03").removeClass("before-03");


                    $("#title-final-03").removeClass("motion-title");
                    $(".area-history-03").addClass("before-03");
                }
                if (scrollTop > scroll03.top + 300 && scrollTop < scroll03.top + 400) {
                    $('.image-03-02').css({
                        'background-position': '-120px',
                    });
                    $('.image-03-01').css({
                        'background-position': '-40px',
                    });

                    $("#title-final-03").addClass("motion-title");
                    $(".area-history-03").removeClass("before-03");
                }
                if (scrollTop > scroll03.top + 400 && scrollTop < scroll03.top + 500) {
                    $('.image-03-02').css({
                        'background-position': '-110px',
                    });
                    $('.image-03-01').css({
                        'background-position': '-50px',
                    });

                    $("#title-final-03").addClass("motion-title");
                    $(".area-history-03").removeClass("before-03");
                }
                if (scrollTop > scroll03.top + 500 && scrollTop < scroll03.top + 600) {
                    $('.image-03-02').css({
                        'background-position': '-100px',
                    });
                    $('.image-03-01').css({
                        'background-position': '-60px',
                    });

                    $("#title-final-03").addClass("motion-title");
                    $(".area-history-03").removeClass("before-03");
                }
                if (scrollTop > scroll03.top + 600 && scrollTop < scroll03.top + 700) {
                    $('.image-03-02').css({
                        'background-position': '-90px',
                    });
                    $('.image-03-01').css({
                        'background-position': '-70px',
                    });


                    $("#title-final-03").addClass("motion-title");
                    $(".area-history-03").removeClass("before-03");
                }
                if (scrollTop > scroll03.top + 700 && scrollTop < scroll03.top + 800) {
                    $('.image-03-02').css({
                        'background-position': '-80px',
                    });
                    $('.image-03-01').css({
                        'background-position': '-80px',
                    });


                    $("#title-final-03").addClass("motion-title");
                    $(".area-history-03").removeClass("before-03");
                }
                if (scrollTop > scroll03.top + 800 && scrollTop < scroll03.top + 900) {
                    $('.image-03-02').css({
                        'background-position': '-70px',
                    });
                    $('.image-03-01').css({
                        'background-position': '-90px',
                    });

                    $("#title-final-03").addClass("motion-title");
                    $(".area-history-03").removeClass("before-03");
                }
                if (scrollTop > scroll03.top + 900 && scrollTop < scroll03.top + 1000) {
                    $('.image-03-02').css({
                        'background-position': '-60px',
                    });
                    $('.image-03-01').css({
                        'background-position': '-100px',
                    });

                    $("#title-final-03").addClass("motion-title");
                    $(".area-history-03").removeClass("before-03");
                }
                if (scrollTop > scroll03.top + 1000 && scrollTop < scroll03.top + 1100) {
                    $('.image-03-02').css({
                        'background-position': '-50px',
                    });
                    $('.image-03-01').css({
                        'background-position': '-110px',
                    });

                    $("#title-final-03").addClass("motion-title");
                    $(".area-history-03").removeClass("before-03");
                }
                if (scrollTop > scroll03.top + 1100 && scrollTop < scroll03.top + 1200) {
                    $('.image-03-02').css({
                        'background-position': '-40px',
                    });
                    $('.image-03-01').css({
                        'background-position': '-120px',
                    });

                    $("#title-final-03").addClass("motion-title");
                    $(".area-history-03").removeClass("before-03");
                }
                if (scrollTop > scroll03.top + 1200 && scrollTop < scroll03.top + 1300) {
                    $('.image-03-02').css({
                        'background-position': '-30px',
                    });
                    $('.image-03-01').css({
                        'background-position': '-130px',
                    });

                    $("#title-final-03").addClass("motion-title");
                    $(".area-history-03").removeClass("before-03");
                }
                if (scrollTop > scroll03.top + 1300 && scrollTop < scroll03.top + 1400) {
                    $('.image-03-02').css({
                        'background-position': '-20px',
                    });
                    $('.image-03-01').css({
                        'background-position': '-140px',
                    });

                    $("#title-final-03").addClass("motion-title");
                    $(".area-history-03").removeClass("before-03");
                }
                if (scrollTop > scroll03.top + 1400 && scrollTop < scroll03.top + 1500) {
                    $('.image-03-02').css({
                        'background-position': '-10px',
                    });
                    $('.image-03-01').css({
                        'background-position': '-150px',
                    });

                    $("#title-final-03").addClass("motion-title");
                    $(".area-history-03").removeClass("before-03");
                }
            });

        }
    });

    const debounce = function(func, wait, immediate) {
        let timeout;
        return function(...args) {
            const context = this;
            const later = function() {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            const callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    };
    
    const target = document.querySelectorAll('[data-anime]');
    const animationClass = 'animate';
    
    function animeScroll() {
        const windowTop = window.pageYOffset + ((window.innerHeight * 4) / 4);
        target.forEach(function(element) {
            if ((windowTop) > element.offsetTop) {
                element.classList.add(animationClass);
            } else {
                element.classList.remove(animationClass);
            }
        })
    }
    
    animeScroll();
    
    if (target.length) {
        window.addEventListener('scroll', debounce(function() {
            animeScroll();
        }, 0));
    }
}