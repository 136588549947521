import { useEffect } from "react"
import { useTranslation } from "react-i18next"

import Header from "../components/Header"
import Subhead from "../components/SubHead"
import Footer from "../components/Footer"
import Loading from "../components/Loading"

import { registerPage } from "../service/services"

import { Container } from '../assets/css/cultura/styles-cultura.js'
import AnimationCulture from "./AnimationCulture"

export default function Culture() {
    const [t] = useTranslation('culture')

    useEffect(() => {
        AnimationCulture();
        registerPage(965);
    }, [])

    return (
        <Container>
           {/* <Loading /> */}
            <Header />
            <Subhead />
            <div className="walker" id="walker">

                <div className="slider">
                    <div>
                        <section className="area-initial-culture">
                            <p id="title-initial" className="" data-anime="bottom">{t('hero')}</p>
                            <button className="bt-next-initial" id="bt-next-initial"><p>{t('next')}</p><img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/arrow-right.svg" /></button>
                            <div className="row center hide-pc">
                                <button className="bt-down"></button>
                            </div>
                        </section>
                    </div>
                    <div>
                        <section className="area-02">
                            <div className="align-area-02">
                                <div className="box-area-item box-02-01" data-op="op">
                                    <p>{t('journey')}</p>
                                </div>
                                <div className="box-area-item box-02-02" data-op="op">
                                    <p>{t('experiences')}</p>
                                </div>
                                <div className="box-area-item box-02-03" data-op="op">
                                    <p>{t('always-ahead')}</p>
                                </div>
                                <div className="box-area-item box-02-04" data-op="op">
                                    <p>{t('always-together')}</p>
                                </div>
                            </div>
                        </section>
                    </div>
                    <div>
                        <section className="area-03">
                            <div className="row-page center-page">
                                <p data-op="op">{t('body')}</p>
                            </div>
                            <div className="row-page center">
                                <figure className="decorate-03-01"></figure>
                            </div>
                            <div className="row-page center">
                                <figure className="decorate-03-02"></figure>
                            </div>
                        </section>
                    </div>
                    <div className="area">
                        <section className="area-04">
                            <div className="row area-text-04">
                                <h1 className="green" data-op="op">{t('title1')}</h1>
                                <p data-op="op">{t('body1.1')} <br />{t('body1.2')}</p>
                            </div>
                            <div className="row">
                                <figure>

                                </figure>
                            </div>
                        </section>
                    </div>
                    <div>
                        <section className="area-05">
                            <div className="row-page center-page column-page">
                                <div className="row">
                                    <h1 className="orange" data-op="op">{t('title2')}</h1>
                                </div>
                                <div className="row">
                                    <p data-op="op">{t('body2')}</p>
                                </div>
                            </div>
                            <div className="row-page">
                                <figure className="decorate-05-01"></figure>
                            </div>
                            <div className="row-page">
                                <figure className="decorate-05-02"></figure>
                            </div>
                            <div className="row-page">
                                <figure className="decorate-05-03"></figure>
                            </div>

                        </section>
                    </div>
                    <div>
                        <section className="area-06">
                            <div className="row-page center-page column-page">
                                <div className="row">
                                    <h1 className="blue" data-op="op">{t('title3')}</h1>
                                </div>
                                <div className="row">
                                    <p data-op="op">{t('body3')}</p>
                                </div>
                            </div>
                            <div className="row-page">
                                <figure className="bg-olimpus"></figure>
                            </div>
                        </section>
                    </div>
                    <div>
                        <section className="area-07">
                            <div className="area-bt-slider">
                                <button className="bt-slider bt-slider-active" id="bt-slider-01">
                                    <div><img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/estrutura/icon-game.svg" /></div>
                                </button>
                                <button className="bt-slider" id="bt-slider-02">
                                    <div><img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/estrutura/icon-tv.svg" /></div>
                                </button>
                                <button className="bt-slider" id="bt-slider-03">
                                    <div><img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/estrutura/icon-beach.svg" /></div>
                                </button>
                                <button className="bt-slider" id="bt-slider-04">
                                    <div><img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/estrutura/icon-rocket.svg" /></div>
                                </button>
                            </div>
                            <div className="area-slider">
                                <div className="area-title-07">
                                    <div className="row">
                                        <h1 className="ciano" data-op="op">{t('title4')}</h1>
                                    </div>
                                    <div className="row">
                                        <p data-op="op">{t('body4')}</p>
                                    </div>
                                </div>
                                <div className="item-slider" id="img-slider-01">
                                    <figure id="img-slider">
                                        <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/estrutura/slider/img-01.jpg" />
                                    </figure>
                                    <div className="area-controller">
                                        <button className="bt-back" id="bt-left-01"></button>
                                        <button className="bt-next" id="bt-right-01"></button>
                                    </div>
                                </div>
                                <div className="item-slider" id="img-slider-02">
                                    <figure id="img-slider">
                                        <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/estrutura/slider/img-02.jpg" />
                                    </figure>
                                    <div className="area-controller">
                                        <button className="bt-back" id="bt-left-02"></button>
                                        <button className="bt-next" id="bt-right-02"></button>
                                    </div>
                                </div>
                                <div className="item-slider" id="img-slider-03">
                                    <figure id="img-slider">
                                        <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/estrutura/slider/img-03.jpg" />
                                    </figure>
                                    <div className="area-controller">
                                        <button className="bt-back" id="bt-left-03"></button>
                                        <button className="bt-next" id="bt-right-03"></button>
                                    </div>
                                </div>
                                <div className="item-slider" id="img-slider-04">
                                    <figure id="img-slider">
                                        <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/estrutura/slider/img-04.jpg" />
                                    </figure>
                                    <div className="area-controller">
                                        <button className="bt-back" id="bt-left-04"></button>
                                        <button className="bt-next" id="bt-right-04"></button>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <div>
                        <section className="area-08">
                            <div className="area-video-08">
                                <video muted playsInline id="item-video-08">
                                    <source type="video/mp4 " src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/videos/cultura/bg-pc.mp4 " />
                                </video>
                                <video muted playsInline id="item-video-08-mobile">
                                    <source type="video/mp4 " src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/videos/cultura/bg-mobile.mp4 " />
                                </video>
                            </div>
                            <div className="row-page center-page column-page content-08 ">
                                <div className="row ">
                                    <h1 className="ciano " data-op="op ">{t('title5')}</h1>
                                </div>
                                <div className="row ">
                                    <p data-op="op ">{t('body5')}</p>
                                </div>
                            </div>

                        </section>
                    </div>
                    <div>
                        <section className="area-09 ">
                            <p className="text-01-09 " data-op="op ">{t('title6.1')}</p>
                            <p className="text-02-09 " data-op="op "><label className="hide-pc "><br /></label>
                                <span className="orange ">{t('title6.2')}</span>
                                <label className="hide-pc "><br /></label>
                                <span>{t('title6.3')}</span>
                                <br />
                                <span className="ciano ">{t('title6.4')}</span>
                            </p>
                        </section>
                    </div>
                    <div>
                        <section className="area-10 ">
                            <div className="row ">
                                <h1 data-op="op ">{t('title7')}</h1>
                            </div>
                            <div className="row ">
                                <a className="bt-tertiary zeus-bt " data-op="op " href="/produtos">
                                    <p>{t('button')}</p>

                                </a>
                                {/* <Link className="bt-tertiary zeus-bt " data-op="op " to="/produtos">
                                    <p>{t('button')}</p>

                                </Link> */}
                            </div>
                        </section>
                    </div>
                    <div className="hide-pc ">
                        <Footer />
                    </div>
                </div>
            </div>
        </Container>
    )
}