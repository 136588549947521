import $ from 'jquery'

export default function AnimationCulture() {
    window.onload = () => {
        setTimeout(() => {
            $('.loading').hide();
        }, 500)
    }
    $(window).ready(function () {
        setTimeout(function () {
            $(window).scrollTop(0);
            setTimeout(function () {
                $('#title-initial').addClass("animate ");
            }, 300);
            setTimeout(function () {
                $('#title-initial').addClass("animate ");
                $('#bt-next-initial').css({
                    'opacity': '1.0',
                });
            }, 500);
        });
        $('#bt-next-initial').click(function() {
            $('html').animate({
                scrollTop: 1350
            }, 200);
        });
        $('.bt-down').click(function() {
            $('html').animate({
                scrollTop: 750
            }, 200);
        });
    });
    $(document).ready(function () {
        if ($(window).width() > 1200) {
            let target = $(".slider").first(),
                currentPosition = parseInt(target.css("transform").split(",")[4]),
                moveBy = function (scrolledBy) {
                    currentPosition += scrolledBy;
                    target.css("transform", "translateX(" + (currentPosition) + "px)")
                },
                lastScrollTop = 0;

            currentPosition = isNaN(currentPosition) ? 0 : currentPosition;
            $(window).bind("scroll", function (e) {
                var scrolledBy = $(window).scrollTop() - lastScrollTop;
                moveBy(-scrolledBy);
                lastScrollTop = $(window).scrollTop();
            });
        } else {
            $('#bt-next-initial').css({
                'display': 'none',
            });
        }
    });
    $(document).ready(function () {
        if ($(window).width() > 1200) {
            // EXIBIR CHAT
            $(window).scroll(function () {
                var height = $(window).height();
                if ($(window).scrollTop() > height * 0.20) {
                    $('.all-chat').css({
                        'z-index': '9998',
                    });
                    $('.chat-fixed').removeClass('anime-end');
                    $('.chat-fixed').addClass('anime-start');

                } else {
                    $('.all-chat').css({
                        'z-index': '2',
                    });
                    $('.chat-fixed').addClass('anime-end');
                    $('.chat-fixed').removeClass('anime-start');
                }
            });
            $('#bt-slider-01').click(function () {
                $("#img-slider-01").show();
                $("#img-slider-02").hide();
                $("#img-slider-03").hide();
                $("#img-slider-04").hide();
                $('#bt-slider-01').addClass("bt-slider-active");
                $('#bt-slider-02').removeClass("bt-slider-active");
                $('#bt-slider-03').removeClass("bt-slider-active");
                $('#bt-slider-04').removeClass("bt-slider-active");
            });

            $('#bt-slider-02').click(function () {
                $("#img-slider-01").hide();
                $("#img-slider-02").show();
                $("#img-slider-03").hide();
                $("#img-slider-04").hide();
                $('#bt-slider-01').removeClass("bt-slider-active");
                $('#bt-slider-02').addClass("bt-slider-active");
                $('#bt-slider-03').removeClass("bt-slider-active");
                $('#bt-slider-04').removeClass("bt-slider-active");

            });

            $('#bt-slider-03').click(function () {
                $("#img-slider-01").hide();
                $("#img-slider-02").hide();
                $("#img-slider-03").show();
                $("#img-slider-04").hide();
                $('#bt-slider-01').removeClass("bt-slider-active");
                $('#bt-slider-02').removeClass("bt-slider-active");
                $('#bt-slider-03').addClass("bt-slider-active");
                $('#bt-slider-04').removeClass("bt-slider-active");

            });

            $('#bt-slider-04').click(function () {
                $('#img-slider-01').hide();
                $('#img-slider-02').hide();
                $('#img-slider-03').hide();
                $('#img-slider-04').show();
                $('#bt-slider-01').removeClass("bt-slider-active");
                $('#bt-slider-02').removeClass("bt-slider-active");
                $('#bt-slider-03').removeClass("bt-slider-active");
                $('#bt-slider-04').addClass("bt-slider-active");
            });

            $('#bt-left-01').click(function () {
                $('#img-slider-01').hide();
                $('#img-slider-02').hide();
                $('#img-slider-03').hide();
                $('#img-slider-04').show();
                $('#bt-slider-01').removeClass("bt-slider-active");
                $('#bt-slider-02').removeClass("bt-slider-active");
                $('#bt-slider-03').removeClass("bt-slider-active");
                $('#bt-slider-04').addClass("bt-slider-active");
            });
            $('#bt-right-01').click(function () {
                $('#img-slider-01').hide();
                $('#img-slider-02').show();
                $('#img-slider-03').hide();
                $('#img-slider-04').hide();
                $('#bt-slider-01').removeClass("bt-slider-active");
                $('#bt-slider-02').addClass("bt-slider-active");
                $('#bt-slider-03').removeClass("bt-slider-active");
                $('#bt-slider-04').removeClass("bt-slider-active");
            });
            $('#bt-left-02').click(function () {
                $('#img-slider-01').show();
                $('#img-slider-02').hide();
                $('#img-slider-03').hide();
                $('#img-slider-04').hide();
                $('#bt-slider-01').addClass("bt-slider-active");
                $('#bt-slider-02').removeClass("bt-slider-active");
                $('#bt-slider-03').removeClass("bt-slider-active");
                $('#bt-slider-04').removeClass("bt-slider-active");
            });
            $('#bt-right-02').click(function () {
                $('#img-slider-01').hide();
                $('#img-slider-02').hide();
                $('#img-slider-03').show();
                $('#img-slider-04').hide();
                $('#bt-slider-01').removeClass("bt-slider-active");
                $('#bt-slider-02').removeClass("bt-slider-active");
                $('#bt-slider-03').addClass("bt-slider-active");
                $('#bt-slider-04').removeClass("bt-slider-active");
            });


            $('#bt-left-03').click(function () {
                $('#img-slider-01').hide();
                $('#img-slider-02').show();
                $('#img-slider-03').hide();
                $('#img-slider-04').hide();
                $('#bt-slider-01').removeClass("bt-slider-active");
                $('#bt-slider-02').addClass("bt-slider-active");
                $('#bt-slider-03').removeClass("bt-slider-active");
                $('#bt-slider-04').removeClass("bt-slider-active");
            });
            $('#bt-right-03').click(function () {
                $('#img-slider-01').hide();
                $('#img-slider-02').hide();
                $('#img-slider-03').hide();
                $('#img-slider-04').show();
                $('#bt-slider-01').removeClass("bt-slider-active");
                $('#bt-slider-02').removeClass("bt-slider-active");
                $('#bt-slider-03').removeClass("bt-slider-active");
                $('#bt-slider-04').addClass("bt-slider-active");
            });

            $('#bt-left-04').click(function () {
                $('#img-slider-01').hide();
                $('#img-slider-02').hide();
                $('#img-slider-03').show();
                $('#img-slider-04').hide();
                $('#bt-slider-01').removeClass("bt-slider-active");
                $('#bt-slider-02').removeClass("bt-slider-active");
                $('#bt-slider-03').addClass("bt-slider-active");
                $('#bt-slider-04').removeClass("bt-slider-active");
            });
            $('#bt-right-04').click(function () {
                $('#img-slider-01').show();
                $('#img-slider-02').hide();
                $('#img-slider-03').hide();
                $('#img-slider-04').hide();
                $('#bt-slider-01').addClass("bt-slider-active");
                $('#bt-slider-02').removeClass("bt-slider-active");
                $('#bt-slider-03').removeClass("bt-slider-active");
                $('#bt-slider-04').removeClass("bt-slider-active");
            });
            $(window).scroll(function () {

                if ($(window).scrollTop() > 300) {
                    setTimeout(function () {
                        $('.box-02-01').addClass("motion-op");
                    }, 300);
                    setTimeout(function () {
                        $('.box-02-02').addClass("motion-op");
                    }, 500);
                    setTimeout(function () {
                        $('.box-02-03').addClass("motion-op");
                    }, 600);
                    setTimeout(function () {
                        $('.box-02-04').addClass("motion-op");
                    }, 700);
                } else {
                    $('.box-02-01').removeClass("motion-op");
                    $('.box-02-02').removeClass("motion-op");
                    $('.box-02-03').removeClass("motion-op");
                    $('.box-02-04').removeClass("motion-op");
                }
            });


            $(window).scroll(function () {
                if ($(window).scrollTop() > 1300) {

                    $('.area-03 p').addClass("motion-op");

                } else {
                    $('.area-03 p').removeClass("motion-op");
                }
            });

            $(window).scroll(function () {
                if ($(window).scrollTop() > 2900) {
                    $('.area-04 h1').addClass("motion-op");
                    setTimeout(function () {
                        $('.area-04 p').addClass("motion-op");
                    }, 300);
                } else {
                    $('.area-04 h1').removeClass("motion-op");
                    $('.area-04 p').removeClass("motion-op");
                }
            });

            $(window).scroll(function () {
                if ($(window).scrollTop() > 3800) {
                    $('.area-05 h1').addClass("motion-op");
                    setTimeout(function () {
                        $('.area-05 p').addClass("motion-op");
                    }, 300);
                } else {
                    $('.area-05 h1').removeClass("motion-op");
                    $('.area-05 p').removeClass("motion-op");
                }
            });

            $(window).scroll(function () {
                if ($(window).scrollTop() > 5400) {
                    $('.area-06 h1').addClass("motion-op");
                    setTimeout(function () {
                        $('.area-06 p').addClass("motion-op");
                    }, 300);
                } else {
                    $('.area-06 h1').removeClass("motion-op");
                    $('.area-06 p').removeClass("motion-op");
                }
            });

            $(window).scroll(function () {
                if ($(window).scrollTop() > 7500) {
                    $('.area-07 h1').addClass("motion-op");
                    setTimeout(function () {
                        $('.area-07 p').addClass("motion-op");
                    }, 300);
                } else {
                    $('.area-07 h1').removeClass("motion-op");
                    $('.area-07 p').removeClass("motion-op");
                }
            });

            $(window).scroll(function () {
                if ($(window).scrollTop() > 8500) {

                    $('#item-video-08').trigger('play');
                    setTimeout(function () {
                        $('.area-08 h1').addClass("motion-op");
                        $('.area-08 p').addClass("motion-op");
                    }, 1000);
                } else {
                    $('.area-08 h1').removeClass("motion-op");
                    $('.area-08 p').removeClass("motion-op");
                }
            });

            $(window).scroll(function () {
                if ($(window).scrollTop() > 10720) {
                    $('.text-01-09').addClass("motion-op");
                    setTimeout(function () {
                        $('.text-02-09').addClass("motion-op");
                    }, 300);
                } else {
                    $('.text-01-09').removeClass("motion-op");
                    $('.text-02-09').removeClass("motion-op");
                }
            });

            $(window).scroll(function () {
                if ($(window).scrollTop() > 12300) {
                    $('.area-10 h1').addClass("motion-op");
                    setTimeout(function () {
                        $('.area-10 a').addClass("motion-op");
                    }, 300);
                } else {
                    $('.area-10 h1').removeClass("motion-op");
                    $('.area-10 a').removeClass("motion-op");
                }
            });
            var count = 0;
            var lastScrollTop = 0,
                delta = 5;
            $(window).scroll(function () {
                var nowScrollTop = $(this).scrollTop();
                if (Math.abs(lastScrollTop - nowScrollTop) >= delta) {
                    if (nowScrollTop > lastScrollTop) {
                        count += 6;

                        $('.decorate-03-01').css({
                            'transform': 'translate3d(0px,' + count / 20 + 'px,  0px)',
                        });
                        $('.decorate-03-02').css({
                            'transform': 'translate3d(0px,' + -count / 20 + 'px,  0px)',
                        });
                        $('.decorate-05-01').css({
                            'transform': 'translate3d(0px,' + count / 30 + 'px,  0px)',
                        });
                        $('.decorate-05-02').css({
                            'transform': 'translate3d(0px,' + -count / 15 + 'px,  0px)',
                        });
                        $('.decorate-05-03').css({
                            'transform': 'translate3d(0px,' + count / 20 + 'px,  0px)',
                        });
                    } else {
                        count -= 6;

                        $('.decorate-03-01').css({
                            'transform': 'translate3d(0px,' + count / 20 + 'px,  0px)',
                        });
                        $('.decorate-03-02').css({
                            'transform': 'translate3d(0px,' + -count / 20 + 'px,  0px)',
                        });

                        $('.decorate-05-01').css({
                            'transform': 'translate3d(0px,' + count / 30 + 'px,  0px)',
                        });
                        $('.decorate-05-02').css({
                            'transform': 'translate3d(0px,' + -count / 15 + 'px,  0px)',
                        });
                        $('.decorate-05-03').css({
                            'transform': 'translate3d(0px,' + count / 20 + 'px,  0px)',
                        });
                    }
                    lastScrollTop = nowScrollTop;
                }

            });
        }
    });
    $(document).ready(function () {
        if ($(window).width() < 1200) {
            var play04 = true;
            $(window).scroll(function () {
                if ($(window).scrollTop() > ($('.area-08').offset().top - 500) && play04 == true) {
                    $('#item-video-08-mobile').trigger('play');
                    setTimeout(function () {
                        play04 = false
                    }, 100);
                }
            });
            $('#item-video-08-mobile').on("ended", function () {
                $('.area-08 h1').css({
                    'opacity': '1.0',
                });
                $('.area-08 p').css({
                    'opacity': '1.0',
                });
            });



            $('#bt-slider-01').click(function () {
                $("#img-slider-01").show();
                $("#img-slider-02").hide();
                $("#img-slider-03").hide();
                $("#img-slider-04").hide();
                $('#bt-slider-01').addClass("bt-slider-active");
                $('#bt-slider-02').removeClass("bt-slider-active");
                $('#bt-slider-03').removeClass("bt-slider-active");
                $('#bt-slider-04').removeClass("bt-slider-active");
            });

            $('#bt-slider-02').click(function () {
                $("#img-slider-01").hide();
                $("#img-slider-02").show();
                $("#img-slider-03").hide();
                $("#img-slider-04").hide();
                $('#bt-slider-01').removeClass("bt-slider-active");
                $('#bt-slider-02').addClass("bt-slider-active");
                $('#bt-slider-03').removeClass("bt-slider-active");
                $('#bt-slider-04').removeClass("bt-slider-active");

            });

            $('#bt-slider-03').click(function () {
                $("#img-slider-01").hide();
                $("#img-slider-02").hide();
                $("#img-slider-03").show();
                $("#img-slider-04").hide();
                $('#bt-slider-01').removeClass("bt-slider-active");
                $('#bt-slider-02').removeClass("bt-slider-active");
                $('#bt-slider-03').addClass("bt-slider-active");
                $('#bt-slider-04').removeClass("bt-slider-active");

            });

            $('#bt-slider-04').click(function () {
                $('#img-slider-01').hide();
                $('#img-slider-02').hide();
                $('#img-slider-03').hide();
                $('#img-slider-04').show();
                $('#bt-slider-01').removeClass("bt-slider-active");
                $('#bt-slider-02').removeClass("bt-slider-active");
                $('#bt-slider-03').removeClass("bt-slider-active");
                $('#bt-slider-04').addClass("bt-slider-active");
            });

            $('#bt-left-01').click(function () {
                $('#img-slider-01').hide();
                $('#img-slider-02').hide();
                $('#img-slider-03').hide();
                $('#img-slider-04').show();
                $('#bt-slider-01').removeClass("bt-slider-active");
                $('#bt-slider-02').removeClass("bt-slider-active");
                $('#bt-slider-03').removeClass("bt-slider-active");
                $('#bt-slider-04').addClass("bt-slider-active");
            });
            $('#bt-right-01').click(function () {
                $('#img-slider-01').hide();
                $('#img-slider-02').show();
                $('#img-slider-03').hide();
                $('#img-slider-04').hide();
                $('#bt-slider-01').removeClass("bt-slider-active");
                $('#bt-slider-02').addClass("bt-slider-active");
                $('#bt-slider-03').removeClass("bt-slider-active");
                $('#bt-slider-04').removeClass("bt-slider-active");
            });
            $('#bt-left-02').click(function () {
                $('#img-slider-01').show();
                $('#img-slider-02').hide();
                $('#img-slider-03').hide();
                $('#img-slider-04').hide();
                $('#bt-slider-01').addClass("bt-slider-active");
                $('#bt-slider-02').removeClass("bt-slider-active");
                $('#bt-slider-03').removeClass("bt-slider-active");
                $('#bt-slider-04').removeClass("bt-slider-active");
            });
            $('#bt-right-02').click(function () {
                $('#img-slider-01').hide();
                $('#img-slider-02').hide();
                $('#img-slider-03').show();
                $('#img-slider-04').hide();
                $('#bt-slider-01').removeClass("bt-slider-active");
                $('#bt-slider-02').removeClass("bt-slider-active");
                $('#bt-slider-03').addClass("bt-slider-active");
                $('#bt-slider-04').removeClass("bt-slider-active");
            });


            $('#bt-left-03').click(function () {
                $('#img-slider-01').hide();
                $('#img-slider-02').show();
                $('#img-slider-03').hide();
                $('#img-slider-04').hide();
                $('#bt-slider-01').removeClass("bt-slider-active");
                $('#bt-slider-02').addClass("bt-slider-active");
                $('#bt-slider-03').removeClass("bt-slider-active");
                $('#bt-slider-04').removeClass("bt-slider-active");
            });
            $('#bt-right-03').click(function () {
                $('#img-slider-01').hide();
                $('#img-slider-02').hide();
                $('#img-slider-03').hide();
                $('#img-slider-04').show();
                $('#bt-slider-01').removeClass("bt-slider-active");
                $('#bt-slider-02').removeClass("bt-slider-active");
                $('#bt-slider-03').removeClass("bt-slider-active");
                $('#bt-slider-04').addClass("bt-slider-active");
            });

            $('#bt-left-04').click(function () {
                $('#img-slider-01').hide();
                $('#img-slider-02').hide();
                $('#img-slider-03').show();
                $('#img-slider-04').hide();
                $('#bt-slider-01').removeClass("bt-slider-active");
                $('#bt-slider-02').removeClass("bt-slider-active");
                $('#bt-slider-03').addClass("bt-slider-active");
                $('#bt-slider-04').removeClass("bt-slider-active");
            });
            $('#bt-right-04').click(function () {
                $('#img-slider-01').show();
                $('#img-slider-02').hide();
                $('#img-slider-03').hide();
                $('#img-slider-04').hide();
                $('#bt-slider-01').addClass("bt-slider-active");
                $('#bt-slider-02').removeClass("bt-slider-active");
                $('#bt-slider-03').removeClass("bt-slider-active");
                $('#bt-slider-04').removeClass("bt-slider-active");
            });
        }
    });
    // PS. Esse documento possui scripts da página INDEX executados apenas em dispositivos MOBILE.
    $(document).ready(function () {

        $('.bt-accept').click(function () {
            $('.component-privacy').hide();
            $('body').removeClass('privacy-active');
        });


        $('.bt-close-video').click(function () {
            $(".all-video-modal").hide();
            $('video').trigger('pause');
        });

        $('.subhead-zeus').click(function () {
            window.location.href = "/zeus";
        });

    });
}