import $ from 'jquery';

export default function AnimationAio() {
    window.onload = () => {
        setTimeout(() => {
            $('.loading').hide();
        }, 500)
    }

    $(document).ready(function () {

        const debounce = function (func, wait, immediate) {
            let timeout;
            return function (...args) {
                const context = this;
                const later = function () {
                    timeout = null;
                    if (!immediate) func.apply(context, args);
                };
                const callNow = immediate && !timeout;
                clearTimeout(timeout);
                timeout = setTimeout(later, wait);
                if (callNow) func.apply(context, args);
            };
        };

        const target = document.querySelectorAll('[data-anime]');
        const animationClass = 'animate';

        function animeScroll() {
            const windowTop = window.pageYOffset + ((window.innerHeight * 4) / 4);
            target.forEach(function (element) {
                if ((windowTop) > element.offsetTop) {
                    element.classList.add(animationClass);
                } else {
                    element.classList.remove(animationClass);
                }
            })
        }

        animeScroll();

        if (target.length) {
            window.addEventListener('scroll', debounce(function () {
                animeScroll();
            }, 0));
        }

        if ($(window).width() > 1200) {


            var count2 = 10;
            var lastScrollTop = 0,
                delta = 5;
            $(window).scroll(function () {
                if ($(window).scrollTop() > $('.aio-about-aio').offset().top - 400 && $(window).scrollTop() < $('.aio-area-cronos').offset().top) {
                    var nowScrollTop = $(this).scrollTop();
                    if (Math.abs(lastScrollTop - nowScrollTop) >= delta) {
                        if (nowScrollTop > lastScrollTop) {
                            count2 += 6;
                            $('.aio-about-aio h1').css({
                                'transform': 'matrix(1, 0, 0, 1, 0,' + -count2 / 12 + ')',
                            });
                            $('.aio-about-aio p').css({
                                'transform': 'matrix(1, 0, 0, 1, 0,' + count2 / 12 + ')',
                            });


                        } else {
                            count2 -= 6;
                            $('.aio-about-aio h1').css({
                                'transform': 'matrix(1, 0, 0, 1, 0,' + -count2 / 12 + ')',
                            });
                            $('.aio-about-aio p').css({
                                'transform': 'matrix(1, 0, 0, 1, 0,' + count2 / 12 + ')',
                            });

                        }
                        lastScrollTop = nowScrollTop;
                    }
                }
            });
            $(window).scroll(function () {
                if ($(window).scrollTop() > $('.aio-about-aio').offset().top - 300) {
                    $('.aio-about-aio h1').css({
                        'opacity': '1.0',
                    });
                    $('.aio-about-aio p').css({
                        'opacity': '1.0',
                    });
                } else {
                    $('.aio-about-aio h1').css({
                        'opacity': '0',
                    });
                    $('.aio-about-aio p').css({
                        'opacity': '0',
                    });
                }
            });


            $(window).scroll(function () {
                if ($(window).scrollTop() > $('.aio-area-zeus').offset().top - 400) {
                    $('#box-zeus-1').css({
                        'opacity': '1.0',
                    });
                    setTimeout(function () {
                        $('#box-zeus-2').css({
                            'opacity': '1.0',
                        });
                    }, 200);
                    setTimeout(function () {
                        $('#box-zeus-3').css({
                            'opacity': '1.0',
                        });
                    }, 300);
                    setTimeout(function () {
                        $('#box-zeus-4').css({
                            'opacity': '1.0',
                        });
                    }, 400);


                } else {
                    $('#box-zeus-1').css({
                        'opacity': '0',
                    });
                    $('#box-zeus-2').css({
                        'opacity': '0',
                    });
                    $('#box-zeus-3').css({
                        'opacity': '0',
                    });
                    $('#box-zeus-4').css({
                        'opacity': '0',
                    });
                }
            });



            var count3 = 10;
            var lastScrollTop = 0,
                delta = 5;
            $(window).scroll(function () {
                if ($(window).scrollTop() > $('.aio-area-fenix').offset().top - 900 && $(window).scrollTop() < $('.aio-area-zeus').offset().top) {
                    var nowScrollTop = $(this).scrollTop();
                    if (Math.abs(lastScrollTop - nowScrollTop) >= delta) {
                        if (nowScrollTop > lastScrollTop) {
                            count3 += 6;
                            $('.aio-area-fenix .col-left').css({
                                'transform': 'matrix(1, 0, 0, 1, 0,' + count3 / 8 + ')',
                            });

                            $('.aio-area-fenix p').css({
                                'transform': 'matrix(1, 0, 0, 1, 0,' + -count3 / 20 + ')',
                            });
                        } else {
                            count3 -= 6;
                            $('.aio-area-fenix .col-left').css({
                                'transform': 'matrix(1, 0, 0, 1, 0,' + count3 / 8 + ')',
                            });

                            $('.aio-area-fenix p').css({
                                'transform': 'matrix(1, 0, 0, 1, 0,' + -count3 / 20 + ')',
                            });
                        }
                        lastScrollTop = nowScrollTop;
                    }
                }
            });


            $(window).scroll(function () {
                if ($(window).scrollTop() > $('.aio-area-fenix').offset().top - 800 && $(window).scrollTop() < $('.aio-area-zeus').offset().top) {
                    $('.aio-area-fenix h1').css({
                        'opacity': '1.0',
                    });
                    $('.aio-area-fenix p').css({
                        'opacity': '1.0',
                    });

                } else {
                    $('.aio-area-fenix h1').css({
                        'opacity': '0',
                    });
                    $('.aio-area-fenix p').css({
                        'opacity': '0',
                    });

                }
            });


            var count = 10;
            $(window).scroll(function () {
                count--;
                $('#aio-aio-container').css({
                    'background-position-y': count,
                });
                $('.aio-area-final').css({
                    'background-position-y': count,
                });
            });


            $('.aio-cockpit').css({
                'background-size': '100%',
                'opacity': '1.0',
                'background-position': 'center',
            });


            $(window).scroll(function () {
                var scrollTop = $(window).scrollTop();
                if (scrollTop >= 0 && scrollTop <= 50) {
                    $('.aio-cockpit').css({
                        'background-size': '100%',
                        'opacity': '1.0',
                        'background-position': 'center',
                    });

                    $('.aio-logo-aio-initial').css({
                        'width': '120px',
                        'opacity': '1.0',
                    });
                    $('.aio-planet-top').css({
                        'top': '54vh',
                    });

                } else if (scrollTop > 50 && scrollTop <= 100) {
                    $('.aio-cockpit').css({
                        'background-size': '120%',
                        'opacity': '1.0',
                        'background-position': 'center',

                    });

                } else if (scrollTop > 100 && scrollTop <= 150) {
                    $('.aio-cockpit').css({
                        'background-size': '130%',
                        'opacity': '1.0',
                        'background-position': 'center',
                    });

                } else if (scrollTop > 150 && scrollTop <= 200) {
                    $('.aio-cockpit').css({
                        'background-size': '140%',
                        'opacity': '1.0',
                        'background-position': 'center',
                    });

                } else if (scrollTop > 200 && scrollTop <= 250) {
                    $('.aio-cockpit').css({
                        'background-size': '150%',
                        'opacity': '1.0',
                        'background-position': 'center',
                    });

                } else if (scrollTop > 250 && scrollTop <= 300) {
                    $('.aio-cockpit').css({
                        'background-size': '200%',
                        'opacity': '1.0',
                        'background-position': 'center',
                    });

                } else if (scrollTop > 300 && scrollTop <= 350) {
                    $('.aio-cockpit').css({
                        'background-size': '250%',
                        'opacity': '1.0',
                        'background-position': 'center',
                    });

                } else if (scrollTop > 350 && scrollTop <= 400) {
                    $('.aio-cockpit').css({
                        'background-size': '300%',
                        'opacity': '1.0',
                        'background-position': 'center',
                    });

                } else if (scrollTop > 400 && scrollTop <= 450) {
                    $('.aio-cockpit').css({
                        'background-size': '350%',
                        'opacity': '1.0',
                        'background-position': 'center',
                    });

                } else if (scrollTop > 450 && scrollTop <= 500) {
                    $('.aio-cockpit').css({
                        'background-size': '400%',
                        'opacity': '1.0',
                        'background-position': 'center',
                    });

                } else if (scrollTop > 500 && scrollTop <= 550) {
                    $('.aio-cockpit').css({
                        'background-size': '450%',
                        'opacity': '1.0',
                        'background-position': 'center',
                    });

                } else if (scrollTop > 550 && scrollTop <= 600) {
                    $('.aio-cockpit').css({
                        'background-size': '550%',
                        'background-position': 'center',
                    });
                    $('.aio-planet-topo-01').css({
                        'top': '0vh',
                    });
                    $('.aio-planet-top').css({
                        'top': '0vh',
                    });
                    $('.aio-logo-aio-initial').css({
                        'width': '200px',
                        'opacity': '1.0',
                    });
                } else if (scrollTop > 1100) {
                    $('.aio-cockpit').css({
                        'background-size': '550%',
                        'background-position': 'center',
                        'opacity': '0',
                    });

                    $('.aio-planet-top').css({
                        'top': '0vh',
                        'opacity': '1.0',
                    });

                    $('.aio-logo-aio-initial').css({
                        'width': '200px',
                        'opacity': '0',
                    });
                }
            });
            // FINAL ANIMACAO EXCLAMACAO
            var count = 10;
            $(window).scroll(function () {
                count--;
                $('#aio-aio-container').css({
                    'background-position-y': count,
                });
                $('.aio-content-09').css({
                    'background-position-y': count,
                });
            });
            // RETIRAR O HOVER DOS BOXES VERTICAIS
            $(".box-vert").hover(function () {
                $('.aio-box-vert').removeClass("active-vert");
            });
            // DIMINUIR BOX FINAL PARA LOGO AIO
            $(window).scroll(function () {
                var scroll09 = $('.aio-scroll-09-01').offset();
                var scroll0902 = $('.aio-scroll-09-02').offset();
                var scrollTop = $(window).scrollTop();
                var height = '100vh';
                var width = '100%';
                var borderactive = '20px';
                var borderinative = '0px';
                var win_scrol = $(this).scrollTop();
                var div_topo = $(".scroll-09-01").offset().top;
                var distancia = div_topo - win_scrol;
                var resultado = distancia;


                if (scrollTop > scroll0902.top) {
                    $('.aio-content-09').css({
                        'width': resultado,
                        'height': resultado,
                        'border-radius': borderactive
                    });
                } else {
                    $('.aio-content-09').css({
                        'width': width,
                        'height': height,
                        'border-radius': borderinative
                    });
                }
            });
            // OCULTAR CONTEÚDO DO BPO
            $(window).scroll(function () {
                var scroll0903 = $('.aio-scroll-09-03').offset();
                var opacityactive = '1.0';
                var opacityinative = '0';
                var scrollTop = $(window).scrollTop();
                if (scrollTop > scroll0903.top) {
                    $('.aio-op-9').css({
                        'opacity': opacityinative,
                    });
                } else {
                    $('.aio-op-9').css({
                        'opacity': opacityactive,
                    });
                }
            });
            // APARECER LOGO FINAL AIO
            $(window).scroll(function () {
                var scrollTop = $(window).scrollTop();
                var scroll09 = $('.aio-scroll-09-01').offset();
                if (scrollTop > scroll09.top) {
                    $('.aio-logo-aio-fechamento').css({
                        'opacity': '1.0',
                    });

                    $('.aio-align-title-aio-final').addClass('iniciar-animacao');
                    $('.aio-align-title-aio-final').removeClass('aio-padrao-animacao');
                    $('.aio-align-title-aio-final').removeClass('padrao-animacao');
                    $('.aio-align-text-aio-final').css({
                        'opacity': '1.0',
                    });
                } else {
                    $('.aio-logo-aio-fechamento').css({
                        'opacity': '0',
                    });
                    $('.aio-align-title-aio-final').removeClass('iniciar-animacao');
                    $('.aio-align-title-aio-final').addClass('aio-padrao-animacao');
                    $('.aio-align-title-aio-final').addClass('padrao-animacao');
                    $('.aio-align-text-aio-final').css({
                        'opacity': '0',
                    });
                }

            });
        }
    });

    $(document).ready(function () {
        if ($(window).width() < 1200) {


            $(window).scroll(function () {
                if ($(window).scrollTop() > $('.aio-about-aio').offset().top - 300) {
                    $('.aio-about-aio h1').css({
                        'opacity': '1.0',
                    });
                    $('.aio-about-aio p').css({
                        'opacity': '1.0',
                    });
                } else {
                    $('.aio-about-aio h1').css({
                        'opacity': '0',
                    });
                    $('.aio-about-aio p').css({
                        'opacity': '0',
                    });
                }
            });


            $(window).scroll(function () {
                if ($(window).scrollTop() > $('.aio-area-zeus').offset().top - 400) {
                    $('#box-zeus-1').css({
                        'opacity': '1.0',
                    });
                    setTimeout(function () {
                        $('#box-zeus-2').css({
                            'opacity': '1.0',
                        });
                    }, 200);
                    setTimeout(function () {
                        $('#box-zeus-3').css({
                            'opacity': '1.0',
                        });
                    }, 300);
                    setTimeout(function () {
                        $('#box-zeus-4').css({
                            'opacity': '1.0',
                        });
                    }, 400);


                } else {
                    $('#box-zeus-1').css({
                        'opacity': '0',
                    });
                    $('#box-zeus-2').css({
                        'opacity': '0',
                    });
                    $('#box-zeus-3').css({
                        'opacity': '0',
                    });
                    $('#box-zeus-4').css({
                        'opacity': '0',
                    });
                }
            });

            $(window).scroll(function () {
                if ($(window).scrollTop() > $('.aio-area-fenix').offset().top - 800 && $(window).scrollTop() < $('.aio-area-zeus').offset().top) {
                    $('.aio-area-fenix h1').css({
                        'opacity': '1.0',
                    });
                    $('.aio-area-fenix p').css({
                        'opacity': '1.0',
                    });

                } else {
                    $('.aio-area-fenix h1').css({
                        'opacity': '0',
                    });
                    $('.aio-area-fenix p').css({
                        'opacity': '0',
                    });

                }
            });


            var count = 10;
            $(window).scroll(function () {
                count--;
                $('#aio-aio-container').css({
                    'background-position-y': count,
                });
                $('.aio-area-final').css({
                    'background-position-y': count,
                });
            });




            $(window).scroll(function () {
                var scrollTop = $(window).scrollTop();
                if (scrollTop >= 0 && scrollTop <= 50) {
                    $('.aio-cockpit').css({
                        'background-size': '100%',
                        'opacity': '1.0',
                        'background-position': 'center',
                    });

                    $('.aio-logo-aio-initial').css({
                        'width': '90px',
                        'opacity': '1.0',
                    });
                    $('.aio-planet-top').css({
                        'top': '54vh',
                    });

                } else if (scrollTop > 50 && scrollTop <= 100) {
                    $('.aio-cockpit').css({
                        'background-size': '100%',
                        'opacity': '1.0',
                        'background-position': 'center',

                    });

                } else if (scrollTop > 100 && scrollTop <= 150) {
                    $('.aio-cockpit').css({
                        'background-size': '150%',
                        'opacity': '1.0',
                        'background-position': 'center',
                    });

                } else if (scrollTop > 150 && scrollTop <= 200) {
                    $('.aio-cockpit').css({
                        'background-size': '200%',
                        'opacity': '1.0',
                        'background-position': 'center',
                    });

                } else if (scrollTop > 200 && scrollTop <= 250) {
                    $('.aio-cockpit').css({
                        'background-size': '250%',
                        'opacity': '1.0',
                        'background-position': 'center',
                    });

                } else if (scrollTop > 250 && scrollTop <= 300) {
                    $('.aio-cockpit').css({
                        'background-size': '450%',
                        'opacity': '1.0',
                        'background-position': 'center',
                    });

                } else if (scrollTop > 300 && scrollTop <= 350) {
                    $('.aio-cockpit').css({
                        'background-size': '500%',
                        'opacity': '1.0',
                        'background-position': 'center',
                    });

                } else if (scrollTop > 350 && scrollTop <= 400) {
                    $('.aio-cockpit').css({
                        'background-size': '650%',
                        'opacity': '1.0',
                        'background-position': 'center',
                    });

                } else if (scrollTop > 400 && scrollTop <= 450) {
                    $('.aio-cockpit').css({
                        'background-size': '700%',
                        'opacity': '1.0',
                        'background-position': 'center',
                    });

                } else if (scrollTop > 450 && scrollTop <= 500) {
                    $('.aio-cockpit').css({
                        'background-size': '800%',
                        'opacity': '1.0',
                        'background-position': 'center',
                    });

                } else if (scrollTop > 500 && scrollTop <= 550) {
                    $('.aio-cockpit').css({
                        'background-size': '1000%',
                        'opacity': '1.0',
                        'background-position': 'center',
                    });

                } else if (scrollTop > 550 && scrollTop <= 600) {
                    $('.aio-cockpit').css({
                        'background-size': '2300%',
                        'background-position': 'center',
                        'display': 'block'
                    });
                    $('.aio-planet-topo-01').css({
                        'top': '0vh',
                    });
                    $('.aio-planet-top').css({
                        'top': '0vh',
                    });
                    $('.aio-logo-aio-initial').css({
                        'width': '160px',
                        'opacity': '1.0',
                    });
                } else if (scrollTop > 1100) {
                    $('.aio-cockpit').css({
                        'background-size': '2500%',
                        'background-position': 'center',
                        'opacity': '0',
                        'display': 'none'
                    });

                    $('.aio-planet-top').css({
                        'top': '0vh',
                        'opacity': '1.0',
                    });

                    $('.aio-logo-aio-initial').css({
                        'width': '160px',
                        'opacity': '0',
                    });
                }
            });
            // FINAL ANIMACAO EXCLAMACAO
            var count = 10;
            $(window).scroll(function () {
                count--;
                $('#aio-container').css({
                    'background-position-y': count,
                });
                $('.aio-content-09').css({
                    'background-position-y': count,
                });
            });
            // RETIRAR O HOVER DOS BOXES VERTICAIS
            $(".box-vert").hover(function () {
                $('.aio-box-vert').removeClass("active-vert");
            });
            // DIMINUIR BOX FINAL PARA LOGO AIO
            $(window).scroll(function () {
                var scroll09 = $('.aio-scroll-09-01').offset();
                var scroll0902 = $('.aio-scroll-09-02').offset();
                var scrollTop = $(window).scrollTop();
                var height = '100vh';
                var width = '100%';
                var borderactive = '20px';
                var borderinative = '0px';
                var win_scrol = $(this).scrollTop();
                var div_topo = $(".scroll-09-01").offset().top;
                var distancia = div_topo - win_scrol;
                var resultado = distancia;


                if (scrollTop > scroll0902.top) {
                    $('.aio-content-09').css({
                        'width': resultado,
                        'height': resultado,
                        'border-radius': borderactive
                    });
                } else {
                    $('.aio-content-09').css({
                        'width': width,
                        'height': height,
                        'border-radius': borderinative
                    });
                }
            });
            // OCULTAR CONTEÚDO DO BPO
            $(window).scroll(function () {
                var scroll0903 = $('.aio-scroll-09-03').offset();
                var opacityactive = '1.0';
                var opacityinative = '0';
                var scrollTop = $(window).scrollTop();
                if (scrollTop > scroll0903.top) {
                    $('.aio-op-9').css({
                        'opacity': opacityinative,
                    });
                } else {
                    $('.aio-op-9').css({
                        'opacity': opacityactive,
                    });
                }
            });
            // APARECER LOGO FINAL AIO
            $(window).scroll(function () {
                var scrollTop = $(window).scrollTop();
                var scroll09 = $('.aio-scroll-09-01').offset();
                if (scrollTop > scroll09.top) {
                    $('.aio-logo-aio-fechamento').css({
                        'opacity': '1.0',
                    });

                    $('.aio-align-title-aio-final').addClass('iniciar-animacao');
                    $('.aio-align-title-aio-final').removeClass('aio-padrao-animacao');
                    $('.aio-align-title-aio-final').removeClass('padrao-animacao');
                    $('.aio-align-text-aio-final').css({
                        'opacity': '1.0',
                    });
                } else {
                    $('.aio-logo-aio-fechamento').css({
                        'opacity': '0',
                    });
                    $('.aio-align-title-aio-final').removeClass('iniciar-animacao');
                    $('.aio-align-title-aio-final').addClass('aio-padrao-animacao');
                    $('.aio-align-title-aio-final').addClass('padrao-animacao');
                    $('.aio-align-text-aio-final').css({
                        'opacity': '0',
                    });
                }

            });
        }
    });
}