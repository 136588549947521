import styled from "styled-components"

export const Container = styled.main `
@media screen and (min-width: 1200px) {
    .category-active {
        background-color: #2998FA !important;
    }
    .category-active {
        color: white !important
    }
    .all-post .bt-link p {
        font-size: 16px !important
    }
    .b-t {
        border-top: 1px solid rgb(241, 241, 241);
        padding-top: 30px
    }
    .initial-blog {
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/blog/bg-blog.jpg');
        height: 753px !important;
        padding-bottom: 100px;
    }
    .initial-blog p {
        width: 400px;
        float: left;
        margin-top: 30px;
        font-size: 20px;
        line-height: 30px;
    }
    .initial-blog .border-title {
        margin-top: 10px !important
    }
    .area-actions-blog {
        width: 100%;
        float: left;
        display: flex;
        margin-top: -217px;
        justify-content: center;
    }
    .post-default p {
        color: rgb(0, 0, 0) !important;
    }
    .category-blog h3 {
        font-weight: 600 !important;
    }
    .category-blog {
        width: 100%;
        float: left;
        background-color: rgba(255, 255, 255, 0.1);
        -webkit-backdrop-filter: saturate(180%) blur(20px);
        backdrop-filter: saturate(180%) blur(20px);
        padding: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 6px;
        color: white;
        padding-left: 20px !important;
    }
    .bt-category-blog {
        float: left;
        display: flex;
        align-items: center;
    }
    .bt-item-category:hover {
        background-color: rgb(160, 160, 160);
    }
    .bt-category-blog button {
        width: auto;
        margin: 5px;
        height: 44px;
        padding: 6px;
        float: left;
        border: 0px;
        padding-left: 18px;
        padding-right: 18px;
        border-radius: 4px;
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
        padding-top: 10px !important;
    }
    .area-spotlight {
        width: 100%;
        height: 500px;
        float: left;
        display: flex;
        justify-content: center;
        margin-top: -127px;
    }
    .area-spotlight .container-medium {
        display: flex;
        justify-content: space-between;
    }
    .area-spotlight .col-left {
        width: 50%;
        float: left;
        padding-right: 8px;
    }
    .col-left .post-default {
        height: calc(100% - 10px) !important;
    }
    .area-spotlight .col-right {
        padding-left: 8px;
        width: 50%;
        float: left;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }
    .area-spotlight .post-default {
        width: 100%;
        float: left;
        height: 100%;
        background-color: rgb(0, 0, 0);
        border-radius: 6px;
        margin-bottom: 10px;
        position: relative;
        overflow: hidden;
        color: white;
    }
    .date-post {
        padding: 30px;
        position: absolute;
        left: 0px;
        bottom: 0px;
        font-weight: 700;
    }
    .type-category {
        padding: 30px;
        position: absolute;
        right: 0px;
        bottom: 0px;
    }
    .area-spotlight h4 {
        padding: 25px;
        position: absolute;
        top: 0px;
        left: 0px;
        font-size: 24px;
        font-weight: 700;
        line-height: 30px;
        text-align: left;
    }
    .area-spotlight .post-default {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    .area-spotilight .post-default:hover img {
        width: 120%;
        opacity: 1.0;
    }
    .all-post {
        width: 100%;
        float: left;
        display: flex;
        justify-content: center;
        padding-bottom: 100px;
    }
    .all-post h3 {
        margin-top: 30px;
        color: black
    }
    .all-post h3 {
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 1199px) {
    .category-active {
        background-color: #2998FA !important;
    }
    .category-active {
        color: white !important
    }
    .area-spotlight h4 {
        line-height: 22px !important;
    }
    .initial-blog {
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/blog/bg-blog.jpg');
        height: 753px !important;
        padding-bottom: 100px;
    }
    .initial-blog p {
        width: 100%;
        float: left;
        margin-top: 30px;
        line-height: 26px;
    }
    .initial-blog .border-title {
        margin-top: 10px !important
    }
    .area-actions-blog {
        width: 100%;
        float: left;
        display: flex;
        margin-top: -217px;
        justify-content: center;
        padding-right: 15px;
    }
    .category-blog h3 {
        font-weight: 600 !important;
    }
    .category-blog {
        width: 100%;
        float: left;
        -webkit-backdrop-filter: saturate(180%) blur(20px);
        display: flex;
        border-radius: 6px;
        color: white;
        flex-direction: column;
    }
    .bt-category-blog {
        float: left;
        display: flex;
        overflow-x: scroll;
        width: calc(100% + 47px);
        margin-left: -16px;
        padding-left: 15px;
        padding-top: 8px;
        align-content: flex-start;
    }
    .bt-category-blog button {
        width: auto;
        min-width: 155px;
        margin-right: 10px;
        height: 44px;
        padding: 6px;
        float: left;
        border: 0px;
        padding-left: 18px;
        padding-right: 18px;
        border-radius: 4px;
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
        padding-top: 10px !important;
        background-color: rgb(255 255 255 / 24%);
        -webkit-backdrop-filter: saturate(180%) blur(20px);
        backdrop-filter: saturate(180%) blur(20px);
        color: white;
    }
    .area-spotlight {
        width: 100%;
        height: auto;
        float: left;
        display: flex;
        justify-content: center;
        margin-top: -107px;
    }
    .area-spotlight .container-medium {
        justify-content: center;
        align-content: center;
    }
    .area-spotlight .col-left {
        width: 100%;
        float: left;
        display: flex;
        justify-content: center;
    }
    .col-left .post-default {
        height: calc(100% - 10px) !important;
    }
    .area-spotlight .col-right {
        width: 100%;
        float: left;
        display: flex;
        justify-content: center;
        align-content: center;
    }
    .area-spotlight .post-default {
        width: 100%;
        float: left;
        height: 230px !important;
        background-color: rgb(0, 0, 0);
        border-radius: 6px;
        position: relative;
        overflow: hidden;
        color: white;
        margin-bottom: 15px;
        margin-right: 0px !important;
    }
    .date-post {
        padding: 30px;
        position: absolute;
        left: 0px;
        bottom: 0px;
        font-weight: 600;
        font-size: 13px;
    }
    .type-category {
        padding: 30px;
        position: absolute;
        right: 0px;
        bottom: 0px;
    }
    .area-spotlight h4 {
        padding: 25px;
        position: absolute;
        top: 0px;
        left: 0px;
        font-size: 16px;
        font-weight: 600;
        line-height: 30px;
        text-align: left;
    }
    .area-spotlight .post-default {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    .post-default img {
        transition: all 0.1s ease;
        opacity: 0.8;
        position: absolute;
        top: 0;
    }
    .area-spotilight .post-default:hover img {
        width: 120%;
        opacity: 1.0;
    }
    .post-default:hover img {
        width: 105%;
        opacity: 1.0;
    }
    .all-post {
        width: 100%;
        float: left;
        display: flex;
        justify-content: center;
        padding-bottom: 100px;
    }
    .all-post h3 {
        margin-top: 30px;
        color: black
    }
    .area-post {
        width: 100%;
        float: left;
    }
    .post-default figure p {
        width: 100%;
        float: left;
        padding: 22px;
        position: absolute;
        top: 0px;
        font-size: 22px;
        font-weight: 700;
        line-height: 32px;
        margin: 0px;
    }
    .all-post .area-post {
        display: block !important
    }
    .date-post-thumb {
        position: absolute;
        bottom: 0px;
        left: 0px;
        padding: 20px;
        color: white;
        font-size: 13px;
        font-weight: 600;
    }
    .post-default figure {
        width: 100%;
        position: relative;
    }
    .area-blog p {
        width: 40%;
        color: white;
    }
}
`;