import Routes from "./Routes";
import Cookie from "./components/Cookie";
import {BrowserRouter} from 'react-router-dom'
import ChatBot from "./components/ChatBot";

function App() {
  return (
    <BrowserRouter>
      <Cookie />
      <ChatBot />
      <Routes />
    </BrowserRouter>
  );
}

export default App;
