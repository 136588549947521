import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { LanguageSelector } from "./LanguageDropdown"

export default function Header() {
    let pathName = window.location.pathname;

    const { t, i18n } = useTranslation()
    const [isLanguageSelectorShown, showLanguageSelector] = useState(false)
    const toggleLanguageSelector = () => showLanguageSelector((prevState) => !prevState)

    return (
        <div className="all-menu">
            <header>
                <div className="container-medium">
                    <a href="/" className="center">
                        <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/logo-services.svg" />
                    </a>
                    {/* <Link to="/">
                        <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/logo-services.svg" />
                    </Link> */}
                    <div className="area-options">
                        <ul>
                            <li className={`${pathName === '/' ? 'option-active' : ''}`}>
                                <a href="/">{t('nav.home')}</a>
                            </li>
                            <li className={`${pathName === '/institucional' ? 'option-active' : ''}`}>
                                <a href="/institucional">{t('nav.company')}</a>
                            </li>
                            <li className={`${pathName === '/cultura' ? 'option-active' : ''}`}>
                                <a href="/cultura">{t('nav.culture')}</a>
                            </li>
                            <li className={`${pathName === '/produtos' ? 'option-active' : ''}`}>
                                <a href="/produtos">{t('nav.products')}</a>
                            </li>
                            <li className={`${pathName === '/blog' ? 'option-active' : ''}`}>
                                <a href="/blog">Blog</a>
                            </li>
                            <li className={`${pathName === '/oportunidades' ? 'option-active' : ''}`}>
                                <a href="/oportunidades">{t('nav.jobs')}</a>
                            </li>
                            <li className={`${pathName === '/fale-conosco' ? 'option-active' : ''}`}>
                                <a href="/fale-conosco">{t('nav.contact')}</a>
                            </li>
                            {/* <li className="option-active"><Link to="/">{t('nav.home')}</Link></li>
                            <li><Link to="/institucional">{t('nav.company')}</Link></li>
                            <li><Link to="/cultura">{t('nav.culture')}</Link></li>
                            <li><Link to="/produtos">{t('nav.products')}</Link></li>
                            <li><Link to="/blog">Blog</Link></li>
                            <li><Link to="/oportunidades">{t('nav.jobs')}</Link></li>
                            <li><Link to="/fale-conosco">{t('nav.contact')}</Link></li> */}
                        </ul>
                        <button className="bt-language" onClick={toggleLanguageSelector}>
                            <p>{i18n.resolvedLanguage.toUpperCase()}</p>
                        </button>
                    </div>
                    {isLanguageSelectorShown && <LanguageSelector onClick={toggleLanguageSelector}/>}
                </div>
            </header>
            <input id="open-menu" type="checkbox" name="menu-mobile" />
            <input type="radio" name="menu-mobile" id="close-menu" />
            <label className="bt-menu" htmlFor="open-menu"></label>
            <nav id="menu-expanded">
                <div className="content-expanded">
                    <div className="row center">
                        <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/logo-services.svg" className="logo-mobile" />
                    </div>
                    <ul>
                        <li className={`${pathName === '/' ? 'option-active' : ''}`}>
                            <a href="/">{t('nav.home')}</a>
                        </li>
                        <li className={`${pathName === '/institucional' ? 'option-active' : ''}`}>
                            <a href="/institucional">{t('nav.company')}</a>
                        </li>
                        <li className={`${pathName === '/cultura' ? 'option-active' : ''}`}>
                            <a href="/cultura">{t('nav.culture')}</a>
                        </li>
                        <li className={`${pathName === '/produtos' ? 'option-active' : ''}`}>
                            <a href="/produtos">{t('nav.products')}</a>
                        </li>
                        <li className={`${pathName === '/blog' ? 'option-active' : ''}`}>
                            <a href="/blog">Blog</a>
                        </li>
                        <li className={`${pathName === '/oportunidades' ? 'option-active' : ''}`}>
                            <a href="/oportunidades">{t('nav.jobs')}</a>
                        </li>
                        <li className={`${pathName === '/fale-conosco' ? 'option-active' : ''}`}>
                            <a href="/fale-conosco">{t('nav.contact')}</a>
                        </li>
                        {/* <li className="option-active"><Link to="/">{t('nav.home')}</Link></li>
                        <li><Link to="/institucional">{t('nav.company')}</Link></li>
                        <li><Link to="/cultura">{t('nav.culture')}</Link></li>
                        <li><Link to="/produtos">{t('nav.products')}</Link></li>
                        <li><Link to="/blog">Blog</Link></li>
                        <li><Link to="/oportunidades">{t('nav.jobs')}</Link></li>
                        <li><Link to="/fale-conosco">{t('nav.contact')}</Link></li> */}
                    </ul>
                    <div className="row center">
                        <button className="bt-language">
                            {i18n.language === "pt" ? "PT" : "EN"}
                        </button>
                    </div>
                    <div className="area-social-head">
                        <a href="https://www.facebook.com/gruposervicesbywebhelp">
                            <div className="icon-social-head">
                                <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/icon-facebook.svg" />
                            </div>
                        </a>
                        <a href="https://www.instagram.com/gruposervicesbywebhelp/">
                            <div className="icon-social-head">
                                <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/icon-instagram.svg" />
                            </div>
                        </a>
                        <a href="https://www.linkedin.com/company/gruposervicesbywebhelp">
                            <div className="icon-social-head">
                                <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/icon-linkedin.svg" />
                            </div>
                        </a>
                        <a href="https://www.youtube.com/channel/UCxFcMiEaaMbrdlc99L7C3uQ?sub_confirmation=1">
                            <div className="icon-social-head">
                                <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/icon-youtube.svg" />
                            </div>
                        </a>
                    </div>
                </div>
            </nav>
        </div>
    )
}