import styled from "styled-components"

export const Container = styled.div `
@media screen and (min-width: 1200px) {
    height: 13602px;
    .motion-col {
        transition: all 0.1s ease;
    }
    p {
        font-size: 22px;
        line-height: 34px !important;
    }
    .walker {
        height: 100vh;
        width: 100vw;
        position: fixed;
    }
    .slider {
        display: flex;
        transition: all 0.1s ease;
        justify-content: flex-start;
    }
     :root {
        --tipo: red;
    }
    .test {
        width: 300px;
        height: 300px;
        position: fixed;
        top: 200px;
        left: 200px;
        z-index: 99;
        background-color: var(--tipo);
    }
    .slide {
        height: 100vh;
        width: 100vw;
        display: inline;
        float: left;
    }
    .slider>div:nth-child(2n+1) .slide {
        background-color: green;
    }
    .slider>div:nth-child(2n) .slide {
        background-color: red;
    }
    /* @media screen and (min-width: 1344px) {
        body {
            height: 13822px;
        }
    } */
    /* body {
        height: 13822px;
    } */
    /* INÃƒÂCIO - AREA 01 */
    .area-initial-culture {
        width: 100vw;
        height: 100vh;
        background-color: white;
        float: left;
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/estrutura/desktop/bg-initial-page.jpg');
        background-size: cover;
        background-position: right center;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding: 54px;
        padding-bottom: 65px;
    }
    .area-initial-culture p {
        color: white;
        font-weight: 700 !important;
        font-size: 100px !important;
        line-height: 100px !important;
    }
    .area-initial-culture button {
        background: transparent;
        border: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .area-initial-culture button p {
        font-size: 40px !important
    }
    .area-initial-culture img {
        width: 40px;
        margin-left: 20px;
    }
    .area-02 {
        width: 100vh;
        height: 100vh;
        float: left;
        padding-top: 50px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        flex-wrap: wrap;
        left: 100vw;
        background-color: rgb(0, 0, 0);
    }
    .area-02 .box-area-item {
        width: calc(50% - 21px);
        padding-bottom: calc(50% - 46px);
        float: left;
        background-color: gray;
        margin: 10px;
        border-radius: 10px;
        background-size: cover;
        position: relative;
    }
    .align-area-02 {
        width: 100vh;
        height: 100vh;
        float: left;
        padding: 7px;
    }
    .area-02 .box-area-item p {
        width: 100%;
        position: absolute;
        bottom: 40px;
        left: 40px;
        color: white;
        font-weight: 700;
        font-size: 28px;
    }
    .box-02-01 {
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/estrutura/desktop/box-02-01.jpg');
    }
    .box-02-02 {
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/estrutura/desktop/box-02-02.jpg');
    }
    .box-02-03 {
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/estrutura/desktop/box-02-03.jpg');
    }
    .box-02-04 {
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/estrutura/desktop/box-02-04.jpg');
    }
    .area-03 {
        width: 1302px;
        float: left;
        height: 100vh;
        background-color: #fff;
        padding-left: 60px;
    }
    .row-page {
        float: left;
        height: 100vh;
    }
    .center-page {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .area-03 p {
        font-size: 22px;
        width: 608px;
        line-height: 30px;
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/estrutura/desktop/decorate-03.png');
        background-repeat-y: repeat;
        background-position: left center;
        padding-left: 120px;
        background-size: 80px;
        background-repeat: repeat-y;
        background-repeat-x: no-repeat;
    }
    .decorate-03-01 {
        width: 250px;
        height: 100vh;
        float: left;
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/estrutura/desktop/decorate-03-01.png');
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: cover;
        margin-left: 110px;
        margin-right: 13px;
    }
    .decorate-03-02 {
        width: 250px;
        height: 100vh;
        float: left;
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/estrutura/desktop/decorate-03-02.png');
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: cover;
    }
    .area-04 {
        width: auto;
        height: 100vh;
        padding-top: 50px;
        float: left;
        padding: 50px;
        padding-left: 180px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-right: 1px solid rgb(48, 48, 48);
        padding-right: 180px;
    }
    .area-04 figure {
        width: 1000px;
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/estrutura/desktop/bg-area-04.jpg');
        overflow: hidden;
        height: calc(100vh - 420px);
        display: flex;
        align-items: center;
        justify-content: center;
        max-height: 500px;
        margin-top: 40px;
    }
    .area-04 img {
        width: 100%;
        /* max-height: 500px; */
        margin-top: 28px;
    }
    .area-text-04 {
        width: 100%;
        float: left;
        /* background-color: red; */
        display: flex;
    }
    .area-text-04 p {
        color: white;
        margin-left: 80px;
        font-size: 22px;
        line-height: 30px;
    }
    .column-page {
        flex-direction: column;
    }
    .area-05 {
        width: auto;
        height: 100vh;
        padding-top: 50px;
        float: left;
        padding: 50px;
        padding-left: 180px;
        padding-left: 180px;
        display: flex;
    }
    .area-05 p {
        width: 450px;
        font-size: 22px;
        color: white;
        line-height: 34px !important;
    }
    .decorate-05-01 {
        width: 250px;
        height: 100vh;
        float: left;
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/estrutura/desktop/decorate-05-01.png');
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: cover;
        margin-left: 180px;
        margin-right: 13px;
    }
    .decorate-05-02 {
        width: 250px;
        height: 100vh;
        float: left;
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/estrutura/desktop/decorate-05-02.png');
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: cover;
        margin-left: 10px;
        margin-right: 13px;
    }
    .decorate-05-03 {
        width: 250px;
        height: 100vh;
        float: left;
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/estrutura/desktop/decorate-05-03.png');
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: cover;
        margin-left: 10px;
        margin-right: 13px;
    }
    .area-06 {
        width: auto;
        height: 100vh;
        padding-top: 50px;
        float: left;
        padding: 50px;
        /* padding-left: 180px; */
        padding-left: 180px;
        display: flex;
        padding-right: 15px !important;
        background-color: white;
    }
    .area-06 p {
        width: 520px;
    }
    .bg-olimpus {
        width: 100vh;
        height: 100vh;
        float: left;
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/estrutura/desktop/bg-olimpus.jpg');
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: cover;
        margin-left: 100px;
        margin-right: 13px;
    }
    .area-07 {
        width: 1395px;
        height: 100vh;
        padding-top: 0px !important;
        float: left;
        padding: 50px;
        display: flex;
        background-color: white;
    }
    .area-bt-slider {
        width: 140px;
        float: left;
        height: 100vh;
        background-color: #11272E;
        padding-top: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .bt-slider {
        width: 100px;
        height: 70px;
        float: left;
        background-color: #0c191f;
        border: 0px;
        border-radius: 8px;
        margin-bottom: 10px;
        margin-top: 10px;
        position: relative;
    }
    .bt-slider div {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0px;
    }
    .bt-slider-active {
        background-color: #3D8CA1 !important;
        border: 0px !important;
    }
    .bt-slider:hover {
        background-color: #09141a;
        border: 1px solid #1d3b49;
    }
    .bt-slider img {
        width: 90%;
    }
    .area-slider {
        width: 780px;
        height: 100vh;
        float: left;
        background-color: #1C3846;
        padding-top: 50px;
        padding-left: 120px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }
    .area-title-07 {
        width: 100%;
        float: left;
        height: 200px;
        /* background-color: red; */
    }
    .area-title-07 p {
        width: 450px;
        color: white;
    }
    .item-slider {
        width: 989px;
        height: calc(100vh - 400px);
        float: left;
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;
    }
    .item-slider img {
        width: 100%;
    }
    #img-slider-02,
    #img-slider-03,
    #img-slider-04 {
        display: none
    }
    .img-slider {
        width: 900px;
        height: 100%;
        float: left;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }
    .item-slider figure {
        width: 900px;
        height: 100%;
        float: left;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }
    .area-controller {
        width: calc(100% - 900px);
        height: 100%;
        float: left;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: flex-end;
    }
    .bt-back {
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/estrutura/desktop/icon-slider-up.svg');
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: cover;
    }
    .bt-next {
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/estrutura/desktop/icon-slider-down.svg');
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: cover;
    }
    .area-controller button {
        width: 40px;
        height: 40px;
        float: left;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .area-controller button img {
        width: 40px;
        float: left;
    }
    .item-slider figure img {
        width: 100%;
    }
    .area-08 {
        height: 100vh;
        padding-top: 50px;
        float: left;
        padding: 50px;
        padding-left: 140px;
        display: flex;
        background-color: #072548;
        position: relative;
        width: 1570px;
        overflow: hidden;
    }
    .area-08 p {
        width: 450px;
        float: left;
        color: white
    }
    .decorate-08 {
        width: 100vh;
        height: 100vh;
        float: left;
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/estrutura/desktop/decorate-08.png');
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: cover;
    }
    .area-09 {
        width: 1500px;
        height: 100vh;
        padding-top: 50px;
        float: left;
        padding-top: 50px;
        padding: 50px;
        padding-left: 140px;
        display: flex;
        background-color: #072548;
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/estrutura/desktop/bg-09.jpg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;
    }
    .text-01-09,
    .text-02-09 {
        width: 600px;
        font-weight: 700;
        font-size: 44px !important;
        color: white;
        text-transform: uppercase;
        line-height: 68px !important;
    }
    .area-09 h1 {
        width: 590px !important;
        color: white;
        font-size: 38px;
    }
    .area-10 {
        width: 2000px;
        height: 100vh;
        padding-top: 50px;
        float: left;
        padding-top: 50px;
        padding: 50px;
        padding-left: 170px;
        background-color: #072548;
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/estrutura/desktop/bg-10.jpg');
        background-repeat: no-repeat;
        background-position: top;
        background-size: cover;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    .area-10 h1 {
        width: 400px !important;
        color: white
    }
    .bt-next-initial {
        opacity: 0;
        transition: all 1.0s ease;
    }
    .motion-op {
        opacity: 1.0 !important;
        transition: all 1.0s ease;
    }
    [data-op="op"] {
        opacity: 0;
        transition: all 1.0s ease !important;
    }
    .bt-tertiary p {
        font-size: 15px !important;
    }
    .area-video-08 {
        width: 100%;
        position: absolute;
        height: 100%;
        left: 0px;
        top: 0px;
        z-index: 8;
        /* mix-blend-mode: darken; */
    }
    .content-08 {
        width: 100%;
        position: absolute;
        height: 100%;
        left: 0px;
        top: 0px;
        padding-left: 150px;
        z-index: 9;
    }
    #item-video-08 {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    #item-video-08-mobile {
        display: none;
    }
}

@media screen and (max-width: 1199px) {
    .motion-col {
        transition: all 0.1s ease;
    }
    p {
        font-size: 22px;
        line-height: 28px;
    }
    h1 {
        font-size: 33px !important;
        line-height: 48px;
    }
    .walker {
        width: 100%;
        overflow-x: hidden;
        float: left;
    }
    .bt-slider-active {
        background-color: #3D8CA1 !important;
        border: 0px !important;
    }
    .slider {
        transition: all 0.1s ease;
    }
    .slide {
        width: 100%;
        float: left;
    }
    .area-initial-culture {
        width: 100%;
        height: 100vh;
        background-color: white;
        float: left;
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/estrutura/mobile/bg-initial.jpg');
        background-size: cover;
        padding: 30px;
        padding-bottom: 63vh;
        flex-direction: column;
        position: relative;
        z-index: 7;
    }
    .area-initial-culture p {
        color: white;
        font-weight: 700 !important;
        font-size: 50px !important;
        margin-top: calc(100vh - 310px);
        line-height: 64px !important;
    }
    .bt-next-initial {
        display: none;
    }
    .area-initial-culture button p {
        font-size: 40px !important;
        display: none;
    }
    .area-initial-culture img {
        width: 40px;
        margin-left: 20px;
    }
    .area-02 {
        width: 100%;
        /* height: 100vh; */
        float: left;
        /* padding-top: 50px; */
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        flex-wrap: wrap;
        /* left: 100vw; */
        /* background-color: rgb(66 255 45); */
        margin-top: -100vh;
        padding-top: 100vh;
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/estrutura/mobile/bg-initial.jpg');
        background-size: cover;
    }
    .area-02 .box-area-item {
        width: calc(50% - 10px);
        padding-bottom: calc(50% - 10px);
        float: left;
        background-color: gray;
        margin: 5px;
        border-radius: 4px;
        position: relative;
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
    }
    .box-02-01 {
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/estrutura/desktop/box-02-01.jpg');
    }
    .box-02-02 {
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/estrutura/desktop/box-02-02.jpg');
    }
    .box-02-03 {
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/estrutura/desktop/box-02-03.jpg');
    }
    .box-02-04 {
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/estrutura/desktop/box-02-04.jpg');
    }
    .align-area-02 {
        width: 100%;
        float: left;
        padding: 5px;
        display: flex;
        flex-wrap: wrap;
    }
    .area-02 .box-area-item p {
        width: 100%;
        position: absolute;
        bottom: 0px;
        left: 0px;
        color: white;
        font-weight: 700;
        font-size: 15px !important;
        padding: 20px;
        line-height: 19px !important;
    }
    .area-03 {
        width: 100%;
        float: left;
        background-color: #fff;
        padding-top: 40px;
        padding-bottom: 20px;
    }
    .row-page {
        float: left;
        width: 100%;
    }
    .area-03 p {
        font-size: 15px !important;
        width: calc(100% - 30px);
        line-height: 24px !important;
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/estrutura/mobile/decorate-03.png');
        background-position: left top;
        padding-left: 60px;
        background-size: 35px;
        background-repeat: no-repeat;
        background-repeat-y: repeat;
        padding-right: 30px;
        margin-left: 15px;
    }
    .decorate-03-01 {
        width: 100%;
        height: 240px;
        float: left;
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/estrutura/mobile/decorate-03-01.png');
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: 100%;
        margin-bottom: 22px;
        margin-top: 50px;
    }
    .decorate-03-02 {
        width: 100%;
        height: 240px;
        float: left;
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/estrutura/mobile/decorate-03-02.png');
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: cover;
    }
    .area-04 {
        width: 100%;
        float: left;
        padding-top: 60px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-right: 1px solid rgb(48, 48, 48);
    }
    .area-04 figure {
        width: calc(100% - 30px);
        overflow: hidden;
        height: 550px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 60px;
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/estrutura/mobile/img-04.jpg');
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: cover;
        margin-left: 40px;
    }
    .area-04 img {
        width: 100%;
    }
    .area-text-04 {
        width: calc(100% - 60px) !important;
        float: left;
        margin-left: 40px;
    }
    .area-text-04 h1 {}
    .area-text-04 p {
        color: white;
        font-size: 16px !important;
        margin-top: 20px;
    }
    .area-05 {
        width: 100%;
        padding-top: 80px;
        float: left;
        padding-bottom: 30px;
    }
    .area-05 p {
        width: 100%;
        font-size: 15px !important;
        color: white;
        line-height: 28px !important;
    }
    .decorate-05-01 {
        width: 100%;
        height: 230px;
        margin-top: 55px;
        float: left;
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/estrutura/mobile/decorate-05-01.png');
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: cover;
    }
    .decorate-05-02 {
        width: 100%;
        height: 230px;
        float: left;
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/estrutura/mobile/decorate-05-02.png');
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: cover;
        margin-top: 20px;
    }
    .decorate-05-03 {
        width: 100%;
        height: 230px;
        float: left;
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/estrutura/mobile/decorate-05-03.png');
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: cover;
        margin-top: 20px;
    }
    .area-06 {
        width: 100%;
        padding-top: 50px;
        float: left;
        padding-bottom: 25px;
        padding-right: 25px !important;
        background-color: white;
    }
    .area-06 p {
        width: 100%;
        font-size: 16px !important;
        line-height: 24px !important;
    }
    .bg-olimpus {
        width: 100%;
        height: 600px;
        float: left;
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/estrutura/mobile/bg-olimpus.jpg');
        background-repeat: no-repeat;
        background-position: top;
        background-size: cover;
        margin-top: 54px;
    }
    .area-07 {
        width: 100%;
        padding-top: 0px !important;
        float: left;
        background-color: white;
    }
    .area-bt-slider {
        width: 100%;
        float: left;
        background-color: #11272E;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
    }
    .bt-slider {
        width: calc(25% - 8px);
        float: left;
        background-color: #0c191f;
        border: 0px;
        border-radius: 8px;
        padding-bottom: calc(25% - 8px);
        position: relative;
    }
    .bt-slider div {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0px;
    }
    .bt-slider img {
        width: 66px;
        position: absolute;
    }
    .area-slider {
        width: 100%;
        /* height: 100vh; */
        float: left;
        background-color: #1C3846;
        /* padding-top: 15px; */
        /* padding-left: 120px; */
        /* display: flex; */
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }
    .area-title-07 {
        width: 100%;
        float: left;
        /* height: 200px; */
        /* background-color: red; */
        padding-left: 40px;
        padding-right: 40px;
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .area-title-07 p {
        width: 100%;
        color: white;
        font-size: 15px !important;
        line-height: 23px !important;
    }
    .item-slider {
        width: 100%;
        /* height: calc(100vh - 400px); */
        float: left;
        /* background-color: gray; */
        /* display: flex; */
        /* align-items: center; */
        /* justify-content: space-between; */
    }
    .item-slider figure {
        width: 100%;
        height: 340px;
        float: left;
        /* background-color: rgb(60, 255, 0); */
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }
    .area-controller {
        width: 100%;
        /* height: 100%; */
        /* background-color: orange; */
        float: left;
        display: flex;
        justify-content: center;
        /* flex-direction: column; */
        padding-top: 20px;
        padding-bottom: 30px;
    }
    .area-controller button {
        width: 35px;
        height: 35px;
        float: left;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0px;
        margin: 18px;
        /* margin-bottom: 10px; */
        background-size: 100%;
    }
    .bt-back {
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/estrutura/mobile/bg-back.svg');
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: cover;
    }
    .bt-next {
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/estrutura/mobile/bg-next.svg');
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: cover;
    }
    .area-controller button img {
        width: 40px;
        float: left;
    }
    .item-slider figure img {
        height: 100%;
    }
    .area-08 {
        /* padding-top: 100px; */
        float: left;
        background-color: #072548;
        width: 100%;
        float: left;
        /* padding-bottom: 80px; */
        position: relative;
        height: 100vh;
    }
    .area-08 p {
        width: calc(100% - 40px);
        float: left;
        color: white;
        font-size: 18px !important;
    }
    .decorate-08 {
        width: 100%;
        height: 500px;
        float: left;
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/estrutura/mobile/decorate-08.png');
        background-repeat: no-repeat;
        background-position: right;
        background-size: auto 100%;
        margin-top: -70px;
    }
    .area-09 {
        width: 100%;
        height: 100vh;
        padding-top: 50px;
        float: left;
        padding-top: 50px;
        padding: 15px;
        background-color: #072548;
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/estrutura/mobile/bg-09.jpg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .area-09 h1 {
        width: 100% !important;
        color: white;
        font-size: 38px;
        text-align: center;
    }
    .text-01-09 {
        font-size: 18px !important;
        line-height: 32px !important;
        font-weight: 700;
        color: white;
        text-transform: uppercase;
        text-align: center;
        margin-top: 100px;
    }
    .text-02-09 {
        font-size: 34px !important;
        line-height: 50px !important;
        font-weight: 700;
        color: white;
        text-transform: uppercase;
        text-align: center;
        margin-top: 110px;
    }
    .column-page {
        padding-left: 40px;
        padding-right: 30px;
    }
    #img-slider-02,
    #img-slider-03,
    #img-slider-04 {
        display: none
    }
    .area-10 {
        width: 100%;
        min-height: 100vh;
        padding-top: 50px;
        float: left;
        padding-top: 50px;
        padding: 50px;
        background-color: #072548;
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/estrutura/mobile/bg-10.jpg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    .area-10 h1 {
        width: 100% !important;
        color: white;
    }
    .area {
        width: 100%;
        float: left;
    }
    #item-video-08-mobile {
        height: 100%;
        width: 100%;
        object-fit: cover;
        position: absolute;
        right: 0px;
        bottom: 0px;
    }
    #item-video-08 {
        display: none
    }
    .area-video-08 {
        width: 100%;
        position: absolute;
        height: 100%;
        left: 0px;
        top: 0px;
        z-index: 8;
        overflow: hidden;
    }
    .content-08 {
        width: 100%;
        position: absolute;
        height: 100vh;
        left: 0px;
        top: 0px;
        z-index: 9;
        padding-top: 80px;
    }
    .area-08 h1,
    .area-08 p {
        opacity: 0;
        transition: all 0.8s ease;
    }
}

@media screen and (min-width: 1200px) and (max-width:1600px) {
    body {
        height: 13602px;
    }
}

@media screen and (min-width: 1600px) and (max-width:1910px) {
    body {
        height: 14002px;
    }
}

@media screen and (min-width: 1910px) and (max-width:2130px) {
    body {
        height: 14601px;
    }
}

@media screen and (min-width: 2130px) and (max-width:2400px) {
    body {
        height: 15003px;
    }
}

@media screen and (min-width: 2130px) {
    body {
        height: 15247px;
    }
}
`;