import styled from "styled-components";

export const Container = styled.main `
@media screen and (min-width: 1200px) {
    /* ------------------- INÍCIO - DESKTOP ------------------- */
    .post-default {
        width: calc(25% - 45px);
        margin-right: 45px;
        float: left;
        position: relative;
        cursor: pointer;
    }
    .post-default img {
        width: auto !important;
        height: 100%;
        opacity: 0.7;
        border-radius: 15px;
        transition: all 0.2s linear;
    }
    .post-default figure {
        width: 100%;
        height: 291px !important;
    }
    .post-default figure p {
        width: 100%;
        float: left;
        padding: 22px;
        position: absolute;
        top: 0px;
        font-size: 22px;
        font-weight: 700;
        line-height: 32px;
        margin: 0px;
        display: block;
        white-space: nowrap;
    }
    .post-default img:hover {
        height: 108%;
        width: auto !important;
    }
    .post-default p {
        width: 100% !important;
        float: left;
        font-size: 14px;
        margin-top: 20px;
        line-height: 22px;
    }
    .area-posts-home {
        width: calc(100% + 45px);
        float: left;
        margin-top: 35px;
    }
    .area-blog .bt-link {
        margin-top: 80px;
    }
    .area-blog .bt-link p {
        color: #2998FA !important;
    }
    .box-about h2,
    .box-about .bt-link p,
    .box-about .bt-link img {
        color: white;
        filter: brightness(5.0) grayscale(1.0);
    }
    .box-ads-1 h2,
    .box-ads-2 h2 {
        font-size: 28px !important;
        line-height: 37px !important;
    }
    .box-ads-1 p {
        width: 54% !important;
        font-size: 15px !important;
        line-height: 22px !important;
    }
    .box-default p {
        width: 74%;
        font-size: 16px;
        float: left;
        font-weight: 300;
        margin-top: 12px;
    }
    .box-about {
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/home/desktop/bg-about.jpg');
        background-size: cover;
        background-position: left;
        background-repeat: no-repeat;
        color: white;
        background-size: 115%;
        background-position-x: -22px;
        background-color: rgb(189, 189, 189) !important;
    }
    .box-manifest {
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/home/desktop/bg-manifest.jpg');
        background-size: cover;
        background-position: right;
        background-repeat: no-repeat;
        color: white
    }
    .box-ads-1 {
        width: calc(100% - 495px);
        height: 480px;
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/home/desktop/image-vr.png');
        background-size: 346px;
        background-position: bottom;
        background-repeat: no-repeat;
        background-position-x: 403px;
    }
    .box-ads-2 {
        width: 480px;
        height: 480px;
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/home/desktop/icons-products.png');
        background-size: 460px;
        background-position: bottom right;
        background-repeat: no-repeat;
        background-position-y: 257px;
    }
    .box-ads-3 {
        width: 100%;
        margin-top: 15px;
        padding-top: 100px;
        height: 480px;
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/home/desktop/bg-jx.png');
        background-size: auto 100%;
        background-position: bottom right;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .box-ads-3 p {
        width: 35% !important;
    }
    .align-content-box {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        padding-top: 60px;
    }
    .area-about .box-default {
        width: calc(50% - 9px);
        padding-bottom: calc(50% - 9px);
        float: left;
        position: relative;
        z-index: 8;
        padding-top: 0px !important;
    }
    .area-about .align-boxes,
    .area-ads .align-boxes {
        display: flex;
        justify-content: space-between;
    }
    .area-right-tasks {
        width: 300px;
        background-color: rgb(12 12 12 / 37%);
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .area-bg-initial {
        width: 100%;
        height: 100%;
        background-color: black;
        top: 0px;
        position: absolute;
        display: flex;
        justify-content: center;
    }
    .area-bg-initial video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .area-social-initial a {
        width: 80px;
        height: 100%;
        border-right: 1px solid rgb(255 255 255 / 14%);
        float: left;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .area-social-initial img {
        height: 23px;
    }
    .area-social-initial a:hover {
        background-color: rgba(0, 0, 0, 0.349);
    }
    .area-right-tasks p {
        float: left;
        color: white;
        font-size: 14px;
        font-weight: 700;
        margin-right: 10px;
    }
    .area-right-tasks:hover {
        opacity: 0.6;
    }
    .center-tasks {
        width: calc(100% - 660px);
        height: 100%;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;
        padding-left: 41px;
    }
    .center-tasks .title-item {
        font-weight: 700;
        font-size: 15px;
        color: white;
    }
    .center-tasks .subtitle-item {
        font-weight: 400;
        font-size: 14px;
        color: white;
        line-height: 17px;
        font-family: 'sf';
    }
    .subtitle-item p {
        font-family: 'sf';
    }
    .area-intro h1 {
        color: white;
        margin-top: 45px;
    }
    .area-intro label {
        color: #2FCFE9;
        font-size: 54px;
        line-height: 68px;
        font-weight: 700;
    }
    .area-intro {
        width: 100%;
        height: 100vh;
        float: left;
        background-color: #353535;
        position: relative;
        z-index: 8;
        margin-top: -24px;
        transition: all 0.4s ease;
    }
    .area-intro .area-title {
        width: 100%;
        height: calc(100% - 80px);
        float: left;
        display: flex;
        justify-content: center;
        position: relative;
    }
    .area-about {
        width: 100%;
        float: left;
        background-color: #fff;
        display: flex;
        justify-content: center;
        padding-top: 25px;
        padding-bottom: 25px;
        position: relative;
        z-index: 8;
        box-shadow: rgb(0 0 0) 0px 5px 50px;
    }
    .area-ads {
        width: 100%;
        float: left;
        background-color: #000;
        display: flex;
        justify-content: center;
        padding-top: 25px;
        padding-bottom: 25px;
    }
    .area-ads .box-default {
        background-color: #111111;
    }
    .area-ads h2,
    .area-ads p {
        color: white;
    }
    .box-ads-3 p {
        width: 50% !important;
        color: white;
    }
    .area-blog p {
        width: 40%;
        color: white;
    }
    .area-blog {
        width: 100%;
        float: left;
        background-color: #000;
        display: flex;
        justify-content: center;
        padding-bottom: 100px;
        padding-top: 50px;
        color: white;
    }
    .bar-tasks {
        width: 100%;
        float: left;
        height: 80px;
        background-color: rgb(58 58 58 / 20%);
        -webkit-backdrop-filter: saturate(180%) blur(20px);
        backdrop-filter: saturate(180%) blur(20px);
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .area-scroll {
        width: 50px;
        height: calc(100vh - 540px);
        float: left;
        background-color: gray;
        position: absolute;
        left: 0px;
        bottom: 0px;
        z-index: 9999;
        visibility: hidden;
    }
    .scroll-01-home {
        width: 100%;
        height: calc(100vh - 498px);
        float: left;
        background-color: red;
        position: absolute;
        bottom: 0px;
    }
    .area-fixed-home {
        width: 100%;
        position: fixed;
        top: calc(100vh - 546px);
        background-color: black;
        z-index: 5;
    }
    .border-decoration {
        width: 100%;
        height: 25px;
        background-color: rgb(0, 0, 0);
        float: left;
        position: relative;
        z-index: 9996;
        box-shadow: rgb(0 0 0) 0px 5px 50px;
    }
    .box-ads-1 p,
    .box-ads-2 p,
    .box-ads-3 p {
        color: rgb(173, 173, 173)
    }
    .box-about p {
        width: 60%;
    }
    .scroll-02-home {
        width: 100%;
        height: 100vh;
        float: left;
        background-color: green;
        left: 30px;
        bottom: 0px;
        position: absolute;
    }
    .box-ads-1,
    .box-ads-2 {
        transition: all 0.4s ease;
    }
    .privacy-active .area-intro {
        height: calc(100vh - 80px);
    }
    .privacy-active .chat-fixed {
        bottom: 100px;
    }
    .bt-link p {
        width: auto !important;
        font-size: 12px !important;
        color: #2998FA !important;
        font-weight: 700;
        text-transform: uppercase;
        float: left;
        margin: auto !important;
    }
}


/* ------------------- FINAL - DESKTOP ------------------- */


/* ------------------- INÍCIO - MOBILE ------------------- */

@media screen and (max-width: 1199px) {
    h2 {
        font-size: 24px !important;
        line-height: 33px !important;
    }
    .post-default {
        width: 230px;
        margin-right: 20px;
        float: left;
        position: relative;
        cursor: pointer;
    }
    .post-default img {
        width: 100%;
        opacity: 0.7;
        border-radius: 15px;
    }
    .post-default figure {
        width: 100%;
    }
    .post-default figure p {
        width: 100%;
        float: left;
        padding: 22px;
        position: absolute;
        top: 0px;
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        margin: 0px;
        font-weight: 600 !important;
    }
    .post-default figure p {
        color: white !important
    }
    .post-default p {
        width: 100% !important;
        float: left;
        font-size: 13px;
        margin-top: 16px;
        color: #a7a7a7;
    }
    .area-blog .bt-link {
        margin-top: 40px;
    }
    .box-about h2,
    .box-about .bt-link p,
    .box-about .bt-link img {
        color: white;
        filter: brightness(5.0) grayscale(1.0);
    }
    .box-default p {
        width: 100%;
        font-size: 13px;
        float: left;
        font-weight: 300;
        margin-top: 12px;
    }
    .bt-link-video p {
        color: #2998FA !important;
        margin-top: 0px !important;
    }
    .bt-link-video {
        margin-top: 45px !important;
        float: left;
    }
    .box-about {
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/home/desktop/bg-about.jpg');
        background-size: 130%;
        background-position: left center;
        background-repeat: no-repeat;
        color: white;
    }
    .box-manifest {
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/home/desktop/bg-manifest.jpg');
        background-size: cover;
        background-position: right;
        background-repeat: no-repeat;
        color: white
    }
    .box-ads-1 {
        width: 100%;
        height: 385px;
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/home/desktop/image-vr.png');
        background-repeat: no-repeat;
        background-size: 243px;
        background-position: calc(100% + 111px) calc(100% + 59px);
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }
    .box-ads-1 p {
        display: none
    }
    .box-ads-2 {
        width: 100%;
        min-height: 365px;
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/home/mobile/icons-products.png');
        background-size: calc(100% - 76px);
        background-position: bottom right;
        background-repeat: no-repeat;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }
    .area-posts-home .bt-link img {
        filter: brightness(5.0) grayscale(1.0);
    }
    .box-ads-3 {
        width: 100%;
        margin-top: 15px;
        padding-top: 33px;
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/home/mobile/bg-jx.png');
        background-size: auto 100%;
        background-position: right;
        background-repeat: no-repeat;
    }
    .area-about .box-default {
        width: 100%;
        height: 385px;
        float: left;
        margin-bottom: 16px;
        padding-bottom: 34px;
        position: relative;
    }
    .area-about .align-boxes,
    .area-right-tasks {
        width: 100%;
        background-color: rgb(12 12 12 / 37%);
    }
    .area-bg-initial {
        width: 100%;
        height: 100%;
        background-color: black;
        top: 0px;
        position: absolute;
        display: flex;
        justify-content: center;
    }
    .area-bg-initial video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .area-social-initial {
        display: none;
    }
    .area-right-tasks p {
        float: left;
        color: white;
        font-size: 14px;
        font-weight: 700;
        margin-right: 10px;
        display: none;
    }
    .area-right-tasks:hover {
        opacity: 0.6;
    }
    .center-tasks {
        width: calc(100% - 50px);
        height: 100%;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;
        padding-left: 23px;
    }
    .center-tasks .title-item {
        font-weight: 700;
        font-size: 13px;
        color: white;
        width: 100%;
    }
    .center-tasks .subtitle-item {
        font-weight: 400;
        font-size: 11px;
        color: white;
        font-family: 'sf';
    }
    .area-intro h1 {
        color: white;
        font-size: 28px;
        line-height: 40px;
    }
    .area-intro label {
        color: #2FCFE9;
        font-weight: 700;
        font-size: 28px;
        line-height: 50px;
        -webkit-background-clip: text;
        -webkit-text-fill-color: #2FCFE9 !important;
        background: transparent;
    }
    .area-intro {
        width: 100%;
        height: 100vh;
        float: left;
        background-color: #353535;
        position: relative;
        z-index: 9;
    }
    .area-intro .area-title {
        width: 100%;
        height: calc(100% - 70px);
        float: left;
        display: flex;
        justify-content: center;
        position: relative;
    }
    .area-about {
        width: 100%;
        float: left;
        background-color: #fff;
        display: flex;
        justify-content: center;
        padding-top: 25px;
        padding-bottom: 10px;
        position: relative;
        z-index: 9;
        box-shadow: rgb(0 0 0) 0px 5px 750px;
    }
    .area-ads {
        width: 100%;
        float: left;
        background-color: #000;
        display: flex;
        justify-content: center;
        padding-top: 25px;
        padding-bottom: 25px;
    }
    .area-ads .box-default {
        background-color: #1b1b1b;
    }
    .area-ads h2 {
        color: white;
    }
    .text-blog-area,
    .area-ads p {
        margin-top: 10px;
        color: rgb(194, 194, 194) !important;
        font-size: 12px;
        line-height: 18px;
    }
    .area-right-tasks {
        width: 70px;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .area-blog p {
        width: 100%;
    }
    .area-blog {
        width: 100%;
        float: left;
        background-color: #000;
        display: flex;
        justify-content: center;
        padding-bottom: 40px;
        padding-top: 20px;
        color: white;
    }
    .bar-tasks {
        width: 100%;
        float: left;
        height: 70px;
        background-color: rgb(58 58 58 / 20%);
        -webkit-backdrop-filter: saturate(180%) blur(20px);
        backdrop-filter: saturate(180%) blur(20px);
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .box-about p {
        width: calc(100% - 40px);
    }
    .box-ads-3 p {
        width: calc(100% - 70px);
    }
    .box-ads-1 .bt-link p {
        color: #2998FA !important
    }
    .box-ads-2 .bt-link p {
        color: #2998FA !important
    }
    .box-ads-3 .bt-link p {
        color: #2998FA !important
    }
    .align-content-box {
        height: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column
    }
    .area-posts-home {
        width: calc(100% + 41px);
        float: left;
        overflow-x: scroll;
        margin-top: 30px;
        margin-left: -20px;
        padding-left: 20px;
    }
    .align-posts-home {
        width: auto;
        float: left;
        display: flex;
    }
    .text-blog-area {
        margin-top: 10px
    }
    .area-scroll {
        width: 50px;
        height: calc(100vh - 542px);
        float: left;
        background-color: gray;
        position: absolute;
        left: 0px;
        bottom: 0px;
        z-index: 9999;
        visibility: hidden;
    }
    .scroll-01-home {
        width: 100%;
        height: 100%;
        float: left;
        background-color: red;
        position: absolute;
        bottom: 0px;
    }
    .area-fixed-home {
        width: 100%;
        position: fixed;
        top: calc(100vh - 546px);
        background-color: black;
        z-index: 5;
    }
    .area-title .container-medium {
        width: calc(100% - 60px);
    }
    .loading {
        width: 100%;
        float: left;
        height: 100%;
        position: fixed;
        background-color: #000000;
        background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/loading.gif');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 180px;
        z-index: 10000;
        opacity: 0.95;
    }
    .all-menu {
        position: absolute;
    }
    .privacy-active .area-intro {
        height: calc(100vh - 80px);
    }

    .box-ads-3 {
        min-height:365px
    }

    .box-ads-3 .bt-link {
        position:absolute;
        bottom:30px
    }
    /* ------------------- FINAL - MOBILE ------------------- */
}

/* ------------------- INÍCIO - ALTERAÇÕES MOBILE ------------------- */
@media screen and (max-width: 700px) {
    .subheader-webhelp {
        width: 100%;
        height: 0px;
        float: left;
        background-position: top;
        background-size: 100%;
        background-repeat: no-repeat;
        margin-top: 50px;
        position: relative;
        padding-bottom: 77%;
        background-image: url("https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/webhelp/webhelp-mobile.png");
    }
    .subheader-webhelp.pt {
        background-image: url("https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/webhelp/webhelp-mobile.png") !important;
    }
    .subheader-webhelp.pt-BR {
        background-image: url("https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/webhelp/webhelp-mobile.png") !important;
    }
    .subheader-webhelp.en {
        background-image: url("https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/webhelp/en/webhelp-mobile.png") !important;
    }
    .subheader-webhelp.en-US {
        background-image: url("https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/webhelp/en/webhelp-mobile.png") !important;
    }
    .subheader-webhelp img {
        height: 100%;
    }
    .area-fixed-home {
        width: 100%;
        position: relative !important;
        top: 0px !important;
        background-color: black;
        z-index: 5;
    }
}
/* ------------------- FINAL - ALTERAÇÕES MOBILE ------------------- */
/* ------------------- INÍCIO - ALTERAÇÕES TABLET ------------------- */
@media screen and (min-width: 701px) and (max-width: 1199px) {
    .subheader-webhelp {
        width: 100%;
        height: 0px;
        float: left;
        background-position: top;
        background-size: 100%;
        background-repeat: no-repeat;
        margin-top: 50px;
        position: relative;
        padding-bottom: 48%;
        background-image: url("https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/webhelp/webhelp-tablet.png");
    }
    .subheader-webhelp.pt {
        background-image: url("https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/webhelp/webhelp-tablet.png") !important;
    }
    .subheader-webhelp.pt-BR {
        background-image: url("https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/webhelp/webhelp-tablet.png") !important;
    }
    .subheader-webhelp.en {
        background-image: url("https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/webhelp/en/webhelp-tablet.png") !important;
    }
    .subheader-webhelp.en-US {
        background-image: url("https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/webhelp/en/webhelp-tablet.png") !important;
    }
    .subheader-webhelp img {
        height: 100%;
    }
    .area-fixed-home {
        width: 100%;
        position: relative !important;
        top: 0px !important;
        background-color: black;
        z-index: 5;
    }
}
/* ------------------- FINAL - ALTERAÇÕES TABLET ------------------- */
/* ------------------- INÍCIO - ALTERAÇÕES DESKTOP ------------------- */
@media screen and (min-width: 1200px) {
    .subheader-webhelp {
        width: 100%;
        height: 370px;
        float: left;
        z-index: 9900;
        background-position: center;
        background-size: auto 100%;
        background-repeat: no-repeat;
        margin-top: 20px;
        position: relative;
        background-image: url("https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/webhelp/webhelp-desktop.png");

    }
    .subheader-webhelp.pt {
        background-image: url("https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/webhelp/webhelp-desktop.png") !important;
    }
    .subheader-webhelp.pt-BR {
        background-image: url("https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/webhelp/webhelp-desktop.png") !important;
    }
    .subheader-webhelp.en {
        background-image: url("https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/webhelp/en/webhelp-desktop.png") !important;
    }
    .subheader-webhelp.en-US {
        background-image: url("https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/webhelp/en/webhelp-desktop.png") !important;
    }
    .subheader-webhelp img {
        height: 100%;
    }
    .area-fixed-home {
        width: 100%;
        position: relative !important;
        top: 0px !important;
        background-color: black;
        z-index: 5;
    }
}
/* ------------------- FINAL - ALTERAÇÕES DESKTOP ------------------- */
}

`;