import styled from "styled-components";

export const Container = styled.div `
    @media screen and (min-width: 1200px) {
        .area-left-cx {
            width: 671px;
            float: left;
            /* background-color: red; */
        }
        .area-right-cx {
            width: 520px;
            float: left;
            /* background-color: pink; */
        }
        .area-left-cx .border-title {
            max-width: 300px !important;
        }
        .video-cx-jx img {
            width: 90px;
        }
        .video-cx-jx {
            width: 100%;
            float: left;
            margin-top: 90px;
            height: 700px;
            background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/institucional/thumb-jx.jpg');
            background-size: 100%;
            background-repeat: no-repeat;
            cursor: pointer;
            transform: scale(1.0);
            transition: all 0.4s linear;
            opacity: 1.0;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .video-cx-jx:hover {
            transform: scale(1.01);
            opacity: 0.9;
        }
        .initial-corporate .container-medium {
            display: flex;
            justify-content: space-between !important;
            align-items: flex-end !important;
            flex-direction: initial !important;
        }
        .initial-corporate {
            height: calc(100vh - 80px) !important;
            min-height: 800px !important;
            padding-bottom: 100px;
            background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/institucional/bg-initial.jpg');
            background-size: 120%;
            background-position: center 0%;
            transition: all 1.0s cubic-bezier(0.25, 0.1, 0.53, 1.0);
        }
        .initial-corporate li {
            font-size: 18px;
            font-weight: 700;
            line-height: 41px;
            list-style: none;
            cursor: pointer;
            transition: all 0.2s linear;
        }
        .initial-corporate li:hover {
            opacity: 0.6;
        }
        .initial-corporate h2 {
            font-size: 56px;
            cursor: pointer;
            transition: all 0.2s linear;
        }
        .initial-corporate h2:hover {
            opacity: 0.6;
        }
        .initial-corporate img {
            margin-left: 10px;
            width: 46px;
        }
        .area-about {
            width: 100%;
            float: left;
            display: flex;
            justify-content: center;
            padding-top: 120px;
            padding-bottom: 120px;
        }
        .area-about h2 {
            color: black
        }
        .area-about .area-title {
            width: 223px;
            float: left;
        }
        .col-2-text {
            width: 485px;
            float: left;
            /* padding-right: 87px; */
            padding-left: 60px;
        }
        .col-3-text {
            width: 485px;
            float: left;
            padding-left: 60px;
        }
        .col-1-text {
            width: calc(100% - 223px);
            float: left;
            padding-left: 60px;
        }
        .part-2-column {
            float: left;
            margin-top: 80px !important;
        }
        .area-about p {
            font-weight: 300;
        }
        .area-boxes {
            width: 100%;
            float: left;
            display: flex;
            justify-content: center;
            position: relative;
            z-index: 3;
        }
        .area-box-about {
            width: calc(25% - 10px);
            float: left;
            /* background-color: red; */
        }
        .area-boxes .container-large {
            display: flex;
            justify-content: space-between;
        }
        .item-box-about {
            width: 100%;
            float: left;
            background-color: white;
            min-height: 200px;
            border-radius: 20px;
            padding: 43px;
            font-weight: 300;
            font-size: 20px;
            line-height: 31px;
            /* padding-bottom: 100px; */
            color: black;
            margin-top: 18px;
            box-shadow: 0px 4px 20px 0px rgb(0 0 0 / 8%);
        }
        .area-box-about p {
            width: 100%;
            float: left;
            font-weight: 700;
            text-align: center;
            color: #032648;
            font-size: 20px;
        }
        .area-lettering {
            width: 100%;
            float: left;
            background-color: #91E6F3;
            padding-top: 352px;
            padding-bottom: 343px;
            margin-top: -210px;
            position: relative;
            z-index: 2;
            overflow: hidden;
        }
        .area-lettering label {
            float: left;
            font-size: 280px;
            font-weight: 700;
            line-height: 270px;
            color: #4A869D;
        }
        .area-lettering p {
            float: left;
            font-size: 280px;
            font-weight: 700;
            line-height: 270px;
            color: #82D2E1;
            transition: all 0.4s linear !important;
        }
        .align-lettering {
            width: 6000px;
            float: left;
        }
        .area-mission {
            width: 100%;
            float: left;
            min-height: 400px;
            display: flex;
            justify-content: center;
            padding-bottom: 160px;
            position: relative;
            z-index: 5;
        }
        .box-default-mission {
            width: 480px;
            height: 480px;
            float: left;
            background-color: #032648;
            border-radius: 20px;
            position: relative;
            color: #fff;
            cursor: pointer;
        }
        .box-vision {
            background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/institucional/bg-vision.jpg');
            background-size: cover;
        }
        .box-mission {
            background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/institucional/bg-mission.jpg');
            background-size: cover;
        }
        .box-value {
            background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/institucional/bg-values.jpg');
            background-size: cover;
        }
        .box-default-mission .border-title {
            opacity: 0;
            width: 0px;
            transition: all 0.2s linear;
        }
        .box-default-mission:hover p {
            opacity: 1.0;
            transform: translatey(0px);
        }
        .box-default-mission:hover {
            background-color: #032648;
            background-image: none;
        }
        .box-default-mission:hover .border-title {
            opacity: 1.0;
            width: 100%;
            transition: all 0.5s linear;
        }
        .box-default-mission h2 {
            transform: translatey(30px);
            transition: all 0.2s linear;
        }
        .box-default-mission:hover h2 {
            transform: translatey(0px);
        }
        .box-default-mission p {
            width: 82%;
            color: #fff;
            padding: 60px;
            font-size: 26px;
            line-height: 40px;
            font-weight: 400;
            margin-top: 24px;
            opacity: 0;
            transition: all 0.2s linear;
            transform: translatey(30px);
        }
        .name-box {
            width: 100%;
            float: left;
            position: absolute;
            bottom: 0px;
            padding: 60px;
        }
        .align-box-mission {
            display: flex;
            justify-content: flex-start;
            margin-top: -300px;
        }
        .align-box-vision {
            display: flex;
            /* justify-content: flex-end; */
            margin-top: -116px;
            margin-left: 518px;
        }
        .align-box-value {
            display: flex;
            justify-content: flex-start;
            margin-top: -335px;
        }
        .area-cx {
            width: 100%;
            min-height: 800px;
            float: left;
            background-color: #F7F7F7;
            display: flex;
            justify-content: center;
            padding-top: 150px;
            padding-bottom: 150px;
            color: white;
        }
        .area-cx h1 {
            font-size: 55px;
            line-height: 80px;
            color: #032648;
        }
        .area-cx h3 {
            width: 550px;
            font-size: 18px;
            font-weight: 500;
            line-height: 27px;
            /* margin-top: 20px; */
            color: #032648;
        }
        .area-boxes-cx {
            display: flex;
            justify-content: space-between;
            margin-top: 40px;
            flex-wrap: wrap;
        }
        .box-default-cx {
            width: 290px;
            height: 290px;
            float: left;
            background-color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin-bottom: 10px;
            margin-left: 10px;
        }
        .box-default-cx p {
            width: 100%;
            float: left;
            text-align: center;
            font-weight: 700;
            margin-top: 20px;
            font-size: 16px;
            padding-left: 20px;
            padding-right: 20px;
            line-height: 24px;
            color: #032648;
            transition: all 0.2s linear;
        }
        .box-default-cx:hover {
            background-color: #2FCFE9;
        }
        .box-default-cx img {
            /* filter: brightness(5.0) grayscale(1.0); */
            width: 55px;
            transition: all 0.2s linear;
        }
        .box-default-cx:hover img {
            filter: brightness(1.0) grayscale(0);
            width: 60px;
        }
        .box-default-cx:hover p {
            color: #032648;
            font-size: 18px;
        }
        .area-culture {
            width: 100%;
            min-height: 100vh;
            float: left;
            background-color: black;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 100px;
            padding-bottom: 100px;
            background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/institucional/bg-culture.jpg');
            background-position: right center;
            background-size: cover;
            color: #fff;
            text-align: right;
        }
        .area-culture .container-medium {
            display: flex;
            align-items: flex-end;
            flex-direction: column;
            height: 100%;
            justify-content: center;
        }
        .area-culture .align-border {
            display: flex;
            justify-content: flex-end;
        }
        .area-culture p {
            width: 420px;
            text-align: right;
            display: flex;
            justify-content: flex-end;
            margin-top: 40px;
            font-size: 22px;
            font-weight: 300;
            line-height: 35px;
            margin-top: 40px;
        }
        .area-culture h1 {
            color: white;
            text-align: right;
        }
        .area-culture .border-title {
            width: 440px;
        }
        .area-culture .bt-link {
            margin-top: 50px
        }
        .area-manifest {
            width: 100%;
            min-height: 800px;
            float: left;
            background-color: white;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding-top: 150px;
            /* padding-bottom: 100px; */
            position: relative;
        }
        .area-manifest h1 {
            font-size: 54px;
            line-height: 90px;
            color: #86868B;
        }
        .align-content-main {
            opacity: 1.0;
            transition: opacity 1.0s linear;
        }
        .thumb-manifest:hover {
            box-shadow: 5px 9px 25px 0px rgba(0, 0, 0, 0.25);
            transform: scale(1.01);
        }
        .area-manifest p {
            font-size: 22px;
            font-weight: 300;
            line-height: normal;
            margin-top: 30px;
            color: black;
        }
        .bt-link-video p {
            width: auto !important;
            font-size: 12px;
            color: #2998FA;
            font-weight: 700;
            text-transform: uppercase;
            float: left;
            margin: auto !important;
        }
        .thumb-manifest .bt-play {
            top: auto !important;
        }
        .area-segments {
            width: 100%;
            min-height: 800px;
            float: left;
            background-color: #032648;
            padding-top: 150px;
            padding-bottom: 150px;
            position: relative;
        }
        .area-segments h1 {
            font-size: 70px;
            line-height: 100px;
        }
        .area-slider-segments {
            width: 100%;
            float: left;
            margin-top: 130px;
            overflow: hidden;
        }
        .overflow-segments {
            width: auto;
            float: left;
            display: flex;
            padding-left: 20px;
            padding-right: 60px;
        }
        .box-default-segment {
            width: 300px;
            height: 300px;
            float: left;
            background-color: #031A31;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin-bottom: 10px;
            margin: 6px
        }
        .box-default-segment p {
            width: 100%;
            float: left;
            text-align: center;
            font-weight: 600;
            margin-top: 30px;
            font-size: 22px;
            padding-left: 20px;
            padding-right: 20px;
            line-height: 30px;
            transition: all 0.2s linear;
            color: white;
        }
        .box-default-segment img {
            width: 50px;
            filter: grayscale(1.0) brightness(6.0);
        }
        .box-default-segment:hover {
            background-color: #2FCFE9;
        }
        .box-default-segment:hover p {
            color: #031A31;
        }
        .box-default-segment:hover img {
            filter: grayscale(1.0) brightness(1.0);
        }
        .bt-left-segments {
            width: 120px;
            height: 330px;
            position: absolute;
            top: 483px;
            left: 0px;
            z-index: 5;
            border: 0px;
            background: linear-gradient(to right, rgb(3 37 73) 0%, rgba(0, 0, 0, 0) 100%);
        }
        .bt-right-segments {
            width: 120px;
            height: 330px;
            position: absolute;
            top: 457px;
            right: 0px;
            z-index: 5;
            border: 0px;
            background: linear-gradient(to left, rgb(3 37 73) 0%, rgba(0, 0, 0, 0) 100%);
        }
        .area-info-culture {
            width: 100%;
            min-height: 100vh;
            float: left;
            background-color: black;
            display: flex;
            justify-content: center;
            padding-top: 100px;
            padding-bottom: 100px;
            align-items: center;
            color: white;
            background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/institucional/bg-info-culture.jpg');
            background-size: cover;
            background-position: center;
        }
        .area-info-culture p {
            width: 660px;
            text-align: left;
            margin-top: 40px;
            font-size: 22px;
            font-weight: 300;
            line-height: 40px;
            margin-top: 40px;
        }
        .box-world {
            width: calc(50% - 600px + 713px);
            height: 500px;
            background-color: rgb(0, 0, 0);
            position: absolute;
            right: 0px;
            border-radius: 40px 0px 0px 40px;
            background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/institucional/bg-planet.jpg');
            background-size: cover;
            background-position: left;
            padding: 75px;
        }
        .box-world h1 {
            color: #fff;
            font-size: 76px;
            line-height: 92px;
            margin-top: 22px
        }
        .box-world .border-title {
            width: 220px;
            height: 20px;
        }
        .bg-blue-01 {
            background-color: #2FCFE9;
        }
        .bg-blue-02 {
            background-color: #C1EFF7;
        }
        .bg-blue-03 {
            background-color: #FFFFFF;
        }
        .box-solutions .container-medium {
            width: 85% !important;
        }
        .area-manifest .container-medium {}
        .thumb-manifest {
            width: 100%;
            float: left;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 100px;
            position: sticky;
            top: 0px;
            background-color: black;
            background-size: cover;
            /* box-shadow: 5px 9px 25px 0px rgb(0 0 0 / 10%); */
            transition: all 0.2s ease;
            cursor: pointer;
            border: 50px solid white;
            /* background-color: white; */
            transition: all 0.4s linear;
            padding-top: 0px !important;
            overflow: hidden;
        }
        .thumb-manifest video {
            width: 100%;
            object-fit: cover;
            opacity: 0.8;
        }
        .area-video-manifest {
            width: 100%;
            height: 1200px;
            float: left;
        }
        .scroll-manifest {
            width: 50px;
            height: 100vh;
            position: absolute;
            /* background-color: red; */
            left: 0px;
            top: -30vh;
        }
        .bt-view {
            position: absolute;
            width: 403px;
            height: 50px;
            bottom: 300px;
            left: calc(50vw - 150px);
            filter: brightness(5.0);
        }
        .bt-view p {
            font-size: 24px !important;
        }
        .bt-view img {
            width: 25px !important;
            margin-left: 15px !important;
        }
        .area-info-culture h1 {
            line-height: 80px;
        }
        .area-manifest {
            text-align: center;
        }
        .area-manifest .border-title {
            width: 380px;
            margin-top: 0px;
            margin-bottom: 20px;
        }
        .area-slider-segments {
            scrollbar-width: none !important;
        }
        /* <!------------------   PLANETA ------------------> */
        .area-world {
            width: 100%;
            min-height: 200vh;
            float: left;
            transition: all 1.0s cubic-bezier(0.25, 0.1, 0.53, 1.0);
            padding-bottom: 100px;
            padding-top: 100px;
            background: linear-gradient( 45deg, #1e616c 0%, #030c14 55%, #04294a 100%);
        }
        .scroll-planet {
            width: 50px;
            height: 100vh;
            position: absolute;
            /* background-color: orange; */
            top: 0vh;
            right: 0px;
            z-index: 99999;
        }
        .scroll-planet-2 {
            width: 50px;
            height: 100vh;
            position: absolute;
            /* background-color: green; */
            top: 20vh;
            right: 0px;
            z-index: 99999;
        }
        .scroll-planet-3 {
            width: 50px;
            height: 100vh;
            position: absolute;
            /* background-color: pink; */
            top: 100vh;
            right: 0px;
            z-index: 99999;
        }
        section {
            position: relative;
        }
        canvas {
            width: 100%;
            height: auto;
            left: 0px;
        }
        .align-word {
            width: 700px;
            overflow: hidden;
            position: absolute;
            opacity: 0;
            height: 100%;
            padding-top: 90px;
        }
        video {
            object-fit: cover;
        }
        .box-planet video {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
            bottom: 0px;
        }
        .box-planet h1 {
            color: #fff;
            font-size: 76px;
            line-height: 92px;
            margin-top: 22px;
        }
        .align-planet {
            max-width: 100%;
            min-width: calc(50% - 600px + 713px);
            width: calc(100% - 0px + 0px);
            height: 100%;
            position: absolute;
            right: 0px;
            top: 0px;
            transition: all 1.0s cubic-bezier(0.25, 0.1, 0.53, 1.0);
            margin-bottom: 100px;
            padding-bottom: 100px;
        }
        .area-text-world {
            width: 400px;
            height: 400px;
            /* background-color: red; */
            position: absolute;
            left: 0px;
            transition: all 1.0s cubic-bezier(0.25, 0.1, 0.53, 1.0);
        }
        .area-text-world img {
            width: 70px;
            float: left;
        }
        .area-text-world p {
            width: 100%;
            color: white;
            margin-top: 20px;
            font-size: 24px;
            font-weight: 300;
            line-height: 40px;
        }
        .container-text {
            width: 1100px;
            margin-left: calc(50vw - 592px);
            transition: all 1.0s cubic-bezier(0.25, 0.1, 0.53, 1.0);
            position: sticky;
            display: flex;
            align-items: center;
            height: 67vh;
            top: 15vh;
        }
        .blur-planet {
            filter: blur(35px) brightness(1.5);
            margin-left: 300px;
            margin-top: 200px;
        }
        .planet-item {
            width: 1000px;
            height: 1000px;
            border-radius: 100%;
            position: absolute;
            top: 20px;
            left: 415px;
            background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/produtos/aio/planet.jpg');
            background-size: 100%;
            transition: all 1.0s cubic-bezier(0.25, 0.1, 0.53, 1.0);
        }
        .area-mission .container-medium {
            width: 1000px;
            position: relative;
        }
        .align-text-mission {
            position: absolute;
            right: 0px;
            color: white;
        }
        .align-text-mission h1 {
            font-size: 39px !important;
            line-height: 60px;
            margin-top: 100px
        }
        .video-planet-word {
            width: 100%;
            height: 100%;
            position: sticky;
            top: 0px;
            left: 0px;
        }
        .box-planet {
            width: 100%;
            height: 100vh;
            position: sticky;
            overflow: hidden;
            top: 0px;
            border-radius: 40px 0px 0px 40px;
            margin-bottom: 100px;
            background-color: rgb(0, 0, 0);
            border-radius: 40px 0px 0px 40px;
            background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/aio/bg-space.jpg');
            background-attachment: fixed;
            background-size: 100% 100%;
            color: white;
            z-index: 9;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            border-radius: 0px 0px 0px 0px;
            transition: all 1.0s cubic-bezier(0.25, 0.1, 0.53, 1.0);
        }
        .typrography-secondary {
            color: #82D3E1 !important
        }
        #video-arrow {
            opacity: 0;
        }
        #border-planet {
            height: 15px !important;
        }
        .align-word {
            transition: all 1.0s cubic-bezier(0.25, 0.1, 0.53, 1.0);
        }
    }

    @media screen and (max-width: 1199px) {
        .initial-corporate .container-medium {
            display: flex;
            justify-content: space-between;
            flex-direction: column-reverse;
            padding-top: calc(50vh - 83px);
            transition: all 1.0s cubic-bezier(0.25, 0.1, 0.53, 1.0);
        }
        .initial-corporate {
            height: 100vh;
            padding-bottom: 100px;
            background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/institucional/bg-initial.jpg');
            background-size: cover;
            background-position: bottom;
            background-repeat: no-repeat;
            position: relative;
            transition: all 1.0s cubic-bezier(0.25, 0.1, 0.53, 1.0);
        }
        .initial-corporate li {
            font-size: 15px;
            font-weight: 700;
            line-height: 41px;
            list-style: none;
            cursor: pointer;
            transform: translate3d(0px, 0px, 0px) !important;
            transition: all 0.2s linear;
        }
        .typrography-secondary {
            color: #82D3E1 !important
        }
        .initial-corporate li:hover {
            opacity: 0.6;
        }
        .initial-corporate h2 {
            font-size: 30px;
            cursor: pointer;
            transition: all 0.2s linear;
        }
        .initial-corporate h2:hover {
            opacity: 0.6;
        }
        .initial-corporate img {
            margin-left: 10px;
            width: 46px;
            display: none;
        }
        .area-about {
            width: 100%;
            float: left;
            display: flex;
            justify-content: center;
            padding-top: 60px;
            padding-bottom: 77px;
        }
        .area-about h2 {
            color: black;
            margin-bottom: 15px;
        }
        .area-about .area-title {
            width: 100%;
            float: left;
        }
        .col-2-text {
            width: 100%;
            float: left;
        }
        .col-3-text {
            width: 100%;
            float: left;
        }
        .col-1-text {
            width: 100%;
            float: left;
        }
        .part-2-column {
            float: left;
        }
        .part-2-column h2 {
            margin-top: 30px;
            margin-bottom: 20px;
        }
        .area-about p {
            font-weight: 300;
            width: 100%;
            font-size: 14px;
        }
        .area-boxes {
            width: 100%;
            float: left;
            /* display: flex; */
            justify-content: center;
            position: relative;
            z-index: 3;
            overflow-x: scroll;
        }
        .area-box-about {
            width: 245px;
            float: left;
            margin-right: 20px;
        }
        .area-boxes .container-large {
            display: flex;
            width: auto !important;
            float: left;
            padding-left: 37px;
            padding-right: 15px;
            padding-bottom: 20px;
        }
        .item-box-about {
            width: 100%;
            float: left;
            background-color: white;
            border-radius: 20px;
            padding: 33px;
            font-weight: 300;
            font-size: 16px;
            line-height: 26px;
            padding-bottom: 39px;
            color: black;
            margin-top: 18px;
            box-shadow: 0px 4px 20px 0px rgb(0 0 0 / 8%);
        }
        .area-box-about p {
            width: 100%;
            float: left;
            font-weight: 700;
            text-align: center;
            color: #032648;
            font-size: 18px;
        }
        .align-lettering {
            width: 6000px;
            float: left;
        }
        .box-vision {
            background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/institucional/bg-vision.jpg');
            background-size: cover;
        }
        .box-mission {
            background-image: url( 'https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/institucional/bg-mission.jpg');
            background-size: cover;
        }
        .box-value {
            background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/institucional/bg-values.jpg');
            background-size: cover;
        }
        .align-text-mission {
            width: 100%;
            color: white;
            float: left;
            margin-bottom: 25px;
        }
        .area-lettering {
            width: 100vw;
            float: left;
            background-color: #91E6F3;
            padding-top: 282px;
            padding-bottom: 352px;
            margin-top: -215px;
            position: relative;
            z-index: 2;
            overflow: hidden;
        }
        .area-lettering p {
            float: left;
            font-size: 118px;
            font-weight: 700 !important;
            line-height: 109px;
            /* font-family: "grupo-services"; */
            color: #82D2E1;
        }
        .area-lettering label {
            float: left;
            font-size: 134px;
            font-weight: 700;
            line-height: 124px;
            color: #4A869D;
        }
        .area-mission {
            width: 100%;
            float: left;
            min-height: 400px;
            display: flex;
            justify-content: center;
            padding-bottom: 80px;
            position: relative;
            z-index: 5;
        }
        .area-mission .container-medium {
            width: calc(100% - 60px);
        }
        .box-default-mission {
            width: 100%;
            height: 365px;
            float: left;
            background-color: #032648;
            border-radius: 20px;
            position: relative;
            color: #fff;
            cursor: pointer;
        }
        .box-mission {
            background-size: cover;
        }
        .box-value {
            background-size: cover;
        }
        .box-default-mission .border-title {
            width: 0px;
            opacity: 0;
            transition: all 0.2s linear;
        }
        .box-default-mission:hover p {
            opacity: 1.0;
            transform: translatey(0px);
        }
        .box-default-mission:hover {
            background-color: #032648;
            background-image: none;
        }
        .box-default-mission:hover .border-title {
            opacity: 1.0;
            width: 100%;
        }
        .box-default-mission h2 {
            transform: translatey(30px);
            transition: all 0.2s linear;
        }
        .box-default-mission:hover h2 {
            transform: translatey(0px);
        }
        .box-default-mission p {
            width: 100%;
            color: #fff;
            padding: 35px;
            font-size: 20px;
            line-height: 33px;
            font-weight: 400;
            /* margin-top: 24px; */
            opacity: 0;
            transition: all 0.2s linear;
            transform: translatey(30px);
        }
        .name-box {
            width: 100%;
            float: left;
            position: absolute;
            bottom: 0px;
            padding: 35px;
        }
        .align-box-mission {
            /* display: flex; */
            justify-content: flex-start;
            margin-top: -300px;
        }
        .align-box-vision {
            display: flex;
            justify-content: flex-end;
            margin-top: 20px;
        }
        .align-box-value {
            display: flex;
            justify-content: flex-start;
            margin-top: 20px;
        }
        .area-cx {
            width: 100%;
            /* min-height: 800px; */
            float: left;
            background-color: #F7F7F7;
            display: flex;
            justify-content: center;
            padding-top: 70px;
            padding-bottom: 40px;
            color: white;
        }
        .area-cx h1 {
            font-size: 38px;
            line-height: 49px;
            color: #032648;
        }
        .overflow-cx {
            width: auto;
            float: left;
            display: flex;
            padding-left: 20px;
            padding-right: 40px;
        }
        .area-cx h3 {
            width: 100%;
            font-size: 17px;
            font-weight: 300;
            line-height: 26px;
            margin-top: 38px;
            color: #000000;
        }
        .area-left-cx {
            width: 100%;
            float: left;
            /* background-color: red; */
        }
        .area-right-cx {
            width: 100%;
            float: left;
            /* background-color: pink; */
        }
        .video-cx-jx {
            width: calc(100% + 80px);
            float: left;
            margin-top: 60px;
            height: 300px;
            background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/institucional/thumb-jx.jpg');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            cursor: pointer;
            transform: scale(1.0);
            transition: all 0.4s linear;
            opacity: 1.0;
            /* padding-bottom: calc(100% - 33px); */
            margin-left: -40px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .area-boxes-cx {
            width: calc(100% + 80px) !important;
            display: flex;
            justify-content: space-between;
            margin-top: 40px;
            overflow-x: scroll;
            margin-left: -39px;
        }
        .box-default-cx {
            width: 200px;
            height: 200px;
            float: left;
            background-color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin-bottom: 10px;
            margin-left: 7px;
            transition: all 0.4s cubic-bezier(0.25, 0.1, 0.53, 1.0);
        }
        .border-title {
            transition: all 0.4s cubic-bezier(0.25, 0.1, 0.53, 1.0);
        }
        .area-left-cx .border-title {
            max-width: 300px !important;
        }
        .box-default-cx p {
            width: 100%;
            float: left;
            text-align: center;
            font-weight: 700;
            margin-top: 20px;
            font-size: 15px;
            padding-left: 20px;
            padding-right: 20px;
            line-height: 19px;
            transition: all 0.4s cubic-bezier(0.25, 0.1, 0.53, 1.0);
            color: #042648;
        }
        .box-default-cx:hover {
            background-color: #2FCFE9;
        }
        .box-default-cx img {
            /* filter: brightness(5.0) grayscale(1.0); */
            width: 34px;
            transition: all 0.4s cubic-bezier(0.25, 0.1, 0.53, 1.0);
        }
        .box-default-cx:hover img {
            filter: brightness(1.0) grayscale(0);
            width: 36px;
        }
        .box-default-cx:hover p {
            color: #042648;
        }
        .area-culture {
            width: 100%;
            height: 100vh;
            min-height: 640px;
            max-height: 800px;
            float: left;
            background-color: black;
            display: flex;
            justify-content: center;
            padding-bottom: 70px;
            background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/institucional/mobile/bg-culture.jpg');
            background-position: center;
            background-size: cover;
            color: #fff;
            text-align: right;
        }
        .area-culture .container-medium {
            width: calc(100% - 80px);
            display: flex;
            height: 100%;
            align-items: flex-end;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
        }
        .area-culture .align-border {
            display: flex;
            justify-content: flex-end;
        }
        .area-culture p {
            width: 100%;
            text-align: right;
            display: flex;
            justify-content: flex-end;
            margin-top: 40px;
            font-size: 17px;
            font-weight: 300;
            line-height: 28px;
            margin-top: 40px;
        }
        .thumb-manifest video {
            height: 100%;
            object-fit: cover;
            opacity: 0.8;
        }
        .scroll-manifest {
            width: 10px;
            height: 100vh;
            position: absolute;
            left: 0px;
            top: -10vh;
        }
        .area-culture h1 {
            color: white;
            text-align: right;
            font-size: 38px;
            line-height: 57px;
            width: 100%;
            margin-bottom: 13px;
        }
        .area-culture .border-title {
            width: 0px;
        }
        .area-culture .bt-link {
            bottom: 0px;
        }
        .area-manifest {
            width: 100%;
            float: left;
            background-color: white;
            display: flex;
            align-items: center;
            padding-top: 70px;
            /* padding-bottom: 50px; */
            flex-direction: column;
            position: relative;
            overflow: hidden;
        }
        .area-manifest h1 {
            font-size: 34px;
            line-height: 90px;
            color: #86868B;
            text-align: center
        }
        .thumb-manifest {
            background-position: center;
        }
        .area-manifest p {
            font-size: 16px !important;
            font-weight: 300;
            line-height: 23px;
            margin-top: 30px;
            color: #86868b;
            text-align: center;
        }
        .thumb-manifest .bt-play {
            top: auto !important;
        }
        .area-segments {
            width: 100%;
            float: left;
            background-color: #032648;
            padding-top: 70px;
            padding-bottom: 100px;
            position: relative;
        }
        .area-segments h1 {
            font-size: 36px;
            line-height: 56px;
        }
        .area-slider-segments {
            width: 100%;
            float: left;
            margin-top: 91px;
            overflow: scroll;
        }
        .overflow-segments {
            width: auto;
            float: left;
            display: flex;
            padding-left: 40px;
            padding-right: 60px;
        }
        .box-default-segment {
            width: 180px;
            height: 180px;
            float: left;
            background-color: #031A31;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin-bottom: 10px;
            margin: 4px;
        }
        .box-default-segment p {
            width: 100%;
            float: left;
            text-align: center;
            font-weight: 600;
            margin-top: 30px;
            font-size: 16px;
            padding-left: 20px;
            padding-right: 20px;
            line-height: 30px;
            transition: all 0.2s linear;
            color: white;
        }
        .box-default-segment img {
            width: 37px;
            filter: grayscale(1.0) brightness(6.0);
        }
        .box-default-segment:hover {
            background-color: #2FCFE9;
        }
        .box-default-segment:hover p {
            color: #031A31;
        }
        .box-default-segment:hover img {
            filter: grayscale(1.0) brightness(1.0);
        }
        .bt-left-segments {
            width: 40px;
            height: 220px;
            position: absolute;
            left: 0px;
            z-index: 5;
            background-color: transparent;
            border: 0px;
            top: calc(100% - 295px);
        }
        .bt-left-segments img,
        .bt-right-segments img {
            width: 8px;
        }
        .bt-right-segments {
            width: 40px;
            height: 220px;
            position: absolute;
            top: calc(100% - 295px);
            right: 0px;
            z-index: 5;
            border: 0px;
            background-color: transparent;
        }
        .area-info-culture {
            width: 100%;
            float: left;
            background-color: black;
            display: flex;
            justify-content: center;
            padding-top: 100px;
            padding-bottom: 52px;
            color: white;
            background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/institucional/bg-info-culture.jpg');
            background-size: cover;
            background-position: center;
        }
        .area-info-culture p {
            width: 100%;
            text-align: left;
            margin-top: 40px;
            font-weight: 300;
            font-size: 16px;
            line-height: 26px;
            margin-top: 40px;
        }
        .area-world {
            width: 100%;
            float: left;
            background-color: black;
            padding-bottom: 70px;
            align-items: center;
            background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/institucional/bg-world.png');
            background-size: cover;
            background-position: center;
        }
        .area-text-world {
            width: 100%;
            float: left;
        }
        .area-text-world img {
            width: 38px;
            float: left;
        }
        .area-text-world p {
            width: 100%;
            color: white;
            margin-top: 13px;
            font-size: 15px;
            font-weight: 300;
            line-height: 26px;
        }
        .box-world {
            width: calc(100% - 15px);
            height: 500px;
            background-color: rgb(0, 0, 0);
            /* position: absolute; */
            /* right: 0px; */
            border-radius: 40px 0px 0px 40px;
            background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/institucional/bg-planet.png');
            background-size: cover;
            background-position: left;
            padding: 37px;
            padding-bottom: 110px;
            margin: 18px;
            margin-left: 31px;
        }
        .box-world h1 {
            color: #fff;
            font-size: 48px;
            line-height: 65px;
            margin-top: 22px;
        }
        .box-world .border-title {
            width: 220px;
            height: 20px;
        }
        .bg-blue-01 {
            background-color: #2FCFE9;
        }
        .bg-blue-02 {
            background-color: #C1EFF7;
        }
        .bg-blue-03 {
            background-color: #FFFFFF;
        }
        .area-about {
            position: relative;
        }
        .area-about .container-medium,
        .area-cx .container-large {
            width: calc(100% - 80px);
        }
        .align-bt-more {
            position: absolute;
            bottom: 0px;
        }
        .area-video-manifest {
            width: 100%;
            margin-top: 60px;
            float: left;
            position: sticky;
            top: 0px;
        }
        .thumb-manifest {
            width: 100%;
            float: left;
            height: 100vh;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: black;
            background-size: cover;
            transition: all 0.2s ease;
            cursor: pointer;
            border: 50px solid white;
            transition: all 0.4s linear;
            padding-top: 0px !important;
            overflow: hidden;
        }
        .container-text,
        .area-segments .container-medium,
        .area-info-culture .container-medium {
            width: calc(100% - 80px);
            float: left;
        }
        .video-planet-word {
            width: 100%;
            height: 100%;
            position: sticky;
            top: 0px;
            left: 0px;
        }
        .box-planet {
            width: 100%;
            height: 100vh;
            overflow: hidden;
            top: 0px;
            border-radius: 40px 0px 0px 40px;
            margin-bottom: 30px;
            border-radius: 40px 0px 0px 40px;
            background-image: url('https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/aio/bg-space.jpg');
            background-attachment: fixed;
            background-size: 100% 100%;
            color: white;
            z-index: 9;
            display: flex;
            justify-content: flex-start;
            overflow: hidden;
            border-radius: 0px 0px 0px 0px;
            transition: all 1.0s cubic-bezier(0.25, 0.1, 0.53, 1.0);
        }
        .scroll-planet {
            width: 10px;
            height: 100vh;
            position: absolute;
            top: 0vh;
            right: 0px;
            z-index: 99999;
        }
        .scroll-planet-2 {
            width: 10px;
            height: 100vh;
            position: absolute;
            top: 20vh;
            right: 0px;
            z-index: 99999;
        }
        .scroll-planet-3 {
            width: 10px;
            height: 100vh;
            position: absolute;
            top: 100vh;
            right: 0px;
            z-index: 99999;
        }
        section {
            position: relative;
        }
        .align-word h1 {
            font-size: 45px;
            line-height: 55px
        }
        .align-word {
            width: 100%;
            height: 100vh;
            overflow: hidden;
            position: absolute;
            opacity: 0;
            padding: 45px;
            padding-top: calc(50vh - 72px);
            margin-top: 30px;
        }
        .align-planet {
            width: 100%;
            position: sticky;
            right: 0px;
            top: 91px;
            transition: all 1.0s cubic-bezier(0.25, 0.1, 0.53, 1.0);
            float: left;
        }
        content {
            overflow-y: hidden;
        }
        #border-planet {
            width: 0px;
            height: 23px;
            transition: all 1.0s cubic-bezier(0.25, 0.1, 0.53, 1.0);
            margin-bottom: 20px;
        }
        .box-planet video {
            height: 100%;
            object-fit: cover;
            position: absolute;
            bottom: 0px;
        }
        .bt-view {
            position: absolute;
            width: 403px;
            height: 50px;
            bottom: calc(50vh - 70px);
            left: calc(50vw - 150px);
            filter: brightness(10.0);
        }
        .container-text {
            width: calc(100% - 80px);
            float: left;
            margin-left: 40px;
        }
        .area-head-page .container-medium {
            display: flex;
            flex-direction: column-reverse !important;
        }
        .initial-corporate h2 {
            font-size: 27px;
            margin-bottom: 30px;
        }
    }
`;