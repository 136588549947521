import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

export default function Cookie() {
    const [t] = useTranslation()

    const [showCookie, setShowCookie] = useState(true);
    useEffect(() => {
        if(localStorage.getItem('services_cookie')) {
            setShowCookie(false)
            document.querySelector('body').classList.remove('privacy-active')
        } else {
            setShowCookie(true)
            document.querySelector('body').classList.add('privacy-active')
        }
    }, [])
    function acceptCookie() {
        localStorage.setItem('services_cookie', true);
        setShowCookie(false);
        document.querySelector('body').classList.remove('privacy-active');
    }

    return (
        <>
            {showCookie ?
                <section className="component-privacy">
                    <div className="container-medium">
                        <img loading="lazy" src="https://storage.googleapis.com/gs-multimidia-assets/site-grupo-services/img/components/icon-privacy.svg" />
                        <p className="fix-cookie">{t('cookie.message')} <a className="link-privacy" target="_blank" rel="noreferrer" href="/central-privacidade">{t('cookie.link')}</a></p>
                        <button onClick={acceptCookie} className="bt-primary bt-accept"><p>{t('cookie.button')}</p></button>
                    </div>
                </section>
                : ''
            }
        </>
    )
}