import styled from "styled-components"

export const Container = styled.main`
@media screen and (min-width: 1200px) {
    .post-col-1 {
        width: calc(50% - 5px);
        float: left;
    }
    .post-col-2 {
        width: calc(50% - 5px);
        float: left;
    }
    .initial-post {
        background-color: #2FCFE9 !important;
        height: 426px !important;
        padding-top: 43px;
    }
    .initial-post .bt-link {
        margin-top: 30px;
    }
    .initial-post .bt-link p {
        color: white !important
    }
    .initial-post .bt-link img {
        filter: brightness(5.0);
    }
    .area-post {
        margin-top: 20px
    }
    .post-default p {
        color: black !important;
    }
    .area-post-blog {
        width: 100%;
        float: left;
        padding-top: 60px;
        padding-bottom: 100px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    .left-post {
        width: 929px;
        float: left;
        padding-right: 100px;
    }
    .right-post {
        width: 269px;
        float: left;
    }
    .image-post img {
        width: 100%;
        margin-top: 40px;
    }
    .left-post h3 {
        color: black;
        line-height: normal;
        font-size: 32px;
    }
    .text-post {
        margin-top: 40px
    }
    .area-post h3 {
        margin-top: 50px;
        margin-bottom: 25px;
    }
    .area-insta {
        width: 100%;
        float: left;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 20px;
    }
    .area-insta figure {
        width: 85px;
        height: 85px;
        float: left;
        background-color: gray;
        margin-bottom: 5px;
    }
    .bt-insta {
        width: 100%;
        float: left;
        height: 48px;
        background-color: black;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
    }
    .bt-insta img {
        width: 24px;
        margin-right: 15px;
    }
    .bt-insta p {
        color: white;
        font-size: 14px
    }
    .bt-insta:hover {
        background-color: rgb(32, 32, 32)
    }
    .area-insta figure {
        overflow: hidden;
    }
    .area-insta img {
        width: 100%;
    }
    .date-post {
        width: 100%;
        font-size: 14px;
        color: gray;
        margin-top: 14px;
    }
    .area-recommended {
        margin-top: 60px
    }
    .author-post {
        width: 100%;
        font-size: 16px;
        font-weight: normal;
        color: gray;
        margin-top: 14px;
    }
    .left-post h4 {
        font-size: 20px;
        color: rgb(106 106 106);
    }
}

@media screen and (max-width: 1199px) {
    .post-col-1 {
        width: 100%;
        float: left;
    }
    .post-col-2 {
        width: 100%;
        float: left;
        margin-top: 6px
    }
    .initial-post {
        background-color: #2FCFE9 !important;
        height: 376px !important;
        padding-top: 43px;
    }
    .initial-post .bt-link {
        margin-top: 30px;
    }
    .initial-post .bt-link p {
        color: white !important
    }
    .initial-post .bt-link img {
        filter: brightness(5.0);
    }
    .area-post-blog {
        width: 100%;
        float: left;
        padding-top: 47px;
        padding-bottom: 60px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    .left-post {
        width: 100%;
        float: left;
        /* border: 1px solid orange; */
        margin-bottom: 50px;
    }
    .right-post {
        width: 100%;
        float: left;
        border: 1px solid blue;
        display: none;
    }
    .image-post img {
        width: 100%;
        margin-top: 15px;
    }
    .left-post h3 {
        color: black;
        line-height: 24px;
    }
    .text-post {
        margin-top: 40px
    }
    .area-post h3 {
        margin-top: 50px;
        margin-bottom: 25px;
    }
    .area-insta {
        width: 100%;
        float: left;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 20px;
    }
    .area-insta figure {
        width: 85px;
        height: 85px;
        float: left;
        background-color: gray;
        margin-bottom: 5px;
    }
    .border-title {
        width: 100%
    }
    .bt-insta {
        width: 100%;
        float: left;
        height: 48px;
        background-color: black;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
    }
    .bt-insta img {
        width: 24px;
        margin-right: 15px;
    }
    .bt-insta p {
        color: white;
        font-size: 14px
    }
    .bt-insta:hover {
        background-color: rgb(32, 32, 32)
    }
    .area-insta figure {
        overflow: hidden;
    }
    .area-insta img {
        width: 100%;
    }
    .date-post {
        width: 100%;
        font-size: 10px;
        color: gray;
        font-weight: 600;
        margin-top: 9px;
        float: left;
    }
    .author-post {
        width: 100%;
        font-size: 14px;
        color: gray;
        font-weight: 600;
        margin-top: 9px;
    }
}
`;